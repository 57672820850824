import { IconButton, Tooltip } from "@mui/material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

const DisplaySettingsButton = ({ handleClick }) => {
  return (
    <Tooltip title="Metric preferences" placement="top" arrow>
      <IconButton
        onClick={handleClick}
        variant="outlined"
        size="medium"
        sx={{
          color: "#4770a4",
          backgroundColor: "rgba(62, 124, 168, 0.3)",
          fontFamily: "QualcommNext-Regular",
          alignSelf: "end",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgba(62, 124, 168, 0.3)",
          },
        }}
      >
        <DisplaySettingsIcon />
      </IconButton>
    </Tooltip>
  );
};
export default DisplaySettingsButton;
