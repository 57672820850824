import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const OptionsButton = styled(Button)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-start",
  padding: "0.5em 1em",
  minWidth: "10rem",
  whiteSpace: "nowrap",
  color: "#214572",
  fontFamily: "QualcommNext-Medium",
  fontSize: "clamp(12px, 100%, 100%)",
  "&:hover": {
    // backgroundColor: "#3e7ca8",
    backgroundColor: "#2e5f9b",
    color: "#FFFFFF",
  },
  textTransform: "var(--mui-button-text-transform)",
}));

// const OptionsButton = () => {
//   return (
//     <CustomButton>

//     </CustomButton>
//   )
// }

// export default OptionsButton
