import { Fragment, useState } from "react";
import { toast } from "react-toastify";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import { schema } from "./EditTenantSchema";
import { editTenant } from "../../../api/api-index";
import { EDIT_TENANT_MESSAGES } from "../../../constants/message-constants";
import BasicForm from "../../ui/Form/BasicForm";
import CommonHeader from "../../ui/CommonHeader";
import errorHandler from "../../helpers/error-helper";
import ClientIDAndSecret from "../CreateNewTenant/ClientIDAndSecret";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";

import classes from "./EditTenant.module.css";

const EditTenant = (props) => {
  const { onClose, tenantDetails, getUpdatedDataFn } = props;
  let clicked = true;
  const [openClientIdAndSecret, setOpenClientIdAndSecret] = useState({
    open: false,
    clientId: "",
    client: "",
    secret: "",
  });
  const heading = `Edit ${
    tenantDetails?.tenantType === "CUSTOMER"
      ? "Customer"
      : tenantDetails?.tenantType
  } Tenant`;
  const submitHandler = (data) => {
    const details = { ...tenantDetails };
    delete details.tenantId;
    delete details.isQualcommManagingDevice;

    if (
      data?.manageDevice != null &&
      !!data.manageDevice &&
      tenantDetails?.tenantType === TENANT_TYPE.customer
    ) {
      details.isQualcommManagingDevice = true;
    }
    if (clicked) {
      clicked = false;
      const options = {
        tenantId: tenantDetails?.tenantId,
        details: {
          ...details,
          tenantName: data?.tenantName.trim(),
        },
      };

      editTenant(options)
        .then((response) => {
          if (response.status) {
            toast.success(EDIT_TENANT_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            if (
              response.data.tenantRecord?.isQualcommManagingDevice &&
              response.data?.clientIdSecret != null
            ) {
              setOpenClientIdAndSecret({
                clientId: response.data.clientIdSecret?.clientId.toLowerCase(),
                secret: response.data.clientIdSecret?.clientSecret,
                client: response.data.clientIdSecret.client,
                open: true,
              });
            } else {
              onClose();
            }
          } else {
            console.error(response.error);
            errorHandler(response.error, EDIT_TENANT_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, EDIT_TENANT_MESSAGES.failure);
          clicked = true;
        });
    }
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs:
      tenantDetails?.tenantType === "CUSTOMER"
        ? [
            {
              name: "tenantName",
              label: "Tenant Name",
              placeholder: "Enter tenant name",
              defaultValue: tenantDetails?.tenantName,
            },
            {
              name: "manageDevice",
              label: "Do you want us to manage your devices ?",
              checkbox: true,
              defaultValue: tenantDetails?.isQualcommManagingDevice,
              disabled: true,
            },
          ]
        : [
            {
              name: "tenantName",
              label: "Tenant Name",
              placeholder: "Enter tenant name",
              defaultValue: tenantDetails?.tenantName,
            },
          ],
  };
  return tenantDetails.noBackdrop != null ? (
    <Fragment>
      <CommonHeader heading={heading} onClose={onClose} />
      <BasicForm details={details} />
    </Fragment>
  ) : (
    <BackdropOverlayModal>
      <div className={classes.container}>
        {openClientIdAndSecret.open ? (
          <ClientIDAndSecret
            onClose={onClose}
            details={openClientIdAndSecret}
          />
        ) : (
          <Fragment>
            <CommonHeader heading={heading} onClose={onClose} />
            <BasicForm details={details} />
          </Fragment>
        )}
      </div>
    </BackdropOverlayModal>
  );
};

export default EditTenant;
