import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(() => ({
  color: "#214572",
  backgroundColor: "rgba(62, 124, 168, 0.3)",
  "&:hover": {
    backgroundColor: "#2e5f9b",
    color: "#FFFFFF",
  },
  padding: "0.5em",
  margin: "0",
  minWidth: "0",
}));

const ActiveColorButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#2e5f9b",
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#2e5f9b",
  },
  padding: "0.5em",
  margin: "0",
  minWidth: "0",
}));

const TenantsButton = (props) => {
  const { onClick, active } = props;
  return active ? (
    <ActiveColorButton onClick={onClick != null ? onClick : null}>
      <HomeWorkIcon />
    </ActiveColorButton>
  ) : (
    <ColorButton onClick={onClick != null ? onClick : null}>
      <HomeWorkIcon />
    </ColorButton>
  );
};

export default TenantsButton;
