import { memo, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./styledAccordionComponents";
import { TimeRow } from "./TimeRow";
import { TIME_CONSTANTS } from "../../../constants/time-constants";
import CustomEndpointComponent from "./CustomEndpointComponent";
import CustomTextField from "../../ui/CustomTextField";

import classes from "./CustomAccordion.module.css";

export const CustomQseaAccordion = memo(function CustomQseaAccordion({
  qseaMetrics,
  setQseaMetrics,
  qseaEndpoint,
  setQseaEndpoint,
  editDisabled,
}) {
  const isChecked =
    qseaMetrics.filter((i) => i.checked === true).length > 1 ? true : false;
  const isAllChecked =
    qseaMetrics.filter((i) => i.checked === false).length > 0 ? false : true;

  const [checkAll, setCheckAll] = useState(false);
  const [qseaMetricsExpanded, setQseaMetricsExpanded] = useState(true);
  const [bulkSelection, setBulkSelection] = useState({
    reportingRate: "2h",
  });
  const [enabled, setEnabled] = useState(editDisabled || false);
  const handleEnableChange = (event) => {
    setEnabled(event.target.checked);
    let newFilteredList = qseaMetrics.map((item) => {
      return {
        ...item,
        checked: false,
        reportingRate: "2h",
      };
    });
    setQseaMetrics(newFilteredList);
    setBulkSelection({ reportingRate: "2h" });
  };
  const handleQseaRadioChange = (event) => {
    setQseaEndpoint({ ...qseaEndpoint, value: event.target.value, url: "" });
  };
  const handleQseaUrlChange = (event) => {
    setQseaEndpoint({ ...qseaEndpoint, url: event.target.value });
  };

  const handleChange = () => {
    setQseaMetricsExpanded((prev) => !prev);
  };
  const handleBulkTime = (event) => {
    setBulkSelection((prev) => {
      return {
        ...prev,
        reportingRate: event.target.value,
      };
    });
  };

  const handleBulk = () => {
    let newFilteredList = qseaMetrics.map((item) => {
      if (item.checked) {
        return {
          ...item,
          reportingRate: bulkSelection.reportingRate,
        };
      } else {
        return item;
      }
    });
    setQseaMetrics(newFilteredList);
  };
  const handleCheckbox = (name) => {
    const newList = qseaMetrics.map((item) => {
      if (item.metricName === name) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return item;
      }
    });
    setQseaMetrics(newList);
  };
  const handleTime = (name, value) => {
    const newList = qseaMetrics.map((item) => {
      if (item.metricName === name) {
        return {
          ...item,
          reportingRate: value,
        };
      } else {
        return item;
      }
    });
    setQseaMetrics(newList);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newList = qseaMetrics.map((item) => {
        if (item.checked) {
          return item;
        } else {
          return {
            ...item,
            checked: true,
          };
        }
      });
      setQseaMetrics(newList);
    } else {
      const newList = qseaMetrics.map((item) => {
        if (!item.checked) {
          return item;
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });
      setQseaMetrics(newList);
    }
  };
  useEffect(() => {
    isAllChecked ? setCheckAll(true) : setCheckAll(false);
  }, [isAllChecked]);
  return (
    <StyledAccordion expanded={qseaMetricsExpanded}>
      <StyledAccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        expandIcon={
          qseaMetricsExpanded ? (
            <IndeterminateCheckBoxRoundedIcon
              fontSize="large"
              htmlColor="#4D6994"
              onClick={() => handleChange()}
            />
          ) : (
            <AddBoxRoundedIcon
              fontSize="large"
              htmlColor="#4D6994"
              onClick={() => handleChange()}
            />
          )
        }
      >
        <div className={classes.header}>
          <div className={classes.selectAll}>
            <FormControlLabel
              disableTypography
              control={
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#4770a4",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#4770a4",
                    },
                  }}
                  checked={enabled}
                  onChange={handleEnableChange}
                />
              }
              label="QSEA Platform Metrics"
            />
            {qseaMetricsExpanded && (
              <FormControlLabel
                disableTypography
                disabled={!qseaMetricsExpanded || !enabled}
                control={
                  <Checkbox
                    checked={checkAll}
                    onClick={(e) => handleSelectAll(e)}
                  />
                }
                label="Select All"
              />
            )}
          </div>
          {qseaMetricsExpanded && (
            <div className={classes.time}>
              <CustomTextField
                disabled={!isChecked}
                sx={{ width: "8em" }}
                size="small"
                label="Reporting rate"
                focused
                select
                value={bulkSelection.reportingRate}
                onChange={(e) => handleBulkTime(e)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  },
                }}
              >
                {["5m", ...TIME_CONSTANTS["hrs"]].map((item, key) => (
                  <MenuItem
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    key={key}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </CustomTextField>

              <Button
                disabled={!isChecked}
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "Capitalize",
                  fontFamily: "QualcommNext-Regular",
                  backgroundColor: "#4D6994",
                }}
                onClick={handleBulk}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {qseaMetrics.map((item) => {
          return (
            <Stack
              key={item.metricName}
              direction={"row"}
              alignItems="center"
              sx={{ mt: 1.2, mb: 1.2 }}
            >
              <FormControlLabel
                sx={{
                  fontSize: "0.9rem",
                  width: "22ch",
                  color: "#777777",
                }}
                disableTypography
                control={
                  <Checkbox
                    disabled={!enabled}
                    onChange={() => handleCheckbox(item.metricName)}
                    checked={item.checked}
                    size="small"
                  />
                }
                label={item.displayName}
              />
              <TimeRow item={item} handleTime={handleTime} />
            </Stack>
          );
        })}
      </StyledAccordionDetails>
      {qseaMetrics.filter((item) => item.checked === true).length > 0 && (
        <div className={classes.endpoint}>
          <CustomEndpointComponent
            endpoint={qseaEndpoint}
            handleRadioChange={handleQseaRadioChange}
            handleUrlChange={handleQseaUrlChange}
            disabled={editDisabled}
            categoryEnabled={enabled}
          />
        </div>
      )}
    </StyledAccordion>
  );
});
