import axios from "axios";
import { editArchiveNotificationsApi } from "./apiEndpoints";

export const editArchiveNotifications = async (payload) => {
  let data;
  await axios
    .patch(editArchiveNotificationsApi, payload)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
