import IconButton from "@mui/material/IconButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const SettingsIcon = (props) => {
  const { onClick } = props;
  return (
    <IconButton aria-label="cancel" size="large" onClick={onClick != null ? onClick : null}>
      <SettingsOutlinedIcon />
    </IconButton>
  );
};

export default SettingsIcon;
