import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import {
  getDeviceLicensesStat,
  searchFeatureName,
  searchByFeatureTitle,
  searchTenantName,
  getOrgByName,
} from "../../../api/api-index";
import { PAGE_SIZE, PAGE_NO } from "../../../constants/page-constants";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import { CSS_ROUTES } from "../../../constants/route-constants";
import { deviceLicensesHeaders } from "../../helpers/header-helpers";
import DataTableHeaders from "../../header/DataTableHeaders";
import Filter from "../../ui/Form/Filter/Filter";
import { BUTTONS_TYPE } from "../../../constants/panel-constants";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import {
  columnsActions,
  devicesLicensesActions,
} from "../../../store/slices/slices-index";
import { INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE } from "../../../constants/form-states";
import { ExpiryDateFormatter } from "../../helpers/utils";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../hooks/useBreadcrumb";

import classes from "../../ui/DataTable.module.css";

const TenantDeviceLicenses = () => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("dashboardInfo");
  const initialState = useSelector((state) => state.columnsInfo.dashboard);
  const [searchStatefn] = useSearchKeys(
    ["feature", "orgName", "tenant", "mappingCount"],
    initialState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantInputValue, setTenantInputValue] = useState(null);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [featureInputValue, setFeatureInputValue] = useState(null);
  const [searchOrganizationValue, setOrganizationValue] = useState(null);
  const [OrgOptions, setOrgOptions] = useState([]);
  const [deviceLicensesList, setDeviceLicensesList] = useState({
    count: 0,
    list: [],
  });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [options, setOptions] = useState({
    pageNo: 0,
    pageSize: PAGE_SIZE,
  });

  const handlePageChange = (_, newPage) => {
    setOptions({ ...options, pageNo: newPage - 1 });
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleClear = () => {
    if (isDirty) {
      setOptions({ pageNo: 0, pageSize: PAGE_SIZE });
      reset(INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE);
      setOpenFilter(false);
    }
  };

  const navigate = useNavigate();
  const orgWatch = watch("orgName");
  const handleRowClick = (params) => {
    // dispatch(devicesLicensesActions.setSelectedFeature(params.row));
    navigate(
      CSS_ROUTES.tenantDeviceLicenses +
        "/" +
        params.row.featureName +
        "/" +
        params.row.tenantId
    );
  };
  const columns = [
    {
      field: "feature",
      headerName: "Feature Title",
      minWidth: 250,
      flex: 1,
      hideable: false,
    },
    {
      field: "orgName",
      headerName: "Organization",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "tenant",
      headerName: "Tenant Name",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "mappingCount",
      headerName: "No of devices",
      minWidth: 100,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (searchOrganizationValue != null && searchOrganizationValue !== "") {
      let option = {
        name: searchOrganizationValue,
        pageNo: PAGE_NO,
        pageSize: PAGE_SIZE,
      };
      getOrgByName(option).then((response) => {
        if (response?.status) {
          const newOrgValues = response.data.organizations.map((name) => {
            return { label: name.name, id: name.organizationId };
          });
          setOrgOptions(newOrgValues);
        } else {
          setOrgOptions([]);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      });
    } else {
      setOrgOptions([]);
    }
  }, [searchOrganizationValue]);

  useEffect(() => {
    if (tenantInputValue != null && tenantInputValue !== "") {
      // Api call
      const payload = {
        tenantName: tenantInputValue,
        organizationId: orgWatch.id,
        pageNo: 0,
        pageSize: 1000,
      };
      searchTenantName(payload).then((response) => {
        if (response?.status && response?.data?.tenants.length > 0) {
          const formattedTenantName = response?.data.tenants.map((item) => ({
            label: item.tenantName,
            value: item.tenantId,
          }));
          setTenantOptions(formattedTenantName);
        } else {
          setTenantOptions([]);
        }
      });
    } else {
      setTenantOptions([]);
    }
  }, [tenantInputValue]);

  useEffect(() => {
    if (
      featureInputValue != null &&
      featureInputValue !== "" &&
      featureInputValue !== 0
    ) {
      // Api call
      searchByFeatureTitle(featureInputValue).then((response) => {
        if (response.status && response.data != null) {
          const formattedFeatureTitle = response.data.map((title) => ({
            label: title.featureTitle,
            id: title.qualcommFeatureId,
          }));
          setFeatureOptions(formattedFeatureTitle);
        } else {
          setFeatureOptions([]);
        }
      });
    } else {
      setFeatureOptions([]);
    }
  }, [featureInputValue]);

  useEffect(() => {
    getDeviceLicensesStat(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.dashboardList != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.dashboardList
              ?.map((item, key) => {
                return {
                  id: uuidv4() || "N/A",
                  orgName: item.organization || "N/A",
                  tenant: item.tenantName || "N/A",
                  feature: item.featureTitle || "N/A",
                  subscriptionId: item.subscriptionId || "N/A",
                  deviceId: item.deviceId || "N/A",
                  installationDate:
                    ExpiryDateFormatter(item.firstDownloadTime) || "N/A",
                  // licenseMapping: item?.deviceFeatureMappingDetailsRecord,
                  mappingCount: item?.mappingCount || "N/A",
                  featureName: item?.qualcommFeatureName,
                  tenantId: item?.tenantId,
                };
              })
              .sort((a, b) => {
                const nameA = a.feature.toUpperCase();
                const nameB = b.feature.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              }) || [];
          setDeviceLicensesList({
            count: response.data.count,
            list: formattedList,
          });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [options]);

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const submitHandler = (data) => {
    console.log("Filter DATA Submitted.", data);
    setOptions({
      pageNo: 0,
      pageSize: PAGE_SIZE,
      organizationId: data.orgName?.id,
      tenantId: data.tenant?.value || undefined,
      qualcommFeatureId: data.feature.id || undefined,
    });

    handleOpenFilter();
  };

  const buttons = [
    {
      key: "filter",
      text: <FilterListSharpIcon />,
      clickHandler: handleOpenFilter,
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const headers = deviceLicensesHeaders({
    routes: {
      devicesAndLicenses: CSS_ROUTES.tenantDeviceLicenses,
    },
  });

  const filterableitems = [
    {
      apiCall: true,
      name: "orgName",
      options: OrgOptions,
      setInputChange: setOrganizationValue,
      label: "Organization Name",
      placeholder: "Enter organization name",
      defaultValue: INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.orgName,
    },
    {
      apiCall: true,
      name: "tenant",
      options: tenantOptions,
      setInputChange: setTenantInputValue,
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.tenant,
    },
    {
      apiCall: true,
      name: "feature",
      options: featureOptions,
      setInputChange: setFeatureInputValue,
      label: "Feature Title",
      placeholder: "Enter feature title",
      defaultValue: INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.feature,
    },
  ];
  // const nonFilterableItems = [
  //   {
  //     name: "subscriptionId",
  //     label: "Subscription ID",
  //     placeholder: "Enter subscription id",
  //     defaultValue: INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.subscriptionId,
  //   },
  //   {
  //     name: "deviceId",
  //     label: "Device ID",
  //     placeholder: "Enter device id",
  //     defaultValue: INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.deviceId,
  //   },
  //   {
  //     name: "installationStartDate",
  //     label: "Installation Start Date",
  //     type: "date",
  //     startDate: true,
  //     defaultValue:
  //       INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.installationStartDate,
  //   },
  //   {
  //     name: "installationEndDate",
  //     label: "Installation End Date",
  //     type: "date",
  //     endDate: true,
  //     defaultValue:
  //       INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE.installationEndDate,
  //   },
  // ];
  const filteredItems =
    searchStatefn.length != 4
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  return (
    <div className={classes.container}>
      {openFilter && (
        <Filter
          items={filteredItems}
          onClose={handleCloseFilter}
          onSubmit={submitHandler}
          handleSubmit={handleSubmit}
          control={control}
          isDirty={isDirty}
          onClear={handleClear}
          watch={watch}
        />
      )}
      <DataTablePanelTest
        backButton={true}
        disableSearch={true}
        buttons={buttons}
      />
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>Device & Licenses</p>
      </StyledBreadcrumbs>
      <div className={classes.data}>
        {/* <DataTableHeaders headers={headers} /> */}
        <CustomDataGrid
          sx={{
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "0.7rem",
              wordBreak: "break-all",
            },
          }}
          getRowHeight={() => "auto"}
          columns={columns}
          rows={filteredList || []}
          onRowClick={(params) => handleRowClick(params)}
          disableColumnFilter
          hideFooterSelectedRowCount
          scrollbarSize={17}
          columnVisibilityModel={initialState}
          onColumnVisibilityModelChange={(newModel) =>
            dispatch(columnsActions.setdashboardColumnsState(newModel))
          }
          getCellClassName={(params) => {
            if (params.field === "id") {
              return "id";
            }
            return "general";
          }}
          components={{
            Pagination: CustomPagination,
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowMessage,
          }}
          componentsProps={{
            pagination: {
              total:
                deviceLicensesList.list.length === filteredList.length
                  ? deviceLicensesList.count
                  : filteredList.length || 0,
              handlePageChange: handlePageChange,
              page: options.pageNo,
            },
            toolbar: {
              reference: setColumnButtonEl,
              children: <DataTableHeaders headers={headers} />,
            },
            panel: {
              anchorEl: columnButtonEl,
              sx: panelStyles,
            },
            columnMenu: {
              sx: columnMenuStyles,
            },
            noRowsOverlay: {
              isLoading,
              text: NO_ROW_MESSAGES.deviceAndLicenses,
            },
          }}
        />
      </div>
    </div>
  );
};

export default TenantDeviceLicenses;
