import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

import CreateEditSearch from "./CreateEditSearch";
import backButtonIcon from "../../assets/images/common/back-button.svg";

import classes from "./DataTablePanel.module.css";

const DataTablePanel = (props) => {
  const {
    name,
    list,
    setFilteredList,
    filterKeys,
    selectedUsers,
    backButton,
    simpleButton,
    editButton,
    clickHandler,
    policyTermsClickHandler,
    addUsersClickHandler,
    disableSearch,
  } = props;

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      {backButton ? (
        <IconButton onClick={handleBackButtonClick}>
          <img src={backButtonIcon} alt="Previous page" />
        </IconButton>
      ) : (
        <div></div>
      )}
      <CreateEditSearch
        name={name}
        list={list}
        setFilteredList={setFilteredList}
        filterKeys={filterKeys}
        selectedUsers={selectedUsers}
        simpleButton={simpleButton}
        editButton={editButton}
        clickHandler={clickHandler}
        policyTermsClickHandler={policyTermsClickHandler}
        addUsersClickHandler={addUsersClickHandler}
        disableSearch={disableSearch}
      />
    </div>
  );
};

export default DataTablePanel;
