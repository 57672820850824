import * as yup from "yup";
const errorMessages = {
  tenantName: {
    required: "Tenant name is required",
    matches: "Tenant name must start and end with alphanumeric character",
  },
};
export const schema = yup
  .object({
    tenantName: yup
      .string()
      .matches(
        /^[a-zA-Z0-9][a-zA-Z0-9 _,*-]*[a-zA-Z0-9]$/gm,
        errorMessages.tenantName.matches
      )
      .required(errorMessages.tenantName.required),
  })
  .required();
