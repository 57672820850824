import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CircularProgress, Menu } from "@mui/material";

import FullScreenIcon from "../../ui/Buttons/FullScreenIcon";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import DataTableHeaders from "../../header/DataTableHeaders";
import { deviceInfoHeaders } from "../../helpers/header-helpers";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import LightTooltip from "../../ui/Tooltip/LightToolTip";
import CustomIframe from "../../ui/customIframe/CustomIframe";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../../api/iframeEndpoints";
import { getAllMetrics, getMetricsBasedonDevice } from "../../../api/api-index";
import { GET_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { METRIC_CATEGORY } from "../../../constants/metrics-constants";
import DeviceMetricPopup from "../../ui/deviceMetricPopup/DeviceMetricPopup";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import { metricsActions } from "../../../store/slices/slices-index";
import MetricPreferences from "../metricPreferences/MetricPreferences";
import DisplaySettingsButton from "../../ui/Buttons/DisplaySettingsButton";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useTimeStatus from "../../../hooks/useTimeStatus";

import classes from "../../ui/DataTable.module.css";
import styles from "./DeviceMetrics.module.css";

let selectedItem = null;
const DeviceMetrics = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("deviceInfo");
  const params = useParams();

  const [dateRange, handleTime] = useTimeStatus();

  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const selectedMetrics = useSelector(
    (state) => state.metricsInfo.deviceHealthMetrics
  );

  const selectedDevice = useSelector(
    (state) => state.customerDeviceInfo.selectedDevice
  );

  const unSelectedIds =
    selectedMetrics.hasOwnProperty(params.deviceId) &&
    selectedMetrics[params.deviceId]
      .filter((item) => item.selected === false)
      .map((item) => item.metricId);

  const [zoom, setzoom] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDetails = (item) => {
    selectedItem = item;
    setzoom(true);
  };
  const handleCloseDetails = () => {
    selectedItem = null;
    setzoom(false);
  };

  useEffect(() => {
    if (isQualcommManagingDevice) {
      const options = {
        deviceId: params.deviceId,
      };
      getMetricsBasedonDevice(options)
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.metricsCategory != null
          ) {
            const formattedList = response.data.metricsCategory.filter(
              (item) => item.metricCategory === METRIC_CATEGORY.deviceMetrics
            );

            const devId = params.deviceId;

            if (selectedMetrics.hasOwnProperty(devId)) {
              formattedList.length > 0
                ? dispatch(
                    metricsActions.setDeviceMetricstate({
                      [devId]: formattedList[0].mddMetricsCategory.map(
                        (item) => {
                          return {
                            ...item,
                            mainAndDetailView: Number(item.metricId),
                            selected:
                              unSelectedIds.length > 0
                                ? unSelectedIds.includes(item.metricId)
                                  ? false
                                  : true
                                : true,
                            currentVersion: response?.data?.currentVersion,
                          };
                        }
                      ),
                    })
                  )
                : dispatch(
                    metricsActions.setDeviceMetricstate({
                      [devId]: [],
                    })
                  );
            } else {
              formattedList.length > 0 &&
                dispatch(
                  metricsActions.setDeviceMetricstate({
                    [devId]: formattedList[0].mddMetricsCategory.map((item) => {
                      return {
                        ...item,
                        mainAndDetailView: Number(item.metricId),
                        selected: true,
                        currentVersion: response?.data?.currentVersion,
                      };
                    }),
                  })
                );
            }
          } else {
            console.log(response);
            // errorHandler(response.error, GET_MESSAGES.failure);
            //error handling function
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          // errorHandler(error, GET_MESSAGES.failure);
        })
        .finally(() => setIsLoading(false));
    } else {
      getAllMetrics({ source: "Device" })
        .then((response) => {
          if (response.status && response.data != null) {
            const formattedList = response.data.Device_Health_Metrics;
            const devId = params.deviceId;

            if (selectedMetrics.hasOwnProperty(devId)) {
              formattedList.length > 0
                ? dispatch(
                    metricsActions.setDeviceMetricstate({
                      [devId]: formattedList.map((item) => {
                        return {
                          ...item,
                          mainAndDetailView: Number(item.metricId),
                          selected:
                            unSelectedIds.length > 0
                              ? unSelectedIds.includes(item.metricId)
                                ? false
                                : true
                              : true,
                        };
                      }),
                    })
                  )
                : dispatch(
                    metricsActions.setDeviceMetricstate({
                      [devId]: [],
                    })
                  );
            } else {
              formattedList.length > 0 &&
                dispatch(
                  metricsActions.setDeviceMetricstate({
                    [devId]: formattedList.map((item) => {
                      return {
                        ...item,
                        mainAndDetailView: Number(item.metricId),
                        selected: true,
                      };
                    }),
                  })
                );
            }
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const headers = deviceInfoHeaders({
    routes: {
      deviceInfo: isQualcommManagingDevice
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.devices +
          "/" +
          params?.deviceId
        : null,
      health:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        params?.deviceId +
        "/health-metrics",
      qsea:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        params?.deviceId +
        "/qsea-metrics",
    },
  });
  const metricsList =
    selectedMetrics.hasOwnProperty(params.deviceId) &&
    selectedMetrics[params.deviceId].filter((item) =>
      isQualcommManagingDevice
        ? item.selected != false && item.currentVersion != null
        : item.selected != false
    );

  return (
    <div className={classes.container}>
      {zoom && (
        <DeviceMetricPopup
          selectedItem={selectedItem}
          heading="Metric Details"
          onClose={handleCloseDetails}
          view={DASHBOARD_NAME.deviceMetricsHistoryView}
          dashboardId={"cbRVPjF4z"}
          deviceId={selectedDevice?.qualcommDeviceId}
        />
      )}
      <DataTablePanelTest disableSearch={true} backButton={true} />

      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{params?.deviceId}</p>
      </StyledBreadcrumbs>

      <div className={classes.data}>
        <DataTableHeaders headers={headers} />

        {!isLoading ? (
          metricsList?.length > 0 ? (
            <div className={styles.panel}>
              <div className={styles.sub_header}>
                <span className={styles.disclaimer}>
                  * <i>All the data is from last 24 hrs</i>
                </span>
                <DisplaySettingsButton handleClick={handleClick} />
              </div>
              <Menu
                id="metrics-center"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-settings",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  elevation: 6,
                }}
                sx={{
                  ".MuiList-root": {
                    padding: 0,
                    minWidth: 200,
                  },
                }}
              >
                <MetricPreferences
                  selectedMetrics={selectedMetrics}
                  handleClose={handleClose}
                  type="deviceMetrics"
                  deviceId={params.deviceId}
                />
              </Menu>
              <div className={styles.info}>
                {metricsList.map((item) => (
                  <div
                    key={item.mainAndDetailView}
                    className={styles.iframeGraph}
                  >
                    <div className={styles.invisibleOverlay}>
                      <div className={styles.header}>
                        <span>{item.displayName}</span>
                        <LightTooltip
                          title="Expand for more details"
                          placement="top"
                          arrow
                        >
                          <div>
                            <FullScreenIcon
                              onClick={() => handleOpenDetails(item)}
                            />
                          </div>
                        </LightTooltip>
                      </div>
                    </div>
                    <div className={styles.lastReported}>
                      <p>Last Reported :</p>
                      <CustomIframe
                        height="80px"
                        src={`${IFRAME_BASE_URL.baseUrl}/YA4Z3p24z/${DASHBOARD_NAME.deviceMetricsLastReportedView}?orgId=1&var-deviceId=${selectedDevice?.qualcommDeviceId}&from=${dateRange.from}&to=${dateRange.to}&panelId=${item.mainAndDetailView}`}
                      />
                    </div>

                    <div className={styles.invisibleOverlay1}></div>
                    <CustomIframe
                      src={`${IFRAME_BASE_URL.baseUrl}/We8RdvF4k/${DASHBOARD_NAME.deviceMetricsMainView}?orgId=1&var-deviceId=${selectedDevice?.qualcommDeviceId}&from=${dateRange.from}&to=${dateRange.to}&panelId=${item.mainAndDetailView}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.noData}>{NO_ROW_MESSAGES.metrics}</div>
          )
        ) : (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};
export default DeviceMetrics;
