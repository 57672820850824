import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import zyter from "../../assets/images/menu/zyter.svg";
import MenuList from "./MenuList";

import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import classes from "./MenuBar.module.css";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const MenuBar = () => {
  const matchesMD = useMediaQuery("(min-width:1400px)", { noSsr: true });
  const [open, setOpen] = useState(true);

  const handleOpenClose = () => {
    setOpen(!open);
  };
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );

  useLayoutEffect(() => {
    setOpen(matchesMD);
  }, [matchesMD]);
  return (
    <Fragment>
      {selectedAppType != null && (
        <div
          className={
            open
              ? classes.container
              : `${classes.container} ${classes["closed-container"]}`
          }
          // className={classes.container}
        >
          <div className={classes.toggle} onClick={handleOpenClose}>
            {open && <AiOutlineMenuFold />}
            {!open && <AiOutlineMenuUnfold />}
          </div>
          <MenuList opened={open} />
          <div className={classes.logo}>
            <span>Powered by</span>
            <img src={zyter} alt="powered by zyter" />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MenuBar;
