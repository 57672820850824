import styled from "@emotion/styled";
import { Popper } from "@mui/material";

export const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-noOptions": {
    fontFamily: "QualcommNext-Regular",
  },
  "& .MuiAutocomplete-listbox": {
    fontFamily: "QualcommNext-Regular",
  },
});
