import { Fragment, useEffect, useState } from "react";
import { Chip } from "@mui/material";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import CustomPagination from "../../../ui/CustomPagination";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import { NO_ROW_MESSAGES } from "../../../../constants/no-row-message-constants";

import classes from "../../../ui/DataTable.module.css";
import styles from "./FeatureContentTable.module.css";

const FeatureContentTable = ({ tableHeader, data, isLoading }) => {
  const columns = [
    {
      field: "containerName",
      headerName: "Name",
      minWidth: 200,
      flex: 4,
    },
    {
      field: "containerVersion",
      headerName: "Latest Version",
      minWidth: 200,
      flex: 4,
    },
    {
      field: "containerSize",
      headerName: "Size",
      minWidth: 200,
      flex: 4,
      renderCell: (params) => {
        return `${params?.row?.containerSize} ${params?.row?.unit}`;
      },
    },
  ];

  const output = {
    Containers: NO_ROW_MESSAGES.containers,
    Models: NO_ROW_MESSAGES.models,
    Template: NO_ROW_MESSAGES.template,
    Plugins: NO_ROW_MESSAGES.plugins,
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={styles.header}>{tableHeader}</span>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              height: "30vh",
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            hideFooterPagination
            columns={columns}
            rows={data || []}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              //   pagination: {
              //     total: featureDataList.count || 0,
              //     handlePageChange: handlePageChange,
              //     page: pageNo,
              //   },

              panel: {
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: {
                isLoading,
                text: output[tableHeader],
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FeatureContentTable;
