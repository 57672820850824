import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Card, CardActionArea, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";

import { editNotification } from "../../../api/api-index";
import useNotificationTrigger from "../../../hooks/useNotificationTrigger";
import { DateFormatter } from "../../helpers/utils";

import styles from "./NotificationContainer.module.css";

const ExpandMore = styled((props) => {
  const { expand, readStatus, ...other } = props;
  return (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        backgroundColor: !readStatus ? "#6495ed" : "#bdbdbd",
      }}
      {...other}
    ></Avatar>
  );
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  cursor: "pointer",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function NotificationContainer({ item, setForceUpdate, handleClose }) {
  const { triggerCheck } = useNotificationTrigger();
  const cardStyles = {
    mt: 1,
    border: "1px solid",
    backgroundColor: !item?.isRead ? "#E3F3F8" : "#F5F6F8",
    borderColor: !item?.isRead ? "#c1d9ff" : "#DEDFDF",
    color: "#777777",
    boxShadow: "none",
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
    if (!item.isRead) {
      editNotification([{ ...item, isRead: true }]).then(() =>
        setForceUpdate({})
      );
    }
  };

  return (
    <Card sx={cardStyles}>
      <CardActionArea
        sx={{ cursor: item.notificationType === "EVENT" && "text" }}
        disableRipple
        onClick={
          item.notificationType === "ALERT"
            ? (e) => {
                triggerCheck(
                  item.notificationName,
                  item.organizationId,
                  item.tenantId
                );
                handleClose();
              }
            : null
        }
      >
        <div className={styles.main}>
          <div className={styles.content_container}>
            <div>
              <Collapse in={expanded} collapsedSize={16} timeout="auto">
                {expanded
                  ? item.notificationDesc
                  : item.notificationDesc.trimEllip(32)}
              </Collapse>
            </div>
            <ExpandMore
              expand={expanded}
              onClick={(e) => handleExpandClick(e)}
              aria-expanded={expanded}
              aria-label="show more"
              readStatus={item.isRead}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <div className={styles.more_content}>
            <i>
              {item.notificationType === "ALERT" && item.handledBy != null && (
                <span> Handled by - {item.handledBy}</span>
              )}
            </i>
            <div>{DateFormatter(item.createdOn)}</div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}
export default NotificationContainer;
