import React from "react";
import {
  Chip,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function SpeedDialButton(props) {
  const {
    showAction,
    isDisable = false,
    title,
    handler = null,
    denied = null,
  } = props;

  function renderChip(title) {
    const chipStyles = {
      fontFamily: "QualcommNext-Regular",
    };

    return (
      <Chip
        label={title}
        color="primary"
        variant="filled"
        sx={chipStyles}
        disabled={isDisable ? true : false}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1em",
      }}
    >
      {renderChip(title)}
      {isDisable ? (
        <IconButton
          disabled={isDisable}
          onClick={() => handler()}
          size="medium"
        >
          <CheckCircleIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <Tooltip title="Approve">
          <IconButton onClick={() => handler()} size="medium">
            <CheckCircleIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}

      {title !== "Add to Global Repository" &&
        (isDisable ? (
          <IconButton
            size="medium"
            disabled={isDisable}
            onClick={() => denied()}
          >
            <CancelIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <Tooltip title="Deny">
            <IconButton size="medium" onClick={() => denied()}>
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ))}
      {/* <SpeedDial
        disabled={isDisable}
        sx={{
          ".MuiFab-root": {
            boxShadow: "none",
            textTransform: "Capitalize",
            fontFamily: "QualcommNext-Regular",
            fontSize: "0.8rem",
            // background: isDisable && "grey",
          },
          ".MuiSpeedDial-actions": {
            paddingLeft: "35px",
          },
          ".MuiFab-extended": {
            cursor: "text",
          },
        }}
        open={showAction}
        ariaLabel="SpeedDial"
        icon={title}
        direction="right"
        FabProps={{
          disabled: isDisable,
          variant: "extended",
          size: "small",
          disableRipple: true,
          color: "primary",
        }}
      >
        <SpeedDialAction
          disabled={isDisable}
          icon={<CheckCircleIcon />}
          tooltipTitle="Approve"
          onClick={() => handler()}
        />

        {title !== "Add to Global Repository" && (
          <SpeedDialAction
            disabled={isDisable}
            icon={<CancelIcon />}
            tooltipTitle="Deny"
            onClick={() => denied()}
          />
        )}
      </SpeedDial> */}
    </div>
  );
}

export default SpeedDialButton;
