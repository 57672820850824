import { createSlice } from "@reduxjs/toolkit";

const initialMetricsState = {
  deviceHealthMetrics: {},
  qseaPlatformMetrics: {},
};

const metricsSlice = createSlice({
  name: "device based metrics",
  initialState: initialMetricsState,
  reducers: {
    setDeviceMetricstate(state, action) {
      state.deviceHealthMetrics = {
        ...state.deviceHealthMetrics,
        ...action.payload,
      };
    },
    setQseaPlatformsstate(state, action) {
      state.qseaPlatformMetrics = {
        ...state.qseaPlatformMetrics,
        ...action.payload,
      };
    },
  },
});
const metricsActions = metricsSlice.actions;
export { metricsSlice, initialMetricsState, metricsActions };
