import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

import DataTableHeaders from "../../header/DataTableHeaders";
import { MDDInfoHeaders } from "../../helpers/header-helpers";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import MDDfeatureEnabledList from "./MDDfeatureEnabledList";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { getMddInfoById, getMddVersions } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import { CustomSelectField } from "../../ui/CustomSelectField";
import { METRIC_CATEGORY } from "../../../constants/metrics-constants";
import { StyledLink } from "../../ui/StyledLink";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import { ROLES } from "../../../constants/role-constants";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";

import classes from "./MDDInfo.module.css";

const MDDInfo = () => {
  const params = useParams();
  const [breadCrumbArray] = useBreadcrumb("mddInfo");

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const [mddVersion, setMddVersion] = useState("");
  const [allmddVersion, setAllMddVersion] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mddDetails, setMddDetails] = useState({
    mddName: "",
    qseaPLatformMetrics: [],
    deviceHealthMetrics: [],
  });

  const [prevMddDetails, setPrevMddDetails] = useState({
    mddName: "",
    qseaPLatformMetrics: [],
    deviceHealthMetrics: [],
  });

  function fetchLatestMddInfo() {
    const options = {
      id: params.mddId,
      version: allmddVersion && allmddVersion[0],
    };
    getMddInfoById(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const healthMetric = [];
          const qseaMetric = [];
          response?.data?.metricsCategory &&
            response?.data?.metricsCategory.map((item) => {
              if (item.metricCategory === METRIC_CATEGORY.qseaMetrics) {
                item.mddMetricsCategory.map((metric) => {
                  qseaMetric.push({ ...metric, id: metric.metricName });
                });
              } else {
                item.mddMetricsCategory.map((metric) => {
                  healthMetric.push({ ...metric, id: metric.metricName });
                });
              }
            });
          const formattedData = {
            mddName: response.data.mddName,
            deviceHealthMetrics: healthMetric,
            qseaPLatformMetrics: qseaMetric,
          };
          setMddDetails(formattedData);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  function fetchMddInfo() {
    setIsLoading(true);
    const options = {
      id: params.mddId,
      version: mddVersion,
    };
    getMddInfoById(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const healthMetric = [];
          const qseaMetric = [];
          response?.data?.metricsCategory &&
            response?.data?.metricsCategory.map((item) => {
              if (item.metricCategory === METRIC_CATEGORY.qseaMetrics) {
                item.mddMetricsCategory.map((metric) => {
                  qseaMetric.push({ ...metric, id: metric.metricName });
                });
              } else {
                item.mddMetricsCategory.map((metric) => {
                  healthMetric.push({ ...metric, id: metric.metricName });
                });
              }
            });

          const formattedData = {
            mddName: response.data.mddName,
            deviceHealthMetrics: healthMetric,
            qseaPLatformMetrics: qseaMetric,
          };
          setPrevMddDetails(formattedData);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  function fetchMddVersions() {
    const options = {
      id: params.mddId,
    };
    getMddVersions(options).then((response) => {
      if (response.status && response.data != null) {
        setAllMddVersion(response.data);
        let getPrevVersion =
          response.data.length > 0 ? response.data[1] : response.data[0];
        setMddVersion(getPrevVersion);
      }
    });
  }

  const headers = MDDInfoHeaders({
    routes: {
      mdd:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        params?.mddId,
      associatedDevices: isQualcommManagingDevice
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd +
          "/" +
          params?.mddId +
          "/associated-devices"
        : null,
    },
  });

  const heading =
    mddDetails != null && mddDetails?.mddName
      ? `${selectedTenant?.tenantName} - Metrics Definition - ${mddDetails?.mddName}`
      : `${selectedTenant?.tenantName} - Metrics Definition - N/A`;

  useEffect(() => {
    if (mddVersion) {
      fetchMddInfo();
    }
  }, [mddVersion]);

  useEffect(() => {
    fetchMddVersions();
  }, []);

  useEffect(() => {
    allmddVersion.length > 0 && fetchLatestMddInfo();
  }, [allmddVersion]);

  const removelatestVersion = () => {
    let updatedVersions = [...allmddVersion];
    updatedVersions.length > 1 && updatedVersions.splice(0, 1);
    return updatedVersions;
  };

  return (
    <div className={classes.container}>
      <DataTablePanelTest
        backButton={true}
        disableSearch={true}
        buttons={null}
      />

      <div className={classes.version_container}>
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{mddDetails?.mddName || "..."}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}

        {allmddVersion && allmddVersion.length > 1 && (
          <div className={classes.version}>
            MDD Version
            <CustomSelectField
              value={mddVersion}
              onChange={(e) => setMddVersion(e.target.value)}
              size="small"
              displayEmpty
            >
              {removelatestVersion().map((version) => (
                <MenuItem
                  key={version}
                  value={version}
                  sx={{ fontFamily: "QualcommNext-Regular" }}
                >
                  v{version}
                </MenuItem>
              ))}
            </CustomSelectField>
          </div>
        )}
      </div>
      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        <div className={classes.info}>
          <div className={classes.info_items}>
            <div className={classes.feature_Table}>
              <MDDfeatureEnabledList
                data={mddDetails.deviceHealthMetrics}
                isLoading={isLoading}
                tableTitle="Device Health Metrics Reported"
                versionTitle={`Latest Version - v${
                  allmddVersion && allmddVersion.length > 0
                    ? allmddVersion[0]
                    : ""
                }`}
                shouldHideTitle={false}
              />
            </div>
            <div className={classes.feature_Table}>
              <MDDfeatureEnabledList
                data={mddDetails.qseaPLatformMetrics}
                isLoading={isLoading}
                tableTitle="QSEA Platform Metrics Reported"
                shouldHideTitle={false}
              />
            </div>
          </div>
          {allmddVersion && allmddVersion.length > 1 && (
            <div className={classes.info_items}>
              <div className={classes.feature_Table}>
                <MDDfeatureEnabledList
                  data={prevMddDetails.deviceHealthMetrics}
                  isLoading={isLoading}
                  tableTitle="Device Health Metrics Reported"
                  versionTitle={`Previous Version - v${mddVersion}`}
                  shouldHideTitle={true}
                />
              </div>
              <div className={classes.feature_Table}>
                <MDDfeatureEnabledList
                  data={prevMddDetails.qseaPLatformMetrics}
                  isLoading={isLoading}
                  tableTitle="QSEA Platform Metrics Reported"
                  shouldHideTitle={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MDDInfo;
