import * as yup from "yup";
const errorMessages = {
  organization: {
    required: "Organization is required",
  },
  tenantName: {
    required: "Tenant name is required",
  },
  featureName: {
    required: "Feature Name is required",
  },
};

export const schema = yup
  .object({
    organization: yup.object().required(errorMessages.organization.required),
    tenantName: yup.object().required(errorMessages.tenantName.required),
    featureName: yup.object().required(errorMessages.featureName.required),
  })
  .required();
