import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";

import DataTablePanel from "../panel/DataTablePanel";
import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { tenantFeaturesHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { getTenantFeaturesList, getTenantsById } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import { PARTIAL_ROUTES, CSS_ROUTES } from "../../constants/route-constants";
import { BUTTONS } from "../../constants/panel-constants";
import {
  columnsActions,
  featuresActions,
  tenantsActions,
} from "../../store/slices/slices-index";
import featureIcon from "../../assets/images/features/feature-icon.svg";
import unMarkIcon from "../../assets/images/features/unmark.svg";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import ConfigureNewFeature from "./ConfigureFeature/ConfigureNewFeature";
import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import DeleteFeature from "./DeleteFeature/DeleteFeature";
import UnmarkFeature from "./UnmarkFeature/UnmarkFeature";
import { FeatureChip, truncateStyles } from "../helpers/utils";
import useSearchKeys from "../../hooks/useSearchKeys";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;

const TenantFeaturesList = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("features");
  const initialState = useSelector((state) => state.columnsInfo.isvFeatures);
  const [searchStatefn] = useSearchKeys(
    ["featureTitle", "featureCategory", "featureDesc"],
    initialState
  );
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [featuresList, setFeaturesList] = useState({ count: 0, list: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openCreateNewFeature, setOpenCreateNewFeature] = useState(false);
  const [openDeleteFeature, setOpenDeleteFeature] = useState(false);
  const [openMarkFeature, setOpenMarkFeature] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const handleRowClick = (row) => {
    const data =
      row?.row != null
        ? {
            id: row.row.id,
            featureName: row.row.featureName,
            featureDesc: row.row.featureDesc,
            featureCategory: row.row.featureCategory,
          }
        : null;
    dispatch(featuresActions.setSelectedFeature(data));
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.myFeatures +
        "/" +
        row.row.featureName
    );
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleCreateNewFeature = () => {
    setOpenCreateNewFeature(true);
  };

  const closeCreateNewFeature = () => {
    setOpenCreateNewFeature(false);
  };

  const handleOpenDeleteFeature = (params) => {
    selectedRow = params.row;
    setOpenDeleteFeature(true);
  };

  const closeDeleteFeature = () => {
    selectedRow = null;
    setOpenDeleteFeature(false);
  };
  const handleOpenUnmarkFeature = (params) => {
    selectedRow = params.row;
    setOpenMarkFeature(true);
  };

  const closeUnmarkFeature = () => {
    selectedRow = null;
    setOpenMarkFeature(false);
  };
  const columns = [
    {
      field: "featureTitle",
      headerName: "Feature Title",
      hideable: false,
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <img src={featureIcon} alt="Edit" />
          {params.value}
        </div>
      ),
    },
    {
      field: "featureCategory",
      headerName: "Category",
      flex: 0.5,
      minWidth: 120,
    },
    {
      field: "isFeatureDownloadable",
      headerName: "Downloadable Feature",
      flex: 1,
      minWidth: 100,
      renderCell: (param) => {
        if (param?.value) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      field: "featureDesc",
      headerName: "Description",
      flex: 3,
      minWidth: 350,
      renderCell: (params) => <div style={truncateStyles}>{params.value}</div>,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.isvAdmin, ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      hideable: false,
      headerName: "Actions",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => (
        <>
          {(selectedAppType === "CSS" && params.row.cssStatus != null) ||
          (selectedAppType === "ISV" && params.row.isvStatus != null) ? (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleOpenUnmarkFeature(params);
              }}
            >
              <Tooltip title="Unmark feature">
                <img src={unMarkIcon} />
              </Tooltip>
            </IconButton>
          ) : selectedAppType === "ISV" ? (
            <DeleteIcon
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDeleteFeature(params);
              }}
            />
          ) : (
            selectedAppType === "CSS" &&
            params.row.isDeletable && (
              <DeleteIcon
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenDeleteFeature(params);
                }}
              />
            )
          )}
          <div>
            {selectedAppType === "ISV" &&
              params?.row?.cssStatus != null &&
              params?.row?.cssStatus.includes("DELETED") &&
              FeatureChip("Feature deleted by CSS")}
            {selectedAppType === "CSS" &&
              params?.row?.isvStatus != null &&
              params?.row?.isvStatus.includes("DELETED") &&
              FeatureChip("Feature deleted by ISV")}
          </div>
        </>
      ),
    });

  const headers = tenantFeaturesHeaders({
    routes: {
      myFeatures:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.myFeatures,
      associatedUsers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.isvTenants +
          "/" +
          params.tenantId +
          PARTIAL_ROUTES.associatedUsers
        : null,
      subscribers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.isvTenants +
          "/" +
          params.tenantId +
          PARTIAL_ROUTES.subscribers
        : null,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      isvId: params.tenantId,
      roleType: selectedAppType,
    };

    getTenantFeaturesList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.features != null &&
          response.data.featureCount != null
        ) {
          const formattedList = response.data.features.map((item) => {
            return {
              id: item.featureId,
              featureTitle: item.featureTitle,
              featureName: item.featureName,
              featureDesc: item.featureDesc,
              featureCategory: item.featureCategory,
              isvStatus: item.isvStatus,
              cssStatus: item.cssStatus,
              isDeletable: item.isDeletable,
              isFeatureDownloadable: item?.isFeatureDownloadable,
            };
          });
          setFeaturesList({
            count: response.data.featureCount,
            list: formattedList,
          });
          setFilteredList(formattedList);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [
    pageNo,
    openCreateNewFeature,
    openDeleteFeature,
    openMarkFeature,
    selectedTenant,
  ]);

  // useEffect(() => {
  //   const options = {
  //     tenantId: params.tenantId,
  //   };
  //   getTenantsById(options).then((response) => {
  //     if (response.status && response.data != null) {
  //       const data = {
  //         tenantId: response.data.tenantId,
  //         tenantName: response.data.tenantName,
  //         organizationId: response.data.organizationId,
  //         organization: response.data.organization,
  //         tenantType: response.data.tenantType,
  //       };
  //       dispatch(tenantsActions.setSelectedTenant(data));
  //     }
  //   });
  // }, []);

  return (
    <Fragment>
      {openMarkFeature && (
        <UnmarkFeature
          onClose={closeUnmarkFeature}
          userDetails={selectedRow}
          roleType={selectedAppType}
        />
      )}
      {openCreateNewFeature && (
        <ConfigureNewFeature
          openCreateNewFeature={openCreateNewFeature}
          setOpenCreateNewFeature={setOpenCreateNewFeature}
          onClose={closeCreateNewFeature}
          tenantId={params.tenantId}
        />
      )}
      {openDeleteFeature && (
        <DeleteFeature
          onClose={closeDeleteFeature}
          userDetails={selectedRow}
          selectedAppType={selectedAppType}
          repositoryType={
            selectedAppType === "CSS" && selectedRow?.isFeatureDownloadable
              ? "Global"
              : undefined
          }
        />
      )}
      <div className={classes.container}>
        {hasPermittedRolesOrAttributes([ROLES.isvAdmin, ROLES.cssAdmin]) && (
          <DataTablePanel
            name={BUTTONS.configureFeature}
            list={featuresList.list || []}
            setFilteredList={setFilteredList}
            filterKeys={searchStatefn}
            clickHandler={handleCreateNewFeature}
            backButton={hasPermittedRolesOrAttributes([ROLES.cssAdmin]) && true}
          />
        )}
        {hasPermittedRolesOrAttributes([
          ROLES.customerAdmin,
          ROLES.isvDeveloper,
          ROLES.isvMonitor,
        ]) && (
          <DataTablePanel
            list={featuresList.list || []}
            setFilteredList={setFilteredList}
            filterKeys={["id", "featureName", "featureCategory", "featureDesc"]}
          />
        )}
        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}

        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setisvFeaturesColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              if (
                (selectedAppType === "CSS" && params.row.cssStatus != null) ||
                (selectedAppType === "ISV" && params.row.isvStatus != null)
              ) {
                return "status";
              }
              if (
                (params?.row?.cssStatus != null &&
                  params?.row?.cssStatus.includes("DELETED")) ||
                (params?.row?.isvStatus != null &&
                  params?.row?.isvStatus.includes("DELETED"))
              ) {
                return "status";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  featuresList.list.length === filteredList.length
                    ? featuresList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.features },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TenantFeaturesList;
