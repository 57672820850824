import axios from "axios";
import { uploadFeatureIconApi } from "./apiEndpoints";

export const uploadFeatureIcon = async (options) => {
  let data = null;
  const url = `${uploadFeatureIconApi}/${options.featureId}/icon?featureId=${options.featureId}`;

  let formdata = new FormData();
  formdata.append("featureIcon", options.featureIcon);

  await axios
    .patch(url, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
