import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { PAGE_SIZE, PAGE_NO } from "../../../constants/page-constants";

import CustomTextField from "../../ui/CustomTextField";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import { schema } from "./policySchema";
import CommonHeader from "../../ui/CommonHeader";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import {
  getFeatureByName,
  searchByFeatureTitle,
  getOrgByName,
  getTenantsList,
  createSubscriptionPolicy,
} from "../../../api/api-index";
import {
  CREATE_SUBSCRIPTION_POLICY_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { MAX_VALUE } from "../../../constants/max-value-constants";
import { StyledPopper } from "../../ui/StyledPopper";
import { debounce } from "../../helpers/utils";

import ChipsetTable from "./ChipsetTable";
import AddChipset from "./AddChipset";
import { HtmlTooltip } from "../../ui/Tooltip/HtmlTooltip";

import classes from "../../ui/Form/FormContainer.module.css";
import styles from "./CreateSubscriptionPolicy.module.css";

let isClicked = true;
const CreateSubscriptionPolicy = ({ onClose, getUpdatedDataFn }) => {
  const {
    control,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
  });

  const watchFeatureName = watch("featureName");

  const watchOrgName = watch("customerOrganizationName");

  const [searchFeatureText, setsearchFeatureText] = useState(null);
  const [searchOrgText, setsearchOrgText] = useState(null);

  const [featureOptions, setFeatureOptions] = useState([]);
  const [OrgOptions, setOrgOptions] = useState([]);

  const [policyData, setPolicyData] = useState({});
  const [TenantData, setTenantData] = useState({});

  const [chipsets, setChipsets] = useState([]);

  const addChipset = (data) => {
    setChipsets([...chipsets, data]);
  };

  const policyFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        customerId: data.customerTenantName,
        isvId: policyData.isvId,
        featureName: policyData.featureName,
        featureId: policyData.featureId,
        policyChipsetsList: chipsets.map((item) => ({
          expirationTimeStamp: moment(item.expirationDate)
            .endOf("day")
            .utc()
            .format(),
          chipset: item.chipsetId,
          maxAllowedDevice:
            item.numberOfDevices === "" ? null : Number(item.numberOfDevices),
        })),
        policyName: data.policyName,
        extPolicyId: data.contractId,
      };
    }
    return request;
  };

  const registerHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      if (chipsets.length > 0) {
        createSubscriptionPolicy(policyFormRequest(data))
          .then((response) => {
            if (response.status && response.data != null) {
              isClicked = true;
              toast.success(CREATE_SUBSCRIPTION_POLICY_MESSAGES.success);
              onClose();
              getUpdatedDataFn && getUpdatedDataFn();
            } else {
              isClicked = true;
              errorHandler(
                response.error,
                CREATE_SUBSCRIPTION_POLICY_MESSAGES.failure
              );
            }
          })
          .catch((error) => {
            isClicked = true;
            console.error("ERROR: ", error);
            errorHandler(error, CREATE_SUBSCRIPTION_POLICY_MESSAGES.failure);
          });
      } else {
        toast.error("Add atleast one chipset to the table");
        isClicked = true;
      }
    }
  };

  const handleDelete = (onChange, value, selected) => {
    const filteredData = selected.filter((v) => v !== value);
    onChange(filteredData);
  };

  useEffect(() => {
    if (
      searchFeatureText !== null &&
      searchFeatureText !== "" &&
      searchFeatureText !== 0
    ) {
      searchByFeatureTitle(searchFeatureText)
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.length > 0
          ) {
            const formattedFeatureTitle = response.data
              .filter((feature) => feature.status !== "MARKED_FOR_DELETE")
              .map((title) => ({
                label: title.featureTitle,
                id: title.featureName,
              }));
            setFeatureOptions(formattedFeatureTitle);
          } else {
            setFeatureOptions([]);
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    } else {
      setFeatureOptions([]);
    }
  }, [searchFeatureText]);

  useEffect(() => {
    if (
      searchFeatureText !== "" &&
      searchFeatureText !== 0 &&
      watchFeatureName !== undefined
    ) {
      getFeatureByName(watchFeatureName?.id)
        .then((response) => {
          if (response.status && response.data != null) {
            setPolicyData(response.data);
          } else {
            setPolicyData({});
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
    if (searchFeatureText === "") {
      setPolicyData({});
      resetField("policyChipsets");
    }
  }, [watchFeatureName, searchFeatureText]);

  useEffect(() => {
    if (searchOrgText !== null && searchOrgText !== "" && searchOrgText !== 0) {
      let option = {
        name: searchOrgText,
        pageNo: PAGE_NO,
        pageSize: PAGE_SIZE,
      };
      getOrgByName(option).then((response) => {
        if (response?.status) {
          const newOrgValues = response.data.organizations.map((name) => {
            return { label: name.name, id: name.organizationId };
          });
          setOrgOptions(newOrgValues);
        } else {
          setOrgOptions([]);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      });
    } else {
      setOrgOptions([]);
    }
  }, [searchOrgText]);

  useEffect(() => {
    if (
      searchOrgText !== "" &&
      searchOrgText !== 0 &&
      watchOrgName !== undefined
    ) {
      const payload = {
        pageNo: 0,
        pageSize: 1000,
        organizationId: watchOrgName?.id,
        tenantType: "CUSTOMER",
      };
      getTenantsList(payload)
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.tenants != null &&
            response.data.count != null
          ) {
            setTenantData(response.data.tenants);
          } else {
            console.error(response.error);
            errorHandler(response.error, GET_MESSAGES.failure);
          }
        })

        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, GET_MESSAGES.failure);
        });
    }
    if (searchOrgText === "") {
      setTenantData({});
      resetField("customerTenantName");
    }
  }, [watchOrgName?.id, searchOrgText]);

  const chipsetListByFeature = policyData?.featurePolicyTerm?.chipset.filter(
    (i) => {
      const arr = chipsets && chipsets.map((chipset) => chipset.chipsetId);

      if (!arr.includes(i)) {
        return i;
      }
    }
  );

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Create Subscription Policy" onClose={onClose} />
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Policy Details</div>
            <div className={styles.sectionContent}>
              <div className={styles.row}>
                <Controller
                  name="policyName"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomTextField
                      onChange={onChange}
                      onBlur={onBlur}
                      size="small"
                      focused
                      fullWidth
                      type="text"
                      label="Policy Name"
                      required
                      value={value || ""}
                      placeholder="Enter Policy Name"
                      error={errors.policyName}
                      helperText={errors?.policyName?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: MAX_VALUE.policyName,
                        },
                      }}
                    />
                  )}
                />

                <Controller
                  name="contractId"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomTextField
                      onChange={onChange}
                      onBlur={onBlur}
                      size="small"
                      focused
                      fullWidth
                      type="text"
                      label="Policy ID"
                      value={value || ""}
                      placeholder="Enter Policy ID"
                      error={errors.contractId}
                      helperText={errors?.contractId?.message}
                      InputProps={{
                        inputProps: {
                          maxLength: 255,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Feature Details</div>
            {/*feature details section */}
            <div className={styles.sectionContent}>
              <div className={styles.row}>
                <Controller
                  name="featureName"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Autocomplete
                      disablePortal
                      clearOnEscape
                      size="small"
                      id={name}
                      freeSolo
                      disableClearable
                      options={featureOptions}
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        chipsets.length != 0 && setChipsets([]);
                      }}
                      onInputChange={debounce((event) => {
                        setsearchFeatureText(event.target.value);
                      })}
                      value={value || null}
                      PopperComponent={StyledPopper}
                      renderInput={(params) => (
                        <CustomTextField
                          focused
                          required
                          {...params}
                          label="Feature Title"
                          placeholder="Search Feature"
                          error={errors?.featureName?.id?.message}
                          helperText={
                            errors?.featureName?.id?.message &&
                            "Select appropriate feature"
                          }
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Policy Terms
              <HtmlTooltip
                placement="right"
                arrow
                title={
                  <Fragment>
                    <div>
                      1.{" "}
                      <u>
                        <b>Max devices</b>
                      </u>{" "}
                      can range from 1 to 999999999999
                    </div>
                    <div>
                      2.{" "}
                      <u>
                        <b>Expiration date</b>
                      </u>{" "}
                      should not be less than{" "}
                      {moment(new Date()).format("DD-MM-YYYY")}
                    </div>
                  </Fragment>
                }
              >
                <InfoOutlinedIcon />
              </HtmlTooltip>
            </div>
            {/*policy terms section */}
            <div className={styles.sectionContent}>
              <AddChipset
                chipsetArray={chipsetListByFeature || []}
                addChipsetFunc={addChipset}
                featureTitle={watchFeatureName}
              />
            </div>
            <div className={styles.sectionContent}>
              <ChipsetTable data={chipsets} setData={setChipsets} />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Customer Details</div>
            <div className={styles.sectionContent}>
              <div className={styles.row}>
                <Controller
                  name="customerOrganizationName"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Autocomplete
                      disablePortal
                      clearOnEscape
                      size="small"
                      id={name}
                      freeSolo
                      disableClearable
                      options={OrgOptions}
                      fullWidth
                      PopperComponent={StyledPopper}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      onInputChange={debounce((event) => {
                        setsearchOrgText(event.target.value);
                      })}
                      value={value || null}
                      renderInput={(params) => (
                        <CustomTextField
                          focused
                          required
                          {...params}
                          label="Organization"
                          placeholder="Search Organization"
                          error={errors?.customerOrganizationName?.message}
                          helperText={
                            errors?.customerOrganizationName?.message &&
                            "Select organization"
                          }
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="customerTenantName"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <CustomTextField
                      onChange={onChange}
                      onBlur={onBlur}
                      size="small"
                      focused
                      fullWidth
                      id={name}
                      label="Tenant Name"
                      value={value != null ? value.trimEllip(10) : ""}
                      select
                      required
                      placeholder="Select Tenant Name"
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxWidth: 200,
                              overflow: "auto",
                            },
                          },
                        },
                      }}
                    >
                      {Array.isArray(TenantData)
                        ? TenantData?.map((option) => (
                            <MenuItem
                              key={option.tenantId}
                              value={option.tenantId}
                              sx={{
                                fontFamily: "QualcommNext-Regular",
                              }}
                            >
                              {option.tenantName}
                            </MenuItem>
                          ))
                        : []}
                    </CustomTextField>
                  )}
                />
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton text={"Submit"} type={"submit"} />
          </div>
        </form>
      </div>
    </BackdropOverlayModal>
  );
};
export default CreateSubscriptionPolicy;
