import { Fragment, useEffect, useState } from "react";

import {
  getFeatureByTenantId,
  getFeatureDetailsByTenantId,
  getFeatureListByFeatureTitleOrTenantId,
} from "../../../../api/api-index";

import classes from "./FeatureMappedDetails.module.css";

const FeatureMappedDetails = ({ id }) => {
  const [featureData, setFeatureData] = useState([]);

  useEffect(() => {
    const payload = {
      isvId: id,
    };
    getFeatureListByFeatureTitleOrTenantId(payload)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedData = response?.data?.map((item) => {
            return {
              featureId: item.qualcommFeatureId,
              featureName: item.featureName,
            };
          });
          setFeatureData(formattedData);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }, []);

  return (
    <Fragment>
      {featureData.length > 0 && (
        <table className={classes.feature_Infotable}>
          <div>Below listed features mapping will be also deleted:</div>
          <br />
          <tr>
            <th>Feature ID</th>
            <th>Feature Name</th>
          </tr>
          {featureData.map((i) => (
            <tr>
              <td>{i.featureId}</td>
              <td>{i.featureName}</td>
            </tr>
          ))}
        </table>
      )}
    </Fragment>
  );
};
export default FeatureMappedDetails;
