import IconButton from "@mui/material/IconButton";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import { Tooltip } from "@mui/material";

const ActivateButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Inactivate policy",
    disabled = false,
  } = props;
  return !disabled ? (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label={tooltipText}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <ContentPasteOffIcon
          sx={{ color: disabled ? "grey" : color, width: 22 }}
        />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      disabled={disabled}
      aria-label={tooltipText}
      size={size != null ? size : "large"}
      onClick={onClick != null ? onClick : null}
    >
      <ContentPasteOffIcon
        sx={{ color: disabled ? "grey" : color, width: 22 }}
      />
    </IconButton>
  );
};

export default ActivateButton;
