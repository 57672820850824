import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import AssociateRoleToUserRow from "./AssociateRoleToUserRow";
import { USER_ROLES } from "../../../constants/role-constants";
import { getAllRoles } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";

import classes from "./AssociateRoleToUser.module.css";

const AssociateRoleToUser = (props) => {
  const { onClose, users, addUsers, appType } = props;
  const [userRoles, setUserRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  // const formattedRoles = USER_ROLES[appType];

  // const handleRoleAssociation = (userRoles) => {
  //   addUsers(userRoles);
  // };

  useEffect(() => {
    getAllRoles()
      .then((response) => {
        const formattedRoles = [];
        if (response.status && response.data != null) {
          Array.isArray(response.data) &&
            response.data.forEach((element) => {
              if (element.roleType === appType) {
                formattedRoles.push({
                  id: element?.roleName,
                  label: element?.roleLabel || element?.roleName,
                });
              }
            });
          setRoles(formattedRoles);
          setisLoading(false);
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
          setisLoading(false);
        }
      })
      .catch((error) => {
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  return (
    <BackdropOverlayModal>
      <form
        className={classes.container}
        onSubmit={(e) => {
          e.preventDefault();
          addUsers(userRoles);
        }}
      >
        <div className={classes.rows}>
          <CommonHeader heading="Associate Role to User" onClose={onClose} />
          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : (
            users?.map((user, index) => (
              <AssociateRoleToUserRow
                key={index}
                user={user}
                setUserRoles={setUserRoles}
                roles={roles}
                userRoles={userRoles}
              />
            ))
          )}
        </div>
        <div key={"buttons"} className={classes.buttons}>
          <SubmitButton text={"Submit"} type={"submit"} />
        </div>
      </form>
    </BackdropOverlayModal>
  );
};

export default AssociateRoleToUser;
