import { createSlice } from "@reduxjs/toolkit";

const initialDevicesLicensesState = {
  selectedFeature: null,
};

const devicesLicensesSlice = createSlice({
  name: "devicesLicenses",
  initialState: initialDevicesLicensesState,
  reducers: {
    setSelectedFeature(state, action) {
      state.selectedFeature = action.payload;
    },
  },
});

const devicesLicensesActions = devicesLicensesSlice.actions;

export {
  devicesLicensesSlice,
  initialDevicesLicensesState,
  devicesLicensesActions,
};
