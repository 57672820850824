import ReactDOM from "react-dom";

import App from "./App";
import KeycloakConfig from "./config/keycloak-config";
import AxiosConfig from "./config/axios-config";

import "./index.css";

const renderApp = () => ReactDOM.render(<App />, document.getElementById("root"));
KeycloakConfig.initKeycloak(renderApp);
AxiosConfig.config();
