import { Fragment, useEffect, useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@mui/material";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getFeatureTenantsList } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { BUTTONS, BUTTONS_TYPE } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import errorHandler from "../../helpers/error-helper";
import CreateFeature from "./CreateFeature/CreateFeature";
import { GET_MESSAGES } from "../../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import EditFeatureOwnership from "./EditFeatureOwnership/EditFeatureOwnership";
import DeleteFeatureOwnership from "./DeleteFeatureOwnership/DeleteFeatureOwnership";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { columnsActions } from "../../../store/slices/columns-slice";
import { OwnershipHeaders } from "../../helpers/header-helpers";
import DataTableHeaders from "../../header/DataTableHeaders";
import { CSS_ROUTES } from "../../../constants/route-constants";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import CommonFilter from "../../ui/Form/commonFilter/CommonFilter";

import classes from "../../ui/DataTable.module.css";

let selectedRow = null;

const FeatureOwnerShip = () => {
  const dispatch = useDispatch();
  const initialState = useSelector(
    (state) => state.columnsInfo.featureOwnership
  );
  const [searchStatefn] = useSearchKeys(
    ["organization", "tenantName", "featureName"],
    initialState
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [featuresTenantsList, setfeaturesTenantsList] = useState({
    count: 0,
    list: [],
  });

  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openCreateFeatureOwnership, setopenCreateFeatureOwnership] =
    useState(false);
  const [openEditFeatureOwnership, setOpenEditFeatureOwnership] =
    useState(false);
  const [openDeleteFeatureOwnership, setOpenDeleteFeatureOwnership] =
    useState(false);
  const [filterOptions, setFilterOptions] = useState({
    featureName: "",
    organization: "",
    tenantName: "",
    pageNo: 0,
    isFilterApplied: false,
  });

  const handlePageChange = (_, newPage) => {
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
    setIsLoading(true);
  };

  const columns = [
    {
      field: "featureName",
      headerName: "Feature Name",
      minWidth: 250,
      flex: 1,
      hideable: false,
    },
    {
      field: "organization",
      headerName: "Organization",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      minWidth: 250,
      flex: 1,
    },
  ];
  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.5,
      hideable: false,
      renderCell: (params) => (
        <div>
          {!params.row.isSubscriptionPolicyCreated && (
            // <IconButton
            //   size="small"
            //   onClick={(event) => {
            //     event.stopPropagation();
            //     handleEdit(params);
            //   }}
            // >
            //   <img src={editIcon} alt="Edit" />
            // </IconButton>
            <EditIconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleEdit(params);
              }}
            />
          )}
          {!params.row.isSubscriptionPolicyCreated && (
            <DeleteIcon
              size="small"
              onClick={() => handleOpenDeleteFeature(params)}
            />
          )}
        </div>
      ),
    });
  const handleCreateFeature = () => {
    setopenCreateFeatureOwnership(true);
  };
  const closeCreateFeature = () => {
    setopenCreateFeatureOwnership(false);
  };
  const closeEditFeature = () => {
    selectedRow = null;
    setOpenEditFeatureOwnership(false);
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditFeatureOwnership(!openEditFeatureOwnership);
  };

  const handleOpenDeleteFeature = (params) => {
    selectedRow = params.row;
    setOpenDeleteFeatureOwnership(true);
  };
  const closeDeleteFeature = () => {
    selectedRow = null;
    setOpenDeleteFeatureOwnership(false);
  };
  function fetchFilteredFeatureOwnershipList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      criteria: "ASSIGNED",
      featureName: filterOptions.featureName
        ? filterOptions.featureName.trim()
        : undefined,
      organizationName: filterOptions.organization
        ? filterOptions.organization.trim()
        : undefined,
      tenantName: filterOptions.tenantName
        ? filterOptions.tenantName.trim()
        : undefined,
    };

    getFeatureTenantsList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.totalCount != null
        ) {
          const formattedList =
            response.data.featureList.map((item) => {
              return {
                id: uuidv4(),
                organization: item.organizationName,
                tenantName: item.tenantName,
                featureName: item.qcommFeatureName,
                tenantId: item.tenantId,
                orgId: item.organizationId,
                featureId: item.qcommFeatureId,
                isSubscriptionPolicyCreated: item.isSubscriptionPolicyCreated,
              };
            }) || [];
          setfeaturesTenantsList({
            count: response.data.totalCount,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          // errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchFilteredFeatureOwnershipList();
  }, [filterOptions]);

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && featuresTenantsList.list.length === 1) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFilteredFeatureOwnershipList();
    }
  }
  const headers = OwnershipHeaders({
    routes: {
      ownership: CSS_ROUTES.featureOwnerShip,
    },
  });

  const buttons = [
    {
      key: "createFeature",
      text: BUTTONS.createFeature,
      clickHandler: handleCreateFeature,
      disabled: false,
      icon: <AddCircleOutlineIcon />,
      type: BUTTONS_TYPE.circle,
    },
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filterableitems = [
    {
      name: "featureName",
      label: "Feature Name",
      placeholder: "Enter feature name",
      defaultValue: filterOptions.featureName,
    },
    {
      name: "organization",
      label: "Organization",
      placeholder: "Enter organization name",
      defaultValue: filterOptions.organization,
    },
    {
      name: "tenantName",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenantName,
    },
  ];

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      featureName: data.featureName,
      organization: data.organization,
      tenantName: data.tenantName,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 3
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              featureName: "",
              organization: "",
              tenantName: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openCreateFeatureOwnership && (
        <CreateFeature
          getUpdatedDataFn={fetchFilteredFeatureOwnershipList}
          onClose={closeCreateFeature}
        />
      )}
      {openEditFeatureOwnership && (
        <EditFeatureOwnership
          getUpdatedDataFn={fetchFilteredFeatureOwnershipList}
          onClose={closeEditFeature}
          userDetails={selectedRow}
        />
      )}
      {openDeleteFeatureOwnership && (
        <DeleteFeatureOwnership
          getUpdatedDataFn={fetchUpdated}
          onClose={closeDeleteFeature}
          userDetails={selectedRow}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={false}
          disableSearch={true}
          buttons={buttons}
        />
        <p>Feature Ownership</p>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setfeatureOwnershipColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : featuresTenantsList.list}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: featuresTenantsList.count,

                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.featureOwnership,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FeatureOwnerShip;
