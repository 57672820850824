import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import UploadFeatureIcon from "./UploadFeatureIcon";
import {
  CONFIGURE_FEATURE_MESSAGES,
  CREATE_FEATURE_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import {
  createNewFeature,
  getAllChipsets,
  getFeatureByTenantId,
} from "../../../api/api-index";
import { schema } from "./ConfigureNewFeatureSchema";
import { CHIPSET_PAGE_SIZE } from "../../../constants/page-constants";
import CommonHeader from "../../ui/CommonHeader";
import BasicForm from "../../ui/Form/BasicForm";
import {
  ACCESS,
  FEATURE_CATEGORIES,
} from "../../../constants/feature-constants";
import KeycloakConfig from "../../../config/keycloak-config";
import errorHandler from "../../helpers/error-helper";
import ConfigureCatalogue from "./ConfigureCatalogue";

import classes from "../../ui/Form/FormContainer.module.css";

const ConfigureNewFeature = (props) => {
  let isClicked = true;
  const { onClose, tenantId, openCreateNewFeature, setOpenCreateNewFeature } =
    props;

  const [chipsets, setChipsets] = useState({
    chipsetNames: [],
    chipsetInfo: null,
  });
  const [featureNames, setFeatureNames] = useState([]);
  const [openModal, setOpenModal] = useState({
    open: openCreateNewFeature,
    featureId: null,
    featureName: null,
    configureFeature: openCreateNewFeature,
    addIcon: false,
    configureCatalogue: false,
    isFeatureDownloadable: true,
  });

  const featureFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        featureName: data.name,
        featureTitle: data.title,
        featureDesc: data.featureDescription,
        featureCategory: data.category,
        label: data.labels,
        featurePolicyTerm: {
          chipset: data.allowedChipsets,
          access: data.access,
          // regionAccessible: data.accessibleInRegions,
          maxDeviceNumber: data.noOfDevices,
        },
        releaseName: data.releaseName,
        userId: KeycloakConfig.getUserId(),
        pushCmd: data.pushToRepository,
        isvId: tenantId,
        isFeatureDownloadable: data?.isFeatureDownloadable ? true : false,
      };
    }

    return request;
  };

  const onIconUpload = (isFeatureDownloadable = false) => {
    setOpenModal({
      ...openModal,
      configureFeature: false,
      addIcon: false,
      configureCatalogue: isFeatureDownloadable === true ? true : false,
    });
    if (!isFeatureDownloadable) {
      onClose();
    }
    // setOpenCreateNewFeature(false);
  };

  const onCloseCatalogueUpload = () => {
    setOpenModal({
      ...openModal,
      configureFeature: false,
      addIcon: false,
      configureCatalogue: false,
    });
    onClose();
  };

  const submitHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      createNewFeature(featureFormRequest(data))
        .then((response) => {
          if (response.status) {
            toast.success(CONFIGURE_FEATURE_MESSAGES.success);
            setOpenModal({
              addIcon: true,
              configureFeature: false,
              featureId: response.data?.featureId,
              featureName: response.data?.featureName,
              isFeatureDownloadable: data?.isFeatureDownloadable,
            });
            // onClose();
          } else {
            errorHandler(response?.error, CONFIGURE_FEATURE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, CONFIGURE_FEATURE_MESSAGES.failure);
          isClicked = true;
        });
    }
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs: [
      {
        name: "name",
        label: "Feature Name",
        placeholder: "Enter feature name",
        select: true,
        selectOptions: featureNames,
      },
      {
        name: "title",
        label: "Feature Title",
        placeholder: "Enter feature title",
      },
      {
        name: "featureDescription",
        label: "Feature Description",
        placeholder: "Enter feature description",
        textarea: true,
      },
      // {
      //   name: "bundleType",
      //   label: "Bundle Type",
      //   placeholder: "Select bundle type",
      //   select: true,
      //   selectOptions: BUNDLE_OPTIONS,
      // },
      {
        name: "category",
        label: "Category",
        placeholder: "Enter category",
        select: true,
        selectOptions: FEATURE_CATEGORIES,
      },
      // {
      //   name: "pushToRepository",
      //   label: "Push to Repository",
      //   placeholder: "Push command",
      //   textarea: true,
      // },
      // {
      //   name: "releaseName",
      //   label: "Release Name",
      //   placeholder: "Enter release name",
      // },
      {
        name: "labels",
        label: "Labels",
        placeholder: "Enter labels",
      },
      {
        header: "Feature Terms",
      },
      {
        name: "allowedChipsets",
        label: "Allowed chipsets",
        placeholder: "Select chipsets",
        multiselect: true,
        select: true,
        selectOptions: chipsets.chipsetNames,
      },
      {
        name: "access",
        label: "Access",
        placeholder: "Select access",
        defaultValue: ACCESS[0],
        select: true,
        selectOptions: ACCESS,
      },
      // {
      //   name: "accessibleInRegions",
      //   label: "Accessible in Regions",
      //   placeholder: "Enter regions",
      // },
      {
        name: "isFeatureDownloadable",
        label: "Downloadable Feature",
        checkbox: true,
        defaultValue: true,
      },
    ],
  };

  useEffect(() => {
    const options = {
      pageNo: 0,
      pageSize: CHIPSET_PAGE_SIZE,
    };
    getAllChipsets(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const chipsetNames = response.data.map(
            (chipset) => chipset.chipsetName
          );
          setChipsets({
            chipsetNames: chipsetNames,
            chipsetInfo: response.data,
          });
        } else {
          console.error(response?.error);
          errorHandler(response?.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);
  useEffect(() => {
    getFeatureByTenantId(tenantId)
      .then((response) => {
        if (response.status && response.data != null) {
          const featureList = response.data.map(
            (item) => item.qcommFeatureName
          );
          setFeatureNames(featureList);
        } else {
          console.error(response?.error);
          errorHandler(response?.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);
  return (
    <BackdropOverlayModal>
      {openModal.configureFeature && (
        <div className={classes.container}>
          <CommonHeader heading="Configure Feature" onClose={onClose} />
          <BasicForm details={details} />
        </div>
      )}
      {openModal.addIcon && (
        <UploadFeatureIcon
          featureId={openModal.featureId}
          isFeatureDownloadable={openModal.isFeatureDownloadable}
          onClose={onIconUpload}
        />
      )}
      {openModal.configureCatalogue && (
        <ConfigureCatalogue
          featureName={openModal.featureName}
          onClose={onCloseCatalogueUpload}
        />
      )}
    </BackdropOverlayModal>
  );
};

export default ConfigureNewFeature;
