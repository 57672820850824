import { Chip, Tooltip } from "@mui/material";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import moment from "moment";
import { STATUS } from "../../constants/status-constants";

function DateFormatter(DateAndTime) {
  if (!DateAndTime) {
    return "N/A";
  }
  const date = moment.utc(DateAndTime).local().format("DD-MM-YYYY");

  const time = moment.utc(DateAndTime).local().format("HH:mm");

  return `${date} | ${time}`;
}

function ExpiryDateFormatter(DateAndTime) {
  if (!DateAndTime) {
    return "N/A";
  }
  const date = moment.utc(DateAndTime).local().format("DD-MM-YYYY");

  return date;
}

function debounce(func) {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 750);
  };
}

function sizeCalc(value) {
  if (value > 104857600) {
    return `${(value / Math.pow(1024, 3)).toFixed(3)} GB`;
  } else if (value < 104857600 && value > 102400) {
    return `${(value / Math.pow(1024, 2)).toFixed(3)} MB`;
  } else {
    return `${(value / Math.pow(1024, 1)).toFixed(3)} KB`;
  }
}

String.prototype.trimEllip = function (length) {
  return this.length >= length ? this.substring(0, length) + "..." : this;
};

function policyStatusChecker(status) {
  const chipStyles = {
    fontFamily: "QualcommNext-Regular",
  };
  const NewChip = (props) => (
    <Chip variant="outlined" sx={chipStyles} {...props} />
  );
  let output = "";
  switch (status) {
    case "ACTIVE":
      output = (
        <NewChip
          label="Active"
          icon={<AssignmentTurnedInOutlinedIcon />}
          color="success"
        />
      );
      break;
    case "INACTIVE":
      output = (
        <NewChip
          label="Inactive"
          icon={<ContentPasteOffIcon />}
          color="error"
        />
      );
      break;
    case "MARKED":
      output = (
        <Tooltip title="Feature marked for delete by ISV">
          <NewChip
            label="Marked for delete"
            icon={<ErrorOutlineIcon />}
            color="warning"
          />
        </Tooltip>
      );
      break;
    default:
      output = "N/A";
      break;
  }

  return output;
}

const NullCheck = (value) => {
  if (value === "") {
    return true;
  } else if (Number(value) > 0 && Number(value) < 999999999999) {
    return true;
  } else {
    return false;
  }
};

const AddToClipBoard = (text = "") => {
  window.navigator.clipboard.writeText(text);
};
const getDocumentLink = (url = "") => {
  const str = url.substring(url.indexOf(";") + 1);
  return `data:text/csv;base64,${str}`;
};

const downloadFile = (content, fileName) => {
  var data = new Blob([content]);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}`);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
};

function fetchFileNameFromAddr(filename = "") {
  // get file extension
  const extractedName = filename.split("/").pop();
  return extractedName;
}
function FeatureChip(message) {
  const chipStyles = {
    fontFamily: "QualcommNext-Regular",
  };

  return (
    <Chip label={message} variant="outlined" color="error" sx={chipStyles} />
  );
}

function getInitials(string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
}

const truncateStyles = {
  whiteSpace: "break-spaces",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
};

function dataURLtoFile(dataurl, filename = "sample.jpg") {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const CustomChip = (props) => (
  <Chip
    sx={{ fontFamily: "QualcommNext-Regular" }}
    variant="outlined"
    {...props}
  />
);

const output = {
  [STATUS.INSTALL_SUCCESS]: (
    <CustomChip label="License Install Success" color="success" />
  ),
  [STATUS.INSTALL_PENDING]: (
    <CustomChip label="License Install Pending" color="info" />
  ),
  [STATUS.INSTALL_FAILURE]: (
    <CustomChip label="License Install Failed" color="error" />
  ),
  [STATUS.MARKED_FOR_UPDATE]: (
    <CustomChip label="License Update Pending" color="info" />
  ),
  [STATUS.MARKED_FOR_DISABLE]: (
    <CustomChip label="License Revoke Pending" color="info" />
  ),
  [STATUS.UPDATE_FAILURE]: (
    <CustomChip label="License Update Failed" color="error" />
  ),
  [STATUS.REVOKE_FAILURE]: (
    <CustomChip label="License Revoke Failed" color="error" />
  ),
  null: <CustomChip label="N/A" color="info" />,
};

function universalChipFormatter(options) {
  return <CustomChip label={options.label} color={options.color} />;
}

export {
  DateFormatter,
  ExpiryDateFormatter,
  sizeCalc,
  policyStatusChecker,
  NullCheck,
  AddToClipBoard,
  getDocumentLink,
  downloadFile,
  fetchFileNameFromAddr,
  FeatureChip,
  getInitials,
  debounce,
  truncateStyles,
  output,
  dataURLtoFile,
  universalChipFormatter,
};
