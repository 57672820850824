import axios from "axios";
import { createPreferredVersionForSDKApi } from "../apiEndpoints";

export const createPreferredVersionForSDK = async (payload) => {
  let data = null;

  await axios
    .post(createPreferredVersionForSDKApi, { mappingList: payload })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
