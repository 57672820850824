import axios from "axios";
import { getUsersRolesApi } from "./apiEndpoints";

export const getAllRoles = async () => {
  let data = null;
  await axios
    .get(getUsersRolesApi)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
