import IconButton from "@mui/material/IconButton";
import FullscreenSharpIcon from "@mui/icons-material/FullscreenSharp";

const FullScreenIcon = (props) => {
  const { onClick } = props;
  return (
    <IconButton aria-label="cancel" size="small" onClick={onClick != null ? onClick : null}>
      <FullscreenSharpIcon htmlColor="#2455b5" />
    </IconButton>
  );
};

export default FullScreenIcon;
