import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const panelStyles = {
  // [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
  //   display: "none",
  // },
  ".MuiFormControl-root": {
    "& .MuiInputLabel-root,& .MuiInput-root": {
      fontFamily: "QualcommNext-Regular",
      color: "#777777",
    },
  },

  "& .MuiTypography-root": {
    color: "#777777",
    fontFamily: "QualcommNext-Regular",
    fontSize: "0.95rem",
  },

  ".MuiButton-root": {
    fontFamily: "QualcommNext-Regular",
    textTransform: "Capitalize",
    color: " #3955d4",
  },
};

const columnMenuStyles = {
  color: "#3955d4",
  "& .MuiMenuItem-root": {
    fontFamily: "QualcommNext-Regular",
  },
};

const CustomDataGrid = styled(DataGrid)(() => ({
  border: 0,
  fontFamily: "QualcommNext-Regular",
  "& .id": {
    color: "#3955d4",
  },
  "& .internalOrg": {
    backgroundColor: "#ccd4f9",
    color: "#777777",
  },
  "& .general": {
    color: "#777777",
  },
  "& .status": {
    background: "#EAEAEA",
    color: "#C8BEBE",
  },
  "& .unread": {
    background: "#E3F3F8",
    color: "#777777",
  },
  "& .read": {
    background: "#F5F6F8",
    color: "#777777",
  },
  "& .selectedVersion": {
    background: " #DAE5FA",
  },

  "& .noCursorRequired": {
    pointerEvents: "none",
    backgroundColor: "#F5F6F8",
  },

  "& .rowDisable": {
    pointerEvents: "none",
    backgroundColor: "#dddddd",
  },

  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cellCheckbox":
    {
      outline: "none",
    },
  "& .MuiDataGrid-toolbarContainer": {
    backgroundColor: "#F2F5F9",
    borderBottom: "0.5em solid #FFFFFF",
  },
  "& .MuiDataGrid-main": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.05)",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.05)",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#214572",
    backgroundColor: "#F4F4FB",
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600",
    },
    border: "none",
    fontSize: "clamp(0.875rem, 0.6926rem + 0.2703vw, 1.125rem)",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    display: "flex",
    flexDirection: "column",
    padding: "0.5em 0",
    gap: "0.5em",
    width: "100%",
  },
  "& .MuiDataGrid-row": {
    // borderRadius: "4px",
    // width: "100%",
    cursor: "pointer",
    fontSize: "clamp(0.8125rem, 0.6301rem + 0.2703vw, 1.0625rem)",
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#F5F6F8",
    "&:hover": {
      backgroundColor: "#E8F0FA",
    },
    "&.Mui-selected": {
      backgroundColor: "#E9F2FF",
    },
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
    "&:hover": {
      backgroundColor: "#E8F0FA",
    },
    "&.Mui-selected": {
      backgroundColor: "#E9F2FF",
    },
  },
  "& .MuiDataGrid-cell": {
    border: "none",
    paddingLeft: "1em",
    "&:focus-within": {
      outline: "none",
    },
  },
}));

const CustomVersionHistoryStatsDataGrid = styled(DataGrid)(() => ({
  border: 0,
  fontFamily: "QualcommNext-Regular",
  "& .id": {
    color: "#3955d4",
  },
  "& .green": {
    color: "#2D9C36",
  },
  "& .red": {
    color: "#E82E2E",
  },
  "& .general": {
    color: "#777777",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cellCheckbox":
    {
      outline: "none",
    },
  "& .MuiDataGrid-toolbarContainer": {
    backgroundColor: "#F2F5F9",
    borderBottom: "0.5em solid #FFFFFF",
  },
  "& .MuiDataGrid-main": {
    backgroundColor: "#FAFCFF",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.05)",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.05)",
  },
  "& .MuiDataGrid-columnHeaders": {
    color: "#214572",
    backgroundColor: "#EDEDFF",
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600",
    },
    border: "none",
    fontSize: "clamp(0.875rem, 0.6926rem + 0.2703vw, 1.125rem)",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    cursor: "default",
    display: "flex",
    flexDirection: "column",
    padding: "0.5em 0",
    gap: "0.5em",
    width: "100%",
  },
  "& .MuiDataGrid-row": {
    // borderRadius: "4px",
    width: "100%",
    cursor: "default",
    fontSize: "clamp(0.8125rem, 0.6301rem + 0.2703vw, 1.0625rem)",
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#FAFCFF",
    "&:hover": {
      backgroundColor: "#FAFCFF",
    },
    "&.Mui-selected": {
      backgroundColor: "#FAFCFF",
    },
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#FAFCFF",
    "&:hover": {
      backgroundColor: "#FAFCFF",
    },
    "&.Mui-selected": {
      backgroundColor: "#FAFCFF",
    },
  },
  "& .MuiDataGrid-cell": {
    border: "none",
    paddingLeft: "1em",
    "&:focus-within": {
      outline: "none",
    },
  },
}));

export {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
  CustomVersionHistoryStatsDataGrid,
};
