import moment from "moment";
import { Button, Checkbox } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import SubmitButton from "../../../ui/Buttons/SubmitButton";
import CustomTextField from "../../../ui/CustomTextField";

import styles from "./AssociateFeatureToDevices.module.css";

const AssociateFeatureToDevicesRowHeader = ({
  bulkDateHandler,
  isBatchingEnabled,
  handleSelectAll,
  isCheckAll,
  handleBatchDate,
  error,
  batchDate,
  maxExpiryDate,
}) => {
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <div className={styles.headercontainer}>
      <div className={styles.text}>
        <Checkbox
          style={{ visibility: !isBatchingEnabled && "hidden" }}
          onChange={handleSelectAll}
          checked={isCheckAll}
          // color="success"
        />
        <span>Device Id</span>
      </div>
      <div className={styles.text}>
        <span>Device Name</span>
      </div>
      {isBatchingEnabled ? (
        <form className={styles.expirationDateField} onSubmit={bulkDateHandler}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              required
              label="Expiration Date"
              value={batchDate}
              onChange={handleBatchDate}
              disablePast
              maxDate={maxExpiryDate !== null ? moment(maxExpiryDate) : null}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => {
                return (
                  <CustomTextField
                    onKeyDown={onKeyDown}
                    sx={{
                      minWidth: "14.5em",
                      "& .MuiInputBase-input": {
                        caretColor: "transparent",
                      },
                      "& .MuiFormHelperText-contained": {
                        fontFamily: "QualcommNext-Regular",
                        color: "#6e6e6e",
                      },
                    }}
                    size="small"
                    focused
                    fullWidth
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    error={params.error}
                    helperText={error && "Check atleast one device"}
                  />
                );
              }}
            />
          </LocalizationProvider>
          {/* <CustomTextField
            sx={{
              "& .MuiFormHelperText-contained": {
                fontFamily: "QualcommNext-Regular",
                color: "#6e6e6e",
              },
            }}
            size="small"
            focused
            label="Expiration Date"
            InputProps={{
              inputProps: {
                min: moment(new Date()).format("YYYY-MM-DD"),
                max: moment(maxExpiryDate).format("YYYY-MM-DD"),
              },
            }}
            value={batchDate}
            onChange={handleBatchDate}
            type="date"
            required
            helperText={error && "Check atleast one device"}
          /> */}
          <div>
            <Button
              variant="contained"
              sx={{
                color: "#FFFFFF",
                backgroundColor: "#4D6994",
                border: "1px solid #4D6994",
                fontFamily: "QualcommNext-Regular",
                "&:hover": {
                  backgroundColor: "#1a4071",
                },
              }}
              disableElevation
              type="submit"
            >
              ADD
            </Button>
            {/* <SubmitButton text={"Add"} type={"submit"} /> */}
          </div>
        </form>
      ) : (
        <div className={styles.expirationDate}>Expiration Date</div>
      )}
    </div>
  );
};
export default AssociateFeatureToDevicesRowHeader;
