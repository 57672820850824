import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, SpeedDial, SpeedDialAction, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";

import { REPO_STATUS } from "../../../../constants/repo-constants";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import {
  downloadFile,
  ExpiryDateFormatter,
  fetchFileNameFromAddr,
  getDocumentLink,
} from "../../../helpers/utils";
import useRepoStatus from "../../../../hooks/useRepoStatus";
import { getAttachmentLink } from "../../../../api/api-index";
import { FILE_DOWNLOAD_MESSAGES } from "../../../../constants/message-constants";

const GlobalStatusTable = ({ list }) => {
  const featureVersionId = list[0].featureVersionId;

  const formattedList = list.map((item) => {
    return {
      id: item.status,
      status: item.status,
      remarks: item.remark,
      handledBy: item.createdBy,
      attachments: item.attachment,
      // attachmentName: item.attachmentName,
      date: ExpiryDateFormatter(item.createdOn),
      qcommFeatureName: item?.qcommFeatureName,
      featureVersionId: item?.featureVersionId,
      // attachmentPath: item?.attachmentName || "",
    };
  });

  function handleDownloadFile(path, fileName) {
    const payload = {
      attachment: path,
    };
    getAttachmentLink(payload).then((res) => {
      if (res.status != false) {
        downloadFile(res, fileName);
        toast.success(FILE_DOWNLOAD_MESSAGES.success);
      } else {
        toast.error(FILE_DOWNLOAD_MESSAGES.failure);
      }
    });
  }

  const [StatusHistory, repoStatusGenerator] = useRepoStatus(formattedList);
  useEffect(() => {
    repoStatusGenerator();
  }, [list]);
  const columns = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.enabled ? (
              <SpeedDial
                sx={{
                  ".MuiFab-root": {
                    boxShadow: "none",
                    textTransform: "Capitalize",
                    fontFamily: "QualcommNext-Regular",
                    fontSize: "0.8rem",
                  },
                }}
                open={true}
                ariaLabel="SpeedDial basic example"
                icon={params.row.status}
                direction="right"
                FabProps={{
                  variant: "extended",
                  size: "small",
                  color: "primary",
                }}
              >
                {params.row.status !== "Added to Global Repository" ? (
                  <SpeedDialAction
                    icon={<CheckCircleIcon color="primary" />}
                    tooltipTitle="Approve"
                  />
                ) : (
                  <SpeedDialAction
                    icon={<CheckCircleIcon color="primary" />}
                    tooltipTitle="Approve"
                  />
                )}
                {params.row.status !== "Added to Global Repository" && (
                  <SpeedDialAction icon={<CancelIcon />} tooltipTitle="Deny" />
                )}
              </SpeedDial>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },

    {
      field: "handledBy",
      headerName: "Handled By",
      minWidth: 100,
      flex: 0.8,
      sortable: false,
    },

    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 0.4,
      sortable: false,
    },

    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 120,
      flex: 1,
      sortable: false,
    },

    {
      field: "attachments",
      headerName: "Attachments",
      minWidth: 120,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return params.row.attachments != null ? (
          <Tooltip title={fetchFileNameFromAddr(params.row.attachments)}>
            <Button
              sx={{
                textTransform: "Capitalize",
                fontFamily: "QualcommNext-Regular",
              }}
              size="small"
              endIcon={<DownloadIcon />}
              variant="outlined"
              onClick={() =>
                handleDownloadFile(
                  params.row.attachments,
                  fetchFileNameFromAddr(params.row.attachments)
                )
              }
              download={params.row.attachments + ".csv"}
              aria-label="download"
            >
              {fetchFileNameFromAddr(params.row.attachments).trimEllip(10)}
            </Button>
          </Tooltip>
        ) : null;
      },
    },
  ];

  return (
    <CustomDataGrid
      sx={{
        height: "35vh",
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "0.8rem",
          wordBreak: "break-word",
        },
        "& .MuiDataGrid-row": {
          cursor: "default",
        },
      }}
      getRowHeight={() => "auto"}
      columns={columns}
      rows={StatusHistory}
      disableColumnFilter
      disableColumnMenu
      hideFooterSelectedRowCount
      hideFooter
      scrollbarSize={17}
      getCellClassName={(params) => {
        if (params.field === "id") {
          return "id";
        }
        if (
          (params.row.handledBy == null && params.row.enabled == null) ||
          params.row.enabled == false
        ) {
          return "status";
        }

        return "general";
      }}
      components={{
        NoRowsOverlay: CustomNoRowMessage,
      }}
      componentsProps={{
        panel: {
          sx: panelStyles,
        },
        columnMenu: {
          sx: columnMenuStyles,
        },
      }}
    />
  );
};

export default GlobalStatusTable;
