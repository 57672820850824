import { Alert } from "@mui/material";
import React from "react";

function AlertBanner({ text = "", severity = "error" }) {
  return (
    <Alert severity={severity} sx={{ fontFamily: "QualcommNext-Medium" }}>
      {text}
    </Alert>
  );
}

export default AlertBanner;
