import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

import DataTablePanelTest from "../panel/DataTablePanelTest";
import FeatureCard from "./FeatureCard";
import LoadMoreButton from "../ui/Buttons/LoadMoreButton";
import CatalogLayout from "./CatalogLayout";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import { getAllCatalog } from "../../api/api-index";
import { GET_MESSAGES } from "../../constants/message-constants";
import { PAGE_SIZE } from "../../constants/page-constants";
import errorHandler from "../helpers/error-helper";

import classes from "./Catalog.module.css";

function CatalogCollection({ type }) {
  const [next, setNext] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loadMore, showLoadMore] = useState(true);
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [catalogList, setCatalogList] = useState({
    totalCount: 0,
    features: [],
  });
  const [filtered, setFiltered] = useState({
    totalCount: 0,
    features: [],
  });

  const handleChange = (event) => {
    setCategory(event.target.value);
    setNext(0);
  };
  const handleMoreImage = () => {
    setNext(next + 1);
    setLoader(true);
  };

  const getAllCatalogfunc = () => {
    setLoading(true);
    const options = {
      pageNo: next,
      pageSize: PAGE_SIZE,
      featureType: "COLLECTION",
      category: category === "All" ? null : category,
    };
    getAllCatalog(options)
      .then((response) => {
        if (response.status && response.data != null) {
          setCatalogList((prev) => {
            return {
              totalCount: response.data.totalCount,
              features:
                next === 0
                  ? response.data.features
                  : [...prev.features, ...response.data.features],
            };
          });

          setFiltered((prev) => {
            return {
              totalCount: response.data.totalCount,
              features:
                next === 0
                  ? response.data.features
                  : [...prev.features, ...response.data.features],
            };
          });
          setLoader(false);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAllCatalogfunc();
  }, [category, type, next]);

  return loading ? (
    <CatalogLayout>
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    </CatalogLayout>
  ) : (
    <CatalogLayout>
      <div className={classes.heading}>
        <span>QSEA Catalog</span>
        <CategoryFilter
          category={category}
          disabled={!loadMore}
          handleChange={handleChange}
        />
        <DataTablePanelTest
          list={catalogList.features || []}
          setFilteredList={(data) =>
            setFiltered((prev) => {
              return {
                totalCount: prev.totalCount,
                features: [...data],
              };
            })
          }
          setKeys={(key) => {
            key ? showLoadMore(false) : showLoadMore(true);
          }}
          filterKeys={["featureTitle", "featureType", "featureCategory"]}
        />
      </div>

      <div id="scrollbaleArea" className={classes.data}>
        {filtered.totalCount === 0 && (
          <div className={classes.noData}>No data found</div>
        )}
        <div className={classes.info}>
          {filtered.totalCount > 0 &&
            filtered.features.map((card, key) => (
              <FeatureCard key={key} data={card} />
            ))}
        </div>
        {filtered.features.length != filtered.totalCount && (
          <div className={classes.loadMore}>
            {!loader ? (
              loadMore && <LoadMoreButton onClick={handleMoreImage} />
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
      </div>
    </CatalogLayout>
  );
}

export default CatalogCollection;
