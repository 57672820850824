import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import { CustomDataGrid } from "../../ui/CustomDataGrid";
import { getDevicesDownloadedFeatureCount } from "../../../api/api-index";
import { ExpiryDateFormatter } from "../../helpers/utils";
import { GET_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";

import styles from "../../ui/Form/FormContainer.module.css";
import classes from "./PolicyTerms.module.css";

let devicesDownloadedFeatureCount = [];
const PolicyTerms = (props) => {
  const [isLoading, setisLoading] = useState(true);
  const combinedList = [];
  const { onClose, selectedFeature } = props;
  let activeChipset = [];
  let inActiveChipset = [];
  let activeList = [];
  let inActiveList = [];
  selectedFeature?.policyId.forEach((element) => {
    if (element.status == null || element.status === "ACTIVE") {
      element.policyChipsetsList.forEach((active) => {
        activeChipset.push(active.chipset);
        activeList.push({ ...active, status: "ACTIVE", id: uuidv4() });
      });
    }
  });
  selectedFeature?.policyId.forEach((element) => {
    if (element.status !== null || element.status !== "ACTIVE") {
      element.policyChipsetsList.forEach((item) => {
        if (
          !activeChipset.includes(item.chipset) &&
          !inActiveChipset.includes(item.chipset)
        ) {
          inActiveChipset.push(item.chipset);
          inActiveList.push({ ...item, id: uuidv4(), status: "INACTIVE" });
        }
      });
    }
  });
  const newData = [...activeList, ...inActiveList];

  const columns = [
    {
      field: "chipset",
      headerName: "Chipset",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "maxAllowedDevice",
      headerName: "Max Devices",
      minWidth: 50,
      flex: 0.8,
      renderCell: (params) => <div>{params.value || "-"}</div>,
    },
    {
      field: "expirationTimeStamp",
      headerName: "Expiration Date",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <div>{ExpiryDateFormatter(params.value)}</div>,
    },
    {
      field: "activeCount",
      headerName: "Devices Downloaded Feature",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
  ];
  const heading =
    selectedFeature?.featureTitle != null
      ? `${selectedFeature.featureTitle} - Policy Terms`
      : "Policy Terms";

  useEffect(() => {
    getDevicesDownloadedFeatureCount(selectedFeature.featureName)
      .then((response) => {
        if (response.status && response.data != null) {
          devicesDownloadedFeatureCount = response.data;
          setisLoading(false);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          setisLoading(false);
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  }, []);

  if (devicesDownloadedFeatureCount?.length === 0) {
    newData.forEach((item) => {
      combinedList.push({ ...item, activeCount: 0 });
    });
  } else {
    newData.forEach((item) => {
      devicesDownloadedFeatureCount.forEach((t2) => {
        if (t2.chipset === item.chipset) {
          combinedList.push({ ...item, activeCount: t2.activeCount });
        } else {
          combinedList.push({ ...item, activeCount: 0 });
        }
      });
    });
  }

  console.log(combinedList);
  return (
    <BackdropOverlayModal>
      <div className={styles.container}>
        <CommonHeader heading={heading} onClose={onClose} />
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <CustomDataGrid
              sx={{
                height: "32vh",
                "& .MuiDataGrid-row": {
                  cursor: "default",
                },
              }}
              columns={columns}
              rows={combinedList}
              disableColumnFilter
              disableColumnMenu
              hideFooter
              hideFooterSelectedRowCount
              scrollbarSize={17}
              getCellClassName={(params) => {
                if (params.row.status === "ACTIVE") {
                  return "internalOrg";
                }
                return "general";
              }}
            />
          </div>
        )}
      </div>
    </BackdropOverlayModal>
  );
};

export default PolicyTerms;
