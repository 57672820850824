import { memo, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  MenuItem,
  Button,
  Switch,
} from "@mui/material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./styledAccordionComponents";
import { TimeRow } from "./TimeRow";
import { TIME_CONSTANTS } from "../../../constants/time-constants";
import CustomEndpointComponent from "./CustomEndpointComponent";
import CustomTextField from "../../ui/CustomTextField";

import classes from "./CustomAccordion.module.css";

export const CustomDeviceAccordion = memo(function CustomDeviceAccordion({
  deviceMetrics,
  setdeviceMetrics,
  deviceEndpoint,
  setDeviceEndpoint,
  editDisabled,
}) {
  const isChecked =
    deviceMetrics.filter((i) => i.checked === true).length > 1 ? true : false;
  const isAllChecked =
    deviceMetrics.filter((i) => i.checked === false).length > 0 ? false : true;

  const [checkAll, setCheckAll] = useState(false);
  const [deviceMetricsExpanded, setDeviceMetricsExpanded] = useState(true);

  const [bulkSelection, setBulkSelection] = useState({
    reportingRate: "2h",
  });
  const [enabled, setEnabled] = useState(editDisabled || false);

  const handleEnableChange = (event) => {
    setEnabled(event.target.checked);
    let newFilteredList = deviceMetrics.map((item) => {
      return {
        ...item,
        checked: false,
        reportingRate: "2h",
      };
    });
    setBulkSelection({ reportingRate: "2h" });
    setdeviceMetrics(newFilteredList);
  };
  const handleDeviceRadioChange = (event) => {
    setDeviceEndpoint({
      ...deviceEndpoint,
      value: event.target.value,
      url: "",
    });
  };
  const handleDeviceUrlChange = (event) => {
    setDeviceEndpoint({ ...deviceEndpoint, url: event.target.value });
  };

  const handleChange = () => {
    setDeviceMetricsExpanded((prev) => !prev);
  };

  const handleBulkTime = (event) => {
    setBulkSelection((prev) => {
      return {
        ...prev,
        reportingRate: event.target.value,
      };
    });
  };

  const handleBulk = () => {
    let newFilteredList = deviceMetrics.map((item) => {
      if (item.checked) {
        return {
          ...item,
          reportingRate: bulkSelection.reportingRate,
        };
      } else {
        return item;
      }
    });
    setdeviceMetrics(newFilteredList);
  };
  const handleCheckbox = (name) => {
    const newList = deviceMetrics.map((item) => {
      if (item.metricName === name) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return item;
      }
    });
    setdeviceMetrics(newList);
  };
  const handleTime = (name, value) => {
    const newList = deviceMetrics.map((item) => {
      if (item.metricName === name) {
        return {
          ...item,
          reportingRate: value,
        };
      } else {
        return item;
      }
    });
    setdeviceMetrics(newList);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newList = deviceMetrics.map((item) => {
        if (item.checked) {
          return item;
        } else {
          return {
            ...item,
            checked: true,
          };
        }
      });
      setdeviceMetrics(newList);
    } else {
      const newList = deviceMetrics.map((item) => {
        if (!item.checked) {
          return item;
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });
      setdeviceMetrics(newList);
    }
  };
  useEffect(() => {
    isAllChecked ? setCheckAll(true) : setCheckAll(false);
  }, [isAllChecked]);

  return (
    <StyledAccordion expanded={deviceMetricsExpanded}>
      <StyledAccordionSummary
        disableTouchRipple
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={
          deviceMetricsExpanded ? (
            <IndeterminateCheckBoxRoundedIcon
              fontSize="large"
              htmlColor="#4D6994"
              onClick={() => handleChange()}
            />
          ) : (
            <AddBoxRoundedIcon
              fontSize="large"
              htmlColor="#4D6994"
              onClick={() => handleChange()}
            />
          )
        }
      >
        <div className={classes.header}>
          <div className={classes.selectAll}>
            <FormControlLabel
              disableTypography
              control={
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#4770a4",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#4770a4",
                    },
                  }}
                  checked={enabled}
                  onChange={handleEnableChange}
                />
              }
              label="Device Health Metrics"
            />
            {deviceMetricsExpanded && (
              <FormControlLabel
                disableTypography
                disabled={!deviceMetricsExpanded || !enabled}
                control={
                  <Checkbox
                    checked={checkAll}
                    onClick={(e) => handleSelectAll(e)}
                  />
                }
                label="Select All"
              />
            )}
          </div>

          {deviceMetricsExpanded && (
            <div className={classes.time}>
              <CustomTextField
                disabled={!isChecked}
                sx={{ width: "8em" }}
                size="small"
                label="Reporting rate"
                focused
                select
                value={bulkSelection.reportingRate}
                onChange={(e) => handleBulkTime(e)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  },
                }}
              >
                {["5m", ...TIME_CONSTANTS["hrs"]].map((item, key) => (
                  <MenuItem
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    key={key}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </CustomTextField>

              <Button
                disabled={!isChecked}
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "Capitalize",
                  fontFamily: "QualcommNext-Regular",
                  backgroundColor: "#4D6994",
                }}
                onClick={handleBulk}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {deviceMetrics.map((item) => {
          return (
            <Stack
              key={item.metricName}
              direction={"row"}
              alignItems="center"
              sx={{ mt: 1.2, mb: 1.2 }}
            >
              <FormControlLabel
                sx={{
                  fontSize: "0.9rem",
                  width: "22ch",
                  color: "#777777",
                }}
                disableTypography
                control={
                  <Checkbox
                    disabled={!enabled}
                    onChange={() => handleCheckbox(item.metricName)}
                    checked={item.checked}
                    size="small"
                  />
                }
                label={item.displayName}
              />
              <TimeRow item={item} handleTime={handleTime} />
            </Stack>
          );
        })}
      </StyledAccordionDetails>
      {deviceMetrics.filter((item) => item.checked === true).length > 0 && (
        <div className={classes.endpoint}>
          <CustomEndpointComponent
            endpoint={deviceEndpoint}
            handleRadioChange={handleDeviceRadioChange}
            handleUrlChange={handleDeviceUrlChange}
            disabled={editDisabled}
            categoryEnabled={enabled}
          />
        </div>
      )}
    </StyledAccordion>
  );
});
