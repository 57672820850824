import axios from "axios";
import { searchFeatureTitleApi } from "./apiEndpoints";

export const searchByFeatureTitle = async (featureTitle) => {
  let data = null;
  await axios
    .get(searchFeatureTitleApi, {
      params: {
        featureTitle,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
