import IconButton from "@mui/material/IconButton";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Tooltip } from "@mui/material";

const ManageTenantIcon = (props) => {
  const { onClick, size, disabled, tooltipText = "Client Id & secret" } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        style={{ visibility: disabled && "hidden" }}
        aria-label="manage tenant"
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <ManageAccountsIcon sx={{ color: "#1a4071" }} />
      </IconButton>
    </Tooltip>
  );
};

export default ManageTenantIcon;
