import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";

import DataTablePanelTest from "../panel/DataTablePanelTest";
import FeatureCard from "./FeatureCard";
import CatalogLayout from "./CatalogLayout";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import { getAllCatalog } from "../../api/api-index";
import { GET_MESSAGES } from "../../constants/message-constants";
import errorHandler from "../helpers/error-helper";
import { COMMON_ROUTES } from "../../constants/route-constants";

import classes from "./Catalog.module.css";

function Catalogue({ type }) {
  const navigate = useNavigate();
  const matchesSM = useMediaQuery("(min-width:1285px)", { noSsr: true });
  const matchesMD = useMediaQuery("(min-width:1600px)", { noSsr: true });
  const matchesLG = useMediaQuery("(min-width:1900px)", { noSsr: true });
  const matchesXL = useMediaQuery("(min-width:2210px)", { noSsr: true });
  const matchesXXL = useMediaQuery("(min-width:2520px)", { noSsr: true });

  const [loadMore, showLoadMore] = useState(true);
  const [catalogList, setCatalogList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const getAllCatalogfunc = () => {
    setLoading(true);
    const options = {
      pageNo: 0,
      pageSize: 1000,
      featureType: type !== null ? type : null,
      category: category === "All" ? null : category,
    };
    getAllCatalog(options)
      .then((response) => {
        if (response.status && response.data != null) {
          setCatalogList(response.data.features);
          setFilteredList(response.data.features);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAllCatalogfunc();
  }, [category, type]);

  const filteredModels = filteredList.filter(
    (item) => item.featureType === "MODEL"
  );
  const filteredContainers = filteredList.filter(
    (item) => item.featureType === "CONTAINER"
  );
  const filteredCollections = filteredList.filter(
    (item) => item.featureType === "COLLECTION"
  );

  const view = matchesXXL
    ? 8
    : matchesXL
    ? 7
    : matchesLG
    ? 6
    : matchesMD
    ? 5
    : matchesSM
    ? 4
    : 3;

  return loading ? (
    <CatalogLayout>
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    </CatalogLayout>
  ) : (
    <CatalogLayout>
      <div className={classes.heading}>
        <span>QSEA Catalog</span>

        <CategoryFilter
          category={category}
          disabled={!loadMore}
          handleChange={handleChange}
        />
        <DataTablePanelTest
          list={catalogList || []}
          setFilteredList={setFilteredList}
          filterKeys={["featureTitle", "featureType", "featureCategory"]}
          setKeys={(key) => {
            key ? showLoadMore(false) : showLoadMore(true);
          }}
        />
      </div>

      <div id="scrollbaleArea" className={classes.data}>
        {filteredList.length === 0 && (
          <div className={classes.noData}>No data found</div>
        )}
        {filteredModels.length > 0 && (
          <>
            <div className={classes.catalogType}>
              Models{" "}
              {filteredModels.length >= view + 1 && (
                <div className={classes.viewMore}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: "Capitalize",
                      fontFamily: "qualcommNext-Regular",
                    }}
                    onClick={() => navigate(COMMON_ROUTES.catalogModels)}
                  >
                    View More
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.info}>
              {filteredModels.slice(0, view).map((card) => (
                <FeatureCard key={card.featureId} data={card} />
              ))}
            </div>
          </>
        )}

        {filteredContainers.length > 0 && (
          <>
            <div className={classes.catalogType}>
              Containers{" "}
              {filteredContainers.length >= view + 1 && (
                <div className={classes.viewMore}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: "Capitalize",
                      fontFamily: "qualcommNext-Regular",
                    }}
                    onClick={() => navigate(COMMON_ROUTES.catalogContainers)}
                  >
                    View More
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.info}>
              {filteredContainers.slice(0, view).map((card) => (
                <FeatureCard key={card.featureId} data={card} />
              ))}
            </div>
          </>
        )}
        {filteredCollections.length > 0 && (
          <>
            <div className={classes.catalogType}>
              Collections{" "}
              {filteredCollections.length >= view + 1 && (
                <div className={classes.viewMore}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: "Capitalize",
                      fontFamily: "qualcommNext-Regular",
                    }}
                    onClick={() => navigate(COMMON_ROUTES.catalogCollections)}
                  >
                    View More
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.info}>
              {filteredCollections.slice(0, view).map((card) => (
                <FeatureCard key={card.featureId} data={card} />
              ))}
            </div>
          </>
        )}
      </div>
    </CatalogLayout>
  );
}

export default Catalogue;
