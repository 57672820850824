import { useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CustomTextField from "../../ui/CustomTextField";
import { StyledPopper } from "../../ui/StyledPopper";

import classes from "./AssociateRoleToUserRow.module.css";

const AssociateRoleToUserRow = (props) => {
  const { user, setUserRoles, roles, userRoles } = props;
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSelectedRow = (value) => {
    const tempRoles = [...userRoles];
    if (value != null) {
      const arr = tempRoles?.filter((row) => row.id !== user.id);
      const row = { ...user, role: value.id };
      setSelectedRow(value);
      setUserRoles([...arr, row]);
    } else {
      setSelectedRow(null);
      setUserRoles((prev) => {
        return prev?.filter((row) => row.id !== user.id);
      });
    }
  };

  console.log(userRoles, "--userRoles");

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: selectedRow && "#E9F2FF" }}
    >
      <div className={classes.text}>
        {selectedRow &&
          (selectedRow?.label?.toLowerCase().includes("admin") ? (
            <AdminPanelSettingsIcon htmlColor="#4D6994" />
          ) : (
            <AccountBoxIcon htmlColor="#4D6994" />
          ))}
        <span>{user?.name}</span>
      </div>
      <Autocomplete
        disablePortal
        options={roles}
        onChange={(e, value) => handleSelectedRow(value)}
        PopperComponent={StyledPopper}
        sx={{ minWidth: "200px", outline: "none", border: "none" }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            placeholder="Roles"
            variant="standard"
            required
          />
        )}
      />
    </div>
  );
};

export default AssociateRoleToUserRow;
