import Chip from "../../ui/Chip/Chip";
import InActiveChipset from "../../ui/Chip/InactiveChip";

import classes from "./CustomerFeaturesInfoItem.module.css";

const CustomerFeaturesInfoItem = (props) => {
  const { text, chipsets, header, icon, muiicon, convertFromBase64 } = props;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {chipsets == null ||
        (chipsets.active.length === 0 && chipsets.inActive.length === 0) ? (
          <div className={classes.text}>{text || "N/A"}</div>
        ) : (
          <div className={classes.chipsets}>
            {chipsets?.active?.map((chip) => (
              <Chip key={chip} text={chip} />
            ))}
            {chipsets?.inActive?.map((chip) => (
              <InActiveChipset key={chip} text={chip} />
            ))}
          </div>
        )}
        <div className={classes.header}>{header}</div>
      </div>
      <div className={classes.img}>
        {icon != null && !convertFromBase64 && (
          <img src={icon} alt={text} className={classes.icon} />
        )}
        {icon != null && convertFromBase64 && (
          <img
            src={"data:image/*;base64," + icon}
            alt={text}
            className={classes.icon}
          />
        )}
        {muiicon != null && muiicon}
      </div>
    </div>
  );
};

export default CustomerFeaturesInfoItem;
