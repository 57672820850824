import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  deleteFeature,
  getPolicyInfoByFeatureId,
} from "../../../api/api-index";
import {
  MARK_FOR_DELETE_MESSAGES,
  DELETE_FEATURE_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./DeleteFeature.module.css";

const DeleteFeature = (props) => {
  let isClicked = true;
  const {
    onClose,
    userDetails,
    selectedAppType,
    repositoryType,
    getUpdatedDataFn,
  } = props;
  const [subscribed, setSubscribed] = useState(false);

  const onSubmit = () => {
    if (isClicked) {
      isClicked = false;
      const options = {
        qcommFeatureName: userDetails?.featureName,
        roleType: selectedAppType,
        repositoryType,
      };
      deleteFeature(options)
        .then((response) => {
          if (response.status) {
            if (response.data === "MARKED_FOR_DELETE") {
              toast.success(MARK_FOR_DELETE_MESSAGES.success);
            } else {
              toast.success(DELETE_FEATURE_MESSAGES.success);
            }
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(response.error, DELETE_FEATURE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, DELETE_FEATURE_MESSAGES.failure);
          isClicked = true;
        });
    }
  };
  useEffect(() => {
    if (repositoryType !== "Local")
      getPolicyInfoByFeatureId(userDetails?.id).then((response) => {
        if (response.status && response.data != null) {
          const activePolicy = response?.data.filter(
            (i) => i.status !== "INACTIVE"
          );
          activePolicy?.length > 0 && setSubscribed(true);
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      });
  }, [repositoryType]);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Feature" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to delete "<b>{userDetails?.featureTitle}</b>"{" "}
            ?
          </p>
          {subscribed && (
            <div className={classes.infoItem}>
              <InfoOutlinedIcon />
              {userDetails?.featureTitle} is having an active subscription{" "}
              policy, <br /> it will go into mark for delete status, such that
              no new <br />
              subscription policy can be created by using{" "}
              {userDetails?.featureTitle}.
            </div>
          )}
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteFeature;
