import { toast } from "react-toastify";

export default function errorHandler(error, defaultMessage) {
  if (error != null && Array.isArray(error?.messages) && error?.messages?.length > 0) {
    console.log("ERROR MESSAGES");
    toast.error(error.messages[0]);
  } else {
    console.log("DEFAULT ERROR");
    toast.error(defaultMessage);
  }
}
