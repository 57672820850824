import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";

import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import DataTableHeaders from "../header/DataTableHeaders";
import { deviceInfoHeaders } from "../helpers/header-helpers";
import DeviceInfoItem from "./DeviceInfoItem";
import EditDevice from "./EditDevice/EditDevice";
import { getDeviceInfo } from "../../api/getDeviceInfo";
import AssociateFeatures from "./AssociateFeatures/AssociateFeatures";

import deviceNameIcon from "../../assets/images/device/device_name.svg";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import mddIcon from "../../assets/images/device/metrics_def.svg";
import serialIcon from "../../assets/images/device/serial.svg";
import featuresInstalled from "../../assets/images/device/features-installed.svg";
import errorHandler from "../helpers/error-helper";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import { GET_MESSAGES } from "../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import DeviceFeatureEnabledList from "./DeviceFeatureEnabledList";
import { BUTTONS_TYPE } from "../../constants/panel-constants";
import editIcon from "../../assets/images/organizations/edit-organizations.svg";
import { ROLES } from "../../constants/role-constants";
import TagsTable from "./TagsTable";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";

import classes from "./DeviceInfo.module.css";
import AlertBanner from "../ui/Alert/AlertBanner";

const DeviceInfo = () => {
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const [openEditDevice, setOpenEditDevice] = useState(false);
  const [openAssociateFeatures, setOpenAssociateFeatures] = useState(false);
  const [breadCrumbArray] = useBreadcrumb("deviceInfo");

  const params = useParams();
  const { customerId, deviceId } = params;

  const [isLoading, setIsLoading] = useState(true);

  const [deviceInfo, setDeviceInfo] = useState({
    deviceFeatureIdList: [],
    deviceId: "",
    deviceName: "",
    serialNo: "",
    tenantId: "",
    featureInstalledCount: "",
  });

  const handleOpenEditDevice = () => {
    setOpenEditDevice(true);
  };

  const closeEditDevice = () => {
    setOpenEditDevice(false);
  };
  const handleOpenAssociateFeatures = () => {
    setOpenAssociateFeatures(true);
  };

  const closeAssociateFeatures = () => {
    setOpenAssociateFeatures(false);
  };

  const headers = deviceInfoHeaders({
    routes: {
      deviceInfo:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        params?.deviceId,
      health:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        params?.deviceId +
        "/health-metrics",
      qsea:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        params?.deviceId +
        "/qsea-metrics",
    },
  });
  useEffect(() => {
    getDeviceInfo({ customerId, deviceId })
      .then((response) => {
        if (response.status && response.data != null) {
          setDeviceInfo({
            ...response.data,
            tenantId: customerId,
            deviceTags: response.data.deviceTags.map((tags) => {
              return { ...tags, id: uuidv4() };
            }),
          });
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [openEditDevice, openAssociateFeatures]);

  const heading =
    deviceInfo.qualcommDeviceId != null && deviceInfo.qualcommDeviceId !== ""
      ? `${selectedTenant?.tenantName} - ${deviceInfo.qualcommDeviceId}`
      : `${selectedTenant?.tenantName} - N/A`;

  const buttons = hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.deviceManager,
    ROLES.cssAdmin,
  ])
    ? [
        {
          key: "associate",
          text: "Edit Features",
          clickHandler: handleOpenAssociateFeatures,
          disabled: isLoading,
          icon: null,
          type: BUTTONS_TYPE.circle,
        },
        {
          key: "edit",
          clickHandler: handleOpenEditDevice,
          disabled: isLoading,
          icon: <EditIcon />,
          onlyIcon: true,
          tooltipText: "Edit",
        },
      ]
    : null;

  return (
    <div className={classes.container}>
      {openEditDevice && (
        <EditDevice onClose={closeEditDevice} data={deviceInfo} />
      )}
      {openAssociateFeatures && (
        <AssociateFeatures onClose={closeAssociateFeatures} data={deviceInfo} />
      )}
      <DataTablePanelTest
        backButton={true}
        disableSearch={true}
        buttons={buttons}
      />

      {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{deviceInfo.qualcommDeviceId || "..."}</p>
      </StyledBreadcrumbs>
      {/* ) : (
        <p>{heading}</p>
      )} */}
      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        {!isLoading && !deviceInfo?.status && (
          <div className={classes.banner_Style}>
            <AlertBanner text="License activation is not applicable as device is inactive" />
          </div>
        )}
        <div className={classes.info}>
          <div className={classes.info_items}>
            <DeviceInfoItem
              text={deviceInfo.deviceName}
              header="Device Name"
              icon={deviceNameIcon}
            />
            <DeviceInfoItem
              text={deviceInfo.qualcommDeviceId}
              header="System Device Id"
              icon={serialIcon}
            />
            <DeviceInfoItem
              text={deviceInfo?.status ? "Active" : "-"}
              header="Device Status"
              muiicon={
                deviceInfo?.status ? (
                  <CheckCircleOutlineOutlinedIcon htmlColor="green" />
                ) : (
                  <CancelOutlinedIcon htmlColor="grey" />
                )
              }
            />

            <DeviceInfoItem
              text={deviceInfo.featureInstalledCount || "0"}
              header="No. of Features Installed"
              icon={featuresInstalled}
            />
            <DeviceInfoItem
              text={deviceInfo?.chipsetName || "N/A"}
              header="Chipset"
              muiicon={<DeveloperBoardIcon htmlColor="#4080d0" />}
            />
            <DeviceInfoItem
              text={
                deviceInfo?.mddName && deviceInfo?.version
                  ? `${deviceInfo?.mddName} - V${deviceInfo?.version} `
                  : "N/A"
              }
              header="MDD Installed"
              icon={mddIcon}
            />

            <div className={classes.feature_Table}>
              <DeviceFeatureEnabledList
                featureData={deviceInfo?.deviceFeatureIdList || []}
                isLoading={isLoading}
              />
            </div>
            <div className={classes.feature_Table}>
              <TagsTable data={deviceInfo?.deviceTags} isLoading={isLoading} />
            </div>
          </div>
          {/* <div className={classes.feature_container}>
            <div className={classes.featureContent}>
              <div className={classes.feature_text}>
                {deviceInfo?.chipsetName || "N/A"}
              </div>
              <div className={classes.feature_header}>Chipset </div>
            </div>
          </div> */}
        </div>
        {/* <div className={classes.feature_Table}></div> */}
      </div>
    </div>
  );
};

export default DeviceInfo;
