import IconButton from "@mui/material/IconButton";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";

const CheckIcon = (props) => {
  const {
    onClick,

    size,

    color = "rgb(33, 69, 114)",

    tooltipText = "Approve",

    disabled = false,
    isvStatus,
  } = props;
  return disabled ? (
    <IconButton
      aria-label={tooltipText}
      style={{ visibility: disabled && "hidden" }}
      size={size != null ? size : "large"}
      onClick={onClick != null ? onClick : null}
    >
      <CheckCircleIcon sx={{ color: disabled ? "grey" : color, width: 22 }} />
    </IconButton>
  ) : (
    <Tooltip title={tooltipText} disabled={isvStatus}>
      <IconButton
        aria-label={tooltipText}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <CheckCircleIcon
          sx={{ color: isvStatus ? "grey" : color, width: 22 }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CheckIcon;
