import { Fragment, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import CreateNewUser from "../../user/CreateNewUser/CreateNewUser";
import EditUser from "../../user/EditUser/EditUser";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { tenantAvailableUsersHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { availableUserList, addUsersToTenant } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../../constants/route-constants";
import { BUTTONS } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import {
  GET_MESSAGES,
  USERS_ASSOCIATION_MESSAGES,
} from "../../../constants/message-constants";
import AssociateRoleToUser from "../AssociateRoleToUser/AssociateRoleToUser";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import { OPERATION_CONSTANTS } from "../../../constants/operation-constants";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { columnsActions } from "../../../store/slices/slices-index";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";

import classes from "../../ui/DataTable.module.css";
let selectedRow = null;

const TenantAddAvailableUsersList = () => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.columnsInfo.availableUsers);
  const [searchStatefn] = useSearchKeys(
    ["name", "phoneNo", "emailId"],
    initialState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [selectedUsers, setSelectedUsers] = useState({ ids: [], rows: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditUserForm, setOpenEditUserForm] = useState(false);
  const [associateRoleToUser, setAssociateRoleToUser] = useState(false);
  const [openCreateNewUserForm, setOpenCreateNewUserForm] = useState({
    submitted: false,
    opened: false,
  });
  const [forceUpdate, setForceUpdate] = useState({});

  const params = useParams();

  const location = useLocation();
  const appType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? TENANT_TYPE.isv
    : TENANT_TYPE.customer;

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );

  const handleEdit = (params) => {
    selectedRow = params.row;
    setSelectedUsers({ ids: [], rows: [] });
    setOpenEditUserForm(!openEditUserForm);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const closeEditUserForm = () => {
    selectedRow = null;
    setOpenEditUserForm(false);
  };

  const handleOpenPanel = () => {
    setOpenCreateNewUserForm((prev) => {
      return { ...prev, opened: true };
    });
  };

  const closeCreateNewUserForm = () => {
    setOpenCreateNewUserForm((prev) => {
      return { ...prev, opened: false };
    });
  };

  const openAssociateRoleToUser = () => {
    setAssociateRoleToUser(true);
  };

  const closeAssociateRoleToUser = () => {
    setAssociateRoleToUser(false);
  };
  const handleAssociateRoleToUsers = (associatedRoles) => {
    if (associatedRoles.length > 0) {
      const getSelectedUsers = () => {
        return associatedRoles?.map((user) => {
          return {
            userId: user.id,
            userRoleInfoJson: {
              [params.tenantId || params.customerId]: {
                roles: [user.role],
                attributes: [],
                appType: appType,
                tenantName: selectedTenant?.tenantName,
              },
            },
          };
        });
      };
      const operationType = {
        opType: OPERATION_CONSTANTS.ADD,
      };
      addUsersToTenant(
        {
          organizationId: params.organizationId,
          tenantId: params.tenantId || params.customerId,
          userIdUserRoleMap: getSelectedUsers(),
        },
        operationType
      )
        .then((response) => {
          if (response) {
            let totalTrueStatus = [];
            let totalFalseStatus = [];
            response.data.map((i) =>
              i.status
                ? totalTrueStatus.push(i.userId)
                : totalFalseStatus.push(i.userId)
            );
            totalTrueStatus.length > 0 &&
              toast.success(
                `${totalTrueStatus.length} ${USERS_ASSOCIATION_MESSAGES.success} ${selectedTenant.tenantName}`
              );
            totalFalseStatus.length > 0 &&
              toast.error(
                `${totalFalseStatus.length} ${USERS_ASSOCIATION_MESSAGES.failure} ${selectedTenant.tenantName}`
              );
          } else {
            //function for error handling
          }
          setForceUpdate({});
          closeAssociateRoleToUser();
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          toast.error(
            `Failed to associate users to tenant ${selectedTenant.tenantName}`
          );
        });
    }
  };

  const handleAddUsers = () => {
    openAssociateRoleToUser();
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      flex: 1,
      minWidth: 150,
    },
  ];

  hasPermittedRolesOrAttributes([
    ROLES.isvAdmin,
    ROLES.customerAdmin,
    ROLES.cssAdmin,
  ]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 100,
      hideable: false,
      renderCell: (params) => (
        <div>
          <EditIconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(params);
            }}
          />
        </div>
      ),
    });

  const headers = tenantAvailableUsersHeaders({
    routes: {
      tenantAvailableUsers:
        params.tenantId != null
          ? CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.isvTenants +
            "/" +
            params.tenantId +
            PARTIAL_ROUTES.associatedUsers +
            PARTIAL_ROUTES.availableUsers
          : params.customerId != null
          ? CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.customerTenants +
            "/" +
            params.customerId +
            PARTIAL_ROUTES.associatedUsers +
            PARTIAL_ROUTES.availableUsers
          : "",
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      organizationId: selectedTenant?.organizationId,
      tenantId: params.tenantId || params.customerId,
    };

    availableUserList(options)
      .then((response) => {
        if (
          response.status &&
          response.data.userDetails != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.userDetails.map((item) => {
            return {
              id: item.userId,
              userId: item.userId,
              name: `${item.firstName} ${item.lastName}`,
              firstName: item.firstName,
              lastName: item.lastName,
              emailId: item.emailId,
              phoneNo: item.phoneNo,
              tenantName: item.tenants?.toString(),
              keycloakId: item.keycloakUserId,
              __check__: { hideable: false },
            };
          });
          setUsersList({ count: response.data.count, list: formattedList });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [
    pageNo,
    openEditUserForm,
    openCreateNewUserForm.submitted,
    openCreateNewUserForm.opened,
    forceUpdate,
  ]);

  return (
    <Fragment>
      {openEditUserForm && (
        <EditUser onClose={closeEditUserForm} userDetails={selectedRow} />
      )}
      {associateRoleToUser && (
        <AssociateRoleToUser
          onClose={closeAssociateRoleToUser}
          users={selectedUsers.rows}
          addUsers={handleAssociateRoleToUsers}
          appType={appType}
        />
      )}
      {openCreateNewUserForm.opened && (
        <CreateNewUser
          onClose={closeCreateNewUserForm}
          organizationId={selectedTenant?.organizationId}
          setSubmittedState={setOpenCreateNewUserForm}
        />
      )}
      <div className={classes.container}>
        <DataTablePanel
          name={BUTTONS.createUser}
          list={usersList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={true}
          clickHandler={handleOpenPanel}
          selectedUsers={selectedUsers.ids}
          addUsersClickHandler={handleAddUsers}
        />
        <p>Add Users - {selectedTenant?.tenantName}</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setavailableUsersColumnsState(newModel))
            }
            selectionModel={selectedUsers.ids}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            checkboxSelection={true}
            disableColumnFilter
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = filteredList.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedUsers({ ids: ids, rows: selectedRows });
            }}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  usersList.list.length === filteredList.length
                    ? usersList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.users },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TenantAddAvailableUsersList;
