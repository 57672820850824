import { useSelector } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Fragment } from "react";
import {
  IoChevronDown,
  IoChevronForward,
  IoArrowForwardSharp,
} from "react-icons/io5";
import { PARTIAL_ROUTES } from "../../constants/route-constants";

import classes from "./ListItem.module.css";

const ListItem = (props) => {
  const { details, customerTenants, opened, type, subMenu } = props;
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate(details.route);
  };

  const active = location.pathname.includes(details.route)
    ? true
    : customerTenants != null && location.pathname === customerTenants.route
    ? true
    : false;

  return (
    <div>
      <div
        className={active ? classes["active-container"] : classes.container}
        onClick={onClick}
      >
        <div className={classes.img}>
          <div
            className={
              active ? classes.indicator : classes["transparent-indicator"]
            }
          ></div>
          {details.ricon == null && (
            <img
              src={active ? details.imgWhite : details.img}
              alt={details.name}
            />
          )}
          {details.ricon && (active ? details.imgWhite : details.img)}
        </div>
        {opened && (
          <div className={classes.name}>
            <span>{details.name}</span>
            {active && subMenu ? (
              <IoChevronDown size="1.3em" color="#FFFFFF" />
            ) : active ? (
              <IoChevronForward size="1.3em" color="#FFFFFF" />
            ) : null}
          </div>
        )}
      </div>

      {subMenu && active && opened ? (
        <div className={classes.subMenu}>
          {subMenu.map((item) => (
            <NavLink
              key={item.name}
              to={item.route}
              className={({ isActive }) =>
                isActive ? classes.sub_active : classes.sub_not_active
              }
            >
              {({ isActive }) => (
                <Fragment>
                  <div className={classes.img}>
                    <img
                      src={isActive ? item.imgWhite : item.img}
                      alt={item.name}
                    />
                    {item.name}
                  </div>
                  {isActive && (
                    <IoArrowForwardSharp size="1.3em" color="#FFFFFF" />
                  )}
                </Fragment>
              )}
            </NavLink>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ListItem;
