import { createSlice } from "@reduxjs/toolkit";

const initialFeaturesState = {
  selectedFeature: null,
};

const featuresSlice = createSlice({
  name: "features",
  initialState: initialFeaturesState,
  reducers: {
    setSelectedFeature(state, action) {
      state.selectedFeature = action.payload;
    },
  },
});

const featuresActions = featuresSlice.actions;

export { featuresSlice, initialFeaturesState, featuresActions };
