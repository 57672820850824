import classes from "./SubscriberInfoItem.module.css";

const SubscriberInfoItem = (props) => {
  const { text, header, icon, convertFromBase64, muiicon } = props;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.text}>{text || "N/A"}</div>
        <div className={classes.header}>{header}</div>
      </div>
      <div className={classes.img}>
        {icon != null && convertFromBase64 == null && (
          <img src={icon} alt={text} className={classes.icon} />
        )}
        {icon != null && convertFromBase64 != null && (
          <img
            src={"data:image/*;base64," + icon}
            alt={text}
            className={classes.icon}
          />
        )}
        {muiicon != null && muiicon}
      </div>
    </div>
  );
};

export default SubscriberInfoItem;
