import axios from "axios";
import { getCloudDeviceApi } from "./apiEndpoints";

export const getCloudDevice = async (payload) => {
    let data;
    await axios
        .get(getCloudDeviceApi, {
            params: {
                ...payload,
            },
        })
        .then((response) => {
            data = response.data;
        }
        )
        .catch((error) => {
            console.log(error);
            data = error.response;
            data.status = false;
        });
    return data;
};
