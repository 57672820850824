import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import DataTablePanel from "../../../panel/DataTablePanel";
import DataTableHeaders from "../../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import { featureRepositoriesHeaders } from "../../../helpers/header-helpers";
import CustomPagination from "../../../ui/CustomPagination";
import CustomToolbar from "../../../ui/CustomToolbar";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import { getAllRepo } from "../../../../api/api-index";
import { PAGE_SIZE } from "../../../../constants/page-constants";
import { CSS_ROUTES } from "../../../../constants/route-constants";
import DeleteIcon from "../../../ui/Buttons/DeleteIcon";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import errorHandler from "../../../helpers/error-helper";
import { REPO_TYPE } from "../../../../constants/repo-constants";
import unMarkIcon from "../../../../assets/images/features/unmark.svg";
// import DeleteGlobalRepo from "../deleteRepo/DeleteGlobalRepo";
import DeleteFeature from "../../DeleteFeature/DeleteFeature";
import UnmarkFeature from "../../UnmarkFeature/UnmarkFeature";
import useSearchKeys from "../../../../hooks/useSearchKeys";
import { columnsActions } from "../../../../store/slices/columns-slice";
import { NO_ROW_MESSAGES } from "../../../../constants/no-row-message-constants";
import CommonFilter from "../../../ui/Form/commonFilter/CommonFilter";
import { BUTTONS, BUTTONS_TYPE } from "../../../../constants/panel-constants";
import { Badge } from "@mui/material";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";

import classes from "../../../ui/DataTable.module.css";

let selectedRow = null;

const GlobalRepositories = () => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.columnsInfo.globalRepo);
  const [searchStatefn] = useSearchKeys(
    ["organizationName", "tenantName", "featureTitle", "latestVersion"],
    initialState
  );

  const selectedOrganizationType = useSelector(
    (state) => state.userInfo.userInfo?.orgType
  );

  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );

  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    organizationName: "",
    featureTitle: "",
    tenantName: "",
    pageNo: 0,
    isFilterApplied: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [globalFeatureVersionList, setGlobalFeatureVersionList] = useState({
    count: 0,
    list: [],
  });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openDeleteRepo, setOpenDeleteRepo] = useState(false);
  const [openMarkFeature, setOpenMarkFeature] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    /*TODO: change route */
    navigate(CSS_ROUTES.globalRepositories + "/" + params?.row?.featureName);
  };

  const handlePageChange = (_, newPage) => {
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
    setIsLoading(true);
  };
  const handleOpenDeleteRepo = (params) => {
    selectedRow = {
      featureName: params.row.featureName,
      featureTitle: params.row.featureTitle,
      id: params.row.featureId,
    };
    setOpenDeleteRepo(true);
  };
  const handleOpenUnmarkFeature = (params) => {
    selectedRow = { ...params.row, id: params.row.featureId };
    setIsLoading(true);

    setOpenMarkFeature(true);
  };
  const closeUnmarkFeature = () => {
    selectedRow = null;
    setOpenMarkFeature(false);
  };
  const closeDeleteRepo = () => {
    selectedRow = null;
    setOpenDeleteRepo(false);
  };
  const columns = [
    {
      field: "featureTitle",
      headerName: "Feature Title",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "organizationName",
      headerName: "Organization",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "latestVersion",
      headerName: "Latest Version",
      flex: 1,
      minWidth: 150,
    },
  ];

  selectedOrganizationType != "Internal" &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 0.2,
      sortable: false,
      minWidth: 200,
      hideable: false,
      renderCell: (params) =>
        params?.row?.isDeletable &&
        (params.row.cssStatus != "MARKED_FOR_DELETE" ? (
          <DeleteIcon
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteRepo(params);
            }}
          />
        ) : (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleOpenUnmarkFeature(params);
            }}
          >
            <Tooltip title="Unmark feature">
              <img src={unMarkIcon} />
            </Tooltip>
          </IconButton>
        )),
    });
  const headers = featureRepositoriesHeaders({
    routes: {
      localRepo: CSS_ROUTES.localRepositories,
      globalRepo: CSS_ROUTES.globalRepositories,
    },
  });

  function fetchFiltereredGlobalRepo() {
    const options = {
      repositoryType: REPO_TYPE.global,
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      featureTitle: filterOptions.featureTitle
        ? filterOptions.featureTitle.trim()
        : undefined,
      organizationName: filterOptions.organizationName
        ? filterOptions.organizationName.trim()
        : undefined,
      tenantName: filterOptions.tenantName
        ? filterOptions.tenantName.trim()
        : undefined,
    };

    getAllRepo(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.featureRepositoryList != null &&
          response.data.totalCount != null
        ) {
          const formattedList =
            response.data.featureRepositoryList.map((item) => {
              return {
                id: item?.qcommFeatureName,
                organizationName: item?.organizationName,
                tenantName: item?.tenantName,
                featureName: item?.qcommFeatureName,
                featureTitle: item?.featureTitle,
                featureId: item?.featureId,
                isDeletable: item?.isDeletable,
                latestStatus: item?.featureRepositoryDetailsList,
                cssStatus: item?.cssStatus,
                latestVersion:
                  item.featureRepositoryDetailsList != null
                    ? item.featureRepositoryDetailsList[0].version
                    : "-",
              };
            }) || [];
          setGlobalFeatureVersionList({
            count: response.data.totalCount,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  const filterableitems = [
    {
      name: "featureTitle",
      label: "Feature Title",
      placeholder: "Enter feature title",
      defaultValue: filterOptions.featureTitle,
    },
    {
      name: "organizationName",
      label: "Organization Name",
      placeholder: "Enter organization name",
      defaultValue: filterOptions.organizationName,
    },
    {
      name: "tenantName",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenantName,
    },
  ];

  useEffect(() => {
    fetchFiltereredGlobalRepo();
  }, [filterOptions]);

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      organizationName: data.organizationName,
      featureTitle: data.featureTitle,
      tenantName: data.tenantName,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  function fetchUpdated() {
    if (
      filterOptions.pageNo > 0 &&
      globalFeatureVersionList?.list?.length === 1
    ) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFiltereredGlobalRepo();
    }
  }

  const buttons = [
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 4
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              organizationName: "",
              featureTitle: "",
              tenantName: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openMarkFeature && (
        <UnmarkFeature
          onClose={closeUnmarkFeature}
          userDetails={selectedRow}
          roleType={selectedAppType}
          getUpdatedDataFn={fetchFiltereredGlobalRepo}
        />
      )}
      {openDeleteRepo && (
        <DeleteFeature
          onClose={closeDeleteRepo}
          userDetails={selectedRow}
          repositoryType={REPO_TYPE.global}
          selectedAppType={selectedAppType}
          getUpdatedDataFn={fetchUpdated}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={false}
          disableSearch={true}
          buttons={buttons}
        />
        <p>Feature Repositories</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.7rem",
                wordBreak: "break-word",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : globalFeatureVersionList.list}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setglobalRepoColumnsState(newModel))
            }
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              if (params.row.cssStatus === "MARKED_FOR_DELETE") {
                return "status";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: globalFeatureVersionList.count,

                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.globalRepositories,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GlobalRepositories;
