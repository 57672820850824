import { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import DataTablePanel from "../panel/DataTablePanel";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import CustomPagination from "../ui/CustomPagination";
import { customerDevicesHeaders } from "../helpers/header-helpers";
import EditIconButton from "../ui/Buttons/EditIconButton";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import DataTableHeaders from "../header/DataTableHeaders";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import { getAllMddList } from "../../api/api-index";
import AssociateDeviceIcon from "../ui/Buttons/AssociateDeviceIcon";
import { PAGE_SIZE } from "../../constants/page-constants";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { BUTTONS } from "../../constants/panel-constants";
import CreateMDD from "./createMDD/CreateMDD";
import DeleteMDD from "./deleteMDD/DeleteMDD";
import EditMDD from "./editMDD/EditMDD";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import { RETENTION_PERIOD } from "../../constants/time-constants";
import useSearchKeys from "../../hooks/useSearchKeys";
import { columnsActions } from "../../store/slices/columns-slice";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;
const MDD = () => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.columnsInfo.mddList);
  const [searchStatefn] = useSearchKeys(
    ["mddName", "version", "retentionPeriod"],
    initialState
  );
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );

  const [isLoading, setIsLoading] = useState(true);
  const [createMDD, setCreateMDD] = useState(false);
  const [editMDD, setEditMDD] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [breadCrumbArray] = useBreadcrumb("devices");
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const [mddList, setMddList] = useState({
    count: 0,
    list: [],
  });
  const [filteredList, setFilteredList] = useState([]);
  const [deleteMDD, setDeleteMDD] = useState(false);

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleRowClick = (param) => {
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        param.id
    );
  };

  const closeDeleteMDD = () => {
    setDeleteMDD(false);
  };

  const openDeleteMDD = (params) => {
    selectedRow = params.row;
    setDeleteMDD(true);
  };

  const columns = [
    {
      field: "mddName",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
    {
      field: "version",
      headerName: "Latest Version",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "retentionPeriod",
      headerName: "Retention Period",
      flex: 1,
      minWidth: 150,

      renderCell: (param) => {
        return (
          <div>
            {
              RETENTION_PERIOD.find(
                (item) => item.value === param.row.retentionPeriod
              ).label
            }
          </div>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 150,
      hideable: false,
      renderCell: (param) => {
        const isActionAllowed = param.row.status != null ? true : false;
        return (
          <div>
            <EditIconButton
              disabled={isActionAllowed}
              size="small"
              tooltipText={isActionAllowed ? "Deletion in progress" : "Edit"}
              onClick={(event) => {
                selectedRow = param.row;
                event.ignore = true;
                setEditMDD(true);
              }}
            />
            {isQualcommManagingDevice ? (
              <AssociateDeviceIcon
                size="small"
                disabled={isActionAllowed}
                tooltipText={
                  isActionAllowed
                    ? "Deletion in progress"
                    : " Associate Devices"
                }
                onClick={(event) => {
                  event.ignore = true;
                  navigate(
                    CSS_ROUTES.organizations +
                      "/" +
                      params.organizationId +
                      PARTIAL_ROUTES.customerTenants +
                      "/" +
                      params.customerId +
                      PARTIAL_ROUTES.mdd +
                      "/" +
                      param.id +
                      "/available-devices"
                  );
                }}
              />
            ) : null}
            <DeleteIcon
              disabled={isActionAllowed}
              tooltipText={isActionAllowed ? "Deletion in progress" : " Delete"}
              onClick={(event) => {
                event.stopPropagation();
                openDeleteMDD(param);
              }}
              size="small"
            />
          </div>
        );
      },
    },
  ];

  const headers = customerDevicesHeaders({
    routes: {
      deviceList: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.devices
        : null,

      customerUsers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.associatedUsers
        : null,

      features: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features
        : null,
      mdd:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd,
      mddDashboard: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mddDashboard
        : null,
    },
  });

  function fetchAllMddList() {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      Id: params.customerId,
    };

    getAllMddList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.mddList != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.mddList.map((item) => {
              return {
                id: item?.mddId,
                mddId: item?.mddId || "-",
                mddName: item?.mddName || "-",
                version: `v${item?.version}` || "-",
                status: item?.status,
                retentionPeriod: item?.retentionPeriod,
              };
            }) || [];
          setMddList({
            count: response.data.count,
            list: formattedList,
          });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchAllMddList();
  }, [pageNo]);
  return (
    <Fragment>
      {createMDD && (
        <CreateMDD
          fetchAllMddList={fetchAllMddList}
          onClose={() => setCreateMDD(false)}
        />
      )}
      {editMDD && (
        <EditMDD
          fetchAllMddList={fetchAllMddList}
          onClose={() => setEditMDD(false)}
          selectedRow={selectedRow}
        />
      )}
      {deleteMDD && (
        <DeleteMDD
          onClose={closeDeleteMDD}
          data={selectedRow}
          refreshData={fetchAllMddList}
        />
      )}
      <div className={classes.container}>
        <DataTablePanel
          name={BUTTONS.createMdd}
          list={mddList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={
            hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? true : false
          }
          clickHandler={() => setCreateMDD(true)}
        />
        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}

        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.5rem",
                wordBreak: "break-word",
                // padding: "1em",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setMddList(newModel))
            }
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params, event) => {
              if (!event.ignore) {
                handleRowClick(params);
              }
            }}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }

              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  mddList.list.length === filteredList.length
                    ? mddList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.metrics },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MDD;
