export const HEADERS = {
  organizationsList: {
    name: "Organizations",
  },
  organizationInfo: {
    name: "Organization Info",
  },
  users: {
    name: "Users",
  },
  isvTenants: {
    name: "ISV Tenants",
  },
  customerTenants: {
    name: "Customer Tenants",
  },
  isvUsers: {
    name: "Associated Users",
  },
  customerUsers: {
    name: "Associated Users",
  },
  isvAvailableUsers: {
    name: "Available Users",
  },
  isvList: {
    name: "ISVs",
  },
  LinuxSdk:{
    name:"QSEALinuxSDK"
  },

  AndroidSdk:{
    name:"QSEAAndroidSDK"
  },

  customersList: {
    name: "Customers",
  },
  myFeatures: {
    name: "Features",
  },
  associatedUsers: {
    name: "Associated Users",
  },
  featureInfo: {
    name: "Feature Info",
  },
  devices: {
    name: "Devices",
  },
  myModels: {
    name: "My Models",
  },
  myContainers: {
    name: "My Containers",
  },
  subscriptionPolicyInfo: {
    name: "Subscription Policy",
  },
  deviceInfo: {
    name: "Device Info",
  },
  tenantAvailableUsers: {
    name: "Available Users",
  },
  myFeatureDevicesList: {
    name: "Devices",
  },
  availableDevicesList: {
    name: "Devices",
  },
  devicesAndLicenses: {
    name: "Device & Licenses",
  },
  versionHistory: {
    name: "Version History",
  },
  features: {
    name: "Features",
  },
  subscribers: {
    name: "Subscribers",
  },
  subscriberInfo: {
    name: "Subscriber Info",
  },
  alerts: {
    name: "Alerts",
  },
  events: {
    name: "Events",
  },
  contents: {
    name: "Contents",
  },
  localRepo: {
    name: "Local Repository",
  },
  globalRepo: {
    name: "Global Repository",
  },
  licenseInfo: {
    name: "Devices & Licenses",
  },
  about: {
    name: "About",
  },
  ownership: {
    name: "Feature Ownership",
  },
  mdd: {
    name: "Metrics Definition List",
  },
  mddDashboard: {
    name: "Metrics Dashboard",
  },
  mddInfo: {
    name: "Metrics Definition Info",
  },
  mddAssociatedDevices: {
    name: "Associated Devices",
  },
  availableDevicesMDD: {
    name: "Available Devices",
  },
  healthMetrics: {
    name: "Device Health Metrics",
  },
  qseaMetrics: {
    name: "QSEA Platform Metrics",
  },
  repoInfo: {
    name: "Repository Info",
  },
  dashboardMetricDetails: {
    name: "Detailed Metrics",
  },
};
