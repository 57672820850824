import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  organizationsSlice,
  tenantsSlice,
  globalSlice,
  featuresSlice,
  userSlice,
  subscribersSlice,
  devicesLicensesSlice,
  columnsSlice,
  metricsSlice,
  customerDeviceSlice,
} from "./slices/slices-index";

const combinedReducers = combineReducers({
  organizationsInfo: organizationsSlice.reducer,
  tenantsInfo: tenantsSlice.reducer,
  globalInfo: globalSlice.reducer,
  featuresInfo: featuresSlice.reducer,
  userInfo: userSlice.reducer,
  subscriberInfo: subscribersSlice.reducer,
  devicesLicensesInfo: devicesLicensesSlice.reducer,
  columnsInfo: columnsSlice.reducer,
  metricsInfo: metricsSlice.reducer,
  customerDeviceInfo: customerDeviceSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "REDUX_STORE_RESET") {
    state = undefined;
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

const config = () => store;

const StoreConfig = {
  config,
};

export default StoreConfig;
