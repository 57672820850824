import { useNavigate } from "react-router-dom";

import { CSS_ROUTES } from "../../constants/route-constants";
import CustomIframe from "../ui/customIframe/CustomIframe";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../api/iframeEndpoints";
import FullScreenIcon from "../ui/Buttons/FullScreenIcon";
import LightTooltip from "../ui/Tooltip/LightToolTip";
import useTimeStatus from "../../hooks/useTimeStatus";

import classes from "./license/DeviceLicensesStat.module.css";

const MicorservicesCrashesStats = () => {
  const navigate = useNavigate();
  const [dateRange, handleTime] = useTimeStatus();
  const handleOpenDetails = () => {
    navigate(CSS_ROUTES.microservicesCrashCount);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>Cloud Microservices crashes</span>
        <LightTooltip title="Expand for more details" placement="top" arrow>
          <div>
            <FullScreenIcon onClick={() => handleOpenDetails()} />
          </div>
        </LightTooltip>
      </div>
      <div className={classes.invisibleOverlay}></div>
      <CustomIframe
        src={`${IFRAME_BASE_URL.baseUrl}/u_SNpaF4k/${DASHBOARD_NAME.cssAdmin}?orgId=1&theme=light&from=${dateRange.from}&to=${dateRange.to}&panelId=6`}
      />
    </div>
  );
};
export default MicorservicesCrashesStats;
