import axios from "axios";
import { uploadFileApi } from "./apiEndpoints";

export const getOrgIconData = async (options) => {
  let data = null,
    url = `${uploadFileApi}/icon`;
  await axios
    .get(url, {
      params: {
        iconPath: options.iconPath,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
