import BackdropOverlayModal from "../ui/BackdropOverlayModal";
import CancelButton from "../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../ui/CommonHeader";
import { deleteSDK } from "../../api/api-index";
import errorHandler from "../helpers/error-helper";
import classes from "./DeleteSdkVersion.module.css";
import { toast } from "react-toastify";
import {
    SDK_DELETE_MESSAGES,
  } from "../../constants/message-constants";
const DeleteSdkVersion = (props) => {
    let isClicked = true;
  const { onClose, versionDetails } = props;
  const onSubmit = () => {
    if (isClicked) {
      isClicked = false;
      const options = {
        repositoryType: versionDetails?.repositoryType,
        version: versionDetails?.version,
      };
      deleteSDK(options)
        .then((response) => {
            console.log(response)
          if (response.status) {
            if (response.data === "SUCCESS") {
              toast.success(SDK_DELETE_MESSAGES.success);
            } 
            onClose();
          } else {
            errorHandler(response.error, SDK_DELETE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, SDK_DELETE_MESSAGES.failure);
          isClicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Version" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to delete version"<b>{versionDetails?.version}</b>"{" "}
            ?
          </p>
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteSdkVersion;
