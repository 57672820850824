import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUserInfoByUserName } from "../../api/api-index";
import KeycloakConfig from "../../config/keycloak-config";
import { ENTITY_TYPE } from "../../constants/keycloak-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../constants/route-constants";
import { fetchOrganizationInfo } from "../../store/actions/organizations-actions";
import { tenantsActions } from "../../store/slices/tenants-slice";
import { userActions } from "../../store/slices/user-slice";
import { OptionsButton } from "../ui/Buttons/OptionsButton";
import TenantsButton from "../ui/Buttons/TenantsButton";
import CleanBackdropOverlayModal from "../ui/CleanBackdropOverlayModal";

import classes from "./Tenants.module.css";

const Tenants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);
  const handleTenantsClick = () => {
    setOpen(!open);
  };

  const handleTenantClick = (key) => {
    dispatch(userActions.setSelectedAppKey(key));
    if (userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.css) {
      navigate(CSS_ROUTES.organizations);
    } else if (userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.isv) {
      const data = {
        tenantId: key,
        tenantName: userInfo.userRoleInfoJson[key].tenantName,
        organizationId: userInfo.organizationId,
        organization: selectedOrganization?.name,
        tenantType: userInfo.userRoleInfoJson[key].appType,
      };
      dispatch(tenantsActions.setSelectedTenant(data));
      navigate(
        `${CSS_ROUTES.organizations}/${selectedOrganization.organizationId}${PARTIAL_ROUTES.isvTenants}/${key}${PARTIAL_ROUTES.myFeatures}`
      );
    } else if (
      userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.customer
    ) {
      const data = {
        tenantId: key,
        tenantName: userInfo.userRoleInfoJson[key].tenantName,
        organizationId: userInfo.organizationId,
        organization: selectedOrganization?.name,
        tenantType: userInfo.userRoleInfoJson[key].appType,
        isQualcommManagingDevice:
          userInfo.userRoleInfoJson[key].isQualcommManagingDevice,
      };
      dispatch(tenantsActions.setSelectedTenant(data));
      // !userInfo.userRoleInfoJson[key].isQualcommManagingDevice
      //   ? navigate(
      //       `${CSS_ROUTES.organizations}/${selectedOrganization.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.features}`
      //     )
      //   : navigate(
      //       `${CSS_ROUTES.organizations}/${selectedOrganization.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.devices}`
      //     );
      navigate(
        `${CSS_ROUTES.organizations}/${selectedOrganization.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.devices}`
      );
    }
    setOpen(false);
  };

  const userRolesInTenancies = [];

  for (const key in userInfo?.userRoleInfoJson) {
    if (Object.hasOwnProperty.call(userInfo?.userRoleInfoJson, key)) {
      const element = userInfo?.userRoleInfoJson[key];
      userRolesInTenancies.push(
        <OptionsButton
          sx={{
            backgroundColor: selectedAppKey === key && "#2e5f9b",
            color: selectedAppKey === key && "#fff",
          }}
          key={key}
          variant="text"
          onClick={(e) => handleTenantClick(key)}
        >
          <div className={classes.option}>
            <abbr
              title={element?.tenantName}
              style={{ textDecoration: "none" }}
            >
              <span>{element?.tenantName.trimEllip(35)}</span>
            </abbr>
            <span>{`[${element?.appType}]`}</span>
          </div>
        </OptionsButton>
      );
    }
  }

  useEffect(() => {
    const options = {
      organizationId: userInfo?.organizationId,
    };
    dispatch(fetchOrganizationInfo(options));
  }, [open]);

  useEffect(() => {
    if (open) {
      const options = {
        userName: KeycloakConfig.getUserId(),
      };
      getUserInfoByUserName(options)
        .then((response) => {
          if (response.status && response.data != null) {
            dispatch(userActions.setUserInfoState(response.data));
          }
        })
        .catch((error) => console.error("ERROR: ", error));
    }
  }, [open]);

  return (
    <div className={classes.container}>
      <TenantsButton onClick={handleTenantsClick} active={open} />
      {open && (
        <CleanBackdropOverlayModal onClick={handleTenantsClick}>
          <div className={classes.list}>{userRolesInTenancies}</div>
        </CleanBackdropOverlayModal>
      )}
    </div>
  );
};

export default Tenants;
