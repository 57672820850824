import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Fragment } from "react";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import BasicForm from "../../ui/Form/BasicForm";
import { schema } from "./ChangePasswordSchema";
import { editUser } from "../../../api/api-index";
import { CHANGE_PASSWORD_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";

import classes from "../../ui/Form/FormContainer.module.css";

const ChangePassword = (props) => {
  const { onClose, userDetails, getUpdatedDataFn } = props;
  let clicked = true;
  const { userId, firstName, lastName, phoneNo, keycloakId, noBackdrop } =
    userDetails;

  const orgId = useSelector(
    (state) => state.organizationsInfo.selectedOrganization?.organizationId
  );

  const editFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        userId: userId,
        phoneNo: phoneNo,
        password: data.password || null,
        keyCloakEditUserDetails: {
          id: keycloakId,
          enabled: null,
          totp: null,
          emailVerified: null,
          firstName: firstName,
          lastName: lastName,
          attributes: {
            organizationId: [orgId],
          },
        },
      };
    }

    return request;
  };
  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      editUser(editFormRequest(data), orgId)
        .then((response) => {
          if (response.status) {
            toast.success(CHANGE_PASSWORD_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(response.error, CHANGE_PASSWORD_MESSAGES.failure);
          }
          onClose && onClose();
          clicked = true;
        })
        .catch((error) => {
          clicked = true;
          console.error("ERROR: ", error);
          errorHandler(error, CHANGE_PASSWORD_MESSAGES.failure);
        });
    }
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs: [
      {
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        type: "password",
      },
      {
        name: "confirmPassword",
        label: "Confirm Password",
        placeholder: "Confirm password",
        type: "password",
      },
    ],
  };

  return noBackdrop != null ? (
    <Fragment>
      <CommonHeader heading="Change Password" onClose={onClose} />
      <BasicForm details={details} />
    </Fragment>
  ) : (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Change Password" onClose={onClose} />
        <BasicForm details={details} />
      </div>
    </BackdropOverlayModal>
  );
};

export default ChangePassword;
