import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import CustomTextField from "../../ui/CustomTextField";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import closeIcon from "../../../assets/images/common/close-icon.svg";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import styles from "./EditDevice.module.css";
export default function TagDataTable({
  tagsData = [],
  setTagsData,
  isLoading,
}) {
  const rows = [...tagsData];
  const [rowId, setRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState({
    id: null,
    keyValue: null,
  });
  const pattern = "^[a-zA-Z0-9 ]+$";

  const handleSave = () => {
    if (selectedRow.keyValue) {
      const data = {
        tagValue: selectedRow.keyValue.trim(),
      };
      const isMatching = data.tagValue.match(pattern) ? true : false;
      if (selectedRow.id && data.tagValue && isMatching) {
        let itemIndex = tagsData.findIndex(
          (element) => element.id === selectedRow.id
        );

        tagsData[itemIndex] = {
          ...tagsData[itemIndex],
          tagValue: data?.tagValue,
        };

        setRowId(null);
      }
    } else {
      setRowId(null);
    }
  };

  const columns = [
    {
      field: "tag",
      headerName: "Key",
      minWidth: 250,
      editable: false,
      flex: 1,
    },
    {
      minWidth: 250,
      field: "tagValue",
      headerName: "Value",
      flex: 1,
      renderCell: (params) => {
        return params?.row?.id === rowId ? (
          <CustomTextField
            id={params?.row?.id}
            type={"text"}
            size="small"
            focused
            defaultValue={params?.row?.tagValue}
            error={
              selectedRow.keyValue &&
              !selectedRow.keyValue.match(pattern) &&
              true
            }
            onChange={(event) => {
              setSelectedRow({
                id: event?.target?.id,
                keyValue: event.target?.value,
              });
            }}
            inputProps={{ maxLength: 255 }}
          />
        ) : (
          params?.row?.tagValue
        );
      },
    },
  ];

  columns.push({
    field: "Actions",
    headerName: "Actions",
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const setOpenEdit = () => {
        setRowId(params?.row?.id);
      };
      const setCloseEdit = () => {
        setRowId(null);
        setSelectedRow({
          id: null,
          keyValue: null,
        });
      };

      const deleteFeatureFromTable = () => {
        let itemIndex = tagsData.filter((element) => {
          if (element.id !== params.row.id) {
            return element;
          }
        });

        setTimeout(() => {
          setTagsData(itemIndex);
        });
      };

      return params?.row?.id !== rowId ? (
        <div>
          <EditIconButton size="small" onClick={setOpenEdit} />
          <DeleteIcon size="small" onClick={() => deleteFeatureFromTable()} />
        </div>
      ) : (
        <div>
          <IconButton
            size="small"
            disabled={
              selectedRow.keyValue && !selectedRow.keyValue.match(pattern)
            }
            onClick={handleSave}
          >
            <DoneIcon />
          </IconButton>
          <IconButton size="small" onClick={setCloseEdit}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
      );
    },
  });

  return (
    <div style={{ height: 250, width: "100%" }}>
      <DataGrid
        sx={{
          mb: 1,
          fontFamily: "QualcommNext-Regular",
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "0.8rem",
            wordBreak: "break-word",
          },
          "& .MuiDataGrid-cell": {
            // border: "none",

            paddingLeft: "1em",

            "&:focus-within": {
              outline: "none",
            },
          },
        }}
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        experimentalFeatures={{ newEditingApi: false }}
        hideFooter
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowMessage,
        }}
        componentsProps={{
          noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.tagData },
        }}
      />
      {selectedRow.keyValue && !selectedRow.keyValue.match(pattern) ? (
        <span className={styles.disclaimer}>
          * Only alphanumeric characters are allowed
        </span>
      ) : (
        ""
      )}
    </div>
  );
}
