export const EDIT_USER_MESSAGES = {
  success: "User details updated successfully",
  failure: "Failed to update user details",
};
export const CREATE_USER_MESSAGES = {
  success: "User created successfully",
  failure: "Failed to create user",
};
export const CREATE_TENANT_MESSAGES = {
  success: "Tenant created successfully",
  tenantNameExists: "Tenant name already exists",
  failure: "Failed to create tenant",
};
export const EDIT_TENANT_MESSAGES = {
  success: "Tenant details updated successfully",
  failure: "Failed to update tenant details",
};
export const CREATE_ORGANIZATION_MESSAGES = {
  success: "Organization created successfully",
  failure: "Failed to create organization",
};
export const EDIT_ORGANIZATION_MESSAGES = {
  success: "Organization details updated successfully",
  failure: "Failed to update organization details",
};
export const DELETE_USER_MESSAGES = {
  success: "User deleted successfully",
  failure: "Failed to delete user",
};
export const DELETE_TENANT_MESSAGES = {
  success: "Tenant deleted successfully",
  failure: "Failed to delete tenant",
};
export const DELETE_ORGANIZATION_MESSAGES = {
  success: "Organization deleted successfully",
  failure: "Failed to delete organization",
};
export const ADD_LOGO_MESSAGES = {
  success: "Logo added successfully",
  failure: "Failed to add logo",
};
export const UPDATE_LOGO_MESSAGES = {
  success: "Logo updated successfully",
  failure: "Failed to update logo",
};
export const USERS_ASSOCIATION_MESSAGES = {
  success: "User(s) added to",
  failure: "User(s) are not added to",
};
export const CHANGE_PASSWORD_MESSAGES = {
  success: "Password changed successfully",
  failure: "Failed to change password",
};
export const CREATE_FEATURE_MESSAGES = {
  success: "Feature created successfully",
  failure: "Failed to create feature",
};

export const CONFIGURE_FEATURE_MESSAGES = {
  success: "Feature configured successfully",
  failure: "Failed to configure feature",
};

export const EDIT_FEATURE_MESSAGES = {
  success: "Feature updated successfully",
  failure: "Failed to update feature",
};
export const DELETE_FEATURE_MESSAGES = {
  success: "Feature deleted successfully",
  failure: "Failed to delete feature",
};
export const EDIT_POLICY_TERMS_MESSAGES = {
  success: "Feature policy updated successfully",
  failure: "Failed to update feature policy",
};
export const EDIT_DEVICE_MESSAGES = {
  success: "Device details updated successfully",
  failure: "Failed to update device details",
};
export const CREATE_SUBSCRIPTION_POLICY_MESSAGES = {
  success: "New policy created successfully",
  failure: "Failed to create new policy",
};
export const EDIT_SUBSCRIPTION_POLICY_MESSAGES = {
  success: "Policy updated successfully",
  failure: "Failed to update policy",
};
export const REMOVE_USER_FROM_TENANCY_MESSAGES = {
  success: "User successfully removed from tenancy",
  failure: "Failed to remove user from tenancy",
};
export const ASSOCIATE_ROLE_TO_USERS_MESSAGES = {
  success: "Successfully associated role to user",
  failure: "Failed to associate role to user",
};
export const DELETE_SUBSCRIPTION_POLICY_MESSAGES = {
  success: "Policy deleted successfully",
  failure: "Failed to delete policy",
};
export const GET_MESSAGES = {
  failure: "Failed to fetch data",
};
export const ASSOCIATE_TO_USERS_MESSAGES = {
  failure: "Failed to associate users to tenant",
};
export const DELETE_VERSION_MESSAGES = {
  success: "Version deleted successfully",
  failure: "Failed to delete version",
};
export const REGENERATE_SECRET_MESSAGES = {
  success: "Regenerated secret successfully",
  failure: "Failed to regenerate secret",
};
export const CREATE_FEATURE_MAPPING_MESSAGES = {
  success: "Feature mapping created successfully",
  failure: "Failed to create feature mapping",
};
export const DELETE_FEATURE_MAPPING_MESSAGES = {
  success: "Feature mapping deleted successfully",
  failure: "Failed to delete feature mapping",
};
export const EDIT_FEATURE_MAPPING_MESSAGES = {
  success: "Feature mapping updated successfully",
  failure: "Failed to update feature mapping",
};
export const ENABLE_FEATURE_ON_DEVICES_MESSAGES = {
  success: "Feature enabled successfully",
  failure: "Failed to enable feature",
};
export const MARK_FOR_DELETE_MESSAGES = {
  success: "Feature marked for delete successfully",
  failure: "Failed to mark for delete feature",
};
export const UNMARK_FOR_DELETE_MESSAGES = {
  success: "Feature unmarked successfully",
  failure: "Failed to unmark feature",
};
export const INACTIVE_POLICY_MESSAGES = {
  success: "Policy status changed to inactive",
  failure: "Failed to change policy status",
};
export const DELETE_NOTIFICATION_MESSAGES = {
  success: "Notifications deleted successfully",
  failure: "Failed to delete notifications",
};
export const ARCHIVE_NOTIFICATION_MESSAGES = {
  success: "Notifications archived successfully",
  failure: "Failed to archive notifications",
};
export const UNARCHIVE_NOTIFICATION_MESSAGES = {
  success: "Notifications unarchived successfully",
  failure: "Failed to unarchive notifications",
};

export const ISV_VERIFY_VERSION = {
  success: "Version verified successfully",
  failure: "Failed to verify version",
};
export const ISV_APPROVED_VERSION = {
  success: "Version approved successfully",
  failure: "Failed to approve version",
};
export const ADD_GLOBAL_VERSION = {
  success: "Version added to Global Repository successfully",
  failure: "Failed to add version",
};
export const DENY_VERIFY_VERSION = {
  success: "Version denied successfully",
  failure: "Failed to deny version",
};
export const CREATE_FEATURE_CATALOG_MESSAGES = {
  success: "Catalog details saved successfully",
  failure: "Failed to save catalog details",
};
export const FILE_DOWNLOAD_MESSAGES = {
  success: "File downloaded",
  failure: "Failed to download file",
};
export const CREATE_MDD_MESSAGES = {
  success: "Metrics definition created successfully",
  failure: "Failed to create Metrics definition mapping",
};
export const EDIT_MDD_MESSAGES = {
  success: "Metrics definition mapping edited successfully",
  failure: "Failed to edit Metrics definition mapping",
};
export const ASSOCIATE_DEVICES_MDD = {
  success: "Device(s) associated successfully",
  failure: "Failed to associate device(s)",
};
export const DELETE_MDD_MAPPING = {
  success: "Metrics definition deleted successfully",
  failure: "Failed to delete metrics definition",
};

export const DELETE_ASSOCIATED_DEVICE = {
  success: "Device deleted successfully",
  failure: "Failed to delete device",
};

export const UPDATE_PREFERRED_VERSION = {
  success: "Preferred version updated successfully",
  failure: "Failed to update preferred version",
};
export const UPDATE_SDK_PREFERRED_VERSION = {
  success: "SDK version updated successfully",
  failure: "Failed to update SDK version",
};
export const SDK_DELETE_MESSAGES = {
  success: "SDK version delete successfully",
  failure: "Failed to delete SDK version",
};
export const ONBOARD_DEVICE_MESSAGES = {
  success: "Device added successfully",
  failure: "Failed to add device",
};
