import React, { useState, useEffect, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { MAX_VALUE } from "../../../constants/max-value-constants";
import CustomTextField from "../../ui/CustomTextField";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import { schema } from "./EditDeviceSchema";
import CommonHeader from "../../ui/CommonHeader";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import { editDevice, getDeviceInfo } from "../../../api/api-index";
import {
  EDIT_DEVICE_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import AddTags from "./AddTag";
import TagDataTable from "./TagDataTable";
import errorHandler from "../../helpers/error-helper";

import classes from "../../ui/Form/FormContainer.module.css";
import styles from "./EditDevice.module.css";
import { HtmlTooltip } from "../../ui/Tooltip/HtmlTooltip";

let isClicked = true;
const EditDevice = ({ onClose, data }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast({
      deviceName: data?.deviceName,
      systemDeviceId: data?.qualcommDeviceId,
    }),
    resolver: yupResolver(schema),
  });

  const [deviceData, setDeviceData] = useState({});
  const [tagsData, setTagsData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(true);

  useEffect(() => {
    getDeviceInfo({
      customerId: data?.tenantId,
      deviceId: data?.qualcommDeviceId,
    })
      .then((response) => {
        if (response.status && response.data != null) {
          setDeviceData(response.data);
          const tagArray = Array.isArray(response.data?.deviceTags)
            ? response.data?.deviceTags.map((item) => ({
                ...item,
                id: uuidv4(),
              }))
            : [];

          setTagsData(tagArray);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setShowConfirm(false));
  }, []);

  const EditFormRequest = (formData) => {
    let request = null;
    if (data != null) {
      request = {
        deviceId: data?.deviceId,
        tenantId: data?.tenantId,
        deviceName: formData.deviceName,
        serialNo: deviceData.serial,
        featureDetailsSubscriptionIdList: deviceData?.deviceFeatureIdList?.map(
          (feature) => feature
        ),
        deviceTags: tagsData.map((item) => ({
          tag: item.tag,
          tagValue: item.tagValue,
        })),
      };
    }
    return request;
  };
  const registerHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      editDevice(EditFormRequest(data))
        .then((response) => {
          if (response.status) {
            toast.success(EDIT_DEVICE_MESSAGES.success);
            onClose();
          } else {
            errorHandler(response?.error, EDIT_DEVICE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          isClicked = true;
          console.error("ERROR: ", error);
          errorHandler(error, EDIT_DEVICE_MESSAGES.failure);
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading={`Edit Device-${data?.qualcommDeviceId || ""}`}
          onClose={onClose}
        />
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.row}>
                <div className={styles.field_div}>
                  <Controller
                    name="deviceName"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        onChange={onChange}
                        onBlur={onBlur}
                        size="small"
                        focused
                        fullWidth
                        type="text"
                        label="Device Name"
                        value={value || ""}
                        placeholder="Enter device name"
                        error={errors.deviceName}
                        helperText={errors?.deviceName?.message}
                        inputProps={{ maxLength: MAX_VALUE.deviceName }}
                      />
                    )}
                  />
                </div>
                <div className={styles.field_div}>
                  <Controller
                    name="systemDeviceId"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        onChange={onChange}
                        onBlur={onBlur}
                        size="small"
                        disabled
                        focused
                        fullWidth
                        type="text"
                        label="System Device ID"
                        value={value || ""}
                        placeholder="System device id"
                        error={errors.systemDeviceId}
                        helperText={errors?.systemDeviceId?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionContent}>
                  <p className={styles.sectionContentTags}>
                    Tags
                    <HtmlTooltip
                      placement="right"
                      arrow
                      title={
                        <Fragment>
                          <div>Only alphanumeric characters are allowed</div>
                        </Fragment>
                      }
                    >
                      <InfoOutlinedIcon />
                    </HtmlTooltip>
                  </p>
                  <AddTags tagsData={tagsData} setTagsData={setTagsData} />
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionContent}>
                  <TagDataTable
                    tagsData={tagsData}
                    setTagsData={setTagsData}
                    isLoading={showConfirm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton
              text={"Submit"}
              type={"submit"}
              disabled={showConfirm}
            />
          </div>
        </form>
      </div>
    </BackdropOverlayModal>
  );
};

export default EditDevice;
