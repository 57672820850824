import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { BiLink } from "react-icons/bi";

const AssociateDeviceIcon = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Associate Devices",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          aria-label={tooltipText}
          disabled={disabled}
          size={size != null ? size : "large"}
          onClick={onClick != null ? onClick : null}
        >
          <BiLink size={22} color={disabled ? "grey" : color} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AssociateDeviceIcon;
