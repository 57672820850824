import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(() => ({
  fontFamily: "QualcommNext-Regular",
  color: "#474A51",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    color: "grey",
    backgroundColor: "#f7f7f7",
    border: "1px solid grey",
  },
  paddingLeft: "1.8rem",
  paddingRight: "1.8rem",
  border: "1px solid #474A51",
}));

const CancelButton = (props) => {
  const { onClick, text } = props;
  return (
    onClick != null && (
      <ColorButton variant="outlined" onClick={onClick}>
        {text}
      </ColorButton>
    )
  );
};

export default CancelButton;
