import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GET_MESSAGES } from "../../constants/message-constants";
import { ROLES } from "../../constants/role-constants";
import { CSS_ROUTES } from "../../constants/route-constants";
import DataTableHeaders from "../header/DataTableHeaders";
import errorHandler from "../helpers/error-helper";
import { SDKHeader } from "../helpers/header-helpers";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import {
  columnMenuStyles,
  CustomDataGrid,
  panelStyles,
} from "../ui/CustomDataGrid";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import RightBottomChevronIcon from "../ui/Buttons/RightBottomChevronIcon";
import SDKHistoryStats from "../sdk/SDKHistory";
import { getAllSDKVersionList } from "../../api/api-index";
import { DateFormatter } from "../helpers/utils";
import versionIcon from "../../assets/images/features/version-icon.svg";
import { PAGE_SIZE } from "../../constants/page-constants";
import { columnsActions } from "../../store/slices/columns-slice";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import DeleteSdkVersion from "../../components/sdk/DeleteSdkVersion";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import { SDK_TYPE } from "../../constants/sdk-constants";

import classes from "../ui/DataTable.module.css";
import vclasses from "./LinuxVersion.module.css";

let selectedRow = null;

const LinuxSDK = () => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.columnsInfo.isLinuxHistory);

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [paramId, setParamId] = useState(null);
  const [versionHistoryList, setVersionHistoryList] = useState({
    count: 0,
    list: [],
  });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
    setIsLoading(true);
  };
  const [openDeleteSdk, setOpenDeleteSdk] = useState(false);

  const handleOpenDeleteSdk = (params) => {
    setOpenDeleteSdk(true);
    selectedRow = {
      repositoryType: "Linux",
      version: params.row.version,
    };
  };
  const closeDeleteSdk = () => {
    setOpenDeleteSdk(false);
    selectedRow = null;
  };

  const handleOpenHistory = (row, list) => {
    if (paramId == null) {
      console.log(row, "row", list);
      setParamId({
        version: row.id,
        containerList: list.map((i) => {
          return {
            ...i,
            pushTime: row.pushTime,
          };
        }),
      });
    } else {
      setParamId(null);
    }
  };
  const columns = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <img src={versionIcon} alt="version" />
          {params.value}
        </div>
      ),
      hideable: false,
    },

    {
      field: "pushTime",
      headerName: "Push Time",
      flex: 1,
      minWidth: 150,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.isvAdmin, ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      minWidth: 200,
      hideable: false,
      renderCell: (params) => (
        <div>
          {!params.row.latest && (
            <DeleteIcon
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDeleteSdk(params);
              }}
            />
          )}
          <RightBottomChevronIcon
            onClick={(event) => {
              event.stopPropagation();
              handleOpenHistory(params.row, params.row.containersList);
            }}
            active={!!paramId?.version && paramId?.version === params.row.id}
          />
          {!!paramId?.version && paramId?.version === params.row.id && (
            <div
              className={vclasses.vcontainer}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <SDKHistoryStats list={paramId.containerList} />
            </div>
          )}
        </div>
      ),
    });
  const headers = SDKHeader({
    routes: {
      LinuxSdk: CSS_ROUTES.linuxSDk,
      AndroidSdk: CSS_ROUTES.androidSDk,
    },
  });
  useEffect(() => {
    const options = {
      repositoryType: SDK_TYPE.sdkLinux,
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
    };

    getAllSDKVersionList(options, options.repositoryType)
      .then((response) => {
        if (response.status && response.data != null) {
          let latest = false;
          const formattedList = response.data.sdkVersionList.map(
            (item, index) => {
              if (index === 0) {
                latest = true;
              } else {
                latest = false;
              }
              return {
                id: item.sdkVersionId,
                featureVersionId: item.featureVersionId,
                version: item.sdkVersion,

                pushTime: DateFormatter(item.createdOn),

                containersList: item.containersList,
                featureName: item.sdkName,
                latest: latest,
              };
            }
          );
          setVersionHistoryList({
            count: response.data.count,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo, openDeleteSdk]);

  return (
    <Fragment>
      {openDeleteSdk && (
        <DeleteSdkVersion
          onClose={closeDeleteSdk}
          versionDetails={selectedRow}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest backButton={false} disableSearch={true} />
        <p>QSEA - SDK Repositories</p>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setSdkLinuxColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : versionHistoryList.list}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: versionHistoryList.count,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.versionHistory,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default LinuxSDK;
