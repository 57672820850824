import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteUser } from "../../../api/deleteUser";
import { DELETE_USER_MESSAGES } from "../../../constants/message-constants";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";
import KeycloakConfig from "../../../config/keycloak-config";
import errorHandler from "../../helpers/error-helper";

import classes from "./DeleteUser.module.css";

const DeleteUser = (props) => {
  const { onClose, userDetails, getUpdatedDataFn } = props;
  let clicked = true;
  const { userId, firstName, lastName } = userDetails;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedUserId = useSelector(
    (state) => state.userInfo.userInfo?.userId
  );

  const handleLogout = () => {
    dispatch({ type: "REDUX_STORE_RESET" });
    navigate("", { state: null });
    KeycloakConfig.doLogout();
  };

  const onSubmit = () => {
    if (clicked) {
      clicked = false;
      deleteUser(userId)
        .then((response) => {
          if (response.status) {
            toast.success(DELETE_USER_MESSAGES.success);

            if (userId === selectedUserId) {
              handleLogout();
            }
            getUpdatedDataFn && getUpdatedDataFn();
            onClose();
          } else {
            clicked = true;
            errorHandler(response.error, DELETE_USER_MESSAGES.failure);
          }
        })
        .catch((error) => {
          clicked = true;
          console.error("ERROR: ", error);
          errorHandler(error, DELETE_USER_MESSAGES.failure);
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete User" onClose={onClose} />
        <div className={classes.warning}>
          <p>
            Are you sure you want to delete
            <span className={classes.userNameStyles}>{` "${firstName} `}</span>
            <span className={classes.userNameStyles}>{`${lastName}" `}</span>?
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <ConfirmDeleteButton text={"Confirm"} onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteUser;
