import { useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { MenuItem } from "@mui/material";

import BackdropOverlayModal from "../BackdropOverlayModal";
import { CustomSelectField } from "../CustomSelectField";
import { METRIC_TIME_VALUES } from "../../../constants/time-constants";
import useTimeStatus from "../../../hooks/useTimeStatus";
import CloseIcon from "../Buttons/CloseIcon";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../../api/iframeEndpoints";
import CustomIframe from "../customIframe/CustomIframe";

import classes from "../metricPopup/MetricPopup.module.css";

const DeviceMetricPopup = ({
  selectedItem,
  heading,
  onClose,
  view,
  dashboardId,
  deviceId,
}) => {
  const params = useParams();

  const [timedropDown, setTimeDropdown] = useState("24h");
  const [dateRange, handleTime] = useTimeStatus();

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <div className={classes.header}>
          <p>
            {heading} - {selectedItem.displayName}
          </p>
          <div className={classes.buttons}>
            <div className={classes.dropdown}>
              <CustomSelectField
                sx={{ width: "9em" }}
                onChange={(e) => {
                  setTimeDropdown(e.target.value);
                  handleTime(e.target.value);
                }}
                size="small"
                displayEmpty
                value={timedropDown}
              >
                {METRIC_TIME_VALUES.map((item) => (
                  <MenuItem
                    key={item.value}
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </div>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <div
          className={classes.invisibleOverlay}
          style={{ backgroundColor: "#fff" }}
        ></div>
        <CustomIframe
          src={`${IFRAME_BASE_URL.baseUrl}/${dashboardId}/${view}?orgId=1&var-deviceId=${deviceId}&from=${dateRange.from}&to=${dateRange.to}&theme=light&panelId=${selectedItem.mainAndDetailView}`}
          key={uuidv4()}
        />
      </div>
    </BackdropOverlayModal>
  );
};
export default DeviceMetricPopup;
