import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

import DataTablePanel from "../../../panel/DataTablePanel";
import DataTableHeaders from "../../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import { customerDevicesHeaders } from "../../../helpers/header-helpers";
import CustomPagination from "../../../ui/CustomPagination";
import CustomToolbar from "../../../ui/CustomToolbar";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import { getMyModelList } from "../../../../api/api-index";
import { PAGE_SIZE } from "../../../../constants/page-constants";
import {
  PARTIAL_ROUTES,
  CSS_ROUTES,
} from "../../../../constants/route-constants";
import featureIcon from "../../../../assets/images/features/default-feature-name.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ROLES } from "../../../../constants/role-constants";
import { HtmlTooltip } from "../../../ui/Tooltip/HtmlTooltip";
import { hasPermittedRolesOrAttributes } from "../../../helpers/user-roles-and-attributes";
import errorHandler from "../../../helpers/error-helper";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import { featuresActions } from "../../../../store/slices/features-slice";
import SelectChipsets from "../../../policy/SelectChipsets.js/SelectChipsets";
import FeatureButton from "../../../ui/Buttons/FeatureButton";
import { columnsActions } from "../../../../store/slices/columns-slice";
import useSearchKeys from "../../../../hooks/useSearchKeys";
import { truncateStyles } from "../../../helpers/utils";
import { NO_ROW_MESSAGES } from "../../../../constants/no-row-message-constants";
import useBreadcrumb from "../../../../hooks/useBreadcrumb";
import { StyledLink } from "../../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../../ui/StyledBreadcrumbs";

import classes from "../../../ui/DataTable.module.css";

let selectedRow = null;
const CustomerFeaturesList = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("devices");
  const initialState = useSelector(
    (state) => state.columnsInfo.customerFeatures
  );
  const [searchStatefn] = useSearchKeys(
    ["name", "category", "description"],
    initialState
  );

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );

  const orgType = useSelector(
    (state) => state.organizationsInfo?.selectedOrganization?.orgType
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [featureList, setFeatureList] = useState({
    count: 0,
    list: [],
  });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openChipsets, setopenChipsets] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const featureDispatcher = (row) => {
    const data =
      row?.row != null
        ? {
            id: row.row.id,
            featureName: row.row.name,
            featureDesc: row.row.category,
            featureCategory: row.row.description,
            featurePolicyTerm: row.row.featurePolicyTerm,
            qualcommFeatureId: row.row.qualcommFeatureId,
            qualcommFeatureName: row.row.qualcommFeatureName,
            preferredVersion: row.row.preferredVersion,
          }
        : null;
    dispatch(featuresActions.setSelectedFeature(data));
  };

  const handleRowClick = (row) => {
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.features +
        "/" +
        row.id
    );
    featureDispatcher(row);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleEnable = (row) => {
    selectedRow = row.row;
    setopenChipsets(true);
  };
  const handleCloseChipsets = () => {
    selectedRow = null;
    setopenChipsets(false);
  };
  const nameStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  };

  const columns = [
    {
      field: "name",
      headerName: "Feature Title",
      minWidth: 200,
      flex: 2,
      hideable: false,
      renderCell: (params) => {
        return (
          <div style={nameStyle}>
            <img src={featureIcon} height="30px" />
            <p>{params?.formattedValue}</p>
            {!isQualcommManagingDevice &&
              !params.row.preferredVersion &&
              params.row.isFeatureDownloadable && (
                <HtmlTooltip
                  placement="top"
                  arrow
                  title={"Preferred version not set on this feature"}
                >
                  <ErrorOutlineIcon htmlColor="#214572" />
                </HtmlTooltip>
              )}
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 150,
      flex: 1,
    },

    {
      field: "preferredVersion",
      headerName: "Preferred Version",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.preferredVersion || "-";
      },
    },
    {
      field: "isFeatureDownloadable",
      headerName: "Downloadable Feature",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.isFeatureDownloadable ? "Yes" : "No";
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
      sortable: false,
      flex: 4,
      renderCell: (params) => <div style={truncateStyles}>{params.value}</div>,
    },
  ];

  isQualcommManagingDevice &&
    hasPermittedRolesOrAttributes([
      ROLES.customerAdmin,
      ROLES.deviceManager,
      ROLES.cssAdmin,
    ]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      hideable: false,
      renderCell: (param) => {
        let msg = null;
        msg =
          param.row.featurePolicyTerm.length === 0
            ? "Feature has an inactive policy"
            : param.row.preferredVersion == null
            ? "Select a preferred version for this feature"
            : "";

        // return  param.row.preferredVersion != null ?
        if (
          (param.row.preferredVersion != null &&
            param.row.featurePolicyTerm.length > 0) ||
          (!param.row.isFeatureDownloadable &&
            param.row.featurePolicyTerm.length > 0)
        ) {
          return (
            <FeatureButton
              onClick={(event) => {
                event.stopPropagation();
                handleEnable(param);
              }}
              label="Enable Feature"
            >
              Enable Feature
            </FeatureButton>
          );
        } else if (
          param.row.preferredVersion == null ||
          param.row.featurePolicyTerm.length == 0
        ) {
          return (
            <Tooltip title={msg} placement="top">
              <span>
                <FeatureButton disabled={true} label="Enable Feature">
                  Enable Feature
                </FeatureButton>
              </span>
            </Tooltip>
          );
        } else if (
          param.row.preferredVersion != null &&
          orgType === "Internal"
        ) {
          <FeatureButton
            onClick={(event) => {
              event.stopPropagation();
              handleEnable(param);
            }}
            label="Enable Feature"
          >
            Enable Feature
          </FeatureButton>;
        } else {
          <Tooltip
            title={"Select a preferred version for this feature"}
            placement="top"
          >
            <span>
              <FeatureButton disabled={true} label="Enable Feature">
                Enable Feature
              </FeatureButton>
            </span>
          </Tooltip>;
        }
      },
    });
  const headers = customerDevicesHeaders({
    routes: {
      deviceList: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.devices
        : null,
      customerUsers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.associatedUsers
        : null,
      features:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.features,
      mdd: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd
        : null,
      mddDashboard: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mddDashboard
        : null,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      customerId: params.customerId,
    };

    getMyModelList(options)
      .then((response) => {
        if (
          response?.status &&
          response.data.count != null &&
          response?.data?.featureDetailsList != null
        ) {
          const formattedList =
            response.data.featureDetailsList.map((item) => {
              return {
                id: item?.featureId,
                name: item?.featureTitle,
                category: item?.featureCategory,
                description: item?.featureDesc,
                featurePolicyTerm: Array.isArray(item?.featurePolicyTerm)
                  ? item?.featurePolicyTerm?.filter(
                      (policy) => policy.status !== "INACTIVE"
                    )
                  : [],
                qualcommFeatureId: item?.qualcommFeatureId,
                qualcommFeatureName: item?.qualcommFeatureName,
                preferredVersion: item.preferredVersion,
                isFeatureDownloadable: item?.isFeatureDownloadable,
              };
            }) || [];
          setFeatureList({
            count: response?.data?.count,
            list: formattedList,
          });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo]);

  return (
    <Fragment>
      {openChipsets && (
        <SelectChipsets
          featureDetails={selectedRow}
          onClose={handleCloseChipsets}
        />
      )}
      <div className={classes.container}>
        <DataTablePanel
          list={featureList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={hasPermittedRolesOrAttributes([ROLES.cssAdmin]) && true}
        />
        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}

        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setcustomerFeaturesColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  featureList.list.length === filteredList.length
                    ? featureList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.features },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerFeaturesList;
