import IconButton from "@mui/material/IconButton";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Tooltip } from "@mui/material";

const UnArchiveButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Unarchive",
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <UnarchiveIcon sx={{ color: color }} />
      </IconButton>
    </Tooltip>
  );
};

export default UnArchiveButton;
