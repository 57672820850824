import { toast } from "react-toastify";

import FeatureMappedDetails from "./FeatureMappedDetails/FeatureMappedDetails";
import { deleteTenant } from "../../../api/deleteTenant";
import { DELETE_TENANT_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./DeleteTenant.module.css";

const DeleteTenant = (props) => {
  const { onClose, userDetails, tenantType = "", getUpdatedDataFn } = props;
  let clicked = true;
  const onSubmit = () => {
    if (clicked) {
      clicked = false;
      const options = {
        tenantId: userDetails?.id,
      };
      deleteTenant(options)
        .then((response) => {
          if (response.status) {
            toast.success(DELETE_TENANT_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            onClose();
          } else {
            console.error(response.error);
            errorHandler(response.error, DELETE_TENANT_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, DELETE_TENANT_MESSAGES.failure);
          clicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Tenant" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to delete <b>{userDetails?.tenantName}</b>{" "}
            tenant
          </p>
          <p>This action is irreversible !</p>
          {tenantType === "ISV" && (
            <FeatureMappedDetails id={userDetails?.id} />
          )}
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteTenant;
