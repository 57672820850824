import { store } from "../../store/store";

const hasPermittedRolesOrAttributes = (roles, attributes) => {
  const userInfo = store.getState()?.userInfo;
  const userInfoForATenancy = userInfo?.userInfo?.userRoleInfoJson[userInfo.selectedAppKey];
  return (
    roles?.some((role) => userInfoForATenancy?.roles?.includes(role)) ||
    attributes?.some((attrubute) => userInfoForATenancy?.attributes?.includes(attrubute))
  );
};

export { hasPermittedRolesOrAttributes };
