import { getAllRepoApi } from "./apiEndpoints";
import axios from "axios";
//name of the function and filename should be same
export const getAllRepo = async (payload) => {
  //api call for get request
  let data;
  await axios
    .post(
      getAllRepoApi,

      {
        ...payload,
      }
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
