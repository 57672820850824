import { Box, Tab, Tabs } from "@mui/material";

import classes from "./NotificationHeader.module.css";

const NotificationHeader = ({ value, handleChange }) => {
  const tabStyles = {
    fontFamily: "QualcommNext-Medium",
    textTransform: "capitalize",
    "&.MuiTab-root.Mui-selected": {
      color: "#214572",
    },
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>Notifications</div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          gap: "1em",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="notification tabs"
        >
          <Tab aria-label="Alerts" sx={tabStyles} label="Alerts" />
          <Tab aria-label="Events" sx={tabStyles} label="Events" />
        </Tabs>
      </Box>
    </div>
  );
};
export default NotificationHeader;
