import { toast } from "react-toastify";
import { editFeature } from "../../../api/api-index";
import { UNMARK_FOR_DELETE_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./UnmarkFeature.module.css";

const UnmarkFeature = (props) => {
  let isClicked = true;
  const { onClose, userDetails, roleType, getUpdatedDataFn } = props;

  const onSubmit = () => {
    if (isClicked) {
      isClicked = false;
      const options = {
        featureId: userDetails?.id,
        roleType,
        payload: { status: "UNMARK_FROM_DELETE" },
      };

      editFeature(options)
        .then((response) => {
          if (response.status) {
            toast.success(UNMARK_FOR_DELETE_MESSAGES.success);
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(response.error, UNMARK_FOR_DELETE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, UNMARK_FOR_DELETE_MESSAGES.failure);
          isClicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Unmark Delete Feature" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to unmark delete{" "}
            <b>{userDetails?.featureTitle}</b> feature?
          </p>
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <SubmitButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default UnmarkFeature;
