import classes from "./OrganizationInfoItem.module.css";

const OrganizationInfoItem = (props) => {
  const { text, header, icon } = props;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.text}>{text || "N/A"}</div>
        <div className={classes.header}>{header}</div>
      </div>
      <div className={classes.img}>
        <img src={icon} alt={text} className={classes.icon} />
      </div>
    </div>
  );
};

export default OrganizationInfoItem;
