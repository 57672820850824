import * as yup from "yup";
const errorMessages = {
  tenantName: {
    required: "Tenant name is required",
  },
};
export const schema = yup
  .object({
    tenantName: yup
      .string()
      .required(errorMessages.tenantName.required)
      .matches(/^(?!\s*$).+/g, errorMessages.tenantName.required),
  })
  .required();
