import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#2e5f9b",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2e5f9b",
    color: "#FFFFFF",
    fontSize: 12,
    fontFamily: "QualcommNext-Regular",
    borderRadius: "2px",
  },
}));

export default LightTooltip;
