import { toast } from "react-toastify";

import { deleteMDDMapping } from "../../../api/api-index";
import { DELETE_MDD_MAPPING } from "../../../constants/message-constants";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";
import errorHandler from "../../helpers/error-helper";

import classes from "./DeleteMDD.module.css";

const DeleteMDD = (props) => {
  const { onClose, data, refreshData } = props;

  let clicked = true;

  const onSubmit = () => {
    if (clicked) {
      clicked = false;
      deleteMDDMapping(data?.mddId)
        .then((response) => {
          if (response.status) {
            toast.success(DELETE_MDD_MAPPING.success);
            onClose();
            refreshData();
          } else {
            console.error(response.error);
            errorHandler(response.error, DELETE_MDD_MAPPING.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, DELETE_MDD_MAPPING.failure);
          clicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Metrics Definition" onClose={onClose} />
        <div className={classes.warning}>
          <p>
            Are you sure you want to delete
            <span
              className={classes.userNameStyles}
            >{` ${data?.mddName}`}</span>
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <ConfirmDeleteButton text={"Confirm"} onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteMDD;
