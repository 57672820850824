import { Fragment, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchOrganizationInfo,
  fetchTenantInfo,
  fetchFeatureInfo,
} from "../store/actions/actions-index";
import {
  CSS_ROUTES,
  TENANTS_ROUTES,
  PARTIAL_ROUTES,
  COMMON_ROUTES,
} from "../constants/route-constants";
import {
  OrganizationPage,
  OrganizationInfoPage,
  OrganizationUsersPage,
  ISVTenantsPage,
  ISVTenantUsersPage,
  CustomerTenantsPage,
  ISVTenantAvailableUsersPage,
  CustomerDevicesPage,
  CustomerFeatureDeviceListPage,
  CustomerTenantUsersPage,
  CustomerTenantAvailableUsersPage,
  TenantISVsPage,
  TenantCustomersPage,
  TenantFeaturesPage,
  TenantFeaturesInfoPage,
  TenantISVUsersPage,
  SubscriptionPolicyPage,
  SubscriptionPolicyInfoPage,
  CustomerTenantDeviceInfoPage,
  TenantISVAvailableUsersPage,
  TenantCustomerAvailableUsersPage,
  TenantCustomerUsersPage,
  LandingPage,
  DashboardPage,
  TenantDeviceLicensesPage,
  UserSettingsPage,
  FeatureOwnerShipPage,
  CustomerTenantFeatureAvailableDevicesPage,
  ISVSubscribersPage,
  ISVSubscriberInfoPage,
  AlertsPage,
  EventsPage,
  ArchivedAlertsPage,
  ArchivedEventsPage,
  LocalRepositoriesPage,
  GlobalRepositoriesPage,
  LocalRepositoryInfoPage,
  GlobalRepositoryInfoPage,
  DashboardLicenseInfoPage,
  ISVFeatureContent,
  TenantCustomerFeatureSubscribersPage,
  CustomerFeaturesPage,
  CustomerFeatureInfoPage,
  TenantFeaturesVersionHistoryPage,
  CatalogPage,
  CatalogInfoPage,
  CatalogHomePage,
  MDDPage,
  MDDInfo,
  AvailableDevicesForMddPage,
  AssociatedMddDevicesPage,
  MddDashboardPage,
  HttpServerRequestsSecondsPage,
  CustomerDeviceHealthMetricsPage,
  CustomerDeviceQSEAMetricsPage,
  MicroservicesCrashCountPage,
  AndroidSDKPage,
  LinuxSDKPage,
  CustomerManagedDevicePage,
} from "../pages/page-index";
import KeycloakConfig from "../config/keycloak-config";
import { ENTITY_TYPE } from "../constants/keycloak-constants";

import { userActions } from "../store/slices/user-slice";
import ISVRoutes from "./ISVRoutes";
import CustomerRoutes from "./CustomerRoutes";

const RouteInformation = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant?.isQualcommManagingDevice
  );
  const selectedEntityType = useSelector(
    (state) => state.userInfo?.selectedEntityType
  );
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const selectedFeature = useSelector(
    (state) => state.featuresInfo.selectedFeature
  );
  const selectedTenantId = useSelector(
    (state) => state.userInfo.selectedAppKey
  );
  const selectedDevice = useSelector(
    (state) => state.customerDeviceInfo.selectedDevice
  );
  const checkOrganizationInfo = location.pathname.includes(
    CSS_ROUTES.organizations
  )
    ? true
    : false;
  const checkTenantInfo = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? true
    : location.pathname.includes(PARTIAL_ROUTES.customerTenants)
    ? true
    : false;
  const checkFeatureInfo = location.pathname.includes(PARTIAL_ROUTES.myFeatures)
    ? true
    : false;

  useEffect(() => {
    // let key = null;
    // const splitPath = location.pathname.split("/");
    // if (!selectedOrganization && checkOrganizationInfo && splitPath.length >= 3) {
    //   const options = {
    //     organizationId: splitPath[2],
    //   };
    //   dispatch(fetchOrganizationInfo(options));
    // }
    // if (!selectedTenant && checkTenantInfo && splitPath.length >= 5) {
    //   const options = {
    //     tenantId: splitPath[4],
    //   };
    //   dispatch(fetchTenantInfo(options));
    //   key = splitPath[4];
    // }
    // if (!selectedTenant && checkTenantInfo && splitPath.length >= 4) {
    //   const options = {
    //     tenantId: splitPath[2],
    //   };
    //   dispatch(fetchTenantInfo(options));
    //   key = splitPath[2];
    // }
    // if (!selectedFeature && checkFeatureInfo && splitPath.length >= 5) {
    //   const options = {
    //     featureId: splitPath[4],
    //   };
    //   dispatch(fetchFeatureInfo(options));
    // }
    // if (!selectedAppType && key != null) {
    //   dispatch(userActions.setSelectedAppKey(key));
    // }
    // // debugger;
    // if (!selectedAppType && splitPath.length >= 2 && key == null && location.pathname !== "/") {
    //   const entityType = TENANTS_ROUTES.isvTenants.includes(splitPath[1])
    //     ? ENTITY_TYPE.isv
    //     : TENANTS_ROUTES.customerTenants.includes(splitPath[1])
    //     ? ENTITY_TYPE.customer
    //     : ENTITY_TYPE.css;
    //   dispatch(userActions.setSelectedEntityType(entityType));
    // }
  }, [
    dispatch,
    selectedOrganization,
    checkOrganizationInfo,
    selectedTenant,
    checkTenantInfo,
    selectedFeature,
    checkFeatureInfo,
    selectedAppType,
    selectedEntityType,
  ]);
  // debugger;
  return (
    <Fragment>
      {KeycloakConfig.isLoggedIn() && (
        <Fragment>
          {selectedAppType == null && selectedEntityType == null && (
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path={COMMON_ROUTES.userSettings}
                element={<UserSettingsPage />}
              />
              <Route
                path={COMMON_ROUTES.catalog}
                element={<CatalogHomePage />}
              />
              <Route
                path={COMMON_ROUTES.catalogContainers}
                element={<CatalogPage type="CONTAINER" />}
              />
              <Route
                path={COMMON_ROUTES.catalogModels}
                element={<CatalogPage type="MODEL" />}
              />
              <Route
                path={COMMON_ROUTES.catalogCollections}
                element={<CatalogPage type="COLLECTION" />}
              />
              <Route
                path={COMMON_ROUTES.CatalogInfo}
                element={<CatalogInfoPage />}
              />
              <Route
                path={COMMON_ROUTES.CatalogContainerInfo}
                element={<CatalogInfoPage />}
              />
              <Route
                path={COMMON_ROUTES.CatalogModelInfo}
                element={<CatalogInfoPage />}
              />
              <Route
                path={COMMON_ROUTES.CatalogCollectionInfo}
                element={<CatalogInfoPage />}
              />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          )}
          {(selectedAppType === ENTITY_TYPE.css ||
            selectedEntityType === ENTITY_TYPE.css) && (
            <Routes>
              <Route path={CSS_ROUTES.dashboard} element={<DashboardPage />} />
              <Route
                path={CSS_ROUTES.httpServerRequestsSecondsCount}
                element={<HttpServerRequestsSecondsPage />}
              />
              <Route
                path={CSS_ROUTES.microservicesCrashCount}
                element={<MicroservicesCrashCountPage />}
              />
              <Route
                path={CSS_ROUTES.tenantDeviceLicenses}
                element={<TenantDeviceLicensesPage />}
              />
              <Route
                path={CSS_ROUTES.tenantDeviceLicensesById}
                element={<DashboardLicenseInfoPage />}
              />
              <Route
                path={CSS_ROUTES.organizations}
                element={<OrganizationPage />}
              />
              <Route
                path={CSS_ROUTES.organizationInfo}
                element={<OrganizationInfoPage />}
              />
              <Route
                path={CSS_ROUTES.organizationUsers}
                element={<OrganizationUsersPage />}
              />
              <Route
                path={CSS_ROUTES.isvTenants}
                element={<ISVTenantsPage />}
              />
              <Route
                path={CSS_ROUTES.isvTenantUsers}
                element={<ISVTenantUsersPage />}
              />
              <Route
                path={CSS_ROUTES.isvTenantAvailableUsers}
                element={<ISVTenantAvailableUsersPage />}
              />
              <Route
                path={CSS_ROUTES.customerTenants}
                element={<CustomerTenantsPage />}
              />
              <Route
                path={CSS_ROUTES.customerTenantUsers}
                element={<CustomerTenantUsersPage />}
              />
              <Route
                path={CSS_ROUTES.customerTenantAvailableUsers}
                element={<CustomerTenantAvailableUsersPage />}
              />
              <Route path={CSS_ROUTES.cssAdmins} element={null} />
              <Route path={CSS_ROUTES.adminActions} element={null} />
              <Route path={CSS_ROUTES.isvTenantActions} element={null} />
              <Route path={CSS_ROUTES.customerTenantActions} element={null} />
              <Route path={CSS_ROUTES.permissions} element={null} />
              <Route
                path={CSS_ROUTES.subscriptionPolicy}
                element={<SubscriptionPolicyPage />}
              />
              <Route
                path={CSS_ROUTES.subscriptionPolicyInfo}
                element={<SubscriptionPolicyInfoPage />}
              />
              <Route
                path={CSS_ROUTES.featureOwnerShip}
                element={<FeatureOwnerShipPage />}
              />
              <Route
                path={CSS_ROUTES.repositories}
                element={<Navigate replace to={CSS_ROUTES.localRepositories} />}
              />
              <Route
                path={CSS_ROUTES.localRepositories}
                element={<LocalRepositoriesPage />}
              />
              <Route
                path={CSS_ROUTES.globalRepositories}
                element={<GlobalRepositoriesPage />}
              />
              <Route
                path={CSS_ROUTES.localRepositoryInfo}
                element={<LocalRepositoryInfoPage />}
              />
              <Route
                path={CSS_ROUTES.globalRepositoryInfo}
                element={<GlobalRepositoryInfoPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenants}
                element={<TenantISVsPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantFeatures}
                element={<TenantFeaturesPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantUsers}
                element={<TenantISVUsersPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantFeaturesInfo}
                element={<TenantFeaturesInfoPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantFeaturesVersionHistory}
                element={<TenantFeaturesVersionHistoryPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantsAddAvailableUsers}
                element={<TenantISVAvailableUsersPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvTenantFeatureSubscribers}
                element={<TenantCustomerFeatureSubscribersPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvSubscribers}
                element={<ISVSubscribersPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvSubscribersInfo}
                element={<ISVSubscriberInfoPage />}
              />
              <Route
                path={TENANTS_ROUTES.isvContents}
                element={<ISVFeatureContent />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenants}
                element={<TenantCustomersPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantDevices}
                element={
                  isQualcommManagingDevice ? (
                    <CustomerDevicesPage />
                  ) : (
                    <CustomerManagedDevicePage />
                  )
                }
              />
              <Route
                path={TENANTS_ROUTES.customerTenantUsers}
                element={<TenantCustomerUsersPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantDeviceInfo}
                element={<CustomerTenantDeviceInfoPage />}
              />
              {selectedDevice?.qualcommDeviceId && (
                <Route
                  path={TENANTS_ROUTES.customerTenantDeviceHealthMetrics}
                  element={<CustomerDeviceHealthMetricsPage />}
                />
              )}
              {selectedDevice?.qualcommDeviceId && (
                <Route
                  path={TENANTS_ROUTES.customerTenantDeviceQSEAMetrics}
                  element={<CustomerDeviceQSEAMetricsPage />}
                />
              )}

              <Route
                path={TENANTS_ROUTES.customerTenantAddAvailableUsers}
                element={<TenantCustomerAvailableUsersPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantFeatures}
                element={<CustomerFeaturesPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantFeatureAvailableDevices}
                element={<CustomerTenantFeatureAvailableDevicesPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantFeatureInfo}
                element={<CustomerFeatureInfoPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantFeatureDevices}
                element={<CustomerFeatureDeviceListPage />}
              />
              <Route
                path={COMMON_ROUTES.userSettings}
                element={<UserSettingsPage />}
              />
              <Route
                path={COMMON_ROUTES.notificationsAlerts}
                element={<AlertsPage />}
              />
              <Route
                path={COMMON_ROUTES.notificationsEvents}
                element={<EventsPage />}
              />
              <Route
                path={COMMON_ROUTES.archiveNotificationsAlerts}
                element={<ArchivedAlertsPage />}
              />
              <Route
                path={COMMON_ROUTES.archiveNotificationsEvents}
                element={<ArchivedEventsPage />}
              />
              <Route path={TENANTS_ROUTES.customerMdd} element={<MDDPage />} />
              <Route
                path={TENANTS_ROUTES.customerMddDashboard}
                element={<MddDashboardPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerMddInfo}
                element={<MDDInfo />}
              />
              {isQualcommManagingDevice && (
                <Route
                  path={TENANTS_ROUTES.availableDevicesForMdd}
                  element={<AvailableDevicesForMddPage />}
                />
              )}
              {isQualcommManagingDevice && (
                <Route
                  path={TENANTS_ROUTES.associatedDevicesForMdd}
                  element={<AssociatedMddDevicesPage />}
                />
              )}
              <Route
                path={CSS_ROUTES.sdk}
                element={<Navigate replace to={CSS_ROUTES.linuxSDk} />}
              />
              <Route
                path={CSS_ROUTES.androidSDk}
                element={<AndroidSDKPage />}
              />
              <Route path={CSS_ROUTES.linuxSDk} element={<LinuxSDKPage />} />
              <Route
                path="*"
                element={<Navigate replace to={CSS_ROUTES.organizations} />}
              />
            </Routes>
          )}
          {(selectedAppType === ENTITY_TYPE.isv ||
            selectedEntityType === ENTITY_TYPE.isv) && (
            <ISVRoutes
              organizationId={selectedOrganization?.organizationId}
              selectedTenantId={selectedTenantId}
            />
          )}
          {(selectedAppType === ENTITY_TYPE.customer ||
            selectedEntityType === ENTITY_TYPE.customer) && (
            <CustomerRoutes
              organizationId={selectedOrganization?.organizationId}
              selectedTenantId={selectedTenantId}
              isQualcommManagingDevice={isQualcommManagingDevice}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default RouteInformation;
