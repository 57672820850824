import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
} from "../../../../constants/route-constants";
import { featureHeaders } from "../../../helpers/header-helpers";
import { ROLES } from "../../../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../../../helpers/user-roles-and-attributes";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";
import DataTableHeaders from "../../../header/DataTableHeaders";
import FeatureContentTable from "./FeatureContentTable";
import errorHandler from "../../../helpers/error-helper";
import { getAllVersionHistory } from "../../../../api/api-index";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import { PAGE_SIZE } from "../../../../constants/page-constants";
import useBreadcrumb from "../../../../hooks/useBreadcrumb";
import { StyledLink } from "../../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../../ui/StyledBreadcrumbs";

import classes from "./FeatureContent.module.css";

const FeatureContent = () => {
  const params = useParams();
  const location = useLocation();
  const [breadCrumbArray] = useBreadcrumb("featuresInfo");

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const selectedFeature = useSelector(
    (state) => state.featuresInfo.selectedFeature
  );

  const [featureVersionInfo, setFeatureVersionInfo] = useState({
    models: [],
    containers: [],
    template: [],
    plugins: [],
  });

  const tenantType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? CSS_ROUTES.organizations +
      "/" +
      params.organizationId +
      PARTIAL_ROUTES.isvTenants
    : location.pathname.includes(PARTIAL_ROUTES.customerTenants)
    ? PARTIAL_ROUTES.customerTenants
    : "";

  const isvCustomerTenantId = params.tenantId || params.customerId;

  const featureType = location.pathname.includes(PARTIAL_ROUTES.features)
    ? PARTIAL_ROUTES.features
    : location.pathname.includes(PARTIAL_ROUTES.myFeatures)
    ? PARTIAL_ROUTES.myFeatures
    : "";

  const headers = featureHeaders({
    routes: {
      featureInfo:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId,
      contents:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId +
        PARTIAL_ROUTES.contents,
      versionHistory:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId +
        PARTIAL_ROUTES.versionHistory,
      subscribers:
        hasPermittedRolesOrAttributes([
          ROLES.isvAdmin,
          ROLES.isvMonitor,
          ROLES.cssAdmin,
        ]) &&
        tenantType +
          "/" +
          isvCustomerTenantId +
          featureType +
          "/" +
          params.featureId +
          PARTIAL_ROUTES.subscribers,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  const heading =
    selectedFeature?.featureTitle != null
      ? `${selectedTenant?.tenantName} - ${selectedFeature?.featureTitle}`
      : `${selectedTenant?.tenantName} - N/A`;

  useEffect(() => {
    if (selectedFeature?.qualcommFeatureName) {
      const options = {
        qcommFeatureName: selectedFeature?.qualcommFeatureName,
        pageNo: 0,
        pageSize: PAGE_SIZE,
      };

      getAllVersionHistory(options, selectedFeature?.qualcommFeatureName)
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.totalCount != null
          ) {
            const modelList = [];
            const containerList = [];
            const templateList = [];
            const pluginsList = [];

            const containers = response?.data?.featureVersion?.find(
              (i) => i.status === "Added to Global Repository"
            )?.containersList;
            Array.isArray(containers) &&
              containers?.forEach((res, key) => {
                if (res.type === "CONTAINER") {
                  containerList.push({ ...res, id: key });
                } else if (res.type === "MODEL") {
                  modelList.push({ ...res, id: key });
                } else if (res.type === "TEMPLATE") {
                  templateList.push({ ...res, id: key });
                } else {
                  pluginsList.push({ ...res, id: key });
                }
              });

            setFeatureVersionInfo({
              models: modelList,
              containers: containerList,
              template: templateList,
              plugins: pluginsList,
            });
          } else {
            console.error(response.error);
            errorHandler(response.error, GET_MESSAGES.failure);
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, GET_MESSAGES.failure);
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedFeature?.qualcommFeatureName]);

  return (
    <Fragment>
      <div className={classes.container}>
        <DataTablePanelTest backButton={true} disableSearch={true} />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{selectedFeature?.featureTitle || "..."}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}
        <div className={classes.data}>
          <DataTableHeaders headers={headers} />
          <div className={classes.info}>
            <div className={classes.info_items}>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  tableHeader="Containers"
                  data={featureVersionInfo.containers}
                  isLoading={isLoading}
                />
              </div>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  tableHeader="Models"
                  data={featureVersionInfo.models}
                  isLoading={isLoading}
                />
              </div>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  tableHeader="Template"
                  data={featureVersionInfo.template}
                  isLoading={isLoading}
                />
              </div>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  tableHeader="Plugins"
                  data={featureVersionInfo.plugins}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FeatureContent;
