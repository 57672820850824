import { getAllMetricsApi } from "./apiEndpoints";
import axios from "axios";
//name of the function and filename should be same
export const getAllMetrics = async (payload) => {
  //api call for get request
  let data;
  await axios
    .get(getAllMetricsApi, {
      params: {
        ...payload,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
