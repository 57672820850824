import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import classes from "./UserTenantInfo.module.css";

const UserTenantInfo = (props) => {
  const { info, onClick } = props;

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.content}>
        <div className={classes.head}>
          <div className={classes.app}>{info?.appType}</div>
        </div>
        <div className={classes.tenant}>
          <HomeRoundedIcon htmlColor="#4D6994" sx={{ fontSize: 40 }} />
          <div>
            <p className={classes.tname}>
              <abbr title={info?.tenantName} style={{ textDecoration: "none" }}>
                {info?.tenantName.trimEllip(35)}
              </abbr>
            </p>
            <p className={classes.ttext}>Tenant</p>
          </div>
        </div>
        <div className={classes.roles}>
          {info?.roles?.map((role) => (
            <div key={role} className={classes.role}>
              {role?.toLowerCase().includes("admin") ? (
                <AdminPanelSettingsIcon
                  htmlColor="#4D6994"
                  sx={{ fontSize: 40 }}
                />
              ) : (
                <AccountBoxIcon htmlColor="#4D6994" sx={{ fontSize: 40 }} />
              )}
              <div>
                <p className={classes["role-name"]}>
                  {role != null ? role : "N/A"}
                </p>
                <p className={classes.rtext}>Role</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserTenantInfo;
