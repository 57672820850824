import axios from "axios";
import { deleteVersionApi } from "./apiEndpoints";

export const deleteVersion = async (payload) => {
  let data = null;
  await axios
    .delete(
      `${deleteVersionApi}/${payload.qcommFeatureName}/${payload.featureVersion}`
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
