import { Fragment, useEffect, useState } from "react";

import FeatureContentTable from "../features/TenantFeaturesInfo/FeatureContent/FeatureContentTable";
import errorHandler from "../helpers/error-helper";
import { getAllVersionHistory } from "../../api/api-index";
import { GET_MESSAGES } from "../../constants/message-constants";
import { PAGE_SIZE } from "../../constants/page-constants";
import { BUNDLE_TYPE } from "../../constants/bundle-type-constants";

import classes from "./CatalogContent.module.css";

const CatalogContent = ({ name, featureType }) => {
  const [featureVersionInfo, setFeatureVersionInfo] = useState({
    models: [],
    containers: [],
    template: [],
    plugins: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const options = {
      qcommFeatureName: name,
      pageNo: 0,
      pageSize: PAGE_SIZE,
    };

    getAllVersionHistory(options, name)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.totalCount > 0
        ) {
          const modelList = [];
          const containerList = [];
          const templateList = [];
          const pluginsList = [];

          const containers = response?.data?.featureVersion?.find(
            (i) => i.status === "Added to Global Repository"
          )?.containersList;
          Array.isArray(containers) &&
            containers?.forEach((res, key) => {
              if (res.type === "CONTAINER") {
                containerList.push({ ...res, id: key });
              } else if (res.type === "MODEL") {
                modelList.push({ ...res, id: key });
              } else if (res.type === "TEMPLATE") {
                templateList.push({ ...res, id: key });
              } else {
                pluginsList.push({ ...res, id: key });
              }
            });

          setFeatureVersionInfo({
            models: modelList,
            containers: containerList,
            template: templateList,
            plugins: pluginsList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Fragment>
      <div className={classes.info}>
        <div className={classes.info_items}>
          {featureType === BUNDLE_TYPE.model ? (
            <>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  isLoading={loading}
                  tableHeader="Models"
                  data={featureVersionInfo.models}
                />
              </div>
              {featureVersionInfo.template.length > 0 && (
                <div className={classes.feature_Table}>
                  <FeatureContentTable
                    isLoading={loading}
                    tableHeader="Template"
                    data={featureVersionInfo.template}
                  />
                </div>
              )}
              {featureVersionInfo.plugins.length > 0 && (
                <div className={classes.feature_Table}>
                  <FeatureContentTable
                    isLoading={loading}
                    tableHeader="Plugins"
                    data={featureVersionInfo.plugins}
                  />
                </div>
              )}
            </>
          ) : featureType === BUNDLE_TYPE.container ? (
            <div className={classes.feature_Table}>
              <FeatureContentTable
                isLoading={loading}
                tableHeader="Containers"
                data={featureVersionInfo.containers}
              />
            </div>
          ) : (
            <>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  isLoading={loading}
                  tableHeader="Containers"
                  data={featureVersionInfo.containers}
                />
              </div>
              <div className={classes.feature_Table}>
                <FeatureContentTable
                  isLoading={loading}
                  tableHeader="Models"
                  data={featureVersionInfo.models}
                />
              </div>
              {featureVersionInfo.template.length > 0 && (
                <div className={classes.feature_Table}>
                  <FeatureContentTable
                    isLoading={loading}
                    tableHeader="Template"
                    data={featureVersionInfo.template}
                  />
                </div>
              )}
              {featureVersionInfo.plugins.length > 0 && (
                <div className={classes.feature_Table}>
                  <FeatureContentTable
                    isLoading={loading}
                    tableHeader="Plugins"
                    data={featureVersionInfo.plugins}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CatalogContent;
