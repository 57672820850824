import { getFeatureInfo } from "../../api/api-index";
import errorHandler from "../../components/helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import { featuresActions } from "../slices/slices-index";

export const fetchFeatureInfo = (options) => {
  return async (dispatch) => {
    await getFeatureInfo(options)
      .then((response) => {
        if (response.status && response.data != null) {
          dispatch(featuresActions.setSelectedFeature(response.data));
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          dispatch(featuresActions.setSelectedFeature({}));
        }
      })
      .catch((error) => {
        dispatch(featuresActions.setSelectedFeature({}));
        console.error("ERROR: failed to fetch, message: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  };
};
