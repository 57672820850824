import { createSlice } from "@reduxjs/toolkit";

const initialGlobalState = {
  appType: null,
};

const globalSlice = createSlice({
  name: "global",
  initialState: initialGlobalState,
  reducers: {
    setAppTypeState(state, action) {
      state.appType = action.payload;
    },
  },
});

const globalActions = globalSlice.actions;

export { globalSlice, initialGlobalState, globalActions };
