import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import CustomTextField from "../../ui/CustomTextField";
import styles from "./EditDevice.module.css";
import SubmitButton from "../../ui/Buttons/SubmitButton";
function AddTag({ tagsData, setTagsData }) {
  const [isEmpty, setIsEmpty] = useState(false);
  const [tagsDetails, setTagsDetails] = useState({
    tagKey: "",
    tagValue: "",
  });

  const pattern = "^[a-zA-Z0-9 ]+$";
  const submitHandler = () => {
    const data = {
      tag: tagsDetails.tagKey,
      tagValue: tagsDetails.tagValue,
    };

    const isMatching =
      data.tag.match(pattern) && data.tagValue.match(pattern) ? true : false;

    if (data.tag && data.tagValue && isMatching) {
      setTagsData([
        {
          ...data,
          id: uuidv4(),
        },
        ...tagsData,
      ]);
      setTagsDetails({ tagKey: "", tagValue: "" });
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.field_div}>
          <CustomTextField
            size="small"
            focused
            fullWidth
            id="key"
            label="Key"
            placeholder="Enter Key"
            onChange={(event, newValue) => {
              setTagsDetails({
                ...tagsDetails,
                tagKey: event?.target?.value,
              });
            }}
            value={tagsDetails.tagKey || ""}
            type="text"
            error={isEmpty && !tagsDetails?.tagKey.match(pattern) && true}
            inputProps={{ maxLength: 255 }}
          />
        </div>
        <div className={styles.field_div}>
          <div className={styles.field_div_1}>
            <CustomTextField
              size="small"
              focused
              fullWidth
              placeholder="Enter Value"
              label="Value"
              id="value"
              onChange={(event, newValue) => {
                setTagsDetails({
                  ...tagsDetails,
                  tagValue: event?.target?.value,
                });
              }}
              value={tagsDetails.tagValue || ""}
              type="text"
              error={isEmpty && !tagsDetails?.tagValue.match(pattern) && true}
              inputProps={{ maxLength: 255 }}
            />

            <SubmitButton
              text={"Add"}
              type={"button"}
              onClick={submitHandler}
            />
          </div>
        </div>
      </div>
      {isEmpty &&
      !tagsDetails?.tagKey.match(pattern) |
        !tagsDetails?.tagValue.match(pattern) ? (
        <span className={styles.disclaimer}>
          * Only alphanumeric characters are allowed
        </span>
      ) : null}
    </>
  );
}

export default AddTag;
