import { getSubscriptionPolicyInfoByFeatureApi } from "./apiEndpoints";
import axios from "axios";

export const getPolicyInfoByFeatureId = async (id) => {
  let data;
  await axios
    .get(getSubscriptionPolicyInfoByFeatureApi, {
      params: {
        featureId: id,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.reponse);
      data = error.response;
      data.status = false;
    });
  return data;
};
