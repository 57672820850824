import { HEADERS } from "../../constants/header-constants";

const organizationsListHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.organizationsList.name,
      info: HEADERS.organizationsList.info,
      route: details.routes.organizationsList,
    },
  ];
  return headers;
};

const organizationHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.organizationInfo.name,
      info: HEADERS.organizationInfo.info,
      route: details.routes.organizationInfo,
    },
    {
      name: HEADERS.users.name,
      info: HEADERS.users.info,
      route: details.routes.users,
    },
    {
      name: HEADERS.isvTenants.name,
      info: HEADERS.isvTenants.info,
      route: details.routes.isvTenants,
    },
    {
      name: HEADERS.customerTenants.name,
      info: HEADERS.customerTenants.info,
      route: details.routes.customerTenants,
    },
  ];

  return headers;
};

const isvTenantsHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.isvUsers.name,
      info: HEADERS.isvUsers.info,
      route: details.routes.isvUsers,
    },
  ];
  return headers;
};

const customerTenantsHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.customerUsers.name,
      info: HEADERS.customerUsers.info,
      route: details.routes.customerUsers,
    },
  ];
  return headers;
};

const isvAvailableUsersHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.isvAvailableUsers.name,
      info: HEADERS.isvAvailableUsers.info,
      route: details.routes.isvAvailableUsers,
    },
  ];
  return headers;
};

const tenantHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.isvList.name,
      info: HEADERS.isvList.info,
      route: details.routes.isvList,
    },
    {
      name: HEADERS.customersList.name,
      info: HEADERS.customersList.info,
      route: details.routes.customersList,
    },
  ];
  return headers;
};

const tenantFeaturesHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.myFeatures.name,
      info: HEADERS.myFeatures.info,
      route: details.routes.myFeatures,
    },
    {
      name: HEADERS.associatedUsers.name,
      info: HEADERS.associatedUsers.info,
      route: details.routes.associatedUsers,
    },
    {
      name: HEADERS.subscribers.name,
      info: HEADERS.subscribers.info,
      route: details.routes.subscribers,
    },
  ];
  return headers;
};

const featureHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.featureInfo.name,
      info: HEADERS.featureInfo.info,
      route: details.routes.featureInfo,
    },
    {
      name: HEADERS.contents.name,
      info: HEADERS.contents.info,
      route: details.routes.contents,
    },
    {
      name: HEADERS.versionHistory.name,
      info: HEADERS.versionHistory.info,
      route: details.routes.versionHistory,
    },
    {
      name: HEADERS.subscribers.name,
      info: HEADERS.subscribers.info,
      route: details.routes.subscribers,
    },
  ];
  return headers;
};
const SDKHeader = (details) => {
  const headers = [
    {
      name: HEADERS.LinuxSdk.name,
      info: HEADERS.LinuxSdk.info,
      route: details.routes.LinuxSdk,
    },
    {
      name: HEADERS.AndroidSdk.name,
      info: HEADERS.AndroidSdk.info,
      route: details.routes.AndroidSdk,
    },
   
  ];
  return headers;
};
const susbcribersHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.subscribers.name,
      info: HEADERS.subscribers.info,
      route: details.routes.subscribers,
    },
  ];
  return headers;
};
const susbcribersInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.subscriberInfo.name,
      info: HEADERS.subscriberInfo.info,
      route: details.routes.subscriberInfo,
    },
  ];
  return headers;
};
const customerDevicesHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.devices.name,
      info: HEADERS.devices.info,
      route: details.routes.deviceList,
    },
    {
      name: HEADERS.customerUsers.name,
      info: HEADERS.customerUsers.info,
      route: details.routes.customerUsers,
    },
    {
      name: HEADERS.features.name,
      info: HEADERS.features.info,
      route: details.routes.features,
    },
    {
      name: HEADERS.mdd.name,
      info: HEADERS.mdd.info,
      route: details.routes.mdd,
    },
    {
      name: HEADERS.mddDashboard.name,
      info: HEADERS.mddDashboard.info,
      route: details.routes.mddDashboard,
    },
    // {
    //   name: HEADERS.myModels.name,
    //   info: HEADERS.myModels.info,
    //   route: details.routes.modelList,
    // },
    // {
    //   name: HEADERS.myContainers.name,
    //   info: HEADERS.myContainers.info,
    //   route: details.routes.containerList,
    // },
  ];
  return headers;
};
const subscriptionPolicyHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.subscriptionPolicyInfo.name,
      info: HEADERS.subscriptionPolicyInfo.info,
      route: details.routes.subscriptionPolicyInfo,
    },
  ];
  return headers;
};
const featureRepositoriesHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.localRepo.name,
      info: HEADERS.localRepo.info,
      route: details.routes.localRepo,
    },
    {
      name: HEADERS.globalRepo.name,
      info: HEADERS.globalRepo.info,
      route: details.routes.globalRepo,
    },
  ];
  return headers;
};
const repositoryInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.repoInfo.name,
      info: HEADERS.repoInfo.info,
      route: details.routes.repoInfo,
    },
  ];
  return headers;
};

const deviceInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.deviceInfo.name,
      info: HEADERS.deviceInfo.info,
      route: details.routes.deviceInfo,
    },
    {
      name: HEADERS.healthMetrics.name,
      info: HEADERS.healthMetrics.info,
      route: details.routes.health,
    },
    {
      name: HEADERS.qseaMetrics.name,
      info: HEADERS.qseaMetrics.info,
      route: details.routes.qsea,
    },
  ];
  return headers;
};

const tenantAvailableUsersHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.tenantAvailableUsers.name,
      info: HEADERS.tenantAvailableUsers.info,
      route: details.routes.tenantAvailableUsers,
    },
  ];
  return headers;
};

const featureDeviceHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.myFeatureDevicesList.name,
      info: HEADERS.myFeatureDevicesList.info,
      route: details.routes.allDevices,
    },
  ];
  return headers;
};
const availableDevicesHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.availableDevicesList.name,
      info: HEADERS.availableDevicesList.info,
      route: details.routes.devicesList,
    },
  ];
  return headers;
};

const deviceLicensesHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.devicesAndLicenses.name,
      info: HEADERS.devicesAndLicenses.info,
      route: details.routes.devicesAndLicenses,
    },
  ];
  return headers;
};
const notificationHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.alerts.name,
      info: HEADERS.alerts.info,
      route: details.routes.alerts,
    },
    {
      name: HEADERS.events.name,
      info: HEADERS.events.info,
      route: details.routes.events,
    },
  ];
  return headers;
};

const licenseInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.licenseInfo.name,
      info: HEADERS.licenseInfo.info,
      route: details.routes.licenseInfo,
    },
  ];
  return headers;
};
const catalogInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.about.name,
      info: HEADERS.about.info,
      route: details.routes.about,
    },
    {
      name: HEADERS.contents.name,
      info: HEADERS.contents.info,
      route: details.routes.contents,
    },
  ];
  return headers;
};

const OwnershipHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.ownership.name,
      info: HEADERS.ownership.info,
      route: details.routes.ownership,
    },
  ];
  return headers;
};

const MDDInfoHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.mddInfo.name,
      info: HEADERS.mddInfo.info,
      route: details.routes.mdd,
    },
    {
      name: HEADERS.mddAssociatedDevices.name,
      info: HEADERS.mddAssociatedDevices.info,
      route: details.routes.associatedDevices,
    },
  ];
  return headers;
};

const availableDevicesMDDHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.availableDevicesMDD.name,
      info: HEADERS.availableDevicesMDD.info,
      route: details.routes.devicesList,
    },
  ];
  return headers;
};
const dashboardMetricDetailHeaders = (details) => {
  const headers = [
    {
      name: HEADERS.dashboardMetricDetails.name,
      info: HEADERS.availableDevicesMDD.info,
      route: details.routes.metricDetails,
    },
  ];
  return headers;
};
export {
  organizationsListHeaders,
  organizationHeaders,
  isvTenantsHeaders,
  customerTenantsHeaders,
  isvAvailableUsersHeaders,
  tenantHeaders,
  tenantFeaturesHeaders,
  featureHeaders,
  customerDevicesHeaders,
  subscriptionPolicyHeaders,
  deviceInfoHeaders,
  tenantAvailableUsersHeaders,
  featureDeviceHeaders,
  deviceLicensesHeaders,
  availableDevicesHeaders,
  susbcribersHeaders,
  susbcribersInfoHeaders,
  notificationHeaders,
  featureRepositoriesHeaders,
  repositoryInfoHeaders,
  licenseInfoHeaders,
  catalogInfoHeaders,
  OwnershipHeaders,
  MDDInfoHeaders,
  availableDevicesMDDHeaders,
  dashboardMetricDetailHeaders,
  SDKHeader,
};
