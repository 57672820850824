import { Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { DateFormatter } from "../../helpers/utils";
import {
  columnMenuStyles,
  CustomVersionHistoryStatsDataGrid,
  panelStyles,
} from "../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";

import classes from "../../ui/DataTable.module.css";

const VersionHistoryStats = ({ list }) => {
  const [versionHistoryStats, setVersionHistoryStats] = useState({
    count: 0,
    list: [],
  });
  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const columns = [
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "imgName",
      headerName: "Name",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "version",
      headerName: "Version",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "size",
      headerName: "Size",
      flex: 0.5,
      minWidth: 100,
    },
    // {
    //   field: "pushTime",
    //   headerName: "Push Time",
    //   flex: 0.8,
    //   minWidth: 140,
    // },
  ];

  useEffect(() => {
    const formattedList = list?.map((item) => {
      return {
        id: item.fcId,
        type:
          item.type.slice(0, 1).toUpperCase() +
          item.type.slice(1).toLowerCase(),
        imgName: item.containerName,
        version: item.containerVersion,
        size: `${item.containerSize} ${item.unit}`,
        pushTime: item.pushTime,
      };
    });
    setVersionHistoryStats({
      count: formattedList.length,
      list: formattedList,
    });
  }, []);

  return (
    <div className={classes.data}>
      <CustomVersionHistoryStatsDataGrid
        columns={columns}
        rows={versionHistoryStats.list || []}
        disableColumnFilter
        disableColumnMenu
        hideFooterSelectedRowCount
        scrollbarSize={17}
        hideFooter
        getCellClassName={(params) => {
          if (params.field === "id") {
            return "id";
          }

          return "general";
        }}
        components={{
          NoRowsOverlay: CustomNoRowMessage,
        }}
        componentsProps={{
          toolbar: {
            reference: setColumnButtonEl,
            children: null,
          },
          panel: {
            anchorEl: columnButtonEl,
            sx: panelStyles,
          },
          columnMenu: {
            sx: columnMenuStyles,
          },
        }}
      />
    </div>
  );
};

export default VersionHistoryStats;
