import axios from "axios";
import { editFeatureApi } from "./apiEndpoints";

export const editFeature = async (options) => {
  let data = null;
  await axios
    .patch(
      editFeatureApi + options?.featureId,
      {
        ...options.payload,
      },
      { params: { roleType: options.roleType } }
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
