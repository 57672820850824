import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CommonHeader from "../../ui/CommonHeader";
import CustomTextField from "../../ui/CustomTextField";
import AddIconButton from "../../ui/Buttons/AddIconButton";
import RemoveIconButton from "../../ui/Buttons/RemoveIconButton";
import UploadCatalogImage from "./UploadCatalogImage";
import AlertBanner from "../../ui/Alert/AlertBanner";
import { configureCatalog, getCatalogInfo } from "../../../api/api-index";
import { CREATE_FEATURE_CATALOG_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";

import classes from "./ConfigureCatalogue.module.css";

let isClicked = true;

function ConfigureCatalogue(props) {
  const {
    onClose,
    featureName,
    featureId,
    type = "New",
    filesArray = [],
    listArray = [{ indexKey: 1, title: "", text: "" }],
  } = props;

  const [list, setList] = useState(listArray);
  const [files, setFiles] = useState(filesArray);
  // const [isVisible, setIsVisible] = useState(false);

  function valueChange(fieldValue, fieldKey, indexKey) {
    let temp = list;
    let itemIndex = list.findIndex((element) => element.indexKey === indexKey);
    temp[itemIndex][fieldKey] = fieldValue;
    setList([...temp]);
  }

  // function dataURLtoFile(dataurl, filename = "sample.jpg") {
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }

  // useEffect(() => {
  //   if (type === "Edit") {
  //     setIsVisible(false);
  //     getCatalogInfo(featureName)
  //       .then((response) => {
  //         setIsVisible(true);
  //         if (response.status && Array.isArray(response.data.titleTextList)) {
  //           const formattedList = [];
  //           const formattedFiles = [];
  //           if (response.data.titleTextList.length > 0) {
  //             response.data.titleTextList.map((element, key) => {
  //               formattedList.push({
  //                 indexKey: key + 1,
  //                 title: element.title,
  //                 text: element.text,
  //               });
  //             });
  //             setList(formattedList);
  //           }

  //           if (response.data.images != null) {
  //             for (const property in response.data.images) {
  //               formattedFiles.push(
  //                 dataURLtoFile(
  //                   "data:text/plain;base64," + response.data.images[property],
  //                   property || "sample.jpg"
  //                 )
  //               );
  //             }
  //             setFiles(formattedFiles);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setIsVisible(true);
  //         console.error(error);
  //         errorHandler(error, CREATE_FEATURE_CATALOG_MESSAGES.failure);
  //       });
  //   }
  // }, []);

  function onAddGroup() {
    let temp = list;
    temp.push({
      indexKey: list[list.length - 1]?.indexKey + 1,
      title: "",
      text: "",
    });
    setList([...temp]);
  }

  function onRemoveGroup(indexKey) {
    let temp = list;
    const filterList = temp.filter((obj) => obj.indexKey !== indexKey);
    setList([...filterList]);
  }
  function onHandleSubmit(e) {
    e.preventDefault();
    let isUnique = true;
    const names = [];
    files.map((item) => {
      if (names.includes(item.name)) {
        isClicked = true;
        isUnique = false;
        return;
      } else {
        names.push(item.name);
      }
    });

    if (isClicked && isUnique) {
      isClicked = false;
      const payload = {
        featureName: featureName,
        listArr: {
          // titleTextData: {
          titleTextList: list.map((item) => ({
            title: item.title,
            text: item.text,
          })),
          // },
        },
        filesArray: files,
      };

      configureCatalog(payload)
        .then((res) => {
          if (res.status) {
            toast.success(CREATE_FEATURE_CATALOG_MESSAGES.success);
            onClose();
          } else {
            errorHandler(res.error, CREATE_FEATURE_CATALOG_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, CREATE_FEATURE_CATALOG_MESSAGES.failure);
          isClicked = true;
        });
    } else {
      toast.error("File name cannot be same");
    }
  }

  return (
    <form onSubmit={(e) => onHandleSubmit(e)}>
      <div className={type != "Edit" ? `${classes.container}` : undefined}>
        {type != "Edit" && (
          <CommonHeader heading="Configure Catalog" onClose={onClose} />
        )}
        <AlertBanner
          severity="info"
          text="These details will be visible in catalog portal publicly"
        />
        {list.map((obj, key) => {
          return (
            <div className={classes.row} key={key}>
              <div className={classes.row1}>
                <div className={classes.row2}>
                  {key === list.length - 1 && list.length != 3 && (
                    <AddIconButton onClick={onAddGroup} size="small" />
                  )}
                  {list.length > 1 && key >= 0 && (
                    <RemoveIconButton
                      size="small"
                      onClick={() => onRemoveGroup(obj.indexKey)}
                    />
                  )}
                </div>
                <CustomTextField
                  size="small"
                  inputProps={{
                    pattern: ".*[^ ].*",
                    title: "Cannot have only spaces",
                    maxLength: 255,
                  }}
                  required
                  focused
                  fullWidth
                  name="title"
                  id="key"
                  label="Title"
                  placeholder="Enter title"
                  value={obj.title}
                  onChange={(event) =>
                    valueChange(
                      event.target.value,
                      event.target.name,
                      obj.indexKey
                    )
                  }
                />

                <CustomTextField
                  size="small"
                  multiline
                  inputProps={{
                    pattern: "s*S+.*",
                    title: "Cannot have only spaces",
                    maxLength: 255,
                  }}
                  focused
                  required
                  fullWidth
                  name="text"
                  id="key"
                  value={obj.text}
                  label="Description"
                  placeholder="Enter Description"
                  onChange={(event) =>
                    valueChange(
                      event.target.value,
                      event.target.name,
                      obj.indexKey
                    )
                  }
                />
                <Divider />
              </div>
            </div>
          );
        })}
        <div>
          <UploadCatalogImage files={files} setFiles={setFiles} />
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />

          <SubmitButton text={"Submit"} type={"submit"} />
        </div>
      </div>
    </form>
  );
}

export default ConfigureCatalogue;
