import { toast } from "react-toastify";

import { updateFeatureTenantMapping } from "../../../../api/api-index";
import { DELETE_FEATURE_MAPPING_MESSAGES } from "../../../../constants/message-constants";
import errorHandler from "../../../helpers/error-helper";
import BackdropOverlayModal from "../../../ui/BackdropOverlayModal";
import CancelButton from "../../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../../ui/CommonHeader";

import classes from "./DeleteFeatureOwnership.module.css";

const DeleteFeatureOwnership = (props) => {
  let clicked = true;
  const { onClose, userDetails, getUpdatedDataFn } = props;

  const onSubmit = () => {
    if (clicked) {
      clicked = false;
      updateFeatureTenantMapping(userDetails.featureId)
        .then((response) => {
          if (response.status) {
            toast.success(DELETE_FEATURE_MAPPING_MESSAGES.success);
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(
              response.error,
              DELETE_FEATURE_MAPPING_MESSAGES.failure
            );
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, DELETE_FEATURE_MAPPING_MESSAGES.failure);
          clicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Feature Ownership" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to delete mapping between
            <b> {userDetails?.featureName}</b> feature and <br />
            <b>{userDetails.tenantName} </b> tenant
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteFeatureOwnership;
