import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { getLicenseInstalledCountGraph } from "../../../api/api-index";
import { GET_MESSAGES } from "../../../constants/message-constants";
import { PAGE_SIZE } from "../../../constants/page-constants";
import errorHandler from "../../helpers/error-helper";

let interval = null;

const DeviceLicensesStatGraph = () => {
  const graphRef = useRef(null);
  const chartRef = useRef(null);
  const [dimension, setDimension] = useState({
    graphHeight: 200,
    graphWidth: 200,
  });

  const [deviceLicensesStat, setDeviceLicensesStat] = useState([]);

  const apiCall = useCallback((chart) => {
    const options = {
      pageNo: 0,
      pageSize: PAGE_SIZE,
    };

    getLicenseInstalledCountGraph(options)
      .then((response) => {
        if (response.status && response.data != null) {
          chart.hideLoading();
          setDeviceLicensesStat([
            {
              name: "Total number of licenses installed Count",
              y: response.data,
            },
          ]);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.log("ERROR: Failed to fetch graph data, MESSAGE: ", error);
        errorHandler(response.error, GET_MESSAGES.failure);
      });
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setDimension({
        graphHeight: graphRef.current.clientHeight,
        graphWidth: graphRef.current.clientWidth,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
  }, []);

  useEffect(() => {
    let chartObj = chartRef.current.chart;
    // chartObj.hideLoading();
    chartObj.showLoading();
    if (interval) {
      clearInterval(interval);
    }
    apiCall(chartObj);
    interval = setInterval(() => {
      apiCall();
    }, 60000);
  }, [apiCall]);

  return (
    <div ref={graphRef}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={{
          chart: {
            type: "column",
            plotBackgroundColor: "transparent",
            backgroundColor: "transparent",
            height: dimension.graphHeight,
            width: dimension.graphWidth,
          },
          title: false,
          legend: false,
          tooltip: false,
          credits: {
            enabled: false,
          },
          xAxis: {
            categories: ["Total number of licenses installed"],
            labels: {
              style: {
                fontSize: "clamp(0.75rem, 0.6893rem + 0.1942vw, 1rem)",
                fontFamily: "QualcommNext-Regular",
              },
            },
            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            gridLineDashStyle: "ShortDash",
            labels: {
              style: {
                fontSize: "clamp(0.75rem, 0.6893rem + 0.1942vw, 1rem);",
                fontFamily: "QualcommNext-Regular",
              },
            },
            title: {
              text: null,
            },
          },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.point.y;
                },
                style: {
                  fontSize: "clamp(0.75rem, 0.6893rem + 0.1942vw, 1rem);",
                  fontFamily: "QualcommNext-Regular",
                },
              },
            },
          },
          series: [
            {
              data: deviceLicensesStat,
            },
          ],
        }}
      />
    </div>
  );
};

export default DeviceLicensesStatGraph;
