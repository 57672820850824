import { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, IconButton, Tooltip, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import EditIcon from "@mui/icons-material/Edit";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import DataTablePanel from "../panel/DataTablePanel";
import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { subscriptionPolicyHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { getSubscriptionPolicyList } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import { CSS_ROUTES } from "../../constants/route-constants";
import { BUTTONS } from "../../constants/panel-constants";
import { ROLES } from "../../constants/role-constants";
import EditSubscriptionPolicy from "./EditSubscriptionPolicy/EditSubscriptionPolicy";
import DeleteSubscription from "./DeleteSubscriptionPolicy/DeleteSubscriptionPolicy";
import SubscriptionPolicyStatus from "./ActiveInactiveSubscriptionPolicy/SubscriptionPolicyStatus";
import CreateSubscriptionPolicy from "./CreateSubscriptionPolicy/CreateSubscriptionPolicy";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { ExpiryDateFormatter, policyStatusChecker } from "../helpers/utils";
import { GET_MESSAGES } from "../../constants/message-constants";
import errorHandler from "../helpers/error-helper";
import editIcon from "../../assets/images/common/edit.svg";
import disableEditIcon from "../../assets/images/common/disableEdit.svg";
import EditIconButton from "../ui/Buttons/EditIconButton";
import ActivateButton from "../ui/Buttons/ActivateButton";
import useSearchKeys from "../../hooks/useSearchKeys";
import { columnsActions } from "../../store/slices/columns-slice";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import CommonFilter from "../ui/Form/commonFilter/CommonFilter";
import { BUTTONS_TYPE } from "../../constants/panel-constants";
import { Badge } from "@mui/material";
import DataTablePanelTest from "../panel/DataTablePanelTest";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;

const SubscriptionPolicyList = () => {
  const dispatch = useDispatch();
  const initialState = useSelector(
    (state) => state.columnsInfo.subscriptionPolicy
  );
  const [searchStatefn] = useSearchKeys(
    [
      "policyName",
      "extid",
      "featureTitle",
      "customerName",
      "isvName",
      "expirationDate",
      "policyStatus",
    ],
    initialState
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    policyName: "",
    featureTitle: "",
    isvName: "",
    customerName: "",
    policyStatus: "Both",
    extid: "",
    pageNo: 0,
    isFilterApplied: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionPolicyList, setSubscriptionPolicyList] = useState({
    count: 0,
    list: [],
  });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditSubscriptionPolicy, setOpenEditSubscriptionPolicy] =
    useState(false);
  const [openCreateSubscriptionPolicy, setOpenCreateSubscriptionPolicy] =
    useState(false);
  const [openInactive, setOpenInactive] = useState(false);
  const [openDeleteSubcriptionModal, setOpenDeleteSubcriptionModal] =
    useState(false);

  const navigate = useNavigate();

  const handleRowClick = (params) => {
    /*TODO: change route */
    navigate(CSS_ROUTES.subscriptionPolicy + "/" + params.id);
  };

  const handlePageChange = (_, newPage) => {
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
    setIsLoading(true);
  };

  const handleOpenPanel = () => {
    setOpenCreateSubscriptionPolicy(true);
  };

  const closeCreateSubscriptionPolicy = () => {
    setOpenCreateSubscriptionPolicy(false);
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditSubscriptionPolicy(!openEditSubscriptionPolicy);
  };

  const closeEditSubscriptionPolicy = () => {
    selectedRow = null;
    setOpenEditSubscriptionPolicy(false);
  };

  const openInactiveSubscriptionPolicy = (params) => {
    selectedRow = params.row;
    setOpenInactive(true);
  };

  const closeInactiveSubscriptionPolicy = () => {
    selectedRow = null;
    setOpenInactive(false);
  };

  const columns = [
    {
      field: "policyName",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
    {
      field: "extid",
      headerName: "Policy ID",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "featureTitle",
      headerName: "Feature Title",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "customerName",
      headerName: "Customer",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "isvName",
      headerName: "ISV",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => ExpiryDateFormatter(params.row.expirationDate),
    },
    {
      field: "policyStatus",
      headerName: "Policy Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => policyStatusChecker(params.row.policyStatus),
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.5,
      hideable: false,
      renderCell: (params) => (
        <div>
          {RenderChips(
            params.row.featureStatus,
            params.row.policyStatus,
            params
          )}
        </div>
      ),
    });

  const RenderChips = (featureStatus, policyStatus, params) => {
    if (policyStatus === "INACTIVE") {
      return (
        <>
          <EditIconButton size="small" disabled={true} />
          <ActivateButton size="small" disabled={true} />
        </>
      );
    } else if (featureStatus === "MARKED_FOR_DELETE") {
      return (
        <>
          <EditIconButton size="small" disabled={true} />
          <ActivateButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              openInactiveSubscriptionPolicy(params);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <EditIconButton
            size="small"
            onClick={(event) => {
              event.ignore = true;
              handleEdit(params);
            }}
          />
          <ActivateButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              openInactiveSubscriptionPolicy(params);
            }}
          />
        </>
      );
    }
  };

  const headers = subscriptionPolicyHeaders({
    routes: {
      subscriptionPolicyInfo: CSS_ROUTES.subscriptionPolicy,
    },
  });

  function fetchFilteredSubscriptionPolicyList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      policyName: filterOptions.policyName
        ? filterOptions.policyName.trim()
        : undefined,
      extPolicyId: filterOptions.extid ? filterOptions.extid.trim() : undefined,
      featureTitle: filterOptions.featureTitle
        ? filterOptions.featureTitle.trim()
        : undefined,
      customerName: filterOptions.customerName
        ? filterOptions.customerName.trim()
        : undefined,
      isvName: filterOptions.isvName ? filterOptions.isvName.trim() : undefined,
      status:
        filterOptions.policyStatus === "Both"
          ? undefined
          : filterOptions.policyStatus.toUpperCase(),
    };

    getSubscriptionPolicyList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.policy != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.policy.map((item, key) => {
              return {
                createdOn: item?.createdOn,
                id: item?.subscriptionId,
                extid: item?.extPolicyId,
                featureTitle: item?.featureTitle,
                featureName: item?.featureName,
                customerName: item?.customerName || item?.customerId,
                customerId: item?.customerId,
                isvName: item?.isvName,
                isvId: item?.isvId,
                expirationDate: item?.expirationTimeStamp,
                policyName: item?.policyName,
                featureStatus: item?.featureStatus,
                policyChipsetsList: item?.policyChipsetsList,
                featureId: item?.featureId,
                allowedRegion: item?.allowedRegion,
                country: item?.country,
                policyStatus:
                  item?.status === "INACTIVE"
                    ? "INACTIVE"
                    : item?.featureStatus
                    ? "MARKED"
                    : "ACTIVE",
              };
            }) || [];
          setSubscriptionPolicyList({
            count: response.data.count,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchFilteredSubscriptionPolicyList();
  }, [filterOptions]);

  const openDelete = (params) => {
    selectedRow = params.row;
    setOpenDeleteSubcriptionModal(true);
  };

  const closeDelete = () => {
    setOpenDeleteSubcriptionModal(false);
  };

  const filterableitems = [
    {
      name: "policyName",
      label: "Name",
      placeholder: "Enter policy name",
      defaultValue: filterOptions.policyName,
    },
    {
      name: "extid",
      label: "Policy ID",
      placeholder: "Enter policy id",
      defaultValue: filterOptions.extid,
    },
    {
      name: "featureTitle",
      label: "Feature Title",
      placeholder: "Enter feature title",
      defaultValue: filterOptions.featureTitle,
    },
    {
      name: "customerName",
      label: "Customer",
      placeholder: "Enter customer name",
      defaultValue: filterOptions.customerName,
    },
    {
      name: "isvName",
      label: "ISV",
      placeholder: "Enter ISV name",
      defaultValue: filterOptions.isvName,
    },
    {
      name: "policyStatus",
      label: "Policy Status",
      placeholder: "Policy Status",
      defaultValue: filterOptions.policyStatus,
      select: true,
      options: ["Both", "Active", "Inactive"],
    },
  ];

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every(
      (x) => x === null || x === "" || x === "Both"
    );
    setFilterOptions({
      policyName: data.policyName,
      featureTitle: data.featureTitle,
      isvName: data.isvName,
      customerName: data.customerName,
      policyStatus: data.policyStatus,
      extid: data.extid,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && organizationsList.list.length === 1) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFilteredSubscriptionPolicyList();
    }
  }

  const buttons = [
    {
      key: "createPolicy",
      text: BUTTONS.subscriptionPolicy,
      clickHandler: handleOpenPanel,
      disabled: false,
      icon: <AddCircleOutlineIcon />,
      type: BUTTONS_TYPE.circle,
    },
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 7
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              policyName: "",
              featureTitle: "",
              isvName: "",
              customerName: "",
              policyStatus: "Both",
              extid: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openEditSubscriptionPolicy && (
        <EditSubscriptionPolicy
          onClose={closeEditSubscriptionPolicy}
          data={selectedRow}
          getUpdatedDataFn={fetchFilteredSubscriptionPolicyList}
        />
      )}

      {openDeleteSubcriptionModal && (
        <DeleteSubscription
          onClose={closeDelete}
          policyId={selectedRow?.id}
          getUpdatedDataFn={fetchFilteredSubscriptionPolicyList}
        />
      )}
      {openInactive && (
        <SubscriptionPolicyStatus
          onClose={closeInactiveSubscriptionPolicy}
          policyDetails={selectedRow}
          getUpdatedDataFn={fetchFilteredSubscriptionPolicyList}
        />
      )}

      {openCreateSubscriptionPolicy && (
        <CreateSubscriptionPolicy
          onClose={closeCreateSubscriptionPolicy}
          getUpdatedDataFn={fetchFilteredSubscriptionPolicyList}
        />
      )}
      <div className={classes.container}>
        {/* <DataTablePanel
          name={BUTTONS.subscriptionPolicy}
          list={subscriptionPolicyList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={false}
          clickHandler={handleOpenPanel}
        /> */}
        <DataTablePanelTest
          backButton={false}
          disableSearch={true}
          buttons={buttons}
        />
        <p>Subscription Policy</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(
                columnsActions.setsubscriptionPolicyColumnsState(newModel)
              )
            }
            columns={columns}
            rows={isLoading ? [] : subscriptionPolicyList.list}
            disableColumnFilter
            onRowClick={(params, event) => {
              if (!event.ignore) {
                handleRowClick(params);
              }
            }}
            getRowHeight={() => "auto"}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }

              if (
                params.row.featureStatus ||
                params.row.policyStatus === "INACTIVE"
              ) {
                return "status";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: subscriptionPolicyList.count,
                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.subscriptionPolicy,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SubscriptionPolicyList;
