import * as yup from "yup";
const errorMessages = {
  firstName: {
    required: "First name is required",
    matches: "Only alphabets are allowed.",
  },
  lastName: {
    required: "Last name is required",
    matches: "Only alphabets are allowed.",
  },

  emailId: {
    required: "Email is required",
    matches: "Enter valid email",
  },

  password: {
    required: "Password is required",
    matches:
      "Must contain at least 8 characters including a minimum of 1 special character, 1 number, and mix of uppercase and lowercase letters",
  },
  phoneNo: {
    required: "Phone number is required",
    min: "Phone number must be atleast 10 digits",
    matches: "Phone number format is incorrect",
  },
  confirmPassword: {
    required: "Password is required",
    matches: "Passwords must match",
  },
};

export const schema = yup
  .object({
    firstName: yup
      .string()
      .required(errorMessages.firstName.required)
      .matches(/^[a-zA-Z]*[ a-zA-Z]+$/g, errorMessages.firstName.matches),
    lastName: yup
      .string()
      .required(errorMessages.lastName.required)
      .matches(/^[a-zA-Z]*[ a-zA-Z]+$/g, errorMessages.lastName.matches),
    phoneNo: yup
      .string()
      .matches(/^[+]*[0-9]+$/g, errorMessages.phoneNo.matches)
      .test("len", "Must be in range of 10 to 13 digits", (val) => {
        return val.match(/[^+]/g).length <= 13 &&
          val.match(/[^+]/g).length >= 10
          ? true
          : false;
      })
      .required(errorMessages.phoneNo.required)
      .typeError(errorMessages.phoneNo.required),
    emailId: yup
      .string()
      .required(errorMessages.emailId.required)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorMessages.emailId.matches
      ),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        errorMessages.password.matches
      )
      .required(errorMessages.password.required),
    confirmPassword: yup
      .string()
      .required(errorMessages.confirmPassword.required)
      .oneOf([yup.ref("password")], errorMessages.confirmPassword.matches),
  })
  .required();
