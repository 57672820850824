import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { MdWarning } from "react-icons/md";

import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import { deleteOrganization } from "../../../api/api-index";
import CommonHeader from "../../ui/CommonHeader";
import { DELETE_ORGANIZATION_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import deleteImage from "../../../assets/images/organizations/delete-organization.png";
import { CSS_ROUTES } from "../../../constants/route-constants";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";

import classes from "./DeleteOrganization.module.css";

const DeleteOrganization = (props) => {
  let clicked = true;
  const {
    onClose,
    onDeleteClose,
    organizationId,
    organizationName,
    getUpdatedDataFn,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = () => {
    if (clicked) {
      clicked = false;
      const options = {
        organizationId,
      };
      deleteOrganization(options)
        .then((response) => {
          if (response.status) {
            toast.success(DELETE_ORGANIZATION_MESSAGES.success);
            if (location.pathname === CSS_ROUTES.organizations) {
              onDeleteClose();
              getUpdatedDataFn && getUpdatedDataFn();
            } else {
              navigate(CSS_ROUTES.organizations);
            }
          } else {
            console.error(response.error);
            errorHandler(response.error, DELETE_ORGANIZATION_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, DELETE_ORGANIZATION_MESSAGES.failure);
          clicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Organization" onClose={onClose} />
        <div className={classes.warning}>
          <MdWarning size={32} color="#FF5B5B" />
          <div className={classes["warning-text"]}>
            <p>
              <b>Warning</b>
            </p>
            <p>This action will remove all the organization entities</p>
            <p>This action is irreversible !</p>
          </div>
        </div>
        <div className={classes.text}>
          <p>
            Are you sure you want to delete{" "}
            <b>{`${organizationName || "-"}`}</b> organization ?
          </p>
        </div>
        <img className={classes.image} src={deleteImage} alt="delete" />
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteOrganization;
