/* Package imports*/
import React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* Component imports */
import AppBar from "./components/appbar/AppBar";
import { StrictMode } from "react";
import MenuBar from "./components/menu/MenuBar";
import RouteInformation from "./routes/RouteInformation";

import { BaseLayout } from "./layouts/layout-index";
import StoreConfig from "./store/store";

/* CSS */
import "react-toastify/dist/ReactToastify.css";
import classes from "./App.module.css";

const store = StoreConfig.config();

const App = () => {
  return (
    /* TODO: Remove strict mode for production build */
    // <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <BaseLayout>
          {/* <AppBar />
            <MenuBar /> */}
          <RouteInformation />
        </BaseLayout>
        <ToastContainer
          toastStyle={{ fontFamily: "QualcommNext-Regular" }}
          className={classes.toast}
          theme="colored"
          autoClose={3000}
        />
      </HashRouter>
    </Provider>
    // </StrictMode>
  );
};

export default App;
