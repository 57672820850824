import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdOutlineUploadFile } from "react-icons/md";

import { fetchOrganizationInfo } from "../../../store/actions/actions-index";
import CancelButton from "../../ui/Buttons/CancelButton";
import CloseIcon from "../../ui/Buttons/CloseIcon";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import {
  ADD_LOGO_MESSAGES,
  UPDATE_LOGO_MESSAGES,
} from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { uploadFile } from "../../../api/uploadFile";
import { getOrgIconData } from "../../../api/api-index";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import { dataURLtoFile } from "../../helpers/utils";

import classes from "./EditUploadPhoto.module.css";

const baseStyle = {
  padding: "2.5em",
  borderRadius: "8px",
  border: "2px dashed #58A7FF",
  backgroundColor: "#F8FAFF",
  color: "#BCCEFA",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "20vh",
  width: "45vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3em",
};

const activeStyle = {
  border: "2px dashed #2196f3",
};

const acceptStyle = {
  border: "2px dashed #00e676",
  backgroundColor: "#00e67633",
};

const rejectStyle = {
  border: "2px dashed #ff1744",
  backgroundColor: "#ff174433",
};
const mdStyles = {
  ...baseStyle,
  flexDirection: "column",
};
const EditUploadPhoto = (props) => {
  const matches = useMediaQuery("(max-width:1000px)");

  let clicked = true;
  const { organizationId, icon, onClose, getUpdatedDataFn } = props;
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );

  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState({
    status: false,
    error: "",
  });

  const onDropAccepted = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setRejected({ status: false, error: "" });
  }, []);

  const onDropRejected = (file) => {
    const errStatus =
      file[0]?.errors[0]?.code === "too-many-files"
        ? "Please select only one file"
        : file[0]?.errors[0]?.code === "file-too-large"
        ? "File size exceeds 1 MB"
        : "File type must be jpeg/png format";
    setRejected({ status: true, error: errStatus || "" });
    setFiles([]);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 1000000,
    accept: ".jpeg, .png, .jpg",
  });

  const style = useMemo(
    () => ({
      ...(matches ? mdStyles : baseStyle),
      ...(rejected.status ? rejectStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, rejected.status, matches]
  );

  const handleDeleteFile = (name) => {
    setFiles([]);
  };
  const handleUploadFile = () => {
    if (clicked) {
      clicked = false;
      if (files.length > 0 && files[0]?.name) {
        uploadFile({
          organizationIcon: files[0],
          organizationId: organizationId,
        })
          .then((response) => {
            if (response.status) {
              toast.success(UPDATE_LOGO_MESSAGES.success);
              onClose();
              getUpdatedDataFn && getUpdatedDataFn();
            } else {
              console.error(response.error);
              errorHandler(response.error, ADD_LOGO_MESSAGES.failure);
              clicked = true;
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, ADD_LOGO_MESSAGES.failure);
            clicked = true;
          });
      } else {
        setRejected({ status: true, error: "Please add file" });
        clicked = true;
      }
    }
  };

  const thumbs = files.map((file, i) => (
    <div className={classes.files} key={i}>
      <div className={classes.img_container}>
        <CloseIcon
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteFile(file?.name);
          }}
        />
      </div>
      <div className={classes.uploadedImg}>
        <img
          src={file.preview ? file.preview : "data:image/*;base64," + file}
          alt={file?.name || "Logo"}
        />
      </div>
      {/* {file?.name && <p>{`Name: ${file.name}`}</p>} */}
      <div className={classes.file_details}>
        <p>
          {file.preview
            ? `Size: ${(file?.size / 1024).toFixed(1)} KB`
            : `Size ${(atob(file).length / 1024).toFixed(1)} KB`}
        </p>
        {/* {file.type && (
          <p>
            Type:{" "}
            <span>{file.type?.slice(file?.type.indexOf("/") + 1) || "-"}</span>
          </p>
        )} */}
      </div>
    </div>
  ));

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    const options = {
      iconPath: icon,
    };
    if (icon) {
      getOrgIconData(options)
        .then((response) => {
          if (response.status && response.data != null) {
            if (response.data) {
              let oldFile = [
                dataURLtoFile(
                  "data:text/plain;base64," + response.data,
                  "TEST"
                ),
              ];
              setFiles(
                oldFile.map((file) =>
                  Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  })
                )
              );
            }
          } else {
            console.log(response.error);
            errorHandler(response.error, error, ADD_LOGO_MESSAGES.failure);
          }
        })
        .catch((error) => {
          console.error(error);
          errorHandler(error, error, ADD_LOGO_MESSAGES.failure);
        });
    }
    // else if (organizationId) {
    //   const options = {
    //     organizationId: organizationId,
    //   };
    //   dispatch(fetchOrganizationInfo(options));
    // }
  }, []);

  // useEffect(() => {
  //   if (
  //     files.length === 0 &&
  //     selectedOrganization != null &&
  //     selectedOrganization.organizationIcon != null
  //   ) {
  //     setFiles([selectedOrganization.organizationIcon]);
  //   }
  // }, [selectedOrganization]);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading="Upload Logo / Avatar"
          // onDelete={openDelete}
          onClose={onClose}
        />
        <div>
          <section {...getRootProps({ style })}>
            <div className={classes.upload_container}>
              <input {...getInputProps()} />
              <MdOutlineUploadFile
                className={classes.uploadIcon}
                color="#58A7FF"
                size={30}
              />
              <p className={classes.upload}>
                Click or drag image file to this area to upload
                <em>
                  (one file with jpeg/png format of max. size 1 MB is allowed)
                </em>
              </p>
            </div>
            {thumbs}
          </section>
          <p className={classes.error}>{rejected.error}</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <SubmitButton onClick={handleUploadFile} text={"Submit"} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default EditUploadPhoto;
