import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BUTTONS_TYPE } from "../../../constants/panel-constants";

const CustomButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#4D6994",
  "&:hover": {
    backgroundColor: "#1a4071",
  },
  textTransform: "var(--mui-button-text-transform)",
  borderRadius: "20px",
  fontFamily: "QualcommNext-Regular",
}));

const CustomSquareButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#4D6994",
  "&:hover": {
    backgroundColor: "#1a4071",
  },
  textTransform: "var(--mui-button-text-transform)",
  borderRadius: "4px",
  fontFamily: "QualcommNext-Regular",
}));

const PanelButtonTest = (props) => {
  const { text, onClick, disabled, icon, type } = props;
  switch (type) {
    case BUTTONS_TYPE.square:
      return (
        <CustomSquareButton
          disableFocusRipple
          variant="contained"
          disableElevation
          disabled={disabled}
          onClick={onClick}
          endIcon={icon}
        >
          {text}
        </CustomSquareButton>
      );

    default:
      return (
        <CustomButton
          variant="contained"
          disableElevation
          disabled={disabled}
          onClick={onClick}
          endIcon={icon}
        >
          {text}
        </CustomButton>
      );
  }
};

export default PanelButtonTest;
