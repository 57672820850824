export const ROLES = {
  cssAdmin: "css-admin",
  cssMonitor: "css-monitor",
  isvAdmin: "isv-admin",
  isvDeveloper: "isv-developer",
  isvMonitor: "isv-monitor",
  customerAdmin: "customer-admin",
  customerMonitor: "customer-monitor",
  deviceManager: "device-manager",
};

export const USER_ROLES = {
  ISV: [
    { id: "isv-admin", label: "ISV Admin" },
    { id: "isv-developer", label: "ISV Developer" },
    { id: "isv-monitor", label: "ISV Monitor" },
  ],
  CUSTOMER: [
    { id: "customer-admin", label: "Customer Admin" },
    { id: "customer-monitor", label: "Customer Monitor" },
    { id: "device-manager", label: "Device Manager" },
  ],
};
