const CustomIframe = (props) => {
  return (
    <iframe
      style={{ border: "none" }}
      width="100%"
      height="100%"
      loading="lazy"
      {...props}
    ></iframe>
  );
};
export default CustomIframe;
