import ListItem from "./ListItem";
import {
  MENU_OPTIONS,
  SUB_MENU_OPTIONS,
} from "../../constants/menu-option-constants";

import classes from "./MenuList.module.css";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { ENTITY_TYPE } from "../../constants/keycloak-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../constants/route-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { ROLES } from "../../constants/role-constants";

const MenuList = (props) => {
  const { opened } = props;
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const selectedEntityType = useSelector(
    (state) => state.userInfo.selectedEntityType
  );
  const selectedTenantId = useSelector(
    (state) => state.userInfo.selectedAppKey
  );
  const isQualcommManagingDevice = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.isQualcommManagingDevice
  );
  const selectedOrganizationId = useSelector(
    (state) => state.organizationsInfo?.selectedOrganization?.organizationId
  );
  const selectedOrganizationType = useSelector(
    (state) => state.organizationsInfo?.selectedOrganization?.orgType
  );

  return (
    <div className={classes.container}>
      {(selectedAppType === ENTITY_TYPE.css ||
        selectedEntityType === ENTITY_TYPE.css) && (
        <Fragment>
          <ListItem details={MENU_OPTIONS.dashboard} opened={opened} />
          <ListItem details={MENU_OPTIONS.organizations} opened={opened} />
          <ListItem details={MENU_OPTIONS.subscriptionPolicy} opened={opened} />
          <ListItem details={MENU_OPTIONS.featureOwnerShip} opened={opened} />
          <ListItem details={MENU_OPTIONS.repositories} opened={opened} />
          <ListItem details={MENU_OPTIONS.sdk} opened={opened} />
        </Fragment>
      )}
      {(selectedAppType === ENTITY_TYPE.isv ||
        selectedEntityType === ENTITY_TYPE.isv) && (
        <Fragment>
          {/* <ListItem
            details={MENU_OPTIONS.isvTenants}
            opened={opened}
            type={ENTITY_TYPE.isv}
          /> */}
          {hasPermittedRolesOrAttributes([
            ROLES.isvAdmin,
            ROLES.isvDeveloper,
            ROLES.isvMonitor,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.isvFeatures,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.isvTenants}/${selectedTenantId}${PARTIAL_ROUTES.myFeatures}`,
              }}
              opened={opened}
            />
          )}
          {hasPermittedRolesOrAttributes([
            ROLES.isvAdmin,
            ROLES.isvMonitor,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.isvUsers,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.isvTenants}/${selectedTenantId}${PARTIAL_ROUTES.associatedUsers}`,
              }}
              opened={opened}
            />
          )}
          {hasPermittedRolesOrAttributes([
            ROLES.isvAdmin,
            ROLES.isvMonitor,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.subscribers,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.isvTenants}/${selectedTenantId}${PARTIAL_ROUTES.subscribers}`,
              }}
              opened={opened}
            />
          )}
        </Fragment>
      )}
      {(selectedAppType === ENTITY_TYPE.customer ||
        selectedEntityType === ENTITY_TYPE.customer) && (
        <Fragment>
          {/* <ListItem
            details={MENU_OPTIONS.customerTenants}
            opened={opened}
            type={ENTITY_TYPE.customer}
          /> */}

          {hasPermittedRolesOrAttributes([
            ROLES.customerAdmin,
            ROLES.customerMonitor,
            ROLES.deviceManager,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.devices,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.devices}`,
              }}
              opened={opened}
            />
          )}
          {hasPermittedRolesOrAttributes([
            ROLES.customerAdmin,
            ROLES.customerMonitor,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.customerUsers,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.associatedUsers}`,
              }}
              opened={opened}
            />
          )}
          {hasPermittedRolesOrAttributes([
            ROLES.customerAdmin,
            ROLES.customerMonitor,
            ROLES.deviceManager,
          ]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.customerFeatures,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.features}`,
              }}
              opened={opened}
            />
          )}
          {hasPermittedRolesOrAttributes([ROLES.customerAdmin]) && (
            <ListItem
              details={{
                ...MENU_OPTIONS.mdd,
                route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.mdd}`,
              }}
              opened={opened}
              subMenu={[
                {
                  ...SUB_MENU_OPTIONS.mddDifinitionList,
                  route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.mdd}`,
                },
                {
                  ...SUB_MENU_OPTIONS.mddDashboard,
                  route: `${CSS_ROUTES.organizations}/${selectedOrganizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.mddDashboard}`,
                },
              ]}
            />
          )}
          {selectedOrganizationType === "Internal" &&
            hasPermittedRolesOrAttributes([
              ROLES.customerAdmin,
              ROLES.customerMonitor,
            ]) && (
              <ListItem details={MENU_OPTIONS.repositories} opened={opened} />
            )}
        </Fragment>
      )}
    </div>
  );
};

export default MenuList;
