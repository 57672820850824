import * as yup from "yup";
const errorMessages = {
  policyName: {
    required: "Policy name  is required",
  },
  contractId: {
    required: "Policy id  is required",
  },
  policyChipsets: {
    required: "Chipset number  is required",
  },
  policyMaxDevices: {
    positive: "Number must be greater than 0",
  },
  policyExpirationDate: {
    required: "Expiration date  is required",
  },
};

export const schema = yup
  .object({
    policyName: yup
      .string()
      .required(errorMessages.policyName.required)
      .matches(/^(?!\s*$).+/g, errorMessages.policyName.required),
    contractId: yup.string().nullable(),
  })
  .required();
