import { Fragment } from "react";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import CustomPagination from "../ui/CustomPagination";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";

import classes from "../ui/DataTable.module.css";
import styles from "./TagsTable.module.css";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";

const TagsTable = ({ data, isLoading = false }) => {
  const columns = [
    {
      field: "tag",
      headerName: "Key",
      minWidth: 200,
      flex: 4,
    },
    {
      field: "tagValue",
      headerName: "Value",
      minWidth: 200,
      flex: 4,
    },
  ];

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={styles.header}>Associated Tags</span>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.5rem",
                wordBreak: "break-word",
              },

              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            getRowHeight={() => "auto"}
            hideFooterPagination
            columns={columns}
            rows={data || []}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              //   pagination: {
              //     total: featureDataList.count || 0,
              //     handlePageChange: handlePageChange,
              //     page: pageNo,
              //   },

              panel: {
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.associatedTags },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TagsTable;
