import axios from "axios";
import { getUserInfoByUserNameApi } from "./apiEndpoints";

export const getUserInfoByUserName = async (options) => {
  let data = null;
  await axios
    .get(getUserInfoByUserNameApi + options.userName)
    .then((response) => {
      data = {
        ...response.data,
        data: {
          ...response.data.data,
          fullName: `${response.data.data.firstName} ${response.data.data.lastName}`,
        },
      };
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
