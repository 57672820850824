import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { hasPermittedRolesOrAttributes } from "../components/helpers/user-roles-and-attributes";
import { ROLES } from "../constants/role-constants";

export default function useRepoStatus(list) {
  const selectedOrganizationType = useSelector(
    (state) => state.userInfo.userInfo?.orgType
  );

  let internal = selectedOrganizationType === "Internal";
  let totalList = [...list];
  const [StatusHistory, setStatusHistory] = useState([
    {
      id: "Added to Local Repository",
      status: "Added to Local Repository",
      remarks: null,
      handledBy: null,
      attachments: null,
      attachmentName: null,
      date: null,
    },
    {
      id: "Verified by Publisher",
      status: "Verified by Publisher",
      remarks: null,
      handledBy: null,
      attachments: null,
      attachmentName: null,
      date: null,
    },
    {
      id: "Approved by Validator",
      status: "Approved by Validator",
      remarks: null,
      handledBy: null,
      attachments: null,
      attachmentName: null,
      date: null,
    },

    {
      id: "Added to Global Repository",
      status: "Added to Global Repository",
      remarks: null,
      handledBy: null,
      attachments: null,
      attachmentName: null,
      date: null,
    },
  ]);
  const listArray = list.map((i) => i.status);

  const repoStatusGenerator = () => {
    StatusHistory.forEach((item) => {
      if (!listArray.includes(item.id)) {
        totalList.filter((i) => i.status === "Denied by Validator").length >
          0 && item.status === "Approved by Validator"
          ? totalList
          : totalList.push(item);
      }
    });

    if (list.length < 4 && !listArray.includes("Denied by Validator")) {
      totalList[listArray.length] = {
        ...totalList[listArray.length],
        enabled:
          (totalList[listArray.length].status ===
            "Added to Global Repository" &&
            internal) ||
          totalList[listArray.length].status === "Verified by Publisher" ||
          (totalList[listArray.length].status === "Approved by Validator" &&
            hasPermittedRolesOrAttributes([ROLES.customerMonitor]))
            ? false
            : true,
      };
    }

    setStatusHistory(totalList);
  };

  return [StatusHistory, repoStatusGenerator];
}
