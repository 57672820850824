import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Chip } from "@mui/material";
import { MdOutlineUploadFile } from "react-icons/md";

import CancelButton from "../../ui/Buttons/CancelButton";
import CloseIcon from "../../ui/Buttons/CloseIcon";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import { ADD_LOGO_MESSAGES } from "../../../constants/message-constants";
import { uploadFeatureIcon } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";

import classes from "./UploadCatalogImage.module.css";

const baseStyle = {
  padding: "2.5em",
  borderRadius: "8px",
  border: "2px dashed #58A7FF",
  backgroundColor: "#F8FAFF",
  color: "#BCCEFA",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "20vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3em",
  flexDirection: "column",
};

const activeStyle = {
  border: "2px dashed #2196f3",
};

const acceptStyle = {
  border: "2px dashed #00e676",
  backgroundColor: "#00e67633",
};

const rejectStyle = {
  border: "2px dashed #ff1744",
  backgroundColor: "#ff174433",
};
const mdStyles = {
  ...baseStyle,
  flexDirection: "column",
};
const UploadCatalogImage = (props) => {
  const matches = useMediaQuery("(max-width:1000px)");
  const { featureId, onClose, files, setFiles } = props;
  const [rejected, setRejected] = useState({
    status: false,
    error: "",
  });

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      if (files.length + acceptedFiles.length <= 3) {
        setFiles([
          ...files,

          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);

        setRejected({ status: false, error: "" });
      } else {
        setRejected({ status: true, error: "Max. 3 files only" });
        // setFiles([]);
      }
    },
    [files]
  );

  const onDropRejected = (file) => {
    const errStatus =
      file[0]?.errors[0]?.code === "too-many-files"
        ? "Please select only one file"
        : file[0]?.errors[0]?.code === "file-too-large"
        ? `${file[0]?.file?.name || ""} file size exceeds 1 MB`
        : "File type must be jpeg/png format";
    setRejected({ status: true, error: errStatus || "" });
    // setFiles([]);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,

    noKeyboard: true,
    maxFiles: 3,
    maxSize: 1000000,
    accept: ".jpeg, .png, .jpg",
  });

  const style = useMemo(
    () => ({
      ...(matches ? mdStyles : baseStyle),
      ...(rejected.status ? rejectStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, rejected.status, matches]
  );

  const handleDeleteFile = (itemKey) => {
    setFiles(files.filter((i, key) => key !== itemKey));
  };

  const handleUploadFile = () => {
    if (files.length > 0 && files[0]?.name) {
      uploadFeatureIcon({
        featureIcon: files[0],
        featureId: featureId,
      })
        .then((response) => {
          if (response.status) {
            toast.success(ADD_LOGO_MESSAGES.success);
            onClose();
          } else {
            errorHandler(response.error, ADD_LOGO_MESSAGES.failure);
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, ADD_LOGO_MESSAGES.failure);
        });
    }
  };

  const thumbs =
    Array.isArray(files) &&
    files.map((file, i) => (
      <div className={classes.files} key={i}>
        {file?.name && (
          <Chip
            sx={{ fontFamily: "QualcommNext-Regular" }}
            label={file?.name.trimEllip(15)}
            // onClick={() => handleDeleteFile(file.name)}
            onDelete={() => handleDeleteFile(i)}
          />
        )}
      </div>
    ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className={classes.containers}>
      <p className={classes.input_label}>Attachments</p>
      <div>
        <section {...getRootProps({ style })}>
          <div className={classes.upload_container}>
            <input {...getInputProps()} />
            <MdOutlineUploadFile
              className={classes.uploadIcon}
              color="#58A7FF"
              size={30}
            />
            <p className={classes.upload}>
              Click or drag image file to this area to upload
              <em>
                (Max. 3 files with jpeg/png format of max. size 1 MB each is
                allowed)
              </em>
            </p>
          </div>
          <div className={classes.chipContainer}>{thumbs}</div>
        </section>
        <p className={classes.error}>{rejected.error}</p>
      </div>
    </div>
  );
};

export default UploadCatalogImage;
