import { Fragment, useState } from "react";

import { CSS_ROUTES } from "../../../constants/route-constants";
import DataTableHeaders from "../../header/DataTableHeaders";
import { dashboardMetricDetailHeaders } from "../../helpers/header-helpers";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import FullScreenIcon from "../../ui/Buttons/FullScreenIcon";
import LightTooltip from "../../ui/Tooltip/LightToolTip";
import MetricPopup from "../../ui/metricPopup/MetricPopup";
import CustomIframe from "../../ui/customIframe/CustomIframe";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../../api/iframeEndpoints";
import useTimeStatus from "../../../hooks/useTimeStatus";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../hooks/useBreadcrumb";

import classes from "../../ui/DataTable.module.css";
import styles from "./metricDetails.module.css";

let selectedItem = null;
const HttpServerRequestsSecondsDetail = () => {
  const [breadCrumbArray] = useBreadcrumb("dashboardInfo");
  const [zoom, setzoom] = useState(false);
  const [dateRange, handleTime] = useTimeStatus();
  const handleOpenDetails = (item) => {
    selectedItem = item;
    setzoom(true);
  };
  const handleCloseDetails = () => {
    selectedItem = null;
    setzoom(false);
  };

  const headers = dashboardMetricDetailHeaders({
    routes: {
      metricDetails: CSS_ROUTES.httpServerRequestsSecondsCount,
    },
  });

  const APP = [
    { app: "user-management", mainView: 8, detailView: 8 },
    { app: "feature-management", mainView: 8, detailView: 8 },
    { app: "device-management", mainView: 8, detailView: 8 },
    { app: "notification-management", mainView: 8, detailView: 8 },
    { app: "license-management", mainView: 8, detailView: 8 },
    { app: "metrics-management", mainView: 8, detailView: 8 },
  ];

  return (
    <Fragment>
      {zoom && (
        <MetricPopup
          heading="HTTP response Code Count Stats"
          selectedItem={selectedItem}
          onClose={handleCloseDetails}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest disableSearch={true} backButton={true} />
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>Detailed Metrics</p>
        </StyledBreadcrumbs>

        <div className={classes.data}>
          <DataTableHeaders headers={headers} />
          <div className={styles.panel}>
            <h2 className={styles.sub_header}>
              HTTP response Code Count Stats
              <span className={styles.disclaimer}>
                * <i>All the data is from last 24 hrs</i>
              </span>
            </h2>

            <div className={styles.info}>
              {APP.map((item) => (
                <div key={item.app} className={styles.iframeGraph}>
                  <div className={styles.header}>
                    <span></span>
                    <LightTooltip
                      title="Expand for more details"
                      placement="top"
                      arrow
                    >
                      <div>
                        <FullScreenIcon
                          onClick={() => handleOpenDetails(item)}
                        />
                      </div>
                    </LightTooltip>
                  </div>
                  <CustomIframe
                    src={`${IFRAME_BASE_URL.baseUrl}/u_SNpaF4k/${DASHBOARD_NAME.cssAdmin}?orgId=1&var-application=${item.app}&theme=light&from=${dateRange.from}&to=${dateRange.to}&panelId=${item.mainView}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HttpServerRequestsSecondsDetail;
