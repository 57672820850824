export const BUNDLE_OPTIONS = ["MODEL", "CONTAINER"];
export const ACCESS = ["Public"];
export const ACCESSIBLE_IN_REGIONS = ["All"];
export const FEATURE_CATEGORIES = [
  "Health",
  "Telecom",
  "Retail",
  "Finance",
  "Aviation",
  "Others",
];
