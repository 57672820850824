import { createSlice } from "@reduxjs/toolkit";

const initialOrganizationsState = {
  organizationsList: [],
  selectedOrganization: null,
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState: initialOrganizationsState,
  reducers: {
    setOrganizationsState(state, action) {
      state.organizationsList = action.payload.organizationsList;
    },
    setSelectedOrganization(state, action) {
      state.selectedOrganization = action.payload;
    },
  },
});

const organizationsActions = organizationsSlice.actions;

export { organizationsSlice, initialOrganizationsState, organizationsActions };
