export const NOTIFICATION_TYPE = ["ALERT", "EVENT"];
export const NOTIFICATION_STATUS = {
  Handled: "HANDLED",
  Unhandled: "UN_HANDLED",
};
export const NOTIFICATION_NAME = {
  ADD_USER_TO_TENANCY: "Add User To Tenancy",
  FEATURE_DELETION_CSS: "Policy Expired for Feature Marked for Delete By CSS",
  FEATURE_DELETION_ISV: "Policy Expired for Feature Marked for Delete By ISV",
  EVALUATE_FOR_FREE: "Evaluate For Free",
  LICENSE: "License",
  ISV_ADDED_FEATURE_TO_LOCAL: "ISV Added a feature to the Local Repository",
  FEATURE_VALIDATED_BY_FEATURE_VALIDATOR:
    "Feature Validated by Feature Validator",
  ISV_DELETED_FROM_LOCAL: "ISV deleted Feature from Local Repository",
  CSS_DELETED_FROM_GLOBAL: "CSS deleted Feature from Global Repository",
  ISV_VERIFIED_VERSION: "ISV verified a Feature Version",
  ISV_DID_NOT_DELETE_FROM_LOCAL:
    "ISV did not delete Feature from Local Repository",
};
