import { createSlice } from "@reduxjs/toolkit";

const initialTenantsState = {
  selectedTenant: null,
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialTenantsState,
  reducers: {
    setSelectedTenant(state, action) {
      state.selectedTenant = action.payload;
    },
  },
});

const tenantsActions = tenantsSlice.actions;

export { tenantsSlice, initialTenantsState, tenantsActions };
