export const BUTTONS = {
  createOrganization: "Create Organization",
  createUser: "Create User",
  createTenant: "Create Tenant",
  addUsers: "Add Users",
  addDevices: "Add Device",
  createFeature: "Create Feature",
  configureFeature: "Configure Feature",
  policyTerms: "Policy Terms",
  policyDetails: "Policy Details",
  subscriptionPolicy: "Create Subscription Policy",
  viewDevices: "View Devices",
  editSubscriptionPolicy: "Subscription Policy",
  createMdd: "Create MDD",
};
export const BUTTONS_TYPE = {
  square: "square",
  circle: "circle",
};
