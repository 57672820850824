import { Fragment, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import EditUser from "../user/EditUser/EditUser";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import ChangePassword from "../user/ChangePassword/ChangePassword";
import DataTableHeaders from "../header/DataTableHeaders";
import { Badge } from "@mui/material";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { getUserListBySearch } from "../../api/api-index";
import { organizationHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import EditIconButton from "../ui/Buttons/EditIconButton";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { PAGE_SIZE } from "../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import { ROLES } from "../../constants/role-constants";
import CreateNewUser from "../user/CreateNewUser/CreateNewUser";
import ChangePasswordIcon from "../ui/Buttons/ChangePasswordIcon";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import DeleteUser from "../user/DeleteUser/DeleteUser";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import useSearchKeys from "../../hooks/useSearchKeys";
import { columnsActions } from "../../store/slices/slices-index";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import CommonFilter from "../ui/Form/commonFilter/CommonFilter";
import { BUTTONS_TYPE, BUTTONS } from "../../constants/panel-constants";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;

const OrganizationUsersList = () => {
  const dispatch = useDispatch();

  const initialState = useSelector((state) => state.columnsInfo.users);
  const [searchStatefn] = useSearchKeys(
    ["name", "phoneNo", "emailId", "tenantName"],
    initialState
  );
  const [breadCrumbArray] = useBreadcrumb("orgInfo");

  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openCreateNewUser, setOpenCreateNewUser] = useState(false);
  const [openEditUser, setOpenEditUserForm] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const params = useParams();
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  const [filterOptions, setFilterOptions] = useState({
    orgName: "",
    phoneNo: "",
    emailId: "",
    pageNo: 0,
    tenant: "",
    isFilterApplied: false,
  });

  const handlePageChange = (_, newPage) => {
    setIsLoading(true);
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
  };

  const handleCreateNewUser = () => {
    setOpenCreateNewUser(true);
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditUserForm(!openEditUser);
  };

  const handleChangePassword = (params) => {
    selectedRow = params.row;
    setOpenChangePassword(true);
  };

  const handleOpenDeleteUser = (params) => {
    selectedRow = params.row;
    setOpenDeleteUser(true);
  };

  const closeCreateNewUser = () => {
    selectedRow = null;
    setOpenCreateNewUser(false);
  };

  const closeEditUser = () => {
    selectedRow = null;
    setOpenEditUserForm(false);
  };

  const closeChangePassword = () => {
    selectedRow = null;
    setOpenChangePassword(false);
  };

  const closeDeleteUser = () => {
    selectedRow = null;
    setOpenDeleteUser(false);
  };

  const buttons = [
    {
      key: "createOrganization",
      text: BUTTONS.createUser,
      clickHandler: handleCreateNewUser,
      disabled: false,
      icon: <AddCircleOutlineIcon />,
      type: BUTTONS_TYPE.circle,
    },
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "tenantName",
      headerName: "Tenant Names",
      flex: 2,
      minWidth: 350,
    },
  ];

  const filterableitems = [
    {
      name: "name",
      label: "Name",
      placeholder: "Enter user name",
      defaultValue: filterOptions.orgName,
    },
    {
      name: "phoneNo",
      label: "Phone",
      placeholder: "Enter phone no",
      defaultValue: filterOptions.phoneNo,
      type: "number",
    },
    {
      name: "emailId",
      label: "Email",
      placeholder: "Enter email id",
      defaultValue: filterOptions.emailId,
    },
    {
      name: "tenantName",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenant,
    },
  ];
  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      hideable: false,
      renderCell: (params) => (
        <div>
          <EditIconButton size="small" onClick={() => handleEdit(params)} />
          <ChangePasswordIcon
            size="small"
            onClick={() => handleChangePassword(params)}
          />
          {
            // params.row.emailId !== KeycloakConfig.getUserId() &&
            <DeleteIcon
              size="small"
              onClick={() => handleOpenDeleteUser(params)}
            />
          }
        </div>
      ),
    });

  const headers = organizationHeaders({
    routes: {
      organizationInfo: CSS_ROUTES.organizations + "/" + params.organizationId,
      users:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.users,
      isvTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants,
      customerTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants,
    },
  });

  function fetchFilteredUserList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      name: filterOptions.orgName ? filterOptions.orgName.trim() : undefined,
      emailId: filterOptions.emailId ? filterOptions.emailId.trim() : undefined,
      phone: filterOptions.phoneNo || undefined,
      tenantName: filterOptions.tenant
        ? filterOptions.tenant.trim()
        : undefined,
      organizationId: params.organizationId,
    };

    getUserListBySearch(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.userDetails != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.userDetails.map((item) => {
              return {
                id: item.userId,
                userId: item.userId,
                name: `${item.firstName} ${item.lastName}`,
                firstName: item.firstName,
                lastName: item.lastName,
                emailId: item.emailId,
                phoneNo: item.phoneNo,
                tenantName: item.tenants?.toString(),
                keycloakId: item.keycloakUserId,
              };
            }) || [];
          setUsersList({
            count: response.data.count,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchFilteredUserList();
  }, [filterOptions]);

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && usersList.list.length === 1) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFilteredUserList();
    }
  }

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 4
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      orgName: data.name,
      phoneNo: data.phoneNo,
      emailId: data.emailId,
      tenant: data.tenantName,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };
  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              orgName: "",
              phoneNo: "",
              emailId: "",
              tenant: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openCreateNewUser && (
        <CreateNewUser
          onClose={closeCreateNewUser}
          organizationId={params.organizationId}
          getUpdatedDataFn={fetchFilteredUserList}
        />
      )}
      {openEditUser && (
        <EditUser
          onClose={closeEditUser}
          userDetails={selectedRow}
          getUpdatedDataFn={fetchFilteredUserList}
        />
      )}
      {openChangePassword && (
        <ChangePassword
          onClose={closeChangePassword}
          userDetails={selectedRow}
          getUpdatedDataFn={fetchFilteredUserList}
        />
      )}
      {openDeleteUser && (
        <DeleteUser
          onClose={closeDeleteUser}
          userDetails={selectedRow}
          getUpdatedDataFn={fetchUpdated}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p> {selectedOrganization?.name}</p>
        </StyledBreadcrumbs>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setusersColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : usersList.list}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                handlePageChange: handlePageChange,
                total: usersList.count,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.users },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default OrganizationUsersList;
