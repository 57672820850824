import { useMemo } from "react";

export default function useSearchKeys(list, initialState) {
  const searchStatefn = useMemo(
    () =>
      list.filter((i) => !initialState.hasOwnProperty(i) || initialState[i]),
    [initialState]
  );

  return [searchStatefn];
}
