import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EditDevice from "./EditDevice/EditDevice";
import DataTablePanel from "../panel/DataTablePanel";
import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { featureDeviceHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { getAllDevices } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import {
  TENANTS_ROUTES,
  PARTIAL_ROUTES,
  CSS_ROUTES,
} from "../../constants/route-constants";
import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import EditIconButton from "../ui/Buttons/EditIconButton";
import { columnsActions } from "../../store/slices/columns-slice";
import { customerDeviceActions } from "../../store/slices/slices-index";
import useSearchKeys from "../../hooks/useSearchKeys";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import { StyledLink } from "../ui/StyledLink";
import { universalChipFormatter } from "../helpers/utils";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;
const FeatureDeviceList = () => {
  const [breadCrumbArray] = useBreadcrumb("featureDeviceList");
  const dispatch = useDispatch();
  const initialState = useSelector(
    (state) => state.columnsInfo.customerViewDevices
  );
  const [searchStatefn] = useSearchKeys(
    [
      "deviceName",
      "qualcommDeviceId",
      "deviceTags",
      "chipsetName",
      "deviceFeatureIdList",
      "deviceStatus",
    ],
    initialState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [deviceList, setDeviceList] = useState({
    count: 0,
    list: [],
  });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditForm, setOpenEditForm] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const selectedFeature = useSelector(
    (state) => state.featuresInfo?.selectedFeature
  );

  const handleRowClick = (row) => {
    localStorage.setItem(
      "selectedDeviceInfoView",
      JSON.stringify({ view: "feature" })
    );

    const data =
      row?.row != null
        ? {
            id: row.row.id,
            qualcommDeviceId: row.row.qualcommDeviceId,
            customerManaged: false,
          }
        : null;
    dispatch(customerDeviceActions.setSelectedDevice(data));
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        row?.row.qualcommDeviceId
    );
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditForm(!openEditForm);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const closeEditDevice = () => {
    selectedRow = null;
    setOpenEditForm(false);
  };

  const columns = [
    {
      field: "qualcommDeviceId",
      headerName: "System Device Id",
      minWidth: 300,
      flex: 1,
      hideable: false,
    },
    {
      field: "chipsetName",
      headerName: "Chipset Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      minWidth: 250,
      flex: 1,
    },

    {
      field: "deviceTags",
      headerName: "Tags",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return <div>{params?.row?.deviceTags?.toString().trimEllip(20)}</div>;
      },
    },

    {
      field: "deviceFeatureIdList",
      headerName: "Features Installed",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.deviceFeatureIdList.length > 0
              ? params?.row?.deviceFeatureIdList?.toString().trimEllip(50)
              : "-"}
          </div>
        );
      },
    },
    {
      field: "deviceStatus",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.deviceStatus === "ACTIVE")
          return (
            <>{universalChipFormatter({ label: "Active", color: "success" })}</>
          );
        else {
          return "-";
        }
      },
    },
  ];

  hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.deviceManager,
    ROLES.cssAdmin,
  ]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <div>
          <EditIconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params);
            }}
          />
        </div>
      ),
    });

  const headers = featureDeviceHeaders({
    routes: {
      allDevices:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.features +
        "/" +
        params.featureId +
        "/" +
        params.featureName,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      tenantId: params.customerId,

      qualcommFeatureId: selectedFeature.qualcommFeatureId,
    };

    getAllDevices(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.deviceDetails != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.deviceDetails.map((item) => {
              return {
                id: item?.deviceId,
                qualcommDeviceId: item?.qualcommDeviceId, // TODO: Change to deviceName
                serial: item?.serialNo,
                tenantId: params.customerId,
                deviceId: item?.deviceId,
                deviceName: item?.deviceName || "N/A",
                deviceTags:
                  item.deviceTags.length > 0
                    ? item.deviceTags.map(
                        (value) => `${value.tag}:${value.tagValue}`
                      )
                    : "-",
                deviceFeatureIdList: item?.deviceFeatureIdList
                  .filter((item) => item.firstDownloadTime != null)
                  .map((feature) => feature?.featureTitle),
                chipsetName: item?.chipsetName || "N/A",
                deviceStatus: item?.status ? "ACTIVE" : "-",
              };
            }) || [];
          setDeviceList({
            count: response.data.count,
            list: formattedList,
          });
          setFilteredList(formattedList);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo, openEditForm]);

  return (
    <Fragment>
      {openEditForm && (
        <EditDevice onClose={closeEditDevice} data={selectedRow} />
      )}
      <div className={classes.container}>
        <DataTablePanel
          list={deviceList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={true}
        />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>Devices</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{`${selectedFeature?.featureName} - Devices`}</p>
        )} */}
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(
                columnsActions.setcustomerViewDevicesColumnsState(newModel)
              )
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  deviceList.list.length === filteredList.length
                    ? deviceList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.devices },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FeatureDeviceList;
