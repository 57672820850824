import { Button } from "@mui/material";
import styled from "@emotion/styled";
import ListIcon from "@mui/icons-material/List";

const CustomMenuButton = styled(Button)(() => ({
  border: "none",
  borderBottomLeftRadius: "0",
  borderBottomRightRadius: "0",
  color: "#FFFFFF",
  backgroundColor: "#4770a4",
  "&:hover": {
    backgroundColor: "#395383",
  },
}));

const MenuButton = (props) => {
  const { onClick } = props;
  return (
    <CustomMenuButton aria-label="headers" size="large" onClick={onClick}>
      <ListIcon
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
    </CustomMenuButton>
  );
};

export default MenuButton;
