import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import CustomTextField from "../../ui/CustomTextField";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import { schema } from "./EditSubscriptionPolicySchema";
import CommonHeader from "../../ui/CommonHeader";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import {
  getFeatureByName,
  editSubscriptionPolicy,
  getSubscriptionPolicyInfo,
} from "../../../api/api-index";
import {
  EDIT_SUBSCRIPTION_POLICY_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import { MAX_VALUE } from "../../../constants/max-value-constants";
import errorHandler from "../../helpers/error-helper";
import AddChipset from "./AddChipset";
import ChipsetTable from "./ChipsetTable";
import { HtmlTooltip } from "../../ui/Tooltip/HtmlTooltip";

import classes from "../../ui/Form/FormContainer.module.css";
import styles from "./EditSubscriptionPolicy.module.css";

let isClicked = true;
const EditSubscriptionPolicy = ({ onClose, data, getUpdatedDataFn }) => {
  const defaultFeatureName = data?.featureName;
  const policyId = data?.id;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
  });

  const [policyData, setPolicyData] = useState({});
  const [policyInfoData, setPolicyInfoData] = useState({});
  const [chipsets, setChipsets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const addChipset = (data) => {
    setChipsets([...chipsets, data]);
  };

  const chipsetListByFeature = policyData?.featurePolicyTerm?.chipset.filter(
    (i) => {
      const arr = chipsets && chipsets.map((chipset) => chipset.chipsetId);

      if (!arr.includes(i)) {
        return i;
      }
    }
  );

  const policyFormRequest = (data, policyInfoData) => {
    let request = null;
    if (data != null) {
      request = {
        extPolicyId: data.contractId,
        policyName: data.policyName,
        policyChipsetsList: chipsets
          .filter((date) => moment(date.expirationDate).isAfter(new Date()))
          .map((item) => ({
            expirationTimeStamp: moment(item.expirationDate)
              .endOf("day")
              .utc()
              .format(),
            chipset: item.chipsetId,
            maxAllowedDevice:
              item.numberOfDevices === "" ? null : Number(item.numberOfDevices),
          })),
        status: "ACTIVE",
        customerId: policyInfoData?.customerId,
        isvId: policyInfoData?.isvId,
        featureName: policyInfoData?.featureName,
        featureId: policyInfoData?.featureId,
        allowedRegion: policyInfoData?.allowedRegion,
        country: policyInfoData?.country,
      };
    }

    return request;
  };

  const registerHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      editSubscriptionPolicy(policyId, policyFormRequest(data, policyInfoData))
        .then((response) => {
          if (response.status && response.data != null) {
            isClicked = true;
            toast.success(EDIT_SUBSCRIPTION_POLICY_MESSAGES.success);
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            isClicked = true;
            errorHandler(
              response.error,
              EDIT_SUBSCRIPTION_POLICY_MESSAGES.failure
            );
          }
        })
        .catch((error) => {
          isClicked = true;
          errorHandler(error, EDIT_SUBSCRIPTION_POLICY_MESSAGES.failure);
        });
    }
  };

  const handleDelete = (onChange, value, selected) => {
    const filteredData = selected.filter((v) => v !== value);
    onChange(filteredData);
  };

  useEffect(() => {
    if (defaultFeatureName) {
      getFeatureByName(defaultFeatureName).then((response) => {
        if (response.status && response.data != null) {
          setPolicyData(response.data);
        } else {
          setPolicyData({});
        }
      });
    }
  }, [defaultFeatureName]);

  useEffect(() => {
    getSubscriptionPolicyInfo(policyId)
      .then((response) => {
        if (response.status && response.data != null) {
          setPolicyInfoData(response?.data);
          setValue("policyName", response?.data?.policyName);
          setValue("contractId", response?.data?.extPolicyId);
          setChipsets(
            response?.data?.policyChipsetsList?.map((item) => ({
              id: uuidv4(),
              chipsetId: item?.chipset,
              expirationDate: item?.expirationTimeStamp,
              numberOfDevices: item?.maxAllowedDevice || "",
              isDelete: false,
            }))
          );
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [policyId]);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading={`Edit Subscription Policy`} onClose={onClose} />
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Policy Details</div>
            <div className={styles.sectionContent}>
              <div className={styles.row}>
                <div className={styles.field_div}>
                  <Controller
                    name="policyName"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        onChange={onChange}
                        onBlur={onBlur}
                        size="small"
                        required
                        value={value || ""}
                        focused
                        fullWidth
                        type="text"
                        label="Policy Name"
                        placeholder="Enter policy name"
                        error={errors.policyName}
                        helperText={errors?.policyName?.message}
                        InputProps={{
                          inputProps: {
                            maxLength: MAX_VALUE.policyName,
                          },
                        }}
                      />
                    )}
                  />
                </div>
                <div className={styles.field_div}>
                  <Controller
                    name="contractId"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomTextField
                        onChange={onChange}
                        onBlur={onBlur}
                        size="small"
                        value={value || ""}
                        focused
                        fullWidth
                        type="text"
                        label="Policy ID"
                        placeholder="Enter Policy ID"
                        error={errors.contractId}
                        helperText={errors?.contractId?.message}
                        InputProps={{
                          inputProps: {
                            maxLength: 255,
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              Policy Terms{" "}
              <HtmlTooltip
                placement="right"
                arrow
                title={
                  <Fragment>
                    <div>
                      1.{" "}
                      <u>
                        <b>Max devices</b>
                      </u>{" "}
                      can range from 1 to 999999999999
                    </div>
                    <div>
                      2.{" "}
                      <u>
                        <b>Expiration date</b>
                      </u>{" "}
                      should not be less than{" "}
                      {moment(new Date()).format("DD-MM-YYYY")}
                    </div>
                  </Fragment>
                }
              >
                <InfoOutlinedIcon />
              </HtmlTooltip>
            </div>
            <div className={styles.sectionContent}>
              <AddChipset
                chipsetArray={chipsetListByFeature || []}
                addChipsetFunc={addChipset}
              />
            </div>
            <div className={styles.sectionContent}>
              <ChipsetTable
                data={chipsets}
                setData={setChipsets}
                isLoading={isLoading}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton text={"Submit"} type={"submit"} />
          </div>
        </form>
      </div>
    </BackdropOverlayModal>
  );
};
export default EditSubscriptionPolicy;
