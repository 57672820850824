import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ClearIcon = (props) => {
  const { onClick, size = "large", disabled } = props;
  return disabled ? (
    <IconButton
      aria-label="edit"
      size={size}
      onClick={onClick != null ? onClick : null}
      disabled
    >
      <FilterAltOffIcon />
    </IconButton>
  ) : (
    <Tooltip title="Clear all">
      <IconButton
        aria-label="edit"
        size={size}
        onClick={onClick != null ? onClick : null}
      >
        <FilterAltOffIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ClearIcon;
