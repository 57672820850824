import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";

import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import CustomTextField from "../../ui/CustomTextField";
import { ExpiryDateFormatter, NullCheck } from "../../helpers/utils";
import editIcon from "../../../assets/images/common/edit.svg";
import closeIcon from "../../../assets/images/common/close-icon.svg";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";

export default function ChipsetTable({ data = [], setData }) {
  const rows = [...data];
  const [rowId, setRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState({
    id: null,
    expirationDate: null,
    numberOfDevices: "",
  });

  const handleSave = () => {
    if (
      selectedRow.id &&
      selectedRow.expirationDate &&
      NullCheck(selectedRow?.numberOfDevices)
    ) {
      // const data = {
      //   tagValue: selectedRow.keyValue.trim(),
      // };
      if (
        selectedRow.expirationDate.isValid() &&
        selectedRow.expirationDate
          .endOf("day")
          .isSameOrAfter(moment(new Date()).endOf("day"))
      ) {
        let itemIndex = data.findIndex(
          (element) => element.id === selectedRow.id
        );
        data[itemIndex] = {
          ...data[itemIndex],
          expirationDate: selectedRow.expirationDate,
          numberOfDevices: selectedRow.numberOfDevices,
        };

        setRowId(null);
      } else {
        setRowId(null);
      }
    } else {
      setRowId(null);
    }

    setSelectedRow({ id: null, expirationDate: null, numberOfDevices: "" });
  };

  const columns = [
    {
      field: "chipsetId",
      headerName: "Chipset",
      minWidth: 150,
      editable: false,
      flex: 1,
    },
    {
      field: "numberOfDevices",
      headerName: "Max No. of Devices",
      minWidth: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.id === rowId ? (
          <CustomTextField
            onKeyPress={(evt) => {
              if (["e", "E", "+", "-", "."].includes(evt.key)) {
                evt.preventDefault();
              }
            }}
            id={params?.row?.id}
            type={"number"}
            size="small"
            focused
            defaultValue={params?.row?.numberOfDevices || ""}
            onChange={(event) => {
              setSelectedRow({
                id: event?.target?.id,
                numberOfDevices: event.target?.value,
                expirationDate: params?.row?.expirationDate,
              });
            }}
            error={!NullCheck(selectedRow?.numberOfDevices)}
          />
        ) : (
          params?.row?.numberOfDevices || "-"
        );
      },
    },
    {
      field: "expirationDate",
      headerName: "Expiration",
      minWidth: 170,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.id === rowId ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id={params.row.id}
              onChange={(event) => {
                setSelectedRow({
                  id: params.row.id,
                  expirationDate: event,
                  numberOfDevices:
                    selectedRow.numberOfDevices || params?.row?.numberOfDevices,
                });
              }}
              disablePast
              value={
                selectedRow.expirationDate == null
                  ? params.row.expirationDate
                  : selectedRow.expirationDate
              }
              inputFormat="DD-MM-YYYY"
              renderInput={(paramss) => (
                <CustomTextField
                  size="small"
                  focused
                  fullWidth
                  {...paramss}
                  error={paramss.error}
                />
              )}
            />
          </LocalizationProvider>
        ) : (
          // <CustomTextField
          //   id={params.row.id}
          //   InputProps={{
          //     inputProps: {
          //       min: moment(new Date()).format("YYYY-MM-DD"),
          //     },
          //   }}
          //   type={"date"}
          //   size="small"
          //   focused
          //   onChange={(event) => {
          //     setSelectedRow({
          //       id: event?.target?.id,
          //       expirationDate: event.target.value,
          //       numberOfDevices:
          //         selectedRow.numberOfDevices || params?.row?.numberOfDevices,
          //     });
          //   }}
          // />
          ExpiryDateFormatter(params?.row?.expirationDate)
        );
      },
    },
  ];

  columns.push({
    field: "Actions",
    headerName: "Actions",
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const setOpenEdit = () => {
        setRowId(params?.row?.id);
      };
      const setCloseEdit = () => {
        setRowId(null);
      };

      const deleteFeatureFromTable = () => {
        let itemIndex = data.filter((element) => {
          if (element.id !== params.row.id) {
            return element;
          }
        });

        setTimeout(() => {
          setData(itemIndex);
        });
      };

      return params?.row?.id !== rowId ? (
        <div>
          <EditIconButton size="small" onClick={setOpenEdit} />
          <DeleteIcon size="small" onClick={() => deleteFeatureFromTable()} />
        </div>
      ) : (
        <div>
          {
            <IconButton aria-label="Save" size="small" onClick={handleSave}>
              <DoneIcon />
            </IconButton>
          }
          <IconButton aria-label="Close" size="small" onClick={setCloseEdit}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
      );
    },
  });

  return (
    <div style={{ height: 250, width: "100%" }}>
      <DataGrid
        sx={{
          fontFamily: "QualcommNext-Regular",
          "& .MuiDataGrid-cell": {
            paddingLeft: "1em",

            "&:focus-within": {
              outline: "none",
            },
          },
        }}
        rows={rows}
        columns={columns}
        experimentalFeatures={{ newEditingApi: false }}
        hideFooter
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowMessage,
        }}
        componentsProps={{
          noRowsOverlay: { text: NO_ROW_MESSAGES.chipset },
        }}
      />
    </div>
  );
}
