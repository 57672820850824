import { useCallback } from "react";
import moment from "moment";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
} from "@mui/material";
import { Controller } from "react-hook-form";

import CancelButton from "../../Buttons/CancelButton";
import SubmitButton from "../../Buttons/SubmitButton";
import CleanBackdropOverlayModal from "../../CleanBackdropOverlayModal";
import CommonHeader from "../../CommonHeader";
import CustomTextField from "../../CustomTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { StyledPopper } from "../../StyledPopper";
import { StyledFormControlForMultiselect } from "../../StyledFormControlForMultiselect";
import { debounce } from "../../../helpers/utils";

import classes from "./Filter.module.css";

const Filter = (props) => {
  const {
    items,
    onClose,
    onSubmit,
    isDirty,
    control,
    handleSubmit,
    onClear,
    watch,
  } = props;

  const registerHandler = (data) => {
    onSubmit(data);
  };
  const handleDelete = (onChange, value, selected) => {
    const filteredData = selected.filter((v) => v !== value);
    onChange(filteredData);
  };

  const handleInputs = () => {
    const rows = [];
    for (let i = 0; i < items.length; i++) {
      const {
        apiCall,
        name,
        type,
        options,
        setInputChange,
        label,
        select,
        multiselect,
        placeholder,
        defaultValue,
        endDate,
        startDate,
      } = items[i];
      // console.log("ITEM: ", items[i]);
      const menuOptions = options?.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{
            fontFamily: "QualcommNext-Regular",
          }}
        >
          {option}
        </MenuItem>
      ));
      if (!!multiselect) {
        rows.push(
          <Controller
            key={name}
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value } }) => (
              <StyledFormControlForMultiselect focused fullWidth>
                <InputLabel
                  id={`label-${name}`}
                  shrink={true}
                  sx={{
                    fontFamily: "QualcommNext-Regular",
                    fontSize: "1.12rem",
                  }}
                >
                  {label}
                </InputLabel>
                <Select
                  sx={{
                    fontFamily: "QualcommNext-Regular",
                    fontSize: "1.12rem",
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  multiple
                  notched={true}
                  value={value || []}
                  label={label}
                  labelId={`label-${name}`}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <div
                          key={value}
                          className={classes.chip}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            handleDelete(onChange, value, selected);
                          }}
                        >
                          {value}
                          <HighlightOffIcon />
                        </div>
                      ))}
                    </Box>
                  )}
                >
                  {menuOptions}
                </Select>
              </StyledFormControlForMultiselect>
            )}
          />
        );
      } else if (apiCall != null) {
        rows.push(
          <Controller
            key={name}
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Autocomplete
                disableClearable
                id={name}
                size="small"
                clearOnEscape
                freeSolo
                disablePortal
                fullWidth
                options={options}
                PopperComponent={StyledPopper}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onInputChange={debounce((event) => {
                  setInputChange(event?.target?.value);
                })}
                value={value || ""}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type={type}
                    focused
                    label={label}
                    placeholder={placeholder}
                  />
                )}
              />
            )}
          />
        );
      } else {
        rows.push(
          <Controller
            key={name}
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <CustomTextField
                id={name}
                size="small"
                type={type}
                onBlur={onBlur}
                onChange={onChange}
                focused
                fullWidth
                label={label}
                value={value || ""}
                placeholder={placeholder}
                select={!!select}
                InputProps={{
                  inputProps: {
                    min:
                      endDate != null
                        ? moment(watch("installationStartDate")).format(
                            "YYYY-MM-DD"
                          )
                        : "",
                    maxLength: 255,
                    max:
                      startDate != null
                        ? moment(new Date()).format("YYYY-MM-DD")
                        : "",
                  },
                }}
              >
                {menuOptions}
              </CustomTextField>
            )}
          />
        );
      }
    }
    console.log("ROWS: ", rows);
    return rows;
  };

  return (
    <CleanBackdropOverlayModal onClick={onClose}>
      <div className={classes.container}>
        <CommonHeader
          heading="Filters"
          onClose={onClose}
          onClear={onClear}
          disabled={!isDirty}
        />
        <form autoComplete="off" onSubmit={handleSubmit(registerHandler)}>
          <div className={classes.rows}>{handleInputs()}</div>
          <div key={"buttons"} className={classes.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton text={"Apply"} type={"submit"} />
          </div>
        </form>
      </div>
    </CleanBackdropOverlayModal>
  );
};

export default Filter;
