import { getDeviceDownloadedFeatureCountApi } from "./apiEndpoints";
import axios from "axios";

export const getDevicesDownloadedFeatureCount = async (featureName) => {
  let data;
  await axios
    .get(
      `${getDeviceDownloadedFeatureCountApi}/${featureName}/active-features/count`,
      {
        params: {
          featureName,
        },
      }
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
