import { useEffect, useState } from "react";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { CustomDataGrid } from "../../ui/CustomDataGrid";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import errorHandler from "../../helpers/error-helper";

import classes from "./PreferredVersion.module.css";
import CommonHeader from "../../ui/CommonHeader";
import CancelButton from "../../ui/Buttons/CancelButton";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  getAllVersionHistory,
  updatePreferredVersion,
} from "../../../api/api-index";
import { ExpiryDateFormatter } from "../../helpers/utils";
import { REPO_TYPE } from "../../../constants/repo-constants";
import {
  GET_MESSAGES,
  UPDATE_PREFERRED_VERSION,
} from "../../../constants/message-constants";
import { toast } from "react-toastify";
let isClicked = true;
const PreferredVersion = ({
  onClose,
  featureName = "",
  featureTitle,
  preferenceType,
  currentVersion,
  info,
  updateFunction,
}) => {
  const [updateType, setUpdateType] = useState(null);
  const [versionList, setVersionList] = useState({ count: 0, list: [] });
  const [selectedUsers, setSelectedUsers] = useState({ ids: [], rows: [] });
  const [selectedVersion, setSelectedVersion] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  function changeStatus(value) {
    if (value === "AUTO") {
      setUpdateType(value);
      versionList.list.length > 0 && setSelectedVersion(versionList.list[0].id);
    } else {
      setUpdateType(value);
      versionList.list.length > 0 && setSelectedVersion(versionList.list[0].id);
    }
    error !== "" && setError("");
  }

  useEffect(() => {
    if (preferenceType != null && currentVersion != null) {
      setSelectedVersion(currentVersion);
      setUpdateType(preferenceType);
    }
  }, []);

  const columns = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 150,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              disabled={
                updateType === "AUTO" || updateType === null ? true : false
              }
              checked={selectedVersion === params.row.id}
              size="small"
            />
            {params.row.version}
          </>
        );
      },
    },
    {
      field: "pushedOn",
      headerName: "Pushed On",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      minWidth: 150,
    },
  ];

  useEffect(() => {
    const options = {
      qcommFeatureName: featureName,
      repositoryType: REPO_TYPE.global,
    };

    getAllVersionHistory(options, featureName)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedList = response.data.featureVersion.map((item) => {
            return {
              id: item.version,
              featureVersionId: item.featureVersionId,
              version: item.version,
              size: `${item.size} ${item.unit}`,
              pushedOn: ExpiryDateFormatter(item.createdOn),
            };
          });
          setVersionList({
            count: response.data.totalCount,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function handleSubmit() {
    if (isClicked) {
      isClicked = false;
      if (updateType != null) {
        const payload = {
          qualcommFeatureName: featureName,
          customerId: info,
          preferredVersion: selectedVersion,
          preferenceType: updateType,
        };

        updatePreferredVersion(payload)
          .then((response) => {
            if (response.status && response.data != null) {
              updateFunction();
              toast.success(UPDATE_PREFERRED_VERSION.success);
              onClose();
            } else {
              errorHandler(response.error, UPDATE_PREFERRED_VERSION.failure);
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, UPDATE_PREFERRED_VERSION.failure);
          })
          .finally(() => (isClicked = true));
      } else {
        setError("Select preference type");
        isClicked = true;
      }
    }
  }

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading={
            currentVersion == null
              ? `Set Preferred Version - ${featureTitle}`
              : `Set Preferred Version - ${featureTitle} - v${currentVersion}`
          }
          onClose={onClose}
        />
        <div className={classes.subContainer}>
          <p>Preference Type</p>
          <FormControl disabled={isLoading}>
            <RadioGroup
              sx={{ gap: "2em" }}
              row
              name="radio-buttons-group"
              value={updateType}
              onChange={(e) => {
                changeStatus(e.target.value);
              }}
            >
              <FormControlLabel
                sx={{ color: "#214572" }}
                disableTypography
                value="AUTO"
                control={<Radio size="small" />}
                label="Automatic Update"
              />

              <FormControlLabel
                sx={{ color: "#214572" }}
                disableTypography
                value="MANUAL"
                control={<Radio size="small" />}
                label="Manual Update"
              />
            </RadioGroup>
            <FormHelperText error={true} color="red">
              {error}
            </FormHelperText>
          </FormControl>
        </div>
        <div>
          <CustomDataGrid
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            sx={{
              height: "25rem",
              // "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              //   py: "0.8rem",
              //   wordBreak: "break-word",
              // },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E6E6E7",
              },
            }}
            selectionModel={selectedUsers.ids}
            // getRowHeight={() => "auto"}
            columns={columns}
            rows={versionList.list || []}
            checkboxSelection={false}
            disableColumnFilter
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              if (updateType === "MANUAL") {
                setSelectedVersion(ids.toString());
              }
            }}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              if (selectedVersion === params.id) {
                return "selectedVersion";
              }
              return "general";
            }}
            componentsProps={{
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.preferredVersion,
              },
            }}
            components={{
              NoRowsOverlay: CustomNoRowMessage,
            }}
          />
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <SubmitButton
            disabled={
              currentVersion === selectedVersion &&
              preferenceType === updateType
            }
            text={"Submit"}
            onClick={handleSubmit}
            type={"submit"}
          />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default PreferredVersion;
