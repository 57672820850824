import { useState } from "react";
import { toast } from "react-toastify";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import { schema } from "./EditOrganizationSchema";
import { editOrganization } from "../../../api/api-index";
import EditUploadPhoto from "./EditUploadPhoto";
import { EDIT_ORGANIZATION_MESSAGES } from "../../../constants/message-constants";
import BasicForm from "../../ui/Form/BasicForm";
import DeleteOrganization from "../DeleteOrganization/DeleteOrganization";
import errorHandler from "../../helpers/error-helper";

import classes from "../../ui/Form/FormContainer.module.css";

const EditOrganization = (props) => {
  let clicked = true;
  const { data, onClose, getUpdatedDataFn } = props;
  const {
    phone: phoneNumber,
    email,
    id: organizationId,
    name,
    organizationIcon,
    website,
    zipcode,
    address,
    country,
    orgType,
  } = data;

  const [openModal, setOpenModal] = useState({
    form: true,
    delete: false,
    uploadFile: false,
  });

  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      editOrganization({ ...data, name: data.name.trim(), organizationId })
        .then((response) => {
          if (response.status) {
            toast.success(EDIT_ORGANIZATION_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            onClose();
          } else {
            console.error(response.error);
            errorHandler(response.error, EDIT_ORGANIZATION_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, EDIT_ORGANIZATION_MESSAGES.failure);
          clicked = true;
        });
    }
  };
  const orgInputs = [
    {
      name: "name",
      label: "Organization Name",
      placeholder: "Enter organization name",
      defaultValue: name,
      disabled: true,
    },
    {
      name: "address",
      label: "Address",
      placeholder: "Enter Address",
      defaultValue: address,
    },

    {
      name: "zipcode",
      label: "Zipcode",
      placeholder: "Enter Zipcode",
      defaultValue: zipcode,
      type: "number",
    },

    {
      name: "country",
      label: "Country",
      placeholder: "Enter Country",
      defaultValue: country,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Enter Email",
      defaultValue: email,
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      placeholder: "Enter Phone Number",
      defaultValue: phoneNumber,
      type: "tel",
    },
    {
      name: "website",
      label: "Website",
      placeholder: "Enter Website URL",
      defaultValue: website,
    },
  ];
  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs:
      orgType === "Internal"
        ? [
            ...orgInputs,
            {
              name: "orgType",
              label: "Internal Organization",
              defaultValue: true,
              checkbox: true,
              disabled: true,
            },
          ]
        : orgInputs,
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading="Edit Organization"
          // onDelete={openDelete}
          onClose={onClose}
        />
        <BasicForm details={details} />
      </div>
    </BackdropOverlayModal>
  );
};
export default EditOrganization;
