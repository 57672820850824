import axios from "axios";
import { getFeatureByTenantApi } from "./apiEndpoints";

export const getFeatureByTenantId = async (tenantId) => {
  let data = null;
  await axios
    .get(`${getFeatureByTenantApi}/${tenantId}`)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.reponse);
      data = error.response;
      data.status = false;
    });
  return data;
};
