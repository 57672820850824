import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import BasicForm from "../../ui/Form/BasicForm";
import { schema } from "./EditFeatureSchema";
import {
  getAllChipsets,
  editFeature,
  getPolicyInfoByFeatureId,
} from "../../../api/api-index";
import KeycloakConfig from "../../../config/keycloak-config";
import {
  EDIT_FEATURE_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { FEATURE_CATEGORIES } from "../../../constants/feature-constants";
import { CHIPSET_PAGE_SIZE } from "../../../constants/page-constants";
import ConfigureCatalogue from "../ConfigureFeature/ConfigureCatalogue";
import UploadFeatureIcon from "../ConfigureFeature/UploadFeatureIcon";
import CloseIcon from "../../ui/Buttons/CloseIcon";

import form from "../../ui/Form/FormContainer.module.css";
import classes from "./EditFeature.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`edit-tabpanel-${index}`}
      aria-labelledby={`edit-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const EditFeature = (props) => {
  const tabStyles = {
    fontFamily: "QualcommNext-Medium",
    fontSize: "clamp(1rem, 0.9088rem + 0.1351vw, 1.125rem)",
    textTransform: "capitalize",
    "&.MuiTab-root.Mui-selected": {
      color: "#214572",
    },
  };
  let isClicked = true;
  const {
    onClose,
    selectedFeature,
    tenantId,
    roleType,
    filesArray,
    listArray,
  } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const [editCatalog, setEditCatalog] = useState(false);
  const [subscribed, setSubscribed] = useState([]);
  const [chipsets, setChipsets] = useState({
    chipsetNames: [],
    chipsetInfo: null,
  });

  const featureFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        featureName: data.name,
        featureTitle: data.title,
        featureDesc: data.featureDescription,
        featureCategory: data.category,
        label: data.labels,
        userId: KeycloakConfig.getUserId(),
        featurePolicyTerm: {
          chipset: data.allowedChipsets,
          access: data.access,
          maxDeviceNumber: data.noOfDevices,
        },
      };
    }

    return request;
  };

  const submitHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      const options = {
        featureId:
          selectedFeature?.featureId != null ? selectedFeature.featureId : null,
        roleType,
        payload: featureFormRequest(data),
      };
      editFeature(options)
        .then((response) => {
          if (response.status) {
            toast.success(EDIT_FEATURE_MESSAGES.success);
            onClose();
          } else {
            errorHandler(response.error, EDIT_FEATURE_MESSAGES.failure);
          }
          isClicked = true;
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, EDIT_FEATURE_MESSAGES.failure);
          isClicked = true;
        });
    }
  };

  // const heading =
  //   selectedFeature?.featureName != null
  //     ? `Edit Feature - ${selectedFeature.featureName}`
  //     : "Edit Feature";

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    // openUploadFile: { text: "Edit Catalog", open: openCatalog },
    inputs: [
      {
        name: "name",
        label: "Feature Name",
        placeholder: "Enter feature name",
        defaultValue:
          selectedFeature?.featureName != null
            ? selectedFeature.featureName
            : "",
        disabled: true,
      },
      {
        name: "title",
        label: "Feature Title",
        placeholder: "Enter feature title",
        defaultValue:
          selectedFeature?.featureTitle != null
            ? selectedFeature.featureTitle
            : "",
      },
      {
        name: "featureDescription",
        label: "Feature Description",
        placeholder: "Enter feature description",
        textarea: true,
        defaultValue:
          selectedFeature?.featureDesc != null
            ? selectedFeature.featureDesc
            : "",
      },
      {
        name: "category",
        label: "Category",
        placeholder: "Enter category",
        select: true,
        selectOptions: FEATURE_CATEGORIES,
        defaultValue:
          selectedFeature?.featureCategory != null
            ? selectedFeature.featureCategory
            : "",
      },

      {
        name: "labels",
        label: "Labels",
        placeholder: "Enter labels",
        defaultValue:
          selectedFeature?.label != null ? selectedFeature.label : "",
      },
      {
        header: "Feature Terms",
      },
      {
        name: "allowedChipsets",
        label: "Allowed chipsets",
        placeholder: "Select chipsets",
        multiselect: true,
        select: true,
        selectOptions: chipsets.chipsetNames,
        subscribed: subscribed,
        defaultValue:
          selectedFeature?.featurePolicyTerm?.chipset != null
            ? selectedFeature.featurePolicyTerm.chipset
            : [],
      },
      {
        name: "isFeatureDownloadable",
        label: "Downloadable Feature",
        checkbox: true,
        defaultValue: selectedFeature?.isFeatureDownloadable,
        disabled: true,
      },
    ],
  };
  useEffect(() => {
    const options = {
      pageNo: 0,
      pageSize: CHIPSET_PAGE_SIZE,
    };
    getAllChipsets(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const chipsetNames = response.data.map(
            (chipset) => chipset.chipsetName
          );
          setChipsets({
            chipsetNames: chipsetNames,
            chipsetInfo: response.data,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  useEffect(() => {
    getPolicyInfoByFeatureId(selectedFeature?.featureId).then((response) => {
      if (response.status && response.data != null) {
        const chipsetArr = [];
        response.data.forEach((item) => {
          Array.isArray(item.chipset) &&
            item.chipset.forEach((chipset) => {
              chipset && chipsetArr.push(chipset);
            });
        });

        response.data?.length > 0 && setSubscribed([...new Set(chipsetArr)]);
      } else {
        errorHandler(response.error, GET_MESSAGES.failure);
      }
    });
  }, []);

  return (
    <>
      <BackdropOverlayModal>
        <div className={form.container}>
          <div className={classes.header}>
            <Tabs value={value} onChange={handleChange} aria-label="Edit tabs">
              <Tab sx={tabStyles} label="Edit feature" />
              <Tab sx={tabStyles} label="Edit Logo" />
              {selectedFeature?.isFeatureDownloadable && (
                <Tab sx={tabStyles} label="Edit catalog" />
              )}
            </Tabs>
            <CloseIcon onClick={onClose} />
          </div>
          <TabPanel value={value} index={0}>
            <BasicForm details={details} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UploadFeatureIcon
              featureId={selectedFeature?.featureId}
              onClose={onClose}
              type="Edit"
              icon={
                selectedFeature?.featureIcon ? selectedFeature?.featureIcon : []
              }
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ConfigureCatalogue
              onClose={onClose}
              type="Edit"
              featureId={selectedFeature?.featureId}
              featureName={selectedFeature?.featureName}
              listArray={listArray}
              filesArray={filesArray}
            />
          </TabPanel>
        </div>
      </BackdropOverlayModal>

      {/* {openModal.editCatalog && (
        <ConfigureCatalogue
          onClose={closeCatalog}
          type="Edit"
          featureId={selectedFeature?.featureId}
          featureName={selectedFeature?.featureName}
        />
      )} */}
    </>
  );
};

export default EditFeature;
