import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";
export const CustomSelectField = styled(Select)(() => ({
  width: "6em",
  fontFamily: "QualcommNext-Regular",
  color: " #4D6994",
  "& .MuiSelect-select": {
    padding: "4px 10px",
  },
  "&.MuiInputBase-root": {
    background: " #FFFFFF",
    borderRadius: "5px",
  },
  "&.MuiOutlinedInput-input": {
    color: " #4D6994",
    borderRadius: "5px",
    border: "1px solid #4D6994",

    fontSize: "clamp(0.8125rem, 0.7213rem + 0.1351vw, 0.9375rem)",
  },

  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    border: "1px solid #4D6994",
  },
}));
