import axios from "axios";
import { deleteOrganizationApi } from "./apiEndpoints";

export const deleteOrganization = async (options) => {
  const { organizationId } = options;
  let data = null;
  await axios
    .delete(`${deleteOrganizationApi}/${organizationId}`)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
