import classes from "./PersonalInfoItem.module.css";

const PersonalInfoItem = (props) => {
  const { field, value } = props;
  return (
    <div className={classes.container}>
      <span>{field}</span>
      <span>:</span>
      <span>{value}</span>
    </div>
  );
};

export default PersonalInfoItem;
