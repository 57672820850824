import * as yup from "yup";
const errorMessages = {
  name: {
    required: "Organization name is required",
    matches:
      "Only alphanumeric characters, hyphen/underscore/space are allowed. Organization name must start and end with alphanumeric character.",
    spaceValidation: "Organization name should not start and end with a space.",
  },
  website: {
    required: "Webiste is required",
    matches: "Enter valid URL",
  },
  email: {
    required: "Email is required",
    matches: "Enter valid email",
  },
  address: {
    required: "Address is required",
    spaceValidation: "Only space is not allowed",
    matches:
      "Only alphanumeric characters, hyphen/underscore/space are allowed.",
  },
  country: {
    required: "Country is required",
    matches: "Only alphabet characters are required.",
  },
  zipcode: {
    required: "Zipcode is required",
    integer: "Only whole numbers are allowed",
  },
  phoneNumber: {
    required: "Phone number is required",
    min: "Phone number must be atleast 10 digits",
    matches: "Phone number format is incorrect",
  },
};

export const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^(?!\s*$).+/g, errorMessages.name.required)
      .required(errorMessages.name.required),
    website: yup
      .string()
      .matches(
        // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
        errorMessages.website.matches
      )
      .required(errorMessages.website.required),
    address: yup
      .string()
      .matches(/^$|^\S+.*/g, errorMessages.address.spaceValidation)
      // .matches(/^([a-zA-Z0-9 _-]+)$/g, errorMessages.address.matches)
      .required(errorMessages.address.required),
    zipcode: yup
      .number()
      .positive()
      .integer()
      .required(errorMessages.zipcode.required)
      .typeError(errorMessages.zipcode.required),
    country: yup
      .string()
      .required(errorMessages.country.required)
      .matches(/^[a-zA-Z][ a-zA-Z]+$/g, errorMessages.country.matches),
    phoneNumber: yup
      .string()
      .matches(/^[+]*[0-9]+$/g, errorMessages.phoneNumber.matches)
      .test("len", "Must be in range of 10 to 13 digits", (val) => {
        return val.match(/[^+]/g).length <= 13 &&
          val.match(/[^+]/g).length >= 10
          ? true
          : false;
      })
      .required(errorMessages.phoneNumber.required)
      .typeError(errorMessages.phoneNumber.required),
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorMessages.email.matches
      )
      .required(errorMessages.email.required),
  })
  .required();
