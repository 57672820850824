import { useLocation } from "react-router-dom";

import classes from "./HeaderInfo.module.css";

const HeaderInfo = (props) => {
  const { headerInfo, handleHeaderClick } = props;
  const location = useLocation();

  const onClick = () => {
    handleHeaderClick(headerInfo.route);
  };

  return location.pathname !== headerInfo.route ? (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.rectangle}>{headerInfo.name}</div>
      <div className={classes.filler}></div>
      <div className={classes["right-angled-triangle"]}></div>
      <div className={classes.parallelogram}></div>
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes["active-rectangle"]}>{headerInfo.name}</div>
      <div className={classes["active-filler"]}></div>
      <div className={classes["active-right-angled-triangle"]}></div>
      <div className={classes["active-parallelogram"]}></div>
    </div>
  );
};

export default HeaderInfo;
