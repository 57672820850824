import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import DataTableHeaders from "../header/DataTableHeaders";
import { susbcribersInfoHeaders } from "../helpers/header-helpers";
import SubscriberInfoItem from "./SubscriberInfoItem";
import orgIcon from "../../assets/images/features/org.svg";
import tenantIcon from "../../assets/images/features/tenant.svg";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import FeatureEnabledList from "./FeatureEnabledList";
import { subscriberActions } from "../../store/slices/slices-index";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { ROLES } from "../../constants/role-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";

import classes from "./SubscriberInfo.module.css";

const SubscriberInfo = () => {
  const dispatch = useDispatch();

  const selectedSubscriber = useSelector(
    (state) => state.subscriberInfo.selectedSubscriber
  );
  const params = useParams();
  const [breadCrumbArray] = useBreadcrumb("subscriberInfo");
  const headers = susbcribersInfoHeaders({
    routes: {
      subscriberInfo:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.subscribers +
        "/" +
        params.customerId,
    },
  });

  const heading = `${selectedSubscriber?.organizationName} - ${selectedSubscriber?.tenantName}`;

  return (
    <div className={classes.container}>
      <DataTablePanelTest backButton={true} disableSearch={true} />
      {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{selectedSubscriber?.tenantName || "N/A"}</p>
      </StyledBreadcrumbs>
      {/* ) : (
        <p>{heading}</p>
      )} */}
      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        <div className={classes.info}>
          <div className={classes.info_items}>
            <SubscriberInfoItem
              text={selectedSubscriber?.organizationName}
              header="Organization"
              icon={orgIcon}
            />
            <SubscriberInfoItem
              text={selectedSubscriber?.tenantName}
              header="Tenant Name"
              icon={tenantIcon}
            />

            <div className={classes.feature_Table}>
              <FeatureEnabledList
                customerId={selectedSubscriber?.customerId}
                tenantId={params?.tenantId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriberInfo;
