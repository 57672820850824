import moment from "moment";
import { useState } from "react";

export default function useTimeStatus() {
  const [dateRange, setDateRange] = useState({
    from: moment().valueOf(),
    to: moment().subtract(24, "hours").valueOf(),
  });

  const handleTime = (time) => {
    if (time.includes("s")) {
      setDateRange({
        from: moment().valueOf(),
        to: moment().subtract(time.split("s")[0], "seconds").valueOf(),
      });
    } else if (time.includes("m")) {
      setDateRange({
        from: moment().valueOf(),
        to: moment().subtract(time.split("m")[0], "minutes").valueOf(),
      });
    } else if (time.includes("h")) {
      setDateRange({
        from: moment().valueOf(),

        to: moment().subtract(time.split("h")[0], "hours").valueOf(),
      });
    } else {
      setDateRange({
        from: moment().valueOf(),

        to: moment().subtract(time.split("d")[0], "days").valueOf(),
      });
    }
  };

  return [dateRange, handleTime];
}
