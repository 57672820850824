import { Fragment, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CreateNewUser from "../../user/CreateNewUser/CreateNewUser";
import EditUser from "../../user/EditUser/EditUser";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { isvAvailableUsersHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import {
  availableUserList,
  addUsersToTenant,
  getUserInfoByUserName,
} from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { BUTTONS } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import {
  ASSOCIATE_TO_USERS_MESSAGES,
  GET_MESSAGES,
  USERS_ASSOCIATION_MESSAGES,
} from "../../../constants/message-constants";
import editIcon from "../../../assets/images/common/edit.svg";
import AssociateRoleToUser from "../AssociateRoleToUser/AssociateRoleToUser";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import KeycloakConfig from "../../../config/keycloak-config";
import { userActions } from "../../../store/slices/user-slice";
import { fetchOrganizationInfo } from "../../../store/actions/organizations-actions";
import EditIconButton from "../../ui/Buttons/EditIconButton";

import classes from "../../ui/DataTable.module.css";

let selectedRow = null;
const AddAvailableUserList = () => {
  let isClicked = true;
  const [pageNo, setPageNo] = useState(0);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [selectedUsers, setSelectedUsers] = useState({ ids: [], rows: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditUserForm, setOpenEditUserForm] = useState(false);
  const [openCreateNewUserForm, setOpenCreateNewUserForm] = useState(false);
  const [associateRoleToUser, setAssociateRoleToUser] = useState(false);
  const [forceUpdate, setForceUpdate] = useState({});

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const appType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? TENANT_TYPE.isv
    : TENANT_TYPE.customer;

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditUserForm(!openEditUserForm);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const closeEditUserForm = () => {
    selectedRow = null;
    setOpenEditUserForm(false);
  };

  const handleOpenPanel = () => {
    setOpenCreateNewUserForm(true);
  };

  const closeCreateNewUserForm = () => {
    setOpenCreateNewUserForm(false);
  };

  const openAssociateRoleToUser = () => {
    setAssociateRoleToUser(true);
  };

  const closeAssociateRoleToUser = () => {
    setAssociateRoleToUser(false);
  };

  const handleAssociateRoleToUsers = (associatedRoles) => {
    if (isClicked) {
      isClicked = false;
      if (associatedRoles.length > 0) {
        const getSelectedUsers = () => {
          return associatedRoles?.map((user) => {
            return {
              userId: user.id,
              userRoleInfoJson: {
                [params.tenantId || params.customerId]: {
                  roles: [user.role],
                  attributes: [],
                  appType: appType,
                  tenantName: selectedTenant?.tenantName,
                },
              },
            };
          });
        };
        addUsersToTenant({
          organizationId: params.organizationId,
          tenantId: params.tenantId || params.customerId,
          userIdUserRoleMap: getSelectedUsers(),
        })
          .then((response) => {
            if (response.status) {
              let totalTrueStatus = [];
              let totalFalseStatus = [];
              response.data.map((i) =>
                i.status
                  ? totalTrueStatus.push(i.userId)
                  : totalFalseStatus.push(i.userId)
              );
              totalTrueStatus.length > 0 &&
                toast.success(
                  `${totalTrueStatus.length} ${USERS_ASSOCIATION_MESSAGES.success} ${selectedTenant.tenantName}`
                );
              totalFalseStatus.length > 0 &&
                toast.error(
                  `${totalFalseStatus.length} ${USERS_ASSOCIATION_MESSAGES.failure} ${selectedTenant.tenantName}`
                );
              const options = {
                userName: KeycloakConfig.getUserId(),
              };

              getUserInfoByUserName(options)
                .then((response) => {
                  if (response.status) {
                    dispatch(userActions.setUserInfoState(response.data));

                    const options = {
                      organizationId: params.organizationId,
                    };
                    dispatch(fetchOrganizationInfo(options));
                  } else {
                    errorHandler(response.error, GET_MESSAGES.failure);
                  }
                })
                .catch((error) => {
                  console.log("ERROR: ", error);
                  errorHandler(error, GET_MESSAGES.failure);
                });
            } else {
              console.log(response.error);
              //error handling function
              errorHandler(response.error, ASSOCIATE_TO_USERS_MESSAGES);
            }
            setForceUpdate({});
            closeAssociateRoleToUser();
            isClicked = true;
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, ASSOCIATE_TO_USERS_MESSAGES);
            setForceUpdate({});
            isClicked = true;
          });
      }
    }
  };

  const handleAddUsers = () => {
    openAssociateRoleToUser();
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "User ID",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "tenantName",
      headerName: "Tenant Names",
      flex: 1,
      minWidth: 300,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 50,
      renderCell: (params) => (
        <div>
          <EditIconButton size="small" onClick={() => handleEdit(params)} />
        </div>
      ),
    });

  const headers = isvAvailableUsersHeaders({
    routes: {
      isvAvailableUsers:
        params.tenantId != null
          ? CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.isvTenants +
            "/" +
            params.tenantId +
            PARTIAL_ROUTES.availableUsers
          : params.customerId != null
          ? CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.customerTenants +
            "/" +
            params.customerId +
            PARTIAL_ROUTES.availableUsers
          : null,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      organizationId: params.organizationId,
      tenantId: params.tenantId || params.customerId,
    };

    availableUserList(options)
      .then((response) => {
        if (
          response.status &&
          response.data.userDetails != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.userDetails.map((item) => {
            return {
              id: item.userId,
              userId: item.userId,
              name: item.fullName,
              firstName: item.firstName,
              lastName: item.lastName,
              emailId: item.emailId,
              phoneNo: item.phoneNo,
              tenantName: item.tenants?.toString(),
              keycloakId: item.keycloakUserId,
            };
          });
          setUsersList({ count: response.data.count, list: formattedList });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          //error handling function
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [pageNo, openEditUserForm, openCreateNewUserForm, forceUpdate]);

  return (
    <Fragment>
      {openEditUserForm && (
        <EditUser onClose={closeEditUserForm} userDetails={selectedRow} />
      )}
      {openCreateNewUserForm && (
        <CreateNewUser
          onClose={closeCreateNewUserForm}
          organizationId={params.organizationId}
          setSubmittedState={setOpenCreateNewUserForm}
        />
      )}
      {associateRoleToUser && (
        <AssociateRoleToUser
          onClose={closeAssociateRoleToUser}
          users={selectedUsers.rows}
          addUsers={handleAssociateRoleToUsers}
          appType={appType}
        />
      )}
      <div className={classes.container}>
        <DataTablePanel
          name={BUTTONS.createUser}
          list={usersList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={["id", "name", "phoneNo", "emailId", "tenantName"]}
          backButton={true}
          clickHandler={handleOpenPanel}
          selectedUsers={selectedUsers.ids}
          addUsersClickHandler={handleAddUsers}
        />
        <p>Add Users - {selectedTenant?.tenantName}</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            columns={columns}
            rows={filteredList || []}
            checkboxSelection={true}
            disableColumnFilter
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = filteredList.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedUsers({ ids: ids, rows: selectedRows });
            }}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  usersList.list.length === filteredList.length
                    ? usersList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AddAvailableUserList;
