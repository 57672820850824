import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import SubmitButton from "../../ui/Buttons/SubmitButton";
import { NullCheck } from "../../helpers/utils";
import CustomTextField from "../../ui/CustomTextField";

import styles from "./AddChipset.module.css";

function AddChipset({ chipsetArray, addChipsetFunc }) {
  const [isEmpty, setIsEmpty] = useState(false);
  const [chipsetDetails, setChipsetDetails] = useState({
    chipsetId: "",
    numberOfDevices: "",
    expirationDate: null,
  });
  const submitHandler = () => {
    if (
      chipsetDetails?.chipsetId &&
      chipsetDetails?.expirationDate &&
      NullCheck(chipsetDetails?.numberOfDevices)
    ) {
      if (
        chipsetDetails.expirationDate.isValid() &&
        chipsetDetails.expirationDate
          .endOf("day")
          .isSameOrAfter(moment(new Date()).endOf("day"))
      ) {
        addChipsetFunc({ ...chipsetDetails, id: uuidv4(), isDelete: true });
        setChipsetDetails({
          chipsetId: "",
          numberOfDevices: "",
          expirationDate: null,
        });
        setIsEmpty(false);
      }
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <div className={styles.field_div}>
      <CustomTextField
        onChange={(e) => {
          setChipsetDetails({ ...chipsetDetails, chipsetId: e.target.value });
        }}
        size="small"
        focused
        id="chipsets"
        fullWidth
        label="Chipsets"
        value={chipsetDetails?.chipsetId}
        select
        placeholder="Select chipset"
        error={isEmpty && !chipsetDetails?.chipsetId && true}
      >
        {chipsetArray?.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              fontFamily: "QualcommNext-Regular",
            }}
          >
            {option}
          </MenuItem>
        ))}
      </CustomTextField>

      <CustomTextField
        onChange={(e) =>
          setChipsetDetails({
            ...chipsetDetails,
            numberOfDevices: e.target.value,
          })
        }
        onKeyPress={(evt) => {
          if (["e", "E", "+", "-", "."].includes(evt.key)) {
            evt.preventDefault();
          }
        }}
        size="small"
        focused
        fullWidth
        type="number"
        label="Max No. of Devices"
        value={chipsetDetails?.numberOfDevices}
        placeholder="Enter Max Devices"
        error={!NullCheck(chipsetDetails?.numberOfDevices)}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Expiration Date"
          value={chipsetDetails?.expirationDate}
          onChange={(event) =>
            setChipsetDetails({
              ...chipsetDetails,
              expirationDate: event,
            })
          }
          disablePast
          inputFormat="DD-MM-YYYY"
          renderInput={(params) => {
            return (
              <CustomTextField
                size="small"
                focused
                fullWidth
                {...params}
                error={
                  (params.error ||
                    (isEmpty && !chipsetDetails?.expirationDate)) &&
                  true
                }
              />
            );
          }}
        />
      </LocalizationProvider>
      {/* <CustomTextField
        onChange={(event) =>
          setChipsetDetails({
            ...chipsetDetails,
            expirationDate: event?.target?.value,
          })
        }
        size="small"
        focused
        fullWidth
        type="date"
        label="Expiration Date"
        value={chipsetDetails?.expirationDate}
        InputProps={{
          inputProps: {
            min: moment(new Date()).format("YYYY-MM-DD"),
          },
        }}
        error={isEmpty && !chipsetDetails?.expirationDate && true}
        placeholder="Select expiration date"
      /> */}

      <div>
        <SubmitButton text={"Add"} type={"button"} onClick={submitHandler} />
      </div>
    </div>
  );
}

export default AddChipset;
