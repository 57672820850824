import { Fragment, useState } from "react";
import { toast } from "react-toastify";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import { schema } from "./CreateNewTenantSchema";
import { createNewTenant } from "../../../api/api-index";
import { CREATE_TENANT_MESSAGES } from "../../../constants/message-constants";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import BasicForm from "../../ui/Form/BasicForm";
import CommonHeader from "../../ui/CommonHeader";
import errorHandler from "../../helpers/error-helper";
import ClientIDAndSecret from "./ClientIDAndSecret";

import classes from "./CreateNewTenant.module.css";

const CreateNewTenant = (props) => {
  const { onClose, tenantDetails, getUpdatedDataFn } = props;
  let clicked = true;
  const [openClientIdAndSecret, setOpenClientIdAndSecret] = useState({
    open: false,
    clientId: "",
    client: "",
    secret: "",
  });
  const heading = `Create ${
    tenantDetails?.tenantType === "CUSTOMER"
      ? "Customer"
      : tenantDetails?.tenantType
  } Tenant`;

  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      const options = {
        ...data,
        tenantName: data.tenantName.trim(),
        ...tenantDetails,
      };

      if (
        data?.manageDevice != null &&
        tenantDetails?.tenantType === TENANT_TYPE.customer
      ) {
        options.isQualcommManagingDevice = data?.manageDevice;
        delete options.manageDevice;
      }

      createNewTenant(options)
        .then((response) => {
          if (response.status) {
            toast.success(CREATE_TENANT_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            if (
              options.isQualcommManagingDevice &&
              response.data?.clientIdSecret != null
            ) {
              setOpenClientIdAndSecret({
                clientId: response.data.clientIdSecret.clientId,
                secret: response.data.clientIdSecret.clientSecret,
                client: response.data.clientIdSecret.client,
                open: true,
              });
            } else {
              onClose();
            }
          } else {
            console.error(response.error);
            errorHandler(response.error, CREATE_TENANT_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, CREATE_TENANT_MESSAGES.failure);
          clicked = true;
        });
    }
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs:
      tenantDetails?.tenantType === "CUSTOMER"
        ? [
            {
              name: "tenantName",
              label: "Tenant Name",
              placeholder: "Enter tenant name",
            },
            // {
            //   radio: true,
            //   name: "registry",
            //   label: "Testing In",
            //   group: [
            //     { label: "ISV Registry", value: "isv" },
            //     { label: "Global Registry", value: "global" },
            //   ],
            // },
            {
              name: "manageDevice",
              label: "Do you want us to manage your devices ?",
              checkbox: true,
            },
          ]
        : [
            {
              name: "tenantName",
              label: "Tenant Name",
              placeholder: "Enter tenant name",
            },
          ],
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        {openClientIdAndSecret.open ? (
          <ClientIDAndSecret
            onClose={onClose}
            details={openClientIdAndSecret}
          />
        ) : (
          <Fragment>
            <CommonHeader heading={heading} onClose={onClose} />
            <BasicForm details={details} />
          </Fragment>
        )}
      </div>
    </BackdropOverlayModal>
  );
};

export default CreateNewTenant;
