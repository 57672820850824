import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./ViewImage.module.css";

const ViewImage = ({ onClose, selectedImage }) => {
  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader onClose={onClose} />
        <img
          className={classes.selectedImage}
          src={"data:image/*;base64," + selectedImage.item}
        />
      </div>
    </BackdropOverlayModal>
  );
};
export default ViewImage;
