import { Button } from "@mui/material";

const LoadMoreButton = ({ onClick }) => {
  return (
    <Button
      aria-label="load more"
      sx={{
        textTransform: "capitalize",
        fontFamily: "QualcommNext-Regular",
        color: "#214572",
      }}
      onClick={onClick != null ? onClick : null}
      size="large"
      disableElevation
    >
      Load More
    </Button>
  );
};

export default LoadMoreButton;
