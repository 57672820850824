import * as yup from "yup";
const errorMessages = {
  chipset: {
    required: "Chipset name is required",
  },
};
export const schema = yup
  .object({
    chipset: yup.string().required(errorMessages.chipset.required),
  })
  .required();
