import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { tenantFeaturesHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { getallSubscribersByISVTenant } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import { PARTIAL_ROUTES, CSS_ROUTES } from "../../constants/route-constants";
import {
  columnsActions,
  subscriberActions,
} from "../../store/slices/slices-index";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { ROLES } from "../../constants/role-constants";
import useSearchKeys from "../../hooks/useSearchKeys";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";

import classes from "../ui/DataTable.module.css";

const SubscribersList = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("features");
  const initialState = useSelector((state) => state.columnsInfo.isvSubscriber);
  const [searchStatefn] = useSearchKeys(
    ["organizationName", "tenantName", "features"],
    initialState
  );

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [featuresList, setFeaturesList] = useState({ count: 0, list: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const handleRowClick = (row) => {
    const data =
      row?.row != null
        ? {
            customerId: row.row.customerId,
            organizationName: row.row.organizationName,
            tenantName: row.row.tenantName,
          }
        : null;
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.subscribers +
        "/" +
        `${row.row.customerId}`
    );
    dispatch(subscriberActions.setSelectedSubscriber(data));
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const columns = [
    {
      field: "organizationName",
      headerName: "Organization",
      flex: 1,
      minWidth: 300,
      hideable: false,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
    {
      field: "features",

      headerName: "Features",
      flex: 6,
      minWidth: 350,
      renderCell: (params) => {
        return <div>{params?.row?.features?.toString()}</div>;
      },
    },
  ];

  const headers = tenantFeaturesHeaders({
    routes: {
      myFeatures:
        hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
        CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.isvTenants +
          "/" +
          params.tenantId +
          PARTIAL_ROUTES.myFeatures,
      associatedUsers:
        hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
        CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.isvTenants +
          "/" +
          params.tenantId +
          PARTIAL_ROUTES.associatedUsers,
      subscribers:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.subscribers,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      isvId: params.tenantId,
    };

    getallSubscribersByISVTenant(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.subscriber != null &&
          response.data.totalCount != null
        ) {
          const formattedList = response.data.subscriber.map((item) => {
            return {
              id: uuidv4(),
              customerId: item.customerId,
              organizationName: item.organizationName,
              tenantName: item.customerName,
              features: item.featureList,
            };
          });
          setFeaturesList({
            count: response.data.totalCount,
            list: formattedList,
          });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo]);

  return (
    <Fragment>
      <div className={classes.container}>
        <DataTablePanelTest
          list={featuresList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          backButton={hasPermittedRolesOrAttributes([ROLES.cssAdmin]) && true}
        />
        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}

        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setisvSubscriberColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  featuresList.list.length === filteredList.length
                    ? featuresList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.subscribers },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SubscribersList;
