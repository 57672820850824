import { toast } from "react-toastify";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import BasicForm from "../../ui/Form/BasicForm";
import { schema } from "./CreateNewUserSchema";
import { createNewUser } from "../../../api/api-index";
import { CREATE_USER_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { MAX_VALUE } from "../../../constants/max-value-constants";

import classes from "../../ui/Form/FormContainer.module.css";

const CreateNewUser = (props) => {
  const { onClose, organizationId, getUpdatedDataFn } = props;
  let clicked = true;
  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      const formattedList = {
        ...data,
        firstName:
          data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
        lastName:
          data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
      };

      createNewUser({ ...formattedList, organizationId })
        .then((response) => {
          if (response.status && response.data != null) {
            toast.success(CREATE_USER_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            onClose();
          } else {
            clicked = true;
            console.log(response.error);
            errorHandler(response.error, CREATE_USER_MESSAGES.failure);
          }
        })
        .catch((error) => {
          clicked = true;
          errorHandler(error, CREATE_USER_MESSAGES.failure);
          // console.error("ERROR: ", error);

          //function for error handling
        });
    }
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs: [
      {
        name: "firstName",
        label: "First Name",
        placeholder: "Enter first name",
        maxLength: MAX_VALUE.firstName,
      },
      {
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter last name",
        maxLength: MAX_VALUE.lastName,
      },
      {
        name: "emailId",
        label: "Email",
        placeholder: "Enter email",
      },
      {
        name: "phoneNo",
        label: "Phone Number",
        placeholder: "Enter phone number",
        type: "tel",
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        type: "password",
      },
      {
        name: "confirmPassword",
        label: "Confirm Password",
        placeholder: "Confirm password",
        type: "password",
      },
    ],
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Create User" onClose={onClose} />
        <BasicForm details={details} />
      </div>
    </BackdropOverlayModal>
  );
};

export default CreateNewUser;
