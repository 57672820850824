import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";

const ClipboardButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Copy",
    disabled = false,
    type = "",
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label={type}
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ClipboardButton;
