import IconButton from "@mui/material/IconButton";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import { Tooltip } from "@mui/material";

const MarkUnreadButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Mark as unread",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <MarkAsUnreadIcon sx={{ color: disabled ? "grey" : color }} />
      </IconButton>
    </Tooltip>
  );
};

export default MarkUnreadButton;
