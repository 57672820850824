import axios from "axios";
import { deleteNotificationsApi } from "./apiEndpoints";

export const deleteNotifications = async (payload) => {
  let data = null;
  console.log(payload);
  await axios
    .delete(deleteNotificationsApi, { data: { ...payload } })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
