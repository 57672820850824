import React from "react";
import { toast } from "react-toastify";

import { updateFeatureVersion } from "../../../api/api-index";
import KeycloakConfig from "../../../config/keycloak-config";
import {
  ADD_GLOBAL_VERSION,
  DENY_VERIFY_VERSION,
} from "../../../constants/message-constants";
import { REPO_STATUS } from "../../../constants/repo-constants";
import errorHandler from "../../helpers/error-helper";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./ConfirmDialogue.module.css";

function ConfirmDialogue(props) {
  let clicked = true;
  const { onClose, versionDetails, setParamId = null, updateListFn } = props;

  const requestFormatter = (data) => {
    let request = null;
    if (data) {
      request = {
        featureName: data.featureName,
        featureVersionId: data.featureVersionId,
        featureVersionStatus: data.nextStatus,
        createdBy: KeycloakConfig.getUserId(),
        oldFeatureVersionStatus:
          data.nextStatus === "ADDED_TO_GLOBAL_REGISTRY"
            ? "VALIDATED"
            : "PUBLISHED",
        comment: data?.comment != null ? data?.comment : "",
      };
    }
    return request;
  };
  const handleSubmit = () => {
    if (clicked) {
      clicked = false;
      updateFeatureVersion(requestFormatter(versionDetails))
        .then((response) => {
          if (response.status) {
            toast.success(
              versionDetails.nextStatus === REPO_STATUS.addToGlobal
                ? ADD_GLOBAL_VERSION.success
                : DENY_VERIFY_VERSION.success
            );
            onClose();
            setParamId && setParamId(null);
          } else {
            console.error(response.error);
            errorHandler(
              response.error,
              versionDetails.nextStatus === REPO_STATUS.addToGlobal
                ? ADD_GLOBAL_VERSION.failure
                : DENY_VERIFY_VERSION.failure
            );
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(
            error,
            versionDetails.nextStatus === REPO_STATUS.addToGlobal
              ? ADD_GLOBAL_VERSION.failure
              : DENY_VERIFY_VERSION.failure
          );
          clicked = true;
        })
        .finally(() => {
          updateListFn && updateListFn();
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Confirm" onClose={onClose} />
        <div className={classes.text}>
          <p>Do you want to continue ?</p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={handleSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
}

export default ConfirmDialogue;
