import { useState } from "react";
import { useSelector } from "react-redux";

import UserSettingsHeaders from "./UserSettingsHeaders/UserSettingsHeaders";
import Profile from "../Profile/Profile";
import ChangePassword from "../ChangePassword/ChangePassword";
import zyter from "../../../assets/images/menu/zyter.svg";
import TenantDetails from "../../tenants/TenantDetails";
import SDKPreference from "../../tenants/sdk/SDKPreference";

import classes from "./UserSettings.module.css";

const UserSettings = () => {
  const [active, setActive] = useState("profile");

  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const handleHeader = (event) => {
    console.log("EVENT TARGET: ", event.target.id);
    setActive(event.target.id);
  };

  const userDetails =
    active === "change-password"
      ? {
          userId: userInfo?.userId,
          firstName: userInfo?.firstName,
          lastName: userInfo?.lastName,
          phoneNo: userInfo?.phoneNo,
          keycloakId: userInfo?.keycloakUserId,
          noBackdrop: true,
        }
      : null;

  return (
    <div className={classes.container}>
      <div className={classes.dcontainer}>
        <UserSettingsHeaders
          active={active}
          handleHeader={handleHeader}
          selectedAppType={selectedAppType}
        />
        <div className={classes.content}>
          {active === "profile" && <Profile />}
          {active === "change-password" && (
            <div className={classes.password}>
              <ChangePassword userDetails={userDetails} />
            </div>
          )}
          {active === "tenantSettings" && (
            <TenantDetails selectedAppType={selectedAppType} />
          )}
          {active === "sdkSettings" && (
            <SDKPreference selectedAppType={selectedAppType} />
          )}
        </div>
      </div>
      <div className={classes.slant}>
        <div className={classes.logo}>
          <span>Powered by</span>
          <img src={zyter} alt="powered by zyter" />
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
