import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import secured from "../../../../assets/images/features/secured-status.svg";
import storage from "../../../../assets/images/features/storage-quota.svg";
import version from "../../../../assets/images/features/latest-version.svg";
import featureNameIcon from "../../../../assets/images/features/default-feature-name.svg";
import { CSS_ROUTES } from "../../../../constants/route-constants";
import DataTableHeaders from "../../../header/DataTableHeaders";
import { repositoryInfoHeaders } from "../../../helpers/header-helpers";
import orgIcon from "../../../../assets/images/features/org.svg";
import tenantIcon from "../../../../assets/images/features/tenant.svg";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";
import LocalRepoInfoItem from "./LocalRepoInfoItem";
import StatusTable from "./StatusTable";
import { getAllRepoHistory, getFeatureIcon } from "../../../../api/api-index";
import { REPO_STATUS, REPO_TYPE } from "../../../../constants/repo-constants";
import CustomUpload from "../../uploadFile/CustomUpload";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import errorHandler from "../../../helpers/error-helper";
import ConfirmDialogue from "../../confirmDialogue/ConfirmDialogue";
import AlertBanner from "../../../ui/Alert/AlertBanner";
import { StyledLink } from "../../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../../hooks/useBreadcrumb";

import classes from "./LocalRepoInfo.module.css";

let selectedRow = null;
const LocalRepoInfo = () => {
  const versionArray = [];
  const params = useParams();
  const [breadCrumbArray] = useBreadcrumb("localRepoInfo");
  const [verifyVersion, setVerifyVersion] = useState(false);
  const [verifyGlobalorDeny, setverifyGlobalorDeny] = useState(false);
  const [repoHistoryList, setrepoHistoryList] = useState({});
  const accordionStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    height: "100%",
    width: "100%",
  };

  const headers = repositoryInfoHeaders({
    routes: {
      repoInfo: CSS_ROUTES.localRepositories + "/" + params.repoId,
    },
  });

  const handleOpenVerifyVersion = (data, id, version) => {
    selectedRow = {
      ...data.row,
      featureVersionId: id,
      featureName: params.repoId,
      version,
    };

    setVerifyVersion(true);
  };
  const handleOpenVerifyGlobalorDeny = (data, id, version, level) => {
    selectedRow = {
      ...data.row,
      featureVersionId: id,
      featureName: params.repoId,
      version,
      nextStatus:
        level === "global" ? REPO_STATUS.addToGlobal : REPO_STATUS.denied,
    };
    setverifyGlobalorDeny(true);
  };

  const handleCloseVerifyVersion = () => {
    setVerifyVersion(false);
  };
  const handleCloseVerifyGlobalorDeny = () => {
    setverifyGlobalorDeny(false);
  };
  useEffect(() => {
    const options = {
      qcommFeatureName: params.repoId,
      repositoryType: REPO_TYPE.local,
    };

    getAllRepoHistory(options, params.repoId)
      .then((response) => {
        if (response.status && response.data != null) {
          let item = response.data;

          const formattedList = {
            organization: item?.organizationName,
            tenant: item?.tenantName,
            featureTitle: item?.featureTitle,
            secured: item?.securedFeature,
            latestVersion: item?.latestVersion,
            size: `${item.size} ${item.unit}`,
            featureIcon: item?.featureIcon,
            featureRepositoryDetailsMap: item.featureRepositoryDetailsMap,
            isvStatus: item?.isvStatus,
          };
          setrepoHistoryList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [verifyVersion, verifyGlobalorDeny]);

  for (const property in repoHistoryList.featureRepositoryDetailsMap) {
    const version =
      repoHistoryList.featureRepositoryDetailsMap[property][0].version;
    versionArray.push(
      <Accordion key={property}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${property}-content`}
          id={`${property}-header`}
        >
          <p className={classes.headerVersion}>Version {version}</p>
        </AccordionSummary>
        <AccordionDetails sx={accordionStyles}>
          <div style={{ flexGrow: 1 }}>
            <StatusTable
              list={repoHistoryList.featureRepositoryDetailsMap[property]}
              handleClick={handleOpenVerifyVersion}
              handleGlobal={handleOpenVerifyGlobalorDeny}
              version={version}
              currentISVStatus={repoHistoryList.isvStatus}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={classes.container}>
      {verifyVersion && (
        <CustomUpload
          onClose={handleCloseVerifyVersion}
          versionDetails={{
            ...selectedRow,
            nextStatus: REPO_STATUS.validated,
            oldStatus: "PUBLISHED",
          }}
        />
      )}
      {verifyGlobalorDeny && (
        <ConfirmDialogue
          onClose={handleCloseVerifyGlobalorDeny}
          versionDetails={selectedRow}
        />
      )}
      <DataTablePanelTest backButton={true} disableSearch={true} />

      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{repoHistoryList?.featureTitle || "..."}</p>
      </StyledBreadcrumbs>

      <div className={classes.data}>
        <DataTableHeaders headers={headers} />

        <div className={classes.info}>
          {repoHistoryList.isvStatus && (
            <div className={classes.alertBanner}>
              <AlertBanner
                text="ISV has marked this feature for deletion"
                severity="error"
              />
            </div>
          )}
          <div className={classes.info_items}>
            <LocalRepoInfoItem
              text={repoHistoryList.organization}
              header="Organization"
              icon={orgIcon}
            />
            <div>
              <LocalRepoInfoItem
                text={repoHistoryList.tenant}
                header="Tenant Name"
                icon={tenantIcon}
              />
            </div>
            <LocalRepoInfoItem
              text={repoHistoryList.featureTitle}
              header="Feature Title"
              icon={
                repoHistoryList?.featureIcon != null
                  ? repoHistoryList.featureIcon
                  : featureNameIcon
              }
              convertFromBase64={
                repoHistoryList?.featureIcon != null ? true : null
              }
            />
            <LocalRepoInfoItem
              text={repoHistoryList.secured ? "Yes" : "No"}
              header="Secured"
              icon={secured}
            />
            <LocalRepoInfoItem
              text={repoHistoryList.latestVersion}
              header="Latest Version"
              icon={version}
            />
            <LocalRepoInfoItem
              text={repoHistoryList.size}
              header="Size"
              icon={storage}
            />
            <div className={classes.feature_Table}>{versionArray}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocalRepoInfo;
