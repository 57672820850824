import { useSelector } from "react-redux";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../constants/route-constants";
import { useParams, useLocation } from "react-router-dom";
import { ROLES } from "../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../components/helpers/user-roles-and-attributes";

export default function useBreadcrumb(type) {
  const params = useParams();
  const location = useLocation();
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo?.selectedTenant
  );
  const selectedFeature = useSelector(
    (state) => state.featuresInfo?.selectedFeature
  );
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo?.selectedOrganization
  );

  const OrgToOrgIDFlow = hasPermittedRolesOrAttributes([ROLES.cssAdmin])
    ? [
        { name: "Organizations", link: CSS_ROUTES.organizations },
        {
          name: selectedOrganization.name?.trimEllip(30),
          link: CSS_ROUTES.organizations + "/" + params.organizationId,
        },
      ]
    : [];
  const ISVTenantsRoute = hasPermittedRolesOrAttributes([ROLES.cssAdmin])
    ? [
        {
          name: "ISV Tenants",
          link:
            CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.isvTenants,
        },
      ]
    : [];

  const customerTenantsRoute = hasPermittedRolesOrAttributes([ROLES.cssAdmin])
    ? [
        {
          name: "Customer Tenants",
          link:
            CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.customerTenants,
        },
      ]
    : [];

  const BREADCRUMB_OPTIONS = {
    orgInfo: [{ name: "Organizations", link: CSS_ROUTES.organizations }],
    dashboardInfo: [{ name: "Dashboard", link: CSS_ROUTES.dashboard }],
    tenantDeviceLicenseInfo: [
      { name: "Dashboard", link: CSS_ROUTES.dashboard },
      { name: "Device & Licenses", link: CSS_ROUTES.tenantDeviceLicenses },
    ],
    subscriptionInfo: [
      { name: "Subscription Policy", link: CSS_ROUTES.subscriptionPolicy },
    ],
    localRepoInfo: [
      { name: "Local Repository", link: CSS_ROUTES.localRepositories },
    ],
    globalRepoInfo: [
      { name: "Global Repository", link: CSS_ROUTES.globalRepositories },
    ],
    features: [...OrgToOrgIDFlow, ...ISVTenantsRoute],
    featuresInfo: [
      ...OrgToOrgIDFlow,
      ...ISVTenantsRoute,
      {
        name: `${selectedTenant?.tenantName?.trimEllip(30)} - Features`,
        link:
          CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.isvTenants +
          "/" +
          params.tenantId +
          PARTIAL_ROUTES.myFeatures,
      },
    ],
    subscriberInfo: location.search
      ? [
          ...OrgToOrgIDFlow,
          ...ISVTenantsRoute,
          {
            name: `${selectedTenant?.tenantName?.trimEllip(30)} - Features`,
            link:
              CSS_ROUTES.organizations +
              "/" +
              params.organizationId +
              PARTIAL_ROUTES.isvTenants +
              "/" +
              params.tenantId +
              PARTIAL_ROUTES.myFeatures,
          },
          {
            name: `${selectedFeature?.featureTitle?.trimEllip(
              30
            )} - Subscribers`,
            link:
              CSS_ROUTES.organizations +
              "/" +
              params.organizationId +
              PARTIAL_ROUTES.isvTenants +
              "/" +
              params.tenantId +
              PARTIAL_ROUTES.myFeatures +
              "/" +
              params.featureId +
              PARTIAL_ROUTES.subscribers,
          },
        ]
      : [
          ...OrgToOrgIDFlow,
          ...ISVTenantsRoute,
          {
            name: `${selectedTenant?.tenantName?.trimEllip(30)} - Subscribers`,
            link:
              CSS_ROUTES.organizations +
              "/" +
              params.organizationId +
              PARTIAL_ROUTES.isvTenants +
              "/" +
              params.tenantId +
              PARTIAL_ROUTES.subscribers,
          },
        ],
    devices: [...OrgToOrgIDFlow, ...customerTenantsRoute],
    deviceInfo:
      JSON.parse(localStorage.getItem("selectedDeviceInfoView"))?.view ===
      "feature"
        ? [
            ...OrgToOrgIDFlow,
            ...customerTenantsRoute,
            {
              name: `${selectedTenant?.tenantName?.trimEllip(30)} - Features`,
              link:
                CSS_ROUTES.organizations +
                "/" +
                params.organizationId +
                PARTIAL_ROUTES.customerTenants +
                "/" +
                params.customerId +
                PARTIAL_ROUTES.features,
            },
            {
              name: `${selectedFeature?.featureName?.trimEllip(
                30
              )} - Feature Info`,
              link:
                CSS_ROUTES.organizations +
                "/" +
                params.organizationId +
                PARTIAL_ROUTES.customerTenants +
                "/" +
                params.customerId +
                PARTIAL_ROUTES.features +
                "/" +
                selectedFeature?.id,
            },
            {
              name: "Devices",
              link:
                CSS_ROUTES.organizations +
                "/" +
                params.organizationId +
                PARTIAL_ROUTES.customerTenants +
                "/" +
                params.customerId +
                PARTIAL_ROUTES.features +
                "/" +
                selectedFeature?.id +
                "/" +
                selectedFeature?.qualcommFeatureName,
            },
          ]
        : [
            ...OrgToOrgIDFlow,
            ...customerTenantsRoute,
            {
              name: `${selectedTenant?.tenantName?.trimEllip(30)} - Devices`,
              link:
                CSS_ROUTES.organizations +
                "/" +
                params.organizationId +
                PARTIAL_ROUTES.customerTenants +
                "/" +
                params.customerId +
                PARTIAL_ROUTES.devices,
            },
          ],
    customerFeatureInfo: [
      ...OrgToOrgIDFlow,
      ...customerTenantsRoute,
      {
        name: `${selectedTenant?.tenantName?.trimEllip(30)} - Features`,
        link:
          CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features,
      },
    ],
    mddInfo: [
      ...OrgToOrgIDFlow,
      ...customerTenantsRoute,
      {
        name: `${selectedTenant?.tenantName?.trimEllip(
          30
        )} - Metrics Definition List`,
        link:
          CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd,
      },
    ],
    featureDeviceList: [
      ...OrgToOrgIDFlow,
      ...customerTenantsRoute,
      {
        name: `${selectedTenant?.tenantName?.trimEllip(30)} - Features`,
        link:
          CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features,
      },
      {
        name: `${selectedFeature?.featureName?.trimEllip(30)} - Feature Info`,
        link:
          CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features +
          "/" +
          params.featureId,
      },
    ],
  };

  return [BREADCRUMB_OPTIONS[type]];
}
