// import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";

import KeycloakConfig from "../../config/keycloak-config";
import { getInitials } from "../helpers/utils";

import classes from "./UserInfo.module.css";

const UserInfo = ({ userName }) => {
  const abbrev = userName
    ? getInitials(userName)
    : KeycloakConfig.userAbbreviation();
  // const name = KeycloakConfig.getUsername();
  // const userInfoForATenancy = useSelector(
  //   (state) => state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
  // );
  return (
    <div className={classes.container}>
      {abbrev && (
        <Avatar
          sx={{
            bgcolor: "#4c73a4",
            width: "2.5rem",
            height: "2.5rem",
            fontFamily: "QualcommNext-Regular",
          }}
        >
          {abbrev}
        </Avatar>
      )}
      {/* {name && (
        <div className={classes.info}>
          <span className={classes.name}>{name}</span>
          <span className={classes.organization}>{userInfoForATenancy?.tenantName}</span>
        </div>
      )} */}
    </div>
  );
};

export default UserInfo;
