import { Checkbox } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import CustomTextField from "../../../ui/CustomTextField";

import classes from "./AssociateFeatureToDevicesRow.module.css";

const AssociateFeatureToDevicesRow = ({
  isBatchingEnabled,
  item,
  isCheck,
  handleSelect,
  dateHandler,
  maxExpiryDate,
}) => {
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <Checkbox
          style={{ visibility: !isBatchingEnabled && "hidden" }}
          checked={isCheck.includes(item?.deviceId)}
          onChange={() => handleSelect(item.deviceId)}
          // color="success"
          size="small"
        />
        <span className={classes.deviceName}>{item.qualcommDeviceId}</span>
      </div>
      <div className={classes.text}>
        <abbr className={classes.deviceName} title={item.deviceName}>
          {item.deviceName.trimEllip(50)}
        </abbr>
      </div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isBatchingEnabled}
          label="Expiration Date"
          id={item.deviceId}
          value={item.expirationTime}
          onChange={(event) => {
            if (maxExpiryDate !== null && event != null && event.isValid()) {
              if (event.isAfter(maxExpiryDate)) {
                return;
              } else if (
                moment(event)
                  .endOf("day")
                  .isBefore(moment(new Date()).endOf("day"))
              ) {
                return;
              } else {
                event && dateHandler(event, item.deviceId);
              }
            } else {
              dateHandler(event, item.deviceId);
            }
          }}
          disablePast
          maxDate={maxExpiryDate !== null ? moment(maxExpiryDate) : null}
          inputFormat="DD-MM-YYYY"
          renderInput={(params) => {
            return (
              <CustomTextField
                onKeyDown={onKeyDown}
                sx={{
                  "& .MuiInputBase-input": {
                    caretColor: "transparent",
                  },
                }}
                size="small"
                focused
                fullWidth
                {...params}
                InputLabelProps={{ shrink: true }}
                error={params.error}
              />
            );
          }}
        />
      </LocalizationProvider>
      {/* <CustomTextField
        disabled={isBatchingEnabled}
        InputLabelProps={{ shrink: true }}
        id={item.deviceId}
        size="small"
        fullWidth
        focused
        label="Expiration Date"
        InputProps={{
          inputProps: {
            min: moment(new Date()).format("YYYY-MM-DD"),
            max:
              maxExpiryDate !== null
                ? moment(maxExpiryDate).format("YYYY-MM-DD")
                : null,
          },
        }}
        value={item.expirationTime}
        // onChange={(e) => dateHandler(e)}
        type="date"
        onChange={(event) => {
          if (maxExpiryDate !== null) {
            if (moment(event?.target?.value).isAfter(maxExpiryDate)) {
              return;
            } else if (
              moment(event?.target?.value).isBefore(
                moment(new Date()).format("YYYY-MM-DD")
              )
            ) {
              return;
            } else {
              event.target.value && dateHandler(event);
            }
          } else {
            dateHandler(event);
          }
        }}
      /> */}
    </div>
  );
};

export default AssociateFeatureToDevicesRow;
