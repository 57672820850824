import PersonalInfo from "../PersonalInfoItem/PersonalInfo";

import classes from "./Profile.module.css";

const Profile = () => {
  return (
    <div className={classes.container}>
      <PersonalInfo />
    </div>
  );
};

export default Profile;
