import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { LinearProgress, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import CustomTextField from "../../ui/CustomTextField";
import { CustomDeviceAccordion } from "../createMDD/CustomDeviceAccordion";
import { CustomQseaAccordion } from "../createMDD//CustomQseaAccordion";
import {
  editMetricsDefinition,
  getAllMetrics,
  getMddInfoById,
} from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import {
  EDIT_MDD_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import { METRIC_CATEGORY } from "../../../constants/metrics-constants";
import { RETENTION_PERIOD } from "../../../constants/time-constants";

import classes from "./EditMDD.module.css";

let newDeviceList = [];
let newQseaList = [];
let previousHealthEndpoint = {};
let previousQseaEndpoint = {};
const EditMDD = ({ fetchAllMddList, onClose, selectedRow }) => {
  let clicked = true;
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const params = useParams();
  const [name, setName] = useState("");
  const [retentionPeriod, setRetentionPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [deviceMetrics, setdeviceMetrics] = useState([]);
  const [qseaMetrics, setQseaMetrics] = useState([]);
  const [deviceEndpoint, setDeviceEndpoint] = useState({
    value: "CSS",
    url: "",
    isQualcommManagingDevice,
  });
  const [qseaEndpoint, setQseaEndpoint] = useState({
    value: "CSS",
    url: "",
    isQualcommManagingDevice,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const deviceCheckedMetrics = deviceMetrics.filter((item) => item.checked);
    const qseaCheckedMetrics = qseaMetrics.filter((item) => item.checked);

    const metricArray = [];
    deviceCheckedMetrics.length > 0 &&
      metricArray.push({
        metricCategory: METRIC_CATEGORY.deviceMetrics,
        endpointType: deviceEndpoint.value,
        endpoint: deviceEndpoint.url || null,
        mddMetricsCategory: deviceCheckedMetrics.map((item) => {
          return {
            metricName: item.metricName,
            reportingRate: item.reportingRate,
            metricType: item.metricType,
            metricId: item.metricId,
          };
        }),
      });
    qseaCheckedMetrics.length > 0 &&
      metricArray.push({
        metricCategory: METRIC_CATEGORY.qseaMetrics,
        endpointType: qseaEndpoint.value,
        endpoint: qseaEndpoint.url || null,
        mddMetricsCategory: qseaCheckedMetrics.map((item) => {
          return {
            metricName: item.metricName,
            reportingRate: item.reportingRate,
            metricType: item.metricType,
            metricId: item.metricId,
          };
        }),
      });
    const requestData = {
      tenantId: params.customerId,
      retentionPeriod,
      mddName: name,
      metricsCategory: metricArray,
    };
    if (clicked) {
      if (deviceCheckedMetrics.length > 0 || qseaCheckedMetrics.length > 0) {
        clicked = false;
        editMetricsDefinition(selectedRow.mddId, requestData)
          .then((response) => {
            if (response.status) {
              toast.success(EDIT_MDD_MESSAGES.success);
              fetchAllMddList();
              onClose();
            } else {
              console.error(response.error);
              errorHandler(response.error, EDIT_MDD_MESSAGES.failure);
              clicked = true;
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, EDIT_MDD_MESSAGES.failure);
            clicked = true;
          })
          .finally(() => {
            clicked = true;
          });
      } else {
        toast.error("Please select atleast one metric");
      }
    }
  };

  useEffect(() => {
    const options = {
      id: selectedRow.mddId,
      version: selectedRow.version.slice(1),
    };
    let deviceMetricsList = [];
    let qseaMetricsList = [];
    getAllMetrics({ source: "Device" })
      .then((response) => {
        if (response.status && response.data != null) {
          deviceMetricsList = response.data.Device_Health_Metrics.map(
            (item) => {
              return {
                metricName: item.metricName,
                displayName: item.displayName,
                reportingRate: item.reportingRate,
                checked: false,
                metricCategory: METRIC_CATEGORY.deviceMetrics,
                metricType: item.metricType,
                metricId: item.metricId,
              };
            }
          );
          qseaMetricsList = response.data.QSEA_Platform_Metrics.map((item) => {
            return {
              metricName: item.metricName,
              displayName: item.displayName,
              reportingRate: item.reportingRate,
              checked: false,
              metricCategory: METRIC_CATEGORY.qseaMetrics,
              metricType: item.metricType,
              metricId: item.metricId,
            };
          });
          getMddInfoById(options)
            .then((response) => {
              if (response.status && response.data != null) {
                const healthMetric = [];
                const qseaMetric = [];

                response?.data?.metricsCategory &&
                  response?.data?.metricsCategory.map((item) => {
                    if (item.metricCategory === METRIC_CATEGORY.qseaMetrics) {
                      previousQseaEndpoint = {
                        value: item.endpointType,
                        url: item.endpoint,
                        isQualcommManagingDevice,
                      };
                      item.mddMetricsCategory.map((metric) => {
                        qseaMetric.push({
                          ...metric,
                          id: metric.categoryId,
                          checked: true,
                          metricCategory: metric.metricCategory,
                          metricType: metric.metricType,
                        });
                      });
                    } else {
                      previousHealthEndpoint = {
                        value: item.endpointType,
                        url: item.endpoint,
                        isQualcommManagingDevice,
                      };
                      item.mddMetricsCategory.map((metric) => {
                        healthMetric.push({
                          ...metric,
                          id: metric.categoryId,
                          checked: true,
                          metricCategory: metric.metricCategory,
                          metricType: metric.metricType,
                        });
                      });
                    }
                  });

                const selectedDeviceMetrics = [
                  ...healthMetric.map((i) => i.metricName),
                ];
                const selectedQseaMetrics = [
                  ...qseaMetric.map((i) => i.metricName),
                ];
                newDeviceList = deviceMetricsList.map((item) => {
                  if (selectedDeviceMetrics.includes(item.metricName)) {
                    return healthMetric.find(
                      (i) => i.metricName === item.metricName
                    );
                  } else {
                    return item;
                  }
                });
                newQseaList = qseaMetricsList.map((item) => {
                  if (selectedQseaMetrics.includes(item.metricName)) {
                    return qseaMetric.find(
                      (i) => i.metricName === item.metricName
                    );
                  } else {
                    return item;
                  }
                });
                setdeviceMetrics(newDeviceList);
                setQseaMetrics(newQseaList);
                setDeviceEndpoint({
                  ...deviceEndpoint,
                  ...previousHealthEndpoint,
                });
                setQseaEndpoint({ ...qseaEndpoint, ...previousQseaEndpoint });
                setName(selectedRow.mddName);
                setRetentionPeriod(response?.data?.retentionPeriod);
              } else {
                console.error(response.error);
                errorHandler(response.error, GET_MESSAGES.failure);
              }
            })
            .catch((error) => {
              console.error("ERROR: ", error);
              errorHandler(error, GET_MESSAGES.failure);
            });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const deviceEndpointDisabledCheck =
    newDeviceList.filter((i) => i.checked === true).length > 0 ? true : false;
  const qseaEndpointDisabledCheck =
    newQseaList.filter((i) => i.checked === true).length > 0 ? true : false;
  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading={"Edit MDD"} onClose={onClose} />
        {!isLoading && qseaMetrics.length > 0 && deviceMetrics.length > 0 ? (
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <div className={`${classes.row} ${classes.firstR}`}>
              <CustomTextField
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                focused
                fullWidth
                type="text"
                label="MDD Name"
                required
                placeholder="Enter MDD Name"
              />
              <CustomTextField
                required
                sx={{ flexBasis: { md: "50%", lg: "25%" } }}
                disabled
                size="small"
                fullWidth
                label="Retention Period"
                focused
                select
                value={retentionPeriod}
              >
                {RETENTION_PERIOD.map((item, key) => (
                  <MenuItem
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    key={key}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </div>
            <div className={classes.row}>
              <CustomDeviceAccordion
                deviceEndpoint={deviceEndpoint}
                setDeviceEndpoint={setDeviceEndpoint}
                deviceMetrics={deviceMetrics}
                setdeviceMetrics={setdeviceMetrics}
                editDisabled={deviceEndpointDisabledCheck}
              />
            </div>
            <div className={classes.row}>
              <CustomQseaAccordion
                qseaEndpoint={qseaEndpoint}
                setQseaEndpoint={setQseaEndpoint}
                qseaMetrics={qseaMetrics}
                setQseaMetrics={setQseaMetrics}
                editDisabled={qseaEndpointDisabledCheck}
              />
            </div>
            <div className={classes.buttons}>
              <CancelButton onClick={onClose} text={"Cancel"} />
              <SubmitButton
                text={"Submit"}
                type={"submit"}
                disabled={
                  JSON.stringify(qseaMetrics) == JSON.stringify(newQseaList) &&
                  JSON.stringify(deviceMetrics) == JSON.stringify(newDeviceList)
                }
              />
            </div>
          </form>
        ) : (
          <LinearProgress />
        )}
      </div>
    </BackdropOverlayModal>
  );
};
export default EditMDD;
