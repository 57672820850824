import { createSlice } from "@reduxjs/toolkit";

const initialCustomerDeviceState = {
  selectedDevice: null,
};

const customerDeviceSlice = createSlice({
  name: "customerDevices",
  initialState: initialCustomerDeviceState,
  reducers: {
    setSelectedDevice(state, action) {
      state.selectedDevice = action.payload;
    },
  },
});

const customerDeviceActions = customerDeviceSlice.actions;

export {
  customerDeviceSlice,
  initialCustomerDeviceState,
  customerDeviceActions,
};
