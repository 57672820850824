import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import noPreview from "../../assets/images/common/no-preview.svg";
import containerIcon from "../../assets/images/catalogs/containers.svg";
import modelIcon from "../../assets/images/catalogs/models.svg";
import collectionIcon from "../../assets/images/catalogs/collections.svg";

import classes from "./FeatureCard.module.css";

function FeatureCard({ data }) {
  const location = useLocation();

  const navigate = useNavigate();
  const cardStyles = {
    border: "1px solid",
    backgroundColor: "#fff",
    "&.MuiCard-root": {
      boxShadow: "0px 0px 5px #C8E0FF",
    },
    border: "#c1d9ff",
    color: "#858585",
    boxShadow: "none",
    width: 270,
  };
  return (
    <Card sx={cardStyles}>
      <CardActionArea
        sx={{ fontFamily: "QualcommNext-Regular" }}
        onClick={() => navigate(`${location.pathname}/${data.featureName}`)}
        disableRipple
      >
        <CardMedia
          className={classes.cardImage}
          component="img"
          height="140"
          image={
            data.featureIcon != null
              ? "data:image/*;base64," + data.featureIcon
              : noPreview
          }
          alt="green iguana"
        />
        <CardContent>
          <div className={classes.featureTitle}>
            {data.featureTitle.trimEllip(22)}
          </div>
          <div className={classes.featureType}>
            <img
              src={
                data?.featureType === "MODEL"
                  ? modelIcon
                  : data?.featureType === "CONTAINER"
                  ? containerIcon
                  : collectionIcon
              }
              height="30px"
              className={classes.image}
            />
            {data?.featureType?.slice(0, 1).toUpperCase() +
              data?.featureType?.slice(1).toLowerCase()}
          </div>
        </CardContent>
        <CardContent sx={{ paddingTop: "0", height: "6vh" }}>
          <div className={classes.featureDesc}>
            {data.featureDesc != null && data.featureDesc.trimEllip(30)}
          </div>
        </CardContent>
        <CardContent
          sx={{
            backgroundColor: "#F6F9FF",
            color: "#5677A0",
          }}
        >
          <div className={classes.featureCategory}>
            Category: {data.featureCategory}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default FeatureCard;
