export const TIME_CONSTANTS = {
  hrs: Array.from({ length: 72 }, (_, i) => `${i + 1}h`),
  mins: Array.from({ length: 60 }, (_, i) => i + 1),
  secs: Array.from({ length: 60 }, (_, i) => i + 1),
};

export const TIME_VALUES = [
  { showTime: "hr", value: "hrs" },
  { showTime: "min", value: "mins" },
  { showTime: "sec", value: "secs" },
];

export const METRIC_TIME_VALUES = [
  { label: "Last 5 Mins", value: "5m" },
  { label: "Last 15 Mins", value: "15m" },
  { label: "Last 30 Mins", value: "30m" },
  { label: "Last 1 hour", value: "1h" },
  { label: "Last 3 hours", value: "3h" },
  { label: "Last 6 hours", value: "6h" },
  { label: "Last 12 hours", value: "12h" },
  { label: "Last 24 hours", value: "24h" },
  { label: "Last 2 days", value: "2d" },
  { label: "Last 7 days", value: "7d" },
  { label: "Last 30 days", value: "30d" },
  { label: "Last 90 days", value: "90d" },
];

export const RETENTION_PERIOD = [
  { label: "1 Day", value: "1d" },
  { label: "2 Days", value: "2d" },
  { label: "30 Days", value: "30d" },
  { label: "60 Days", value: "60d" },
  { label: "90 Days", value: "90d" },
  { label: "6 Months", value: "6m" },
  { label: "1 Year", value: "1y" },
  { label: "3 Years", value: "3y" },
  { label: "5 Years", value: "5y" },
  { label: "7 Years", value: "7y" },
  { label: "10 Years", value: "10y" },
  { label: "15 Years", value: "15y" },
  { label: "20 Years", value: "20y" },
];
