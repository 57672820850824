import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  userInfo: null,
  selectedAppKey: null,
  selectedEntityType: null,
  forceUpdate: {},
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    resetUserInfo(state, action) {
      state.userInfo = null;
      state.selectedAppKey = null;
    },
    setUserInfoState(state, action) {
      state.userInfo = action.payload;
    },
    setSelectedAppKey(state, action) {
      state.selectedAppKey = action.payload;
    },
    setSelectedEntityType(state, action) {
      state.selectedEntityType = action.payload;
    },
    forceStateUpdate(state, action) {
      state.forceUpdate = action.payload;
    },
  },
});

const userActions = userSlice.actions;

export { userSlice, initialUserState, userActions };
