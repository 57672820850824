import axios from "axios";
import { getPreferredVersionForSDKByCustomerIdApi } from "../apiEndpoints";

export const getPreferredVersionForSDKById = async (payload) => {
  let data = null;

  await axios
    .get(getPreferredVersionForSDKByCustomerIdApi, { params: { ...payload } })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
