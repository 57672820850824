import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { schema } from "./SelectChipsetsSchema";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import BasicForm from "../../ui/Form/BasicForm";
import CommonHeader from "../../ui/CommonHeader";
import { featuresActions } from "../../../store/slices/features-slice";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../../constants/route-constants";

import classes from "./SelectChipsets.module.css";

const SelectChipsets = (props) => {
  const { onClose, featureDetails } = props;
  const params = useParams();
  const navigate = useNavigate();
  console.log(featureDetails);
  const dispatch = useDispatch();

  const submitHandler = (data) => {
    const datas =
      featureDetails != null
        ? {
            id: featureDetails.id,
            featureName: featureDetails.name,
            featureDesc: featureDetails.category,
            featureCategory: featureDetails.description,
            featurePolicyTerm: featureDetails.featurePolicyTerm,
            qualcommFeatureId: featureDetails.qualcommFeatureId,
            qualcommFeatureName: featureDetails.qualcommFeatureName,
            chipsetName: data.chipset,
            preferredVersion: featureDetails.preferredVersion,
          }
        : null;
    dispatch(featuresActions.setSelectedFeature(datas));
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.features +
        "/" +
        featureDetails.id +
        "/available-devices"
    );
  };

  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs: [
      {
        name: "chipset",
        label: "Chipset",
        placeholder: "Select Chipset",
        select: true,
        selectOptions: featureDetails.featurePolicyTerm
          ?.filter((policy) => policy.status !== "INACTIVE")[0]
          ?.policyChipsetsList.map((item) => item.chipset),
      },
    ],
  };
  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <Fragment>
          <CommonHeader
            heading={`Select Chipset- ${featureDetails?.name}`}
            onClose={onClose}
          />
          <BasicForm details={details} />
        </Fragment>
      </div>
    </BackdropOverlayModal>
  );
};

export default SelectChipsets;
