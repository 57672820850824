import { useState } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { MenuItem } from "@mui/material";

import BackdropOverlayModal from "../BackdropOverlayModal";
import { CustomSelectField } from "../CustomSelectField";
import { METRIC_TIME_VALUES } from "../../../constants/time-constants";
import useTimeStatus from "../../../hooks/useTimeStatus";
import CloseIcon from "../Buttons/CloseIcon";
import CustomIframe from "../customIframe/CustomIframe";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../../api/iframeEndpoints";

import classes from "./MetricPopup.module.css";

const MetricPopup = ({ heading, selectedItem, onClose }) => {
  const [timedropDown, setTimeDropdown] = useState("24h");
  const [dateRange, handleTime] = useTimeStatus();
  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <div className={classes.header}>
          <p>{heading}</p>
          <div className={classes.buttons}>
            <div className={classes.dropdown}>
              <CustomSelectField
                sx={{ width: "9em" }}
                onChange={(e) => {
                  setTimeDropdown(e.target.value);
                  handleTime(e.target.value);
                }}
                size="small"
                displayEmpty
                value={timedropDown}
              >
                {METRIC_TIME_VALUES.map((item) => (
                  <MenuItem
                    key={item.value}
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </div>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <div className={classes.invisibleOverlay}></div>
        <CustomIframe
          key={uuidv4()}
          src={`${IFRAME_BASE_URL.baseUrl}/u_SNpaF4k/${DASHBOARD_NAME.cssAdmin}?orgId=1&from=${dateRange.from}&to=${dateRange.to}&var-application=${selectedItem.app}&theme=light&panelId=${selectedItem.detailView}`}
        />
      </div>
    </BackdropOverlayModal>
  );
};
export default MetricPopup;
