import { COMMON_ROUTES } from "./route-constants";

const MENU_NAMES = {
  home: "Home",
  models: "Models",
  containers: "Containers",
  collections: "Collections",
};
export const CATALOG_MENU_OPTIONS = [
  {
    name: MENU_NAMES.home,
    route: COMMON_ROUTES.catalog,
  },
  {
    name: MENU_NAMES.models,
    route: COMMON_ROUTES.catalogModels,
  },
  {
    name: MENU_NAMES.containers,
    route: COMMON_ROUTES.catalogContainers,
  },
  {
    name: MENU_NAMES.collections,
    route: COMMON_ROUTES.catalogCollections,
  },
];
