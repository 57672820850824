import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { toast } from "react-toastify";
import CustomTextField from "../../ui/CustomTextField";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import { schema } from "../EditDevice/EditDeviceSchema";
import CommonHeader from "../../ui/CommonHeader";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import { editDevice } from "../../../api/api-index";
import { EDIT_DEVICE_MESSAGES } from "../../../constants/message-constants";
import classes from "../../ui/Form/FormContainer.module.css";
import styles from "./AssociateFeature.module.css";
import FeaturesDataTable from "./FeaturesDataTable";
import AddFeatureExpiration from "./AddFeatureExpiration";
import errorHandler from "../../helpers/error-helper";
let isClicked = true;
const AssociateFeatures = ({ onClose, data }) => {
  const {
    control,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast({
      deviceName: data?.deviceName,
      systemDeviceId: data?.qualcommDeviceId,
    }),
    resolver: yupResolver(schema),
  });

  const [featureList, setFeatureList] = useState(
    data?.deviceFeatureIdList || []
  );

  const [state, setstate] = useState(true);

  const qualcommFeatureId = featureList.map((item) => item?.qualcommFeatureId);
  const EditFormRequest = (formData) => {
    let request = null;
    if (data != null) {
      request = {
        deviceId: data?.deviceId,
        tenantId: data?.tenantId,
        deviceName: data?.deviceName,
        serialNo: data?.serialNo,
        featureDetailsSubscriptionIdList: featureList?.map((feature) => {
          return {
            deviceId: data?.qualcommDeviceId,
            tenantId: data?.tenantId,
            qualcommFeatureName: feature?.qualcommFeatureName,
            qualcommFeatureId: feature?.qualcommFeatureId,
            subscriptionId: feature?.subscriptionId,
            featureName: feature?.featureName,
            featureId: feature?.featureId,
            preferredVersion: feature?.preferredVersion,
            tempExpTime: feature.currentAction
              ? feature.tempExpTime != null
                ? moment(feature.tempExpTime).endOf("day").utc().format()
                : moment(feature.expirationTime).endOf("day").utc().format()
              : undefined,

            status: feature.currentAction
              ? feature.currentAction
              : feature.status,
          };
        }),
      };
    }
    return request;
  };
  const registerHandler = (data) => {
    if (isClicked) {
      isClicked = false;
      editDevice(EditFormRequest(data))
        .then((response) => {
          if (response.status) {
            toast.success(EDIT_DEVICE_MESSAGES.success);
          } else {
            errorHandler(response.data.error, EDIT_DEVICE_MESSAGES.failure);
          }
          onClose();
          isClicked = true;
        })
        .catch((error) => {
          isClicked = true;
          console.error("ERROR: ", error);
          errorHandler(error, EDIT_DEVICE_MESSAGES.failure);
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={styles.container}>
        <CommonHeader
          heading={`Edit Feature-${data?.qualcommDeviceId || ""}`}
          onClose={onClose}
        />
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <AddFeatureExpiration
                featureList={featureList}
                featureArray={qualcommFeatureId}
                setFeatureList={setFeatureList}
                tenantId={data?.tenantId}
                deviceInfo={data}
                setstate={setstate}
              />
            </div>
          </div>
          <div className={styles.section}>
            <p className={styles.table_title}>Features List</p>
            <FeaturesDataTable
              featureList={featureList}
              setFeatureList={setFeatureList}
              setstate={setstate}
            />
          </div>
          <div className={styles.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton disabled={state} text={"Submit"} type={"submit"} />
          </div>
        </form>
      </div>
    </BackdropOverlayModal>
  );
};

export default AssociateFeatures;
