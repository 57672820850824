import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Fragment } from "react";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import BasicForm from "../../ui/Form/BasicForm";
import { schema } from "./EditUserSchema";
import { editUser } from "../../../api/api-index";
import { EDIT_USER_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { USER_ROLES } from "../../../constants/role-constants";
import { MAX_VALUE } from "../../../constants/max-value-constants";

import classes from "../../ui/Form/FormContainer.module.css";

const EditUser = (props) => {
  const { onClose, userDetails, getUpdatedDataFn } = props;
  let clicked = true;

  const {
    userId,
    firstName,
    lastName,
    emailId,
    phoneNo,
    keycloakId,
    password,
    noBackdrop = false,
    role,
    appType,
  } = userDetails ?? {};

  const orgId = useSelector(
    (state) => state.organizationsInfo?.selectedOrganization?.organizationId
  );
  // const formattedRoles = USER_ROLES.reduce(
  //   (roles, current) => {
  //     return current.label.toLowerCase().includes(appType?.toLowerCase())
  //       ? {
  //           ...roles,
  //           label: [...roles.label, current.label],
  //           value: [...roles.value, current],
  //         }
  //       : roles;
  //   },
  //   { label: [], value: [] }
  // );
  // const selectedRole = USER_ROLES.find((i) => i.id === role);
  const editFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        userId: userId,
        phoneNo: data.phoneNo,
        password: password || null,
        keyCloakEditUserDetails: {
          id: keycloakId,
          enabled: null,
          totp: null,
          emailVerified: null,
          firstName:
            data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
          lastName:
            data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
          attributes: {
            organizationId: [orgId],
          },
        },
      };
    }

    return request;
  };

  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      editUser(editFormRequest(data), orgId)
        .then((response) => {
          if (response.status && response.data != null) {
            toast.success(EDIT_USER_MESSAGES.success);
            getUpdatedDataFn && getUpdatedDataFn();
            onClose();
          } else {
            clicked = true;
            console.error();
            errorHandler(response.error, EDIT_USER_MESSAGES.failure);
          }
        })
        .catch((error) => {
          clicked = true;
          console.error("ERROR: ", error);
          errorHandler(error, EDIT_USER_MESSAGES.failure);
        });
    }
  };
  const inputData = [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "Enter first name",
      defaultValue: firstName,
      maxLength: MAX_VALUE.firstName,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter last name",
      defaultValue: lastName,
      maxLength: MAX_VALUE.lastName,
    },
    {
      name: "emailId",
      label: "Email",
      placeholder: "Enter email",
      defaultValue: emailId,
      disabled: true,
    },
    {
      name: "phoneNo",
      label: "Phone Number",
      placeholder: "Enter phone number",
      type: "tel",
      defaultValue: phoneNo,
    },
  ];
  const details = {
    schema: schema,
    onSubmit: submitHandler,
    onClose: onClose,
    inputs: role
      ? [
          ...inputData,
          // {
          //   name: "role",
          //   label: "Role",
          //   placeholder: "Select role",
          //   defaultValue: selectedRole.label,
          //   select: true,
          //   selectOptions: formattedRoles.label,
          // },
        ]
      : inputData,
  };

  return noBackdrop ? (
    <Fragment>
      <CommonHeader heading="Edit User Details" onClose={onClose} />
      <BasicForm details={details} />
    </Fragment>
  ) : (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Edit User Details" onClose={onClose} />
        <BasicForm details={details} />
      </div>
    </BackdropOverlayModal>
  );
};

export default EditUser;
