import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { availableDevicesMDDHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import {
  createDeviceMappingForMDD,
  getAvailableDevicesForMDD,
} from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../constants/route-constants";
import {
  ASSOCIATE_DEVICES_MDD,
  GET_MESSAGES,
} from "../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import errorHandler from "../helpers/error-helper";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import { ROLES } from "../../constants/role-constants";
import { columnsActions } from "../../store/slices/columns-slice";
import { toast } from "react-toastify";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";

import classes from "../ui/DataTable.module.css";

let isClicked = true;
const AvailableDevicesForMdd = () => {
  const dispatch = useDispatch();
  const initialState = useSelector(
    (state) => state.columnsInfo.subscriptionPolicy
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [devicesList, setdevicesList] = useState({
    count: 0,
    list: [],
    mddName: "",
  });
  const [selectedDevices, setSelectedDevices] = useState({ ids: [], rows: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const params = useParams();

  const selectedFeature = useSelector(
    (state) => state.featuresInfo?.selectedFeature
  );

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  function fetchAvailableDevices() {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      tenantId: params.customerId,
      mddId: params.mddId,
    };

    getAvailableDevicesForMDD(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.qualcommDeviceIdName.map(
            (item, index) => {
              return {
                id: item.qualcommDeviceId || index,
                deviceId: item.qualcommDeviceId,
                name: item.deviceName || "-",
              };
            }
          );
          setdevicesList({
            count: response.data.count,
            list: formattedList,
            mddName: response.data.mddName,
          });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          //error handling function
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        // errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  const handleAssociateDevices = () => {
    const options = {
      deviceId: selectedDevices.ids,
      mddId: params.mddId,
    };
    setSelectedDevices({ ids: [], rows: [] });
    if (isClicked) {
      isClicked = false;
      createDeviceMappingForMDD(options)
        .then((response) => {
          if (response.status && response.data != null) {
            toast.success(
              `${response.data.length} ${ASSOCIATE_DEVICES_MDD.success}`
            );
            fetchAvailableDevices();
          } else {
            errorHandler(response.error, ASSOCIATE_DEVICES_MDD.failure);
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, ASSOCIATE_DEVICES_MDD.failure);
          isClicked = true;
        })
        .finally(() => {
          isClicked = true;
        });
    }
  };

  const columns = [
    {
      field: "deviceId",
      headerName: "System Device ID",
      flex: 1,
      minWidth: 350,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Device Name",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
  ];

  const headers = availableDevicesMDDHeaders({
    routes: {
      devicesList:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        params.mddId +
        "/available-devices",
    },
  });
  const buttons = hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.deviceManager,
    ROLES.cssAdmin,
  ])
    ? [
        {
          key: "selectDevices",
          text: "Associate Selected Devices",
          clickHandler: handleAssociateDevices,
          disabled: selectedDevices.ids.length === 0 ? true : false,
          icon: null,
        },
      ]
    : null;

  //Api call for fetching list of available devices for tenant and mddId

  useEffect(() => {
    fetchAvailableDevices();
  }, [pageNo]);

  return (
    <Fragment>
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          list={devicesList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={["name", "deviceId"]}
          buttons={buttons}
        />
        <p>Associate Devices - {devicesList.mddName || "N/A"}</p>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.6rem",
                wordBreak: "break-word",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            checkboxSelection={true}
            disableColumnFilter
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = filteredList.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedDevices({ ids: ids, rows: selectedRows });
            }}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  devicesList.list.length === filteredList.length
                    ? devicesList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.availableDevices,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AvailableDevicesForMdd;
