import { useNavigate } from "react-router-dom";

import DeviceLicensesStatGraph from "./DeviceLicensesStatGraph";
import FullScreenIcon from "../../ui/Buttons/FullScreenIcon";
import LightTooltip from "../../ui/Tooltip/LightToolTip";
import { CSS_ROUTES } from "../../../constants/route-constants";

import classes from "./DeviceLicensesStat.module.css";

const DeviceLicensesStat = () => {
  const navigate = useNavigate();

  const handleOpenDetails = () => {
    navigate(CSS_ROUTES.tenantDeviceLicenses);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>License Stats</span>
        <LightTooltip title="Expand for more details" placement="top" arrow>
          <div>
            <FullScreenIcon onClick={handleOpenDetails} />
          </div>
        </LightTooltip>
      </div>
      <DeviceLicensesStatGraph />
    </div>
  );
};

export default DeviceLicensesStat;
