import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#FF7373",
  "&:hover": {
    backgroundColor: "#ff3c2f",
  },
  paddingLeft: "1.8rem",
  paddingRight: "1.8rem",
}));

const ConfirmDeleteButton = (props) => {
  const { onClick, text } = props;
  return <ColorButton onClick={onClick != null ? onClick : null}>{text}</ColorButton>;
};

export default ConfirmDeleteButton;
