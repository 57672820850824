import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { GET_MESSAGES } from "../../../constants/message-constants";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { ROLES } from "../../../constants/role-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import DataTableHeaders from "../../header/DataTableHeaders";
import errorHandler from "../../helpers/error-helper";
import { featureHeaders } from "../../helpers/header-helpers";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import CheckIcon from "../../ui/Buttons/CheckCircleIcon";
import {
  columnMenuStyles,
  CustomDataGrid,
  panelStyles,
} from "../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import RightBottomChevronIcon from "../../ui/Buttons/RightBottomChevronIcon";
import VersionHistoryStats from "../VersionHistoryStats/VersionHistoryStats";
import { getAllVersionHistory } from "../../../api/api-index";
import { DateFormatter } from "../../helpers/utils";
import CustomUpload from "../uploadFile/CustomUpload";
import versionIcon from "../../../assets/images/features/version-icon.svg";
import DeleteVersion from "../DeleteVersion/DeleteVersion";
import { REPO_STATUS } from "../../../constants/repo-constants";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { columnsActions } from "../../../store/slices/columns-slice";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";

import classes from "../../ui/DataTable.module.css";
import vclasses from "./TenantFeaturesVersionHistory.module.css";

let selectedRow = null;

const TenantFeaturesVersionHistory = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("featuresInfo");
  const initialState = useSelector(
    (state) => state.columnsInfo.isvVersionHistory
  );
  const [searchStatefn] = useSearchKeys(
    ["version", "size", "pushTime", "status"],
    initialState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [paramId, setParamId] = useState(null);
  const [verifyVersion, setVerifyVersion] = useState(false);
  const [versionHistoryList, setVersionHistoryList] = useState({
    count: 0,
    list: [],
  });
  const [openDeleteVersion, setOpenDeleteVersion] = useState(false);
  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const selectedFeature = useSelector(
    (state) => state.featuresInfo.selectedFeature
  );

  const isvStatus =
    selectedFeature.isvStatus === "MARKED_FOR_DELETE" ? true : false;

  const params = useParams();
  const location = useLocation();

  const tenantType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? CSS_ROUTES.organizations +
      "/" +
      params.organizationId +
      PARTIAL_ROUTES.isvTenants
    : location.pathname.includes(PARTIAL_ROUTES.customerTenants)
    ? PARTIAL_ROUTES.customerTenants
    : "";

  const isvCustomerTenantId = params.tenantId || params.customerId;

  const featureType = location.pathname.includes(PARTIAL_ROUTES.features)
    ? PARTIAL_ROUTES.features
    : location.pathname.includes(PARTIAL_ROUTES.myFeatures)
    ? PARTIAL_ROUTES.myFeatures
    : "";

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleOpenHistory = (row, list) => {
    if (paramId == null) {
      setParamId({
        version: row.id,
        containerList: list.map((i) => {
          return {
            ...i,
            pushTime: row.pushTime,
          };
        }),
      });
    } else {
      setParamId(null);
    }
  };
  const handleOpenDeleteVersion = (params) => {
    selectedRow = params.row;
    setOpenDeleteVersion(true);
  };

  const closeDeleteVersion = () => {
    selectedRow = null;
    setOpenDeleteVersion(false);
  };

  const handleOpenVerifyVersion = (params) => {
    selectedRow = params.row;
    setVerifyVersion(true);
  };

  const handleCloseVerifyVersion = () => {
    setVerifyVersion(false);
  };

  const columns = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <img src={versionIcon} alt="version" />
          {params.value}
        </div>
      ),
      hideable: false,
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "pushTime",
      headerName: "Push Time",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      hideable: false,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.isvAdmin, ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      minWidth: 200,
      hideable: false,
      renderCell: (params) => (
        <div>
          {hasPermittedRolesOrAttributes([ROLES.isvAdmin]) &&
          params.row.status === "Added to Local Repository" ? (
            <CheckIcon
              size="small"
              tooltipText="Verify"
              onClick={() => handleOpenVerifyVersion(params)}
              isvStatus={isvStatus}
            />
          ) : (
            <CheckIcon size="small" disabled={true} isvStatus={isvStatus} />
          )}
          {params.row.repositoryType !== "Global" && (
            <DeleteIcon
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDeleteVersion(params);
              }}
            />
          )}

          <RightBottomChevronIcon
            onClick={(event) => {
              event.stopPropagation();
              handleOpenHistory(params.row, params.row.containersList);
            }}
            active={!!paramId?.version && paramId?.version === params.row.id}
          />

          {!!paramId?.version && paramId?.version === params.row.id && (
            <div
              className={vclasses.vcontainer}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <VersionHistoryStats list={paramId.containerList} />
            </div>
          )}
        </div>
      ),
    });

  const headers = featureHeaders({
    routes: {
      featureInfo:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId,
      contents:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId +
        PARTIAL_ROUTES.contents,
      versionHistory:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId +
        PARTIAL_ROUTES.versionHistory,
      subscribers:
        hasPermittedRolesOrAttributes([
          ROLES.isvAdmin,
          ROLES.isvMonitor,
          ROLES.cssAdmin,
        ]) &&
        tenantType +
          "/" +
          isvCustomerTenantId +
          featureType +
          "/" +
          params.featureId +
          PARTIAL_ROUTES.subscribers,
    },
  });

  useEffect(() => {
    const options = {
      qcommFeatureName: selectedFeature?.featureName,
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
    };

    getAllVersionHistory(options, selectedFeature?.featureName)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedList = response.data.featureVersion.map((item) => {
            return {
              id: item.version,
              featureVersionId: item.featureVersionId,
              version: item.version,
              size: `${item.size} ${item.unit}`,
              pushTime: DateFormatter(item.createdOn),
              status: item.status,
              containersList: item.containersList,
              featureName: item.qcommFeatureName,
              repositoryType: item?.repositoryType,
            };
          });
          setVersionHistoryList({
            count: response.data.totalCount,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo, openDeleteVersion, verifyVersion]);

  const heading =
    selectedFeature?.featureTitle != null
      ? `${selectedTenant?.tenantName} - ${selectedFeature.featureTitle}`
      : `${selectedTenant?.tenantName} - N/A`;

  return (
    <Fragment>
      {openDeleteVersion && (
        <DeleteVersion
          onClose={closeDeleteVersion}
          versionDetails={selectedRow}
        />
      )}

      {verifyVersion && (
        <CustomUpload
          heading="Verify"
          onClose={handleCloseVerifyVersion}
          versionDetails={{
            ...selectedRow,
            nextStatus: REPO_STATUS.published,
            oldStatus: "ADDED_TO_LOCAL_REGISTRY",
          }}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest backButton={true} disableSearch={true} />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{selectedFeature?.featureTitle || "N/A"}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(
                columnsActions.setisvVersionHistoryColumnsState(newModel)
              )
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={versionHistoryList.list || []}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: versionHistoryList.count,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.versionHistory,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TenantFeaturesVersionHistory;
