import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import ReplayIcon from "@mui/icons-material/Replay";

import SubmitButton from "../../ui/Buttons/SubmitButton";
import CommonHeader from "../../ui/CommonHeader";
import CustomTextField from "../../ui/CustomTextField";
import KeycloakConfig from "../../../config/keycloak-config";
import { regenerateSecretId } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import { REGENERATE_SECRET_MESSAGES } from "../../../constants/message-constants";
import ClipboardButton from "../../ui/Buttons/ClipboardButton";
import { AddToClipBoard } from "../../helpers/utils";

import classes from "./ClientIDAndSecret.module.css";

const ClientIDAndSecret = (props) => {
  const { onClose, details, heading = true } = props;
  const [regenerate, setRegenerate] = useState(false);
  const [regenerationDetails, setRegenerationDetails] = useState({});

  const handleSecretRegeneration = () => {
    setRegenerate(true);
  };

  useEffect(() => {
    if (regenerate) {
      const options = {
        client: details?.client,
        realm: KeycloakConfig.getRealm(),
      };
      regenerateSecretId(options)
        .then((response) => {
          if (response.status) {
            toast.success(REGENERATE_SECRET_MESSAGES.success);
            setRegenerationDetails((prevValue) => ({
              ...prevValue,
              secret: response.data,
            }));
          } else {
            console.error(response.error);
            errorHandler(response.error, REGENERATE_SECRET_MESSAGES.failure);
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, REGENERATE_SECRET_MESSAGES.failure);
        });
      setRegenerate(false);
    }
  }, [regenerate]);
  useEffect(() => {
    setRegenerationDetails(details);
  }, [details]);

  return (
    <div className={classes.container}>
      {heading && (
        <CommonHeader heading="Client ID & Secret" onClose={onClose} />
      )}

      <div className={classes.fields}>
        <CustomTextField
          id="clientid"
          label="Client ID"
          value={regenerationDetails?.clientId || ""}
          variant="standard"
          inputProps={{ readOnly: true }}
          InputProps={{
            endAdornment: (
              <ClipboardButton
                type="clientId"
                onClick={() => {
                  AddToClipBoard(regenerationDetails?.clientId);
                  toast.info("Copied", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    autoClose: 500,
                  });
                }}
              />
              // <Tooltip title="Add to clipboard">
              //   <IconButton
              //     onClick={() => AddToClipBoard(regenerationDetails?.clientId)}
              //   >
              //     <ContentCopyIcon />
              //   </IconButton>
              // </Tooltip>
            ),
          }}
        />
        <CustomTextField
          id="secret"
          label="Secret"
          value={regenerationDetails?.secret || ""}
          variant="standard"
          inputProps={{ readOnly: true }}
          multiline
          InputProps={{
            endAdornment: (
              <ClipboardButton
                type="clientSecret"
                onClick={() => {
                  AddToClipBoard(regenerationDetails?.secret);

                  toast.info("Copied", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    autoClose: 500,
                  });
                }}
              />
            ),
          }}
        />
        <SubmitButton
          text="Regenerate Secret"
          endIcon={<ReplayIcon />}
          onClick={handleSecretRegeneration}
        />
      </div>
    </div>
  );
};

export default ClientIDAndSecret;
