import { Menu } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Notification from "./Notification";
import NotificationButton from "../../ui/Buttons/NotificationButton";
import { COMMON_ROUTES } from "../../../constants/route-constants";
import { getAllNotifications } from "../../../api/api-index";
import { NOTIFICATION_TYPE } from "../../../constants/notifications-constants";

import classes from "./NotificationPopup.module.css";
const NotificationPopup = () => {
  const navigate = useNavigate();
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);
  const selectedRole = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.roles[0]
  );
  const [value, setValue] = useState(0);
  const [forceUpdate, setForceUpdate] = useState({});
  const [notifcations, setNotifcations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setValue(0);
  };
  const handleNotifications = () => {
    navigate(`${COMMON_ROUTES.notificationsAlerts}`);
    handleClose();
  };
  const UnHandledNotificationsCount = notifcations
    .filter((i) => i.notificationType === "ALERT")
    .filter((item) => item.handledBy == null).length;

  useEffect(() => {
    const options = {
      pageNo: 0,
      pageSize: 5,
      notificationType: NOTIFICATION_TYPE[value],
      tenantId: selectedAppType != "CSS" ? selectedAppKey : null,
      role: selectedRole,
    };

    getAllNotifications(options)
      .then((response) => {
        if (response?.status && response?.data != null) {
          console.log(response.data);
          setNotifcations(response?.data.notificationList);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }, [open, value, forceUpdate, selectedAppKey]);
  return (
    <Fragment>
      <NotificationButton
        open={open}
        handleClick={handleClick}
        UnHandledNotificationsCount={UnHandledNotificationsCount}
      />
      <Menu
        id="notification-center"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 6,
        }}
        sx={{
          ".MuiList-root": {
            padding: 0,
          },
        }}
      >
        <div className={classes.container}>
          <Notification
            handleClose={handleClose}
            notifcations={notifcations}
            setValue={setValue}
            value={value}
            setForceUpdate={setForceUpdate}
          />
          <div
            className={classes.bottomNavigation}
            onClick={handleNotifications}
          >
            View All Notifications
          </div>
        </div>
      </Menu>
    </Fragment>
  );
};
export default NotificationPopup;
