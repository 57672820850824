import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";

import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { featureHeaders } from "../../helpers/header-helpers";
import EditFeature from "../EditFeature/EditFeature";
import DataTableHeaders from "../../header/DataTableHeaders";
import { fetchFeatureInfo } from "../../../store/actions/actions-index";
import TenantFeaturesInfoItem from "./TenantFeaturesInfoItem";
import About from "./About";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import featureNameIcon from "../../../assets/images/features/default-feature-name.svg";
import featureImage from "../../../assets/images/features/featureName.svg";
import accessStatusIcon from "../../../assets/images/features/access-status.svg";
import secured from "../../../assets/images/features/secured-status.svg";
import storage from "../../../assets/images/features/storage-quota.svg";
import version from "../../../assets/images/features/latest-version.svg";
import categoryIcon from "../../../assets/images/features/category.svg";
import { ROLES } from "../../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import disableEditIcon from "../../../assets/images/organizations/disableEditOrg.svg";
import { getCatalogInfo } from "../../../api/api-index";
import { StyledLink } from "../../ui/StyledLink";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";

import classes from "./TenantFeaturesInfo.module.css";

const TenantFeaturesInfo = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const location = useLocation();
  const [openEditFeature, setOpenEditFeature] = useState(false);
  const [breadCrumbArray] = useBreadcrumb("featuresInfo");
  const [list, setList] = useState([{ indexKey: 1, title: "", text: "" }]);
  const [files, setFiles] = useState([]);
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const selectedFeature = useSelector(
    (state) => state.featuresInfo.selectedFeature
  );
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const tenantType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? CSS_ROUTES.organizations +
      "/" +
      params.organizationId +
      PARTIAL_ROUTES.isvTenants
    : location.pathname.includes(PARTIAL_ROUTES.customerTenants)
    ? PARTIAL_ROUTES.customerTenants
    : "";

  const isvCustomerTenantId = params.tenantId || params.customerId;

  const featureType = location.pathname.includes(PARTIAL_ROUTES.features)
    ? PARTIAL_ROUTES.features
    : location.pathname.includes(PARTIAL_ROUTES.myFeatures)
    ? PARTIAL_ROUTES.myFeatures
    : "";

  const headers = featureHeaders({
    routes: {
      featureInfo:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId,
      contents: selectedFeature?.isFeatureDownloadable
        ? tenantType +
          "/" +
          isvCustomerTenantId +
          featureType +
          "/" +
          params.featureId +
          PARTIAL_ROUTES.contents
        : undefined,
      versionHistory: selectedFeature?.isFeatureDownloadable
        ? tenantType +
          "/" +
          isvCustomerTenantId +
          featureType +
          "/" +
          params.featureId +
          PARTIAL_ROUTES.versionHistory
        : undefined,
      subscribers:
        hasPermittedRolesOrAttributes([
          ROLES.isvAdmin,
          ROLES.isvMonitor,
          ROLES.cssAdmin,
        ]) &&
        tenantType +
          "/" +
          isvCustomerTenantId +
          featureType +
          "/" +
          params.featureId +
          PARTIAL_ROUTES.subscribers,
    },
  });

  const handleOpenEditFeature = () => {
    setOpenEditFeature(true);
  };

  const closeEditFeature = () => {
    setOpenEditFeature(false);
  };

  function dataURLtoFile(dataurl, filename = "sample.jpg") {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    const options = {
      featureId: params.featureId,
    };
    dispatch(fetchFeatureInfo(options));

    getCatalogInfo(options.featureId)
      .then((response) => {
        if (response.status && Array.isArray(response.data.titleTextList)) {
          const formattedList = [];
          const formattedFiles = [];
          if (response.data.titleTextList.length > 0) {
            response.data.titleTextList.map((element, key) => {
              formattedList.push({
                indexKey: key + 1,
                title: element.title,
                text: element.text,
              });
            });
            setList(formattedList);
          }

          if (response.data.images != null) {
            for (const property in response.data.images) {
              formattedFiles.push(
                dataURLtoFile(
                  `data:image/${property.split(".").pop()};base64,` +
                    response.data.images[property],
                  property || "sample.jpg"
                )
              );
            }
            setFiles(formattedFiles);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        // errorHandler(error, CREATE_FEATURE_CATALOG_MESSAGES.failure);
      });
  }, [openEditFeature]);

  const heading =
    selectedFeature?.featureTitle != null
      ? `${selectedTenant?.tenantName} - ${selectedFeature?.featureTitle}`
      : `${selectedTenant?.tenantName} - N/A`;

  const buttons = hasPermittedRolesOrAttributes([
    ROLES.isvAdmin,
    ROLES.cssAdmin,
  ])
    ? [
        {
          key: "edit",
          clickHandler: handleOpenEditFeature,
          disabled: selectedFeature?.qualcommFeatureName ? false : true,
          icon: selectedFeature?.qualcommFeatureName ? (
            <EditIcon />
          ) : (
            <img src={disableEditIcon} />
          ),
          onlyIcon: true,
          tooltipText: "Edit",
        },
      ]
    : null;
  return (
    <Fragment>
      {openEditFeature && (
        <EditFeature
          onClose={closeEditFeature}
          selectedFeature={selectedFeature}
          tenantId={isvCustomerTenantId}
          roleType={selectedAppType}
          filesArray={files}
          listArray={list}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{selectedFeature?.featureTitle || "..."}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}
        <div className={classes.data}>
          <DataTableHeaders headers={headers} />
          <div className={classes.info}>
            {selectedFeature && (
              <div className={classes.items}>
                <div className={classes["info-items"]}>
                  <div className={classes.additional}>Basic Info</div>
                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.featureName != null
                        ? selectedFeature.featureName
                        : "N/A"
                    }
                    header="Feature Name"
                    icon={featureImage}
                    convertFromBase64={false}
                  />
                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.featureTitle != null
                        ? selectedFeature.featureTitle
                        : "N/A"
                    }
                    header="Feature Title"
                    icon={
                      selectedFeature?.featureIcon != null
                        ? selectedFeature.featureIcon
                        : featureNameIcon
                    }
                    convertFromBase64={
                      selectedFeature?.featureIcon != null ? true : false
                    }
                  />
                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.size != null
                        ? `${selectedFeature?.size} ${selectedFeature?.unit}`
                        : "N/A"
                    }
                    header="Size"
                    icon={storage}
                  />
                  <TenantFeaturesInfoItem
                    text={selectedFeature?.version}
                    header="Latest Version"
                    icon={version}
                  />

                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.featureCategory != null
                        ? selectedFeature.featureCategory
                        : "N/A"
                    }
                    header="Category"
                    icon={categoryIcon}
                  />
                  <TenantFeaturesInfoItem
                    text={selectedFeature?.securedFeature ? "Yes" : "No"}
                    header="Secured"
                    icon={secured}
                  />
                  <TenantFeaturesInfoItem
                    text={selectedFeature?.isFeatureDownloadable ? "Yes" : "No"}
                    header="Downloadable Feature"
                    muiicon={
                      selectedFeature?.isFeatureDownloadable ? (
                        <FileDownloadIcon htmlColor="#649EE6" />
                      ) : (
                        <FileDownloadOffIcon htmlColor="#649EE6" />
                      )
                    }
                  />
                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.label != null
                        ? selectedFeature.label
                        : "N/A"
                    }
                    header="Labels"
                    icon={null}
                  />
                  <About text={selectedFeature?.featureDesc} header="About" />
                  <div className={classes.additional}>Feature Terms</div>

                  <TenantFeaturesInfoItem
                    chipsets={selectedFeature?.featurePolicyTerm?.chipset}
                    header="Allowed Chipsets"
                    muiicon={<DeveloperBoardIcon htmlColor="#4080d0" />}
                  />
                  <TenantFeaturesInfoItem
                    text={
                      selectedFeature?.featurePolicyTerm?.access != null
                        ? selectedFeature.featurePolicyTerm.access
                        : "N/A"
                    }
                    header="Access Type"
                    icon={accessStatusIcon}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.licence}>*Licence Terms & Conditions</div>
        </div>
      </div>
    </Fragment>
  );
};

export default TenantFeaturesInfo;
