export const INITIAL_DEVICE_AND_LICENSE_FILTER_FORM_STATE = {
  orgName: "",
  tenant: "",
  feature: "",
  subscriptionId: "",
  deviceId: "",
  installationStartDate: "",
  installationEndDate: "",
};
export const INITIAL_NOTIFICATIONS_FORM_STATE = {
  orgName: "",
  tenant: "",
  notificationName: [],
  status: "",
};
