import { editDeviceTenantApi } from "./apiEndpoints";
import axios from "axios";
export const editDeviceTenantId = async (options) => {
  let data;
  await axios
    .patch(
      editDeviceTenantApi + options?.tenantId,

      options?.allDeviceId
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
