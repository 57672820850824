import { Fragment, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import CancelButton from "../../ui/Buttons/CancelButton";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CommonHeader from "../../ui/CommonHeader";
import { CustomDataGrid } from "../../ui/CustomDataGrid";
import { ExpiryDateFormatter } from "../../../components/helpers/utils";
import errorHandler from "../../../components/helpers/error-helper";
import {
  GET_MESSAGES,
  UPDATE_PREFERRED_VERSION,
  UPDATE_SDK_PREFERRED_VERSION,
} from "../../../constants/message-constants";

import classes from "./EditSDKPreference.module.css";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { CustomSelectField } from "../../ui/CustomSelectField";
import {
  createPreferredVersionForSDK,
  getAllSDKVersionList,
  getPreferredVersionForSDKById,
} from "../../../api/api-index";
import { toast } from "react-toastify";
import LinuxAndroidSDKList from "./LinuxAndroidSDK";
let isClicked = true;
const EditSDKPreference = ({ onClose, details, getUpdatedDataFn }) => {
  const [updateType, setUpdateType] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [error, setError] = useState("");

  const [sdkVersion, setSdkVersion] = useState([]);
  const [copySdkVersion, setCopySdkVersion] = useState([]);

  function changeStatus(preferenceType, repoType, version) {
    let tempArray = [...sdkVersion];

    if (preferenceType != null) {
      var foundIndex = tempArray.findIndex(
        (el) => el.repositoryType === repoType
      );
      tempArray[foundIndex] = {
        ...tempArray[foundIndex],
        preferenceType: preferenceType,
        preferredVersion: version,
      };

      setError("");
      setSdkVersion(tempArray);
    } else {
      var foundIndex = tempArray.findIndex(
        (el) => el.repositoryType === repoType
      );
      tempArray[foundIndex] = {
        ...tempArray[foundIndex],
        preferredVersion: version,
      };

      setSdkVersion(tempArray);
    }
  }

  useEffect(() => {
    const payload = {
      customerId: details.id,
      repositoryType: null,
    };

    getPreferredVersionForSDKById(payload)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedData = response.data.map((item) => {
            return {
              ...item,
              prevSelectedVersion: item.preferredVersion,
              customerId: details.id,
            };
          });

          setSdkVersion(formattedData);
          setCopySdkVersion(formattedData);
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  function handleSubmit() {
    const isEmpty = sdkVersion.filter((i) => i.preferenceType === "NA");

    if (isClicked) {
      isClicked = false;
      if (isEmpty.length <= 1) {
        const payload = sdkVersion.map((item) => {
          return {
            customerId: item.customerId,
            preferredVersion: item.preferredVersion || "0.0.0",
            preferenceType:
              item.preferenceType === "NA" ? "NA" : item.preferenceType,
            repositoryType: item.repositoryType,
          };
        });

        createPreferredVersionForSDK(payload)
          .then((response) => {
            if (response.status && response.data != null) {
              toast.success(UPDATE_SDK_PREFERRED_VERSION.success);
              onClose();
              getUpdatedDataFn && getUpdatedDataFn();
            } else {
              errorHandler(
                response.error,
                UPDATE_SDK_PREFERRED_VERSION.failure
              );
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, UPDATE_SDK_PREFERRED_VERSION.failure);
          })
          .finally(() => (isClicked = true));
      } else {
        setError("Preference type cannot be N/A for both Linux and Andoid");
        isClicked = true;
      }
    }
  }

  return (
    <>
      <CommonHeader heading={`QSEA-SDK Management`} onClose={onClose} />
      <div className={classes.multiColumns}>
        {sdkVersion.map((item) => {
          return (
            <Fragment>
              <LinuxAndroidSDKList
                tableHeader={item.repositoryType}
                updateType={item.preferenceType}
                error={error}
                selectedVersion={item.preferredVersion}
                changeStatus={changeStatus}
                setSelectedVersion={setSelectedVersion}
                prevSelectedVersion={item.prevSelectedVersion}
                tenantId={details.id}
              />
            </Fragment>
          );
        })}
      </div>
      <FormHelperText error={true} color="red">
        <i>{error}</i>
      </FormHelperText>
      <div className={classes.buttons}>
        <CancelButton onClick={onClose} text={"Cancel"} />
        <SubmitButton
          disabled={
            JSON.stringify(sdkVersion) === JSON.stringify(copySdkVersion)
          }
          text={"Submit"}
          onClick={handleSubmit}
          type={"submit"}
        />
      </div>
    </>
  );
};
export default EditSDKPreference;
