import zyter from "../../assets/images/menu/zyter.svg";

import classes from "./CatalogLayout.module.css";

const CatalogLayout = (props) => {
  return (
    <div className={classes.main}>
      <div className={classes.container}> {props.children}</div>

      <div className={classes.footer}>
        <span>Powered by</span>
        <img src={zyter} alt="powered by zyter" />
        <span>
          <strong style={{ margin: "0 0.5em" }}>|</strong> &copy; 2022{" "}
          <strong style={{ margin: "0 0.5em" }}>|</strong> All Rights Reserved
        </span>
      </div>
    </div>
  );
};
export default CatalogLayout;
