import { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import CustomPagination from "../../ui/CustomPagination";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { ExpiryDateFormatter } from "../../helpers/utils";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants"
import classes from "../../ui/DataTable.module.css";
import styles from "./LicenseTable.module.css";

const LicenseTable = ({ featureData = [], isLoading }) => {
  const rowData = featureData.map((item) => ({
    id: uuidv4(),
    subscriptionId: item?.subscriptionId || "N/A",
    deviceID: item?.deviceId || "N/A",
    installationDate: ExpiryDateFormatter(item?.installationDate),
    policyName: item?.policyName,
  }));

  const columns = [
    {
      field: "policyName",
      headerName: "Policy Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "deviceID",
      headerName: "Device ID",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "installationDate",
      headerName: "Installation Date",
      minWidth: 100,
      flex: 1,
    },
  ];

  return (
    <Fragment>
      <div className={classes.container}>
        {/* <span className={styles.header}>Features Enabled</span> */}
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            hideFooterPagination
            hideFooter
            columns={columns}
            rows={rowData || []}
            disableColumnFilter
            disableColumnMenu
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              //   pagination: {
              //     total: featureDataList.length || 0,
              //     handlePageChange: handlePageChange,
              //     page: pageNo,
              //   },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.devicesAndLicenses },

              panel: {
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LicenseTable;
