import { Fragment, useEffect, useState } from "react";
import { Chip } from "@mui/material";

import { CustomDataGrid } from "../ui/CustomDataGrid";
import CustomPagination from "../ui/CustomPagination";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import featureIcon from "../../assets/images/features/feature-icon.svg";
import PolicyTerms from "../policy/PolicyTerms/PolicyTerms";
import { getMyModelList } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import { ExpiryDateFormatter } from "../helpers/utils";
import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";

import classes from "../ui/DataTable.module.css";
import styles from "./FeatureEnabledList.module.css";

let selectedRow = null;
const DeviceFeatureEnabledList = ({ customerId, tenantId }) => {
  const [isOpenPolicyTerms, setIsOpenPolicyTerms] = useState(false);
  const [featureDataList, setfeatureDataList] = useState({
    count: 0,
    list: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const [pageNo, setPageNo] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };
  const handlePolicy = (params) => {
    selectedRow = params.row;
    setIsOpenPolicyTerms(true);
  };
  const columns = [
    {
      field: "featureTitle",
      headerName: "Feature Title",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <img src={featureIcon} alt="icon" />

          {params.value}
        </div>
      ),
    },
    {
      field: "preferredVersion",
      headerName: "Preferred Version",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "policyId",
      headerName: "Policy ID",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params?.value.map((item) => {
              if (item.status !== "INACTIVE") {
                return (
                  <span
                    key={item.extPolicyId}
                    className={`${styles.status} ${styles.active}`}
                  >
                    {item.extPolicyId}
                  </span>
                );
              } else {
                return (
                  <span className={styles.status} key={item.extPolicyId}>
                    {item.extPolicyId}
                  </span>
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  hasPermittedRolesOrAttributes([
    ROLES.isvAdmin,
    ROLES.isvMonitor,
    ROLES.cssAdmin,
  ]) &&
    columns.push({
      field: "policyTerms",
      headerName: "",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          sx={{
            fontFamily: "QualcommNext-Regular",
            backgroundColor: "#7C9EC5",
          }}
          color="primary"
          label="Policy Terms"
          onClick={() => handlePolicy(params)}
        />
      ),
    });
  const closePolicyTerms = () => {
    setIsOpenPolicyTerms(false);
  };

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      customerId: customerId,
      isvId: tenantId,
    };

    getMyModelList(options)
      .then((response) => {
        if (
          response?.status &&
          response.data.count != null &&
          response?.data?.featureDetailsList != null
        ) {
          const formattedList = response.data.featureDetailsList.map((item) => {
            return {
              id: item.featureId,
              policyId: item.featurePolicyTerm || "-",
              featureTitle: item.featureTitle,
              featureName: item.featureName,
              preferredVersion: item?.preferredVersion || "-",
            };
          });

          setfeatureDataList({
            count: response?.data?.count,
            list: formattedList,
          });
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo]);

  return (
    <Fragment>
      {isOpenPolicyTerms && (
        <PolicyTerms selectedFeature={selectedRow} onClose={closePolicyTerms} />
      )}

      <div className={classes.container}>
        <span className={styles.header}>Features</span>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={featureDataList.list}
            disableColumnFilter
            disableColumnMenu
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: featureDataList.count || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },

              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.features },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DeviceFeatureEnabledList;
