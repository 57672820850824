import { createSlice } from "@reduxjs/toolkit";

const initialColumnsState = {
  dashboard: {},
  organizations: {},
  users: {},
  availableUsers: {},
  customerTenants: {},
  isvTenants: {},
  isvFeatures: {},
  isvUsers: {},
  isvSubscriber: {},
  isvVersionHistory: {},
  isLinuxHistory: {},
  isAndroidHistory: {},

  //
  isvFeatureSubscriber: {},
  customerDevices: {},
  customerViewDevices: {},
  customerUsers: {},
  customerFeatures: {},
  customerAvailableDevices: {},
  subscriptionPolicy: {},
  featureOwnership: {},
  localRepo: {},
  globalRepo: {},
  //
  associatedDevice: {},
  mddList: {},
};

const columnsSlice = createSlice({
  name: "columns",
  initialState: initialColumnsState,
  reducers: {
    setdashboardColumnsState(state, action) {
      state.dashboard = action.payload;
    },
    setorganizationsColumnsState(state, action) {
      state.organizations = action.payload;
    },
    setusersColumnsState(state, action) {
      state.users = action.payload;
    },
    setavailableUsersColumnsState(state, action) {
      state.availableUsers = action.payload;
    },
    setcustomerTenantsColumnsState(state, action) {
      state.customerTenants = action.payload;
    },
    setisvTenantsColumnsState(state, action) {
      state.isvTenants = action.payload;
    },
    setisvFeaturesColumnsState(state, action) {
      state.isvFeatures = action.payload;
    },
    setisvUsersColumnsState(state, action) {
      state.isvUsers = action.payload;
    },
    setisvSubscriberColumnsState(state, action) {
      state.isvSubscriber = action.payload;
    },
    setisvVersionHistoryColumnsState(state, action) {
      state.isvVersionHistory = action.payload;
    },
    setSdkLinuxColumnsState(state, action) {
      state.isLinuxHistory = action.payload;
    },
    setSdkAndroidColumnsState(state, action) {
      state.isAndroidHistory = action.payload;
    },
    setisvFeatureSubscriberColumnsState(state, action) {
      state.isvFeatureSubscriber = action.payload;
    },
    setcustomerDevicesColumnsState(state, action) {
      state.customerDevices = action.payload;
    },
    setcustomerViewDevicesColumnsState(state, action) {
      state.customerViewDevices = action.payload;
    },
    // setcustomerViewDevicesColumnsState(state, action) {
    //   state.customerViewDevices = action.payload;
    // }, ------repetition
    setcustomerUsersColumnsState(state, action) {
      state.customerUsers = action.payload;
    },
    setcustomerFeaturesColumnsState(state, action) {
      state.customerFeatures = action.payload;
    },
    setcustomerAvailableDevicesColumnsState(state, action) {
      state.customerAvailableDevices = action.payload;
    },
    setsubscriptionPolicyColumnsState(state, action) {
      state.subscriptionPolicy = action.payload;
    },
    setfeatureOwnershipColumnsState(state, action) {
      state.featureOwnership = action.payload;
    },
    setlocalRepoColumnsState(state, action) {
      state.localRepo = action.payload;
    },
    setglobalRepoColumnsState(state, action) {
      state.globalRepo = action.payload;
    },
    setAssociatedDevice(state, action) {
      state.associatedDevice = action.payload;
    },
    setAssociatedDevice(state, action) {
      state.associatedDevice = action.payload;
    },
    setMddList(state, action) {
      state.mddList = action.payload;
    },
  },
});
const columnsActions = columnsSlice.actions;
export { columnsSlice, initialColumnsState, columnsActions };
