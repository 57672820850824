import { Navigate, Route, Routes } from "react-router-dom";
import {
  COMMON_ROUTES,
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../constants/route-constants";
import {
  TenantISVsPage,
  TenantFeaturesPage,
  TenantISVUsersPage,
  TenantFeaturesInfoPage,
  TenantFeaturesVersionHistoryPage,
  TenantISVAvailableUsersPage,
  TenantCustomerFeatureSubscribersPage,
  UserSettingsPage,
  AlertsPage,
  EventsPage,
  ArchivedAlertsPage,
  ArchivedEventsPage,
  ISVSubscribersPage,
  ISVSubscriberInfoPage,
  ISVFeatureContent,
} from "../pages/page-index";
const ISVRoutes = ({ organizationId, selectedTenantId }) => {
  return (
    <Routes>
      <Route path={TENANTS_ROUTES.isvTenants} element={<TenantISVsPage />} />
      <Route
        path={TENANTS_ROUTES.isvTenantFeatures}
        element={<TenantFeaturesPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvTenantUsers}
        element={<TenantISVUsersPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvTenantFeaturesInfo}
        element={<TenantFeaturesInfoPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvTenantFeaturesVersionHistory}
        element={<TenantFeaturesVersionHistoryPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvTenantsAddAvailableUsers}
        element={<TenantISVAvailableUsersPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvTenantFeatureSubscribers}
        element={<TenantCustomerFeatureSubscribersPage />}
      />

      <Route path={COMMON_ROUTES.userSettings} element={<UserSettingsPage />} />
      <Route
        path={COMMON_ROUTES.notificationsAlerts}
        element={<AlertsPage />}
      />
      <Route
        path={COMMON_ROUTES.notificationsEvents}
        element={<EventsPage />}
      />
      <Route
        path={COMMON_ROUTES.archiveNotificationsAlerts}
        element={<ArchivedAlertsPage />}
      />
      <Route
        path={COMMON_ROUTES.archiveNotificationsEvents}
        element={<ArchivedEventsPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvSubscribers}
        element={<ISVSubscribersPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvSubscribersInfo}
        element={<ISVSubscriberInfoPage />}
      />
      <Route
        path={TENANTS_ROUTES.isvContents}
        element={<ISVFeatureContent />}
      />

      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`${CSS_ROUTES.organizations}/${organizationId}${PARTIAL_ROUTES.isvTenants}/${selectedTenantId}${PARTIAL_ROUTES.myFeatures}`}
          />
        }
      />
    </Routes>
  );
};
export default ISVRoutes;
