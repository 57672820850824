import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Badge, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import DataTableHeaders from "../header/DataTableHeaders";
import { panelStyles, CustomDataGrid } from "../ui/CustomDataGrid";
import { notificationHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import {
  deleteNotifications,
  getAllNotifications,
  archiveNotifications,
  getAllNotificationNames,
  editNotification,
} from "../../api/api-index";
import { PAGE_SIZE, PAGE_NO } from "../../constants/page-constants";
import { COMMON_ROUTES } from "../../constants/route-constants";
import { BUTTONS_TYPE } from "../../constants/panel-constants";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import errorHandler from "../helpers/error-helper";
import {
  ARCHIVE_NOTIFICATION_MESSAGES,
  DELETE_NOTIFICATION_MESSAGES,
  GET_MESSAGES,
} from "../../constants/message-constants";
import HandledButton from "../ui/Buttons/HandledButton";
import MarkUnreadButton from "../ui/Buttons/MarkUnreadButton";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import ArchiveButton from "../ui/Buttons/ArchiveButton";
import FilterButton from "../ui/Buttons/FilterButton";
import { searchTenantName, getOrgByName } from "../../api/api-index";
import Filter from "../ui/Form/Filter/Filter";
import { INITIAL_NOTIFICATIONS_FORM_STATE } from "../../constants/form-states";
import { DateFormatter } from "../helpers/utils";
import { NOTIFICATION_STATUS } from "../../constants/notifications-constants";
import ClearIcon from "../ui/Buttons/ClearAllButton";
import KeycloakConfig from "../../config/keycloak-config";
import useNotificationTrigger from "../../hooks/useNotificationTrigger";

import classes from "../ui/DataTable.module.css";
import styles from "./Alerts.module.css";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";

const Alerts = () => {
  const navigate = useNavigate();
  const { triggerCheck } = useNotificationTrigger();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
    reset,
  } = useForm();
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const selectedRole = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.roles[0]
  );
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);

  const defaultValue = {
    pageNo: 0,
    pageSize: PAGE_SIZE,
    notificationType: "ALERT",
    tenantId: selectedAppType != "CSS" ? selectedAppKey : null,
    role: selectedRole,
  };
  const [options, setOptions] = useState(defaultValue);
  const [typeFilter, setTypeFilter] = useState(false);
  const [ForceUpdate, setForceUpdate] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [notificationList, setnotificationList] = useState({
    count: 0,
    list: [],
  });
  const [filteredList, setFilteredList] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState({ ids: [], rows: [] });

  const [searchOrganizationValue, setOrganizationValue] = useState(null);
  const [tenantInputValue, setTenantInputValue] = useState(null);

  const [OrgOptions, setOrgOptions] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [notificationNamesOptions, setnotificationNamesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const orgWatch = watch("orgName");
  const handlePageChange = (_, newPage) => {
    setOptions({ ...options, pageNo: newPage - 1 });
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleClear = () => {
    if (isDirty) {
      setOptions(defaultValue);
      reset(INITIAL_NOTIFICATIONS_FORM_STATE);
    }
    setTypeFilter(false);
  };

  const columns = [
    {
      field: "content",
      headerName: "Select All",
      flex: 1,
      sortable: false,
      renderCell: (param) => {
        const {
          value: {
            notificationDesc,
            isRead,
            handledBy,
            createdOn,
            organizationId,
            tenantId,
            notificationName,
          },
        } = param;
        return (
          <div
            className={styles.notification_container}
            onClick={() =>
              triggerCheck(notificationName, organizationId, tenantId)
            }
          >
            <div className={styles.row_one}>{notificationDesc}</div>
            <div className={styles.row_two}>
              {
                <i>
                  {handledBy != null && <span> Handled by - {handledBy}</span>}
                </i>
              }
              <div>{DateFormatter(createdOn)}</div>
            </div>
          </div>
        );
      },
    },
  ];

  const headers = notificationHeaders({
    routes: {
      alerts: COMMON_ROUTES.notificationsAlerts,
      events: COMMON_ROUTES.notificationsEvents,
    },
  });

  useEffect(() => {
    if (searchOrganizationValue != null && searchOrganizationValue !== "") {
      let option = {
        name: searchOrganizationValue,
        pageNo: PAGE_NO,
        pageSize: PAGE_SIZE,
      };
      getOrgByName(option).then((response) => {
        if (response?.status) {
          const newOrgValues = response.data.organizations.map((name) => {
            return { label: name.name, id: name.organizationId };
          });
          setOrgOptions(newOrgValues);
        } else {
          setOrgOptions([]);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      });
    } else {
      setOrgOptions([]);
    }
  }, [searchOrganizationValue]);

  useEffect(() => {
    if (tenantInputValue != null && tenantInputValue !== "") {
      // Api call
      const payload = {
        tenantName: tenantInputValue,
        organizationId: orgWatch.id,
        pageNo: 0,
        pageSize: 1000,
      };
      searchTenantName(payload).then((response) => {
        if (response?.status && response?.data?.tenants?.length > 0) {
          const formattedTenantName = response?.data?.tenants.map((item) => ({
            label: item.tenantName,
            value: item.tenantId,
          }));
          setTenantOptions(formattedTenantName);
        } else {
          setTenantOptions([]);
        }
      });
    } else {
      setTenantOptions([]);
    }
  }, [tenantInputValue]);
  useEffect(() => {
    const options = {
      role: selectedRole,
      notificationType: "ALERT",
    };
    getAllNotificationNames(options)
      .then((response) => {
        if (response?.status && response?.data != null) {
          const formattedList =
            response.data.map((item) => item.notificationName) || [];

          setnotificationNamesOptions(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);
  useEffect(() => {
    getAllNotifications(options)
      .then((response) => {
        if (response?.status && response?.data != null) {
          const formattedList =
            response.data.notificationList.map((item) => {
              return {
                id: item.notificationId,
                content: item,
              };
            }) || [];
          setnotificationList({
            count: response?.data?.notificationCount,
            list: formattedList,
          });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [options, ForceUpdate]);

  const handledAlert = () => {
    editNotification(
      selectedAlerts.rows.map((item) => {
        return {
          ...item.content,
          isRead: true,
          status: "HANDLED",
          handledBy: KeycloakConfig.getUserId(),
        };
      })
    ).then(() => {
      setForceUpdate({});
      setSelectedAlerts({ ids: [], rows: [] });
    });
  };
  const handleUnread = () => {
    editNotification(
      selectedAlerts.rows.map((item) => {
        return {
          ...item.content,
          isRead: false,
        };
      })
    ).then(() => {
      setForceUpdate({});
      setSelectedAlerts({ ids: [], rows: [] });
    });
  };
  const handleDelete = () => {
    deleteNotifications({ notificationIdList: selectedAlerts.ids }).then(
      (response) => {
        if (response.status) {
          toast.success(DELETE_NOTIFICATION_MESSAGES.success);
          setForceUpdate({});
        } else {
          toast.error(DELETE_NOTIFICATION_MESSAGES.failure);
        }
      }
    );
  };
  const handleArchive = () => {
    archiveNotifications({ notificationIdList: selectedAlerts.ids }).then(
      (response) => {
        if (response.status) {
          toast.success(ARCHIVE_NOTIFICATION_MESSAGES.success);
          setForceUpdate({});
        } else {
          toast.error(ARCHIVE_NOTIFICATION_MESSAGES.failure);
        }
      }
    );
  };
  const handleFilter = () => {
    const selectedType = selectedAlerts.rows.map(
      (item) => item.content.notificationName
    );
    reset(INITIAL_NOTIFICATIONS_FORM_STATE);
    setTypeFilter(true);
    setOptions({
      ...defaultValue,
      notificationName: selectedType,
    });
  };
  const handleRead = () => {
    editNotification(
      notificationList.list.map((item) => {
        return {
          ...item.content,
          isRead: true,
        };
      })
    ).then(() => setForceUpdate({}));
  };

  const submitHandler = (data) => {
    setOptions({
      ...defaultValue,
      organizationId: data.orgName?.id || undefined,
      tenantId: data.tenant?.value
        ? data.tenant.value
        : selectedAppType != "CSS"
        ? selectedAppKey
        : null,
      status: NOTIFICATION_STATUS[data.status],
      notificationName:
        data.notificationName.length > 0 ? data.notificationName : undefined,
    });

    handleOpenFilter();
    setTypeFilter(false);
  };
  const buttons =
    selectedAlerts.ids.length === 0
      ? [
          {
            key: "filter",
            text: isDirty ? (
              <Badge color="error" variant="dot">
                <FilterListSharpIcon />
              </Badge>
            ) : (
              <FilterListSharpIcon />
            ),
            clickHandler: handleOpenFilter,
            disabled: false,
            icon: null,
            type: BUTTONS_TYPE.square,
          },
        ]
      : null;

  const items = [
    {
      name: "notificationName",
      label: "Notification Name",
      placeholder: "Select Notification Name",
      multiselect: true,
      select: true,
      options: notificationNamesOptions,
      defaultValue: INITIAL_NOTIFICATIONS_FORM_STATE.notificationName,
    },
    {
      name: "status",
      label: "Status",
      placeholder: "Select Status",
      select: true,
      options: Object.keys(NOTIFICATION_STATUS),
      defaultValue: INITIAL_NOTIFICATIONS_FORM_STATE.status,
    },
  ];

  const formattedItems =
    selectedAppType === "CSS"
      ? [
          {
            apiCall: true,
            name: "orgName",
            options: OrgOptions,
            setInputChange: setOrganizationValue,
            label: "Organization Name",
            placeholder: "Enter organization name",
            defaultValue: INITIAL_NOTIFICATIONS_FORM_STATE.orgName,
          },
          {
            apiCall: true,
            name: "tenant",
            options: tenantOptions,
            setInputChange: setTenantInputValue,
            label: "Tenant Name",
            placeholder: "Enter tenant name",
            defaultValue: INITIAL_NOTIFICATIONS_FORM_STATE.tenant,
          },
          ...items,
        ]
      : items;

  return (
    <Fragment>
      <div className={classes.container}>
        {openFilter && (
          <Filter
            items={formattedItems}
            onClose={handleCloseFilter}
            onSubmit={submitHandler}
            handleSubmit={handleSubmit}
            control={control}
            isDirty={isDirty}
            onClear={handleClear}
            watch={watch}
          />
        )}
        <DataTablePanelTest
          backButton={false}
          disableSearch={true}
          buttons={buttons}
        />
        <p>Notifications</p>
        <div className={classes.data}>
          <CustomDataGrid
            // sx={{
            //   "&.MuiDataGrid-root .MuiDataGrid-cell": {
            //     whiteSpace: "normal",
            //   },

            //   ".MuiDataGrid-columnHeader": {
            //     marginLeft: "0.5em",
            //   },
            // }}
            sx={{
              ".MuiDataGrid-columnHeader": {
                marginLeft: "0.5em",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            checkboxSelection
            hideFooterSelectedRowCount
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = notificationList.list.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedAlerts({ ids: ids, rows: selectedRows });
            }}
            selectionModel={selectedAlerts.ids}
            getCellClassName={(params) => {
              if (!params.row.content.isRead) {
                return "unread";
              }
              return "read";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  notificationList.list.length === filteredList.length
                    ? notificationList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: options.pageNo,
                dynamicName: "Notifications",
              },
              toolbar: {
                children: <DataTableHeaders headers={headers} />,
                icons:
                  selectedAlerts?.ids.length === 0 ? (
                    <div>
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={() =>
                          navigate(COMMON_ROUTES.archiveNotificationsAlerts)
                        }
                      >
                        View archived
                      </Button>
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => handleRead()}
                      >
                        Mark all as read
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.buttonList}>
                      <HandledButton size="small" onClick={handledAlert} />
                      <MarkUnreadButton size="small" onClick={handleUnread} />
                      <DeleteIcon
                        size="small"
                        color="rgb(33, 69, 114)"
                        onClick={handleDelete}
                      />
                      <ArchiveButton size="small" onClick={handleArchive} />
                      {typeFilter ? (
                        <ClearIcon
                          color="rgb(33, 69, 114)"
                          size="small"
                          onClick={() => {
                            setOptions(defaultValue);
                            setTypeFilter(false);
                            setSelectedAlerts({ ids: [], rows: [] });
                          }}
                        />
                      ) : (
                        <FilterButton size="small" onClick={handleFilter} />
                      )}
                    </div>
                  ),
              },
              panel: {
                sx: panelStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.alerts,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Alerts;
