import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../../../constants/route-constants";
import DataTableHeaders from "../../../header/DataTableHeaders";
import { repositoryInfoHeaders } from "../../../helpers/header-helpers";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";
import secured from "../../../../assets/images/features/secured-status.svg";
import storage from "../../../../assets/images/features/storage-quota.svg";
import version from "../../../../assets/images/features/latest-version.svg";
import featureNameIcon from "../../../../assets/images/features/default-feature-name.svg";
import orgIcon from "../../../../assets/images/features/org.svg";
import tenantIcon from "../../../../assets/images/features/tenant.svg";
import GlobalRepoInfoItem from "./GlobalRepoInfoItem";
import { REPO_TYPE } from "../../../../constants/repo-constants";
import { getAllRepoHistory, getFeatureIcon } from "../../../../api/api-index";
import errorHandler from "../../../helpers/error-helper";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import StatusTable from "../localRepo/StatusTable";
import GlobalStatusTable from "./GlobalStatusTable";
import { StyledLink } from "../../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../../hooks/useBreadcrumb";

import classes from "../localRepo/LocalRepoInfo.module.css";

const GlobalRepoInfo = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("globalRepoInfo");
  const accordionStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    height: "100%",
    width: "100%",
  };

  const params = useParams();
  const [repoHistoryList, setrepoHistoryList] = useState({});

  const headers = repositoryInfoHeaders({
    routes: {
      repoInfo: CSS_ROUTES.globalRepositories + "/" + params.repoId,
    },
  });

  useEffect(() => {
    const options = {
      qcommFeatureName: params.repoId,
      repositoryType: REPO_TYPE.global,
    };

    getAllRepoHistory(options, params.repoId)
      .then((response) => {
        if (response.status && response.data != null) {
          const item = response.data;
          const formattedList = {
            organization: item?.organizationName,
            tenant: item?.tenantName,
            featureTitle: item?.featureTitle,
            secured: item?.securedFeature,
            latestVersion: item?.latestVersion,
            size: `${item.size} ${item.unit}`,
            featureIcon: item?.featureIcon,
            featureRepositoryDetailsMap: item.featureRepositoryDetailsMap,
          };

          setrepoHistoryList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  const versionArray = [];

  for (const property in repoHistoryList.featureRepositoryDetailsMap) {
    const version =
      repoHistoryList.featureRepositoryDetailsMap[property][0].version;
    versionArray.push(
      <Accordion key={property}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${property}-content`}
          id={`${property}-header`}
        >
          <p className={classes.headerVersion}>Version {version}</p>
        </AccordionSummary>
        <AccordionDetails sx={accordionStyles}>
          <div style={{ flexGrow: 1 }}>
            <GlobalStatusTable
              list={repoHistoryList.featureRepositoryDetailsMap[property]}
              handleClick={null}
              handleGlobal={null}
              version={version}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={classes.container}>
      <DataTablePanelTest backButton={true} disableSearch={true} />
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{repoHistoryList?.featureTitle || "..."}</p>
      </StyledBreadcrumbs>

      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        <div className={classes.info}>
          <div className={classes.info_items}>
            <GlobalRepoInfoItem
              text={repoHistoryList.organization}
              header="Organization"
              icon={orgIcon}
            />
            <div>
              <GlobalRepoInfoItem
                text={repoHistoryList.tenant}
                header="Tenant Name"
                icon={tenantIcon}
              />
            </div>
            <GlobalRepoInfoItem
              text={repoHistoryList.featureTitle}
              header="Feature Title"
              icon={
                repoHistoryList?.featureIcon != null
                  ? repoHistoryList.featureIcon
                  : featureNameIcon
              }
              convertFromBase64={
                repoHistoryList?.featureIcon != null ? true : null
              }
            />
            <GlobalRepoInfoItem
              text={repoHistoryList.secured ? "Yes" : "No"}
              header="Secured"
              icon={secured}
            />
            <GlobalRepoInfoItem
              text={repoHistoryList.latestVersion}
              header="Latest Version"
              icon={version}
            />
            <GlobalRepoInfoItem
              text={repoHistoryList.size}
              header="Size"
              icon={storage}
            />
            <div className={classes.feature_Table}>{versionArray}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GlobalRepoInfo;
