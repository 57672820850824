import { MenuItem, Select } from "@mui/material";

import { FEATURE_CATEGORIES } from "../../../constants/feature-constants";
import { StyledFormControlForMultiselect } from "../../ui/StyledFormControlForMultiselect";

import classes from "../Catalog.module.css";

const CategoryFilter = ({ category, handleChange, disabled }) => {
  return (
    <div className={classes.filter}>
      Filter by category
      <StyledFormControlForMultiselect
        size="small"
        sx={{ m: 1, minWidth: 120 }}
      >
        <Select
          disabled={disabled}
          labelId="category-select"
          id="simple-select"
          value={category}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>All</em>;
            }

            return selected;
          }}
        >
          <MenuItem sx={{ fontFamily: "QualcommNext-Regular" }} value="All">
            <em>All</em>
          </MenuItem>
          {FEATURE_CATEGORIES.map((name) => (
            <MenuItem
              sx={{ fontFamily: "QualcommNext-Regular" }}
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControlForMultiselect>
    </div>
  );
};
export default CategoryFilter;
