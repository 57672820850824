import IconButton from "@mui/material/IconButton";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { styled } from "@mui/material";

const CustomIconButton = styled(IconButton)(() => ({
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#1a407133",
  },
  textTransform: "var(--mui-button-text-transform)",
  borderRadius: "3px",
  fontFamily: "QualcommNext-Regular",
  border: "1px solid #99999944",
  padding: "0",
  marginLeft: "0.5em",
}));

const ActiveCustomIconButton = styled(IconButton)(() => ({
  backgroundColor: "#214572",
  "&:hover": {
    backgroundColor: "#214572",
  },
  textTransform: "var(--mui-button-text-transform)",
  borderRadius: "3px",
  fontFamily: "QualcommNext-Regular",
  border: "1px solid #99999944",
  padding: "0",
  marginLeft: "0.5em",
  transform: "rotate(90deg)",
}));

const RightBottomChevronIcon = (props) => {
  const { onClick, active } = props;
  return active ? (
    <ActiveCustomIconButton
      aria-label="edit"
      size="large"
      onClick={onClick != null ? onClick : null}
    >
      <ChevronRightRoundedIcon htmlColor="#FFFFFF" />
    </ActiveCustomIconButton>
  ) : (
    <CustomIconButton aria-label="edit" size="large" onClick={onClick != null ? onClick : null}>
      <ChevronRightRoundedIcon htmlColor="#5677A0" />
    </CustomIconButton>
  );
};

export default RightBottomChevronIcon;
