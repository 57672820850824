import { Fragment, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import FullscreenSharpIcon from "@mui/icons-material/FullscreenSharp";
import { toast } from "react-toastify";
import { Box } from "@mui/system";

import noPreview from "../../assets/images/common/no-preview.svg";
import qLogo from "../../assets/images/appbar/qcloud.svg";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import ViewImage from "./viewImage/ViewImage";
import CatalogContent from "./CatalogContent";
import CatalogLayout from "./CatalogLayout";
import {
  createNotification,
  getCatalogInfo,
  getTenantsById,
  getPolicyInfoByFeatureId,
  searchFeatureNameAndId,
  getCustomerFeatureInfo,
} from "../../api/api-index";
import { GET_MESSAGES } from "../../constants/message-constants";
import errorHandler from "../helpers/error-helper";

import classes from "./CatalogInfo.module.css";

let selectedImage = null;
let selectedTenant = null;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.data}
      role="tabpanel"
      hidden={value !== index}
      id={`catalog-tabpanel-${index}`}
      aria-labelledby={`catalog-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

const CatalogInfo = () => {
  const imageArray = [];
  const [loading, setloading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [truncateValue, setTruncateValue] = useState(20);
  const params = useParams();
  const tabStyles = {
    fontFamily: "QualcommNext-Medium",
    fontSize: "clamp(1rem, 0.9088rem + 0.1351vw, 1.125rem)",
    textTransform: "capitalize",
    "&.MuiTab-root.Mui-selected": {
      color: "#214572",
    },
  };
  const [value, setValue] = useState(0);
  const [infoData, setInfoData] = useState({});

  const [openfull, setOpenfull] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onCloseImage = () => {
    setOpenfull(false);
  };
  const handleOpenImage = (item, name) => {
    setOpenfull(true);
    selectedImage = { item, name };
  };

  const handleTrigger = (name) => {
    const options = {
      notificationName: name,
      tenantId: selectedTenant.tenantId,
      organizationId: selectedTenant.organizationId,
      parameters: [selectedTenant.tenantName, infoData.featureName],
    };

    createNotification(options).then((response) => {
      if (response.status) toast.success("Request sent successfully");
    });
  };
  useEffect(() => {
    getCatalogInfo(params.featureName)
      .then((response) => {
        if (response.status && response.data != null) {
          setInfoData(response.data);
          setloading(false);
        } else {
          console.log(response.error);
          setloading(false);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  // useEffect(() => {
  //   const options = {
  //     tenantId: infoData.isvId,
  //   };
  //   if (!disabled)
  //     getTenantsById(options).then((response) => {
  //       if (response.status && response.data != null) {
  //         selectedTenant = response.data.organizationId;
  //       }
  //     });
  // }, [disabled]);

  useEffect(() => {
    selectedTenant = JSON.parse(localStorage.getItem("selectedTenant"));
    if (infoData.featureName) {
      if (selectedTenant.appType === "CUSTOMER") {
        const payload = {
          featureId: infoData.featureId,
          tenantId: selectedTenant.tenantId,
        };
        getCustomerFeatureInfo(payload).then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.featurePolicyTerm != null
          ) {
            const activePolicy = response.data.featurePolicyTerm.filter(
              (policy) => policy.status != "INACTIVE"
            );
            console.log(activePolicy);
            activePolicy.length === 0 && setDisabled(false);
          } else {
            setDisabled(false);
          }
        });
      } else {
        setDisabled(false);
      }
    }
  }, [infoData.featureName]);
  for (const property in infoData.images) {
    imageArray.push(
      <ImageList key={property} variant="masonry" cols={1} gap={20}>
        <ImageListItem
          key={property}
          cols={2}
          sx={{
            ".MuiImageListItemBar-root:hover": {
              opacity: 1,
              transition: "all 0.2s ease-in",
            },
          }}
        >
          <img
            src={`data:image/*;base64, ${infoData.images[property]}`}
            alt={property}
            loading="lazy"
          />
          <ImageListItemBar
            sx={{
              opacity: 0,
              height: "100%",
              alignItems: "flex-start",
            }}
            actionIcon={
              <Tooltip title="Large view" arrow>
                <IconButton
                  size="small"
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  onClick={() =>
                    handleOpenImage(infoData.images[property], property)
                  }
                >
                  <FullscreenSharpIcon htmlColor="#fff" />
                </IconButton>
              </Tooltip>
            }
          />
        </ImageListItem>
      </ImageList>
    );
  }

  return loading ? (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  ) : (
    <CatalogLayout>
      {openfull && (
        <ViewImage onClose={onCloseImage} selectedImage={selectedImage} />
      )}
      <DataTablePanelTest backButton={true} disableSearch={true} />
      <div style={{ overflowY: "auto" }}>
        <p className={classes.heading}>{infoData.featureTitle}</p>
        <div className={classes.feature}>
          <div className={classes.logo}>
            <img
              src={
                infoData.featureIcon != null
                  ? "data:image/*;base64," + infoData.featureIcon
                  : noPreview
              }
              alt="logo"
            />
          </div>
          <div className={classes.featureInfo}>
            <p className={classes.published}>Publisher - {infoData.isv}</p>
            <div>
              <p>
                <span className={classes.key}>Category - </span>
                {"  "}
                {infoData.featureCategory}{" "}
                <span>
                  (<span className={classes.key}>Version -</span>{" "}
                  {infoData.version})
                </span>
              </p>
            </div>
            <div>
              <span className={classes.key}>Descripition -</span>
              <span className={classes.desc}>
                {infoData?.featureDesc != null
                  ? infoData?.featureDesc
                      .trimEllip(truncateValue)
                      .replace("...", "")
                  : ""}
              </span>
              {infoData?.featureDesc &&
                infoData?.featureDesc.length > 20 &&
                (truncateValue === 20 ? (
                  <Button
                    size="small"
                    sx={{
                      "&:hover": {
                        textDecoration: "Underline",
                      },
                      fontFamily: "QualcommNext-Regular",
                      textDecoration: "Underline",
                      textTransform: "capitalize",
                      ml: 1,
                    }}
                    onClick={() => setTruncateValue(255)}
                  >
                    View More
                  </Button>
                ) : (
                  <Button
                    size="small"
                    sx={{
                      "&:hover": {
                        textDecoration: "Underline",
                      },
                      textDecoration: "Underline",
                      fontFamily: "QualcommNext-Regular",
                      textTransform: "capitalize",
                      ml: 1,
                    }}
                    onClick={() => setTruncateValue(20)}
                  >
                    View Less
                  </Button>
                ))}
            </div>

            {selectedTenant?.appType != "CSS" && (
              <div className={classes.buttonGroup}>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "QualcommNext-Regular",
                  }}
                  variant="contained"
                  disabled={disabled}
                  disableElevation
                  onClick={() => handleTrigger("Evaluate For Free")}
                  aria-label="subscribe"
                >
                  Evaluate
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "QualcommNext-Regular",
                  }}
                  variant="contained"
                  disabled={disabled}
                  disableElevation
                  onClick={() => handleTrigger("License")}
                  aria-label="license"
                >
                  License
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={classes.catalogContainer}>
          <Box sx={{ borderBottom: 1, borderColor: "#C4C4C4" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="catalog tabs"
            >
              <Tab sx={tabStyles} label="About" />
              <Tab sx={tabStyles} label="Contents" />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div className={classes.about}>
              {infoData.titleTextList != null ? (
                <>
                  <div className={classes.contentContainer}>
                    {infoData.titleTextList.map((item, key) => (
                      <div key={key} className={classes.column}>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    ))}
                  </div>
                  {Object.keys(infoData.images).length !== 0 && (
                    <div className={classes.imageContainer}>{imageArray}</div>
                  )}
                </>
              ) : (
                <div className={classes.noData}>No data found</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CatalogContent
              name={infoData.featureName}
              featureType={infoData.featureType}
            />
          </TabPanel>
        </div>
      </div>
    </CatalogLayout>
  );
};
export default CatalogInfo;
