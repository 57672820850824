import classes from "./NotificationContent.module.css";
const NotificationContent = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="notificationPanel"
      hidden={value !== index}
      id={`notificationPanel-${index}`}
      aria-labelledby={`notification-${index}`}
      {...other}
      className={classes.container}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};
export default NotificationContent;
