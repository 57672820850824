import { useNavigate } from "react-router-dom";
import { NOTIFICATION_NAME } from "../constants/notifications-constants";
const useNotificationTrigger = () => {
  const {
    ADD_USER_TO_TENANCY,
    FEATURE_DELETION_CSS,
    FEATURE_DELETION_ISV,
    EVALUATE_FOR_FREE,
    LICENSE,
    ISV_ADDED_FEATURE_TO_LOCAL,
    FEATURE_VALIDATED_BY_FEATURE_VALIDATOR,
    ISV_DELETED_FROM_LOCAL,
    CSS_DELETED_FROM_GLOBAL,
    ISV_VERIFIED_VERSION,
    ISV_DID_NOT_DELETE_FROM_LOCAL,
  } = NOTIFICATION_NAME;
  const navigate = useNavigate();
  const triggerCheck = (name, orgId, tenantId, featureId) => {
    switch (name) {
      case ADD_USER_TO_TENANCY:
        navigate(`/organizations/${orgId}/users`);
        break;
      case FEATURE_DELETION_CSS:
        navigate(`/organizations/${orgId}/isv-tenants/${tenantId}/my-features`);
        break;
      case FEATURE_DELETION_ISV:
        navigate(`/organizations/${orgId}/isv-tenants/${tenantId}/my-features`);
        break;
      case EVALUATE_FOR_FREE:
        navigate(`/subscription-policy`);
        break;
      case LICENSE:
        navigate(`/subscription-policy`);
        break;
      case ISV_ADDED_FEATURE_TO_LOCAL:
        // feature id and redux update:TODO/cancelled
        navigate(`/organizations/${orgId}/isv-tenants/${tenantId}/my-features`);
        break;
      case FEATURE_VALIDATED_BY_FEATURE_VALIDATOR:
        navigate(`/repositories/local`);
        break;
      case ISV_DELETED_FROM_LOCAL:
        navigate(`/repositories/global`);
        break;
      case CSS_DELETED_FROM_GLOBAL:
        navigate(`/organizations/${orgId}/isv-tenants/${tenantId}/my-features`);
        break;
      case ISV_VERIFIED_VERSION:
        navigate(
          `/organizations/${orgId}/customer-tenants/${tenantId}/features`
        );
        break;
      case ISV_DID_NOT_DELETE_FROM_LOCAL:
        navigate(`/repositories/local`);
        break;
      default:
        break;
    }
  };

  return { triggerCheck };
};
export default useNotificationTrigger;
