import DeleteIcon from "./Buttons/DeleteIcon";
import CloseIcon from "./Buttons/CloseIcon";

import classes from "./CommonHeader.module.css";
import EditIcon from "./Buttons/EditIcon";
import ClearIcon from "./Buttons/ClearAllButton";
import FeatureButton from "./Buttons/FeatureButton";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import { IconButton, Tooltip } from "@mui/material";
import EditIconButton from "./Buttons/EditIconButton";
const CommonHeader = (props) => {
  const { heading, onDelete, onClose, onEdit, onClear, onBatch, disabled } =
    props;
  return (
    <div className={classes.header}>
      <p>{heading}</p>
      <div className={classes.buttons}>
        {onEdit != null && <EditIconButton onClick={onEdit} />}
        {onClear != null && <ClearIcon disabled={disabled} onClick={onClear} />}
        {onDelete != null && <DeleteIcon onClick={onDelete} />}
        {onBatch != null && (
          <FeatureButton onClick={onBatch} label="Enable Batching">
            Apply to all
          </FeatureButton>
        )}
        {onClose == null ? <div></div> : <CloseIcon onClick={onClose} />}
      </div>
    </div>
  );
};

export default CommonHeader;
