import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  COMMON_ROUTES,
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../constants/route-constants";
import {
  TenantCustomersPage,
  CustomerDevicesPage,
  TenantCustomerUsersPage,
  CustomerTenantDeviceInfoPage,
  TenantCustomerAvailableUsersPage,
  CustomerFeaturesPage,
  CustomerTenantFeatureAvailableDevicesPage,
  CustomerFeatureInfoPage,
  UserSettingsPage,
  AlertsPage,
  EventsPage,
  ArchivedAlertsPage,
  ArchivedEventsPage,
  CustomerFeatureDeviceListPage,
  LocalRepositoriesPage,
  GlobalRepositoriesPage,
  LocalRepositoryInfoPage,
  GlobalRepositoryInfoPage,
  MDDPage,
  MDDInfo,
  AvailableDevicesForMddPage,
  AssociatedMddDevicesPage,
  MddDashboardPage,
  CustomerDeviceHealthMetricsPage,
  CustomerDeviceQSEAMetricsPage,
  CustomerManagedDevicePage,
} from "../pages/page-index";
const CustomerRoutes = ({
  organizationId,
  selectedTenantId,
  isQualcommManagingDevice,
}) => {
  const selectedDevice = useSelector(
    (state) => state.customerDeviceInfo.selectedDevice
  );
  return (
    <Routes>
      <Route
        path={TENANTS_ROUTES.customerTenants}
        element={<TenantCustomersPage />}
      />
      <Route
        path={TENANTS_ROUTES.customerTenantDevices}
        element={
          isQualcommManagingDevice ? (
            <CustomerDevicesPage />
          ) : (
            <CustomerManagedDevicePage />
          )
        }
      />
      <Route
        path={TENANTS_ROUTES.customerTenantUsers}
        element={<TenantCustomerUsersPage />}
      />
      {isQualcommManagingDevice && (
        <Route
          path={TENANTS_ROUTES.customerTenantDeviceInfo}
          element={<CustomerTenantDeviceInfoPage />}
        />
      )}
      {selectedDevice?.qualcommDeviceId && (
        <Route
          path={TENANTS_ROUTES.customerTenantDeviceHealthMetrics}
          element={<CustomerDeviceHealthMetricsPage />}
        />
      )}
      {selectedDevice?.qualcommDeviceId && (
        <Route
          path={TENANTS_ROUTES.customerTenantDeviceQSEAMetrics}
          element={<CustomerDeviceQSEAMetricsPage />}
        />
      )}
      <Route
        path={TENANTS_ROUTES.customerTenantAddAvailableUsers}
        element={<TenantCustomerAvailableUsersPage />}
      />
      {/* <Route
                path={TENANTS_ROUTES.customerTenantModels}
                element={<CustomerMyModelsPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantModelInfo}
                element={<CustomerContainerInfoPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantContainers}
                element={<CustomerMyContainersPage />}
              />

              <Route
                path={TENANTS_ROUTES.customerTenantContainerInfo}
                element={<CustomerContainerInfoPage />}
              />

              <Route
                path={TENANTS_ROUTES.customerTenantModelDevices}
                element={<CustomerFeatureDeviceListPage />}
              />
              <Route
                path={TENANTS_ROUTES.customerTenantContainerDevices}
                element={<CustomerFeatureDeviceListPage />}
              /> */}
      <Route
        path={TENANTS_ROUTES.customerTenantFeatures}
        element={<CustomerFeaturesPage />}
      />
      <Route
        path={TENANTS_ROUTES.customerTenantFeatureAvailableDevices}
        element={<CustomerTenantFeatureAvailableDevicesPage />}
      />
      <Route
        path={TENANTS_ROUTES.customerTenantFeatureInfo}
        element={<CustomerFeatureInfoPage />}
      />
      <Route
        path={TENANTS_ROUTES.customerTenantFeatureDevices}
        element={<CustomerFeatureDeviceListPage />}
      />
      <Route path={COMMON_ROUTES.userSettings} element={<UserSettingsPage />} />
      <Route
        path={COMMON_ROUTES.notificationsAlerts}
        element={<AlertsPage />}
      />
      <Route
        path={COMMON_ROUTES.notificationsEvents}
        element={<EventsPage />}
      />
      <Route
        path={COMMON_ROUTES.archiveNotificationsAlerts}
        element={<ArchivedAlertsPage />}
      />
      <Route
        path={COMMON_ROUTES.archiveNotificationsEvents}
        element={<ArchivedEventsPage />}
      />
      <Route
        path={CSS_ROUTES.repositories}
        element={<Navigate replace to={CSS_ROUTES.localRepositories} />}
      />
      <Route
        path={CSS_ROUTES.localRepositories}
        element={<LocalRepositoriesPage />}
      />
      <Route
        path={CSS_ROUTES.globalRepositories}
        element={<GlobalRepositoriesPage />}
      />
      <Route
        path={CSS_ROUTES.localRepositoryInfo}
        element={<LocalRepositoryInfoPage />}
      />
      <Route
        path={CSS_ROUTES.globalRepositoryInfo}
        element={<GlobalRepositoryInfoPage />}
      />
      <Route path={TENANTS_ROUTES.customerMdd} element={<MDDPage />} />
      <Route
        path={TENANTS_ROUTES.customerMddDashboard}
        element={<MddDashboardPage />}
      />
      <Route path={TENANTS_ROUTES.customerMddInfo} element={<MDDInfo />} />
      {isQualcommManagingDevice && (
        <Route
          path={TENANTS_ROUTES.availableDevicesForMdd}
          element={<AvailableDevicesForMddPage />}
        />
      )}
      {isQualcommManagingDevice && (
        <Route
          path={TENANTS_ROUTES.associatedDevicesForMdd}
          element={<AssociatedMddDevicesPage />}
        />
      )}
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`${CSS_ROUTES.organizations}/${organizationId}${PARTIAL_ROUTES.customerTenants}/${selectedTenantId}${PARTIAL_ROUTES.devices}`}
          />
        }
      />
    </Routes>
  );
};
export default CustomerRoutes;
