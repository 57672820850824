import { Button, Tooltip, Typography } from "@mui/material";
import { COMMON_ROUTES } from "../../../constants/route-constants";
import { BsJournalText } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const CatalogButton = () => {
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo?.selectedTenant
  );

  const handleLocal = () => {
    localStorage.setItem(
      "selectedTenant",
      JSON.stringify({ ...selectedTenant, appType: selectedAppType })
    );
  };
  return (
    <Tooltip
      title={
        <p style={{ fontFamily: "QualcommNext-Regular" }}>Open in new tab</p>
      }
      arrow
    >
      <Button
        variant="contained"
        sx={{
          textTransform: "Capitalize",
          fontFamily: "QualcommNext-Regular",
          backgroundColor: "#4D6994",
          "&:hover": {
            backgroundColor: "#4D6994",
          },
        }}
        disableElevation
        component={Link}
        to={COMMON_ROUTES.catalog}
        onClick={handleLocal}
        target="_blank"
        endIcon={<BsJournalText />}
      >
        catalog
      </Button>
    </Tooltip>
  );
};
export default CatalogButton;
