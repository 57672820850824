import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import BackdropOverlayModal from "../../../ui/BackdropOverlayModal";
import CommonHeader from "../../../ui/CommonHeader";
import AssociateFeatureToDevicesRow from "./AssociateFeatureToDevicesRow";
import SubmitButton from "../../../ui/Buttons/SubmitButton";
import CancelButton from "../../../ui/Buttons/CancelButton";
import AssociateFeatureToDevicesRowHeader from "./AssociateFeatureToDevicesRowHeader";
import { enableFeatureOnDevices } from "../../../../api/api-index";
import { ENABLE_FEATURE_ON_DEVICES_MESSAGES } from "../../../../constants/message-constants";
import errorHandler from "../../../helpers/error-helper";

import styles from "./AssociateFeatureToDevices.module.css";
import classes from "../../../ui/Form/FormContainer.module.css";

let isClicked = true;
const AssociateFeatureToDevices = ({
  onClose,
  userDetails,
  selectedFeature,
  setForceUpdate,
}) => {
  const params = useParams();
  const maxExpiryDate = userDetails[0]?.expirationTime;
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  const [isBatchingEnabled, setIsBatchingEnabled] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [batchDate, setBatchDate] = useState(null);
  const [error, setError] = useState(null);

  const handleBatchEnable = () => {
    setIsBatchingEnabled(!isBatchingEnabled);
    setIsCheck([]);
  };

  const handleSelectAll = (e) => {
    // to select all checkboxes
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.deviceId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleSelect = (id) => {
    // logic to select single cehckbox
    if (isCheck.includes(id)) {
      setIsCheck(isCheck.filter((i) => i !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const handleBatchDate = (e) => {
    //  to add bulk date
    setBatchDate(e);
  };

  const bulkDateHandler = (e) => {
    // logic to add the date to all the selected devices
    e.preventDefault();
    if (isCheck.length > 0) {
      setList(
        list.map((item) =>
          isCheck.includes(item.deviceId)
            ? {
                ...item,
                expirationTime: batchDate,
              }
            : item
        )
      );
      setIsBatchingEnabled(false);
      setBatchDate(null);
      setIsCheck([]);
    }
  };

  const dateHandler = (e, id) => {
    // single date handler  for a device
    setList(
      list.map((product) => {
        return product.deviceId === id
          ? {
              ...product,
              expirationTime: e,
            }
          : product;
      })
    );
  };
  const enableFeatureFormRequest = (data) => {
    // formatting data for api
    let request = null;
    if (data != null) {
      request = {
        featureName: selectedFeature.qualcommFeatureName,
        featureId: selectedFeature.id,
        qualcommFeatureId: selectedFeature.qualcommFeatureId,
        qualcommFeatureName: selectedFeature.qualcommFeatureName,
        subscriptionId: selectedFeature.featurePolicyTerm[0].subscriptionId,
        tenantId: params?.customerId,
        preferredVersion: selectedFeature.preferredVersion,
        deviceDetailsList: data.map((item) => {
          return {
            ...item,
            expirationTime: moment(item?.expirationTime || maxExpiryDate)
              .endOf("day")
              .utc()
              .format(),
            chipsetName: selectedFeature.chipsetName,
          };
        }),
      };
    }

    return request;
  };

  const checkExpirationTime = () => {
    let isExpirationEmpty;
    list &&
      list.map((item) => {
        if (!item.expirationTime) {
          isExpirationEmpty = true;
        } else {
          isExpirationEmpty = false;
        }
      });
    return isExpirationEmpty;
  };

  const handleSubmit = () => {
    // api call for submitting data
    if (
      selectedOrganization &&
      selectedOrganization.orgType === "Internal" &&
      checkExpirationTime()
    ) {
      toast.error("Please enter the Expiration Date");
    } else {
      if (isClicked) {
        isClicked = false;
        enableFeatureOnDevices(enableFeatureFormRequest(list))
          .then((response) => {
            if (response.status && response.data != null) {
              toast.success(ENABLE_FEATURE_ON_DEVICES_MESSAGES.success);
              setForceUpdate({});
              onClose();
            } else {
              errorHandler(
                response.error,
                ENABLE_FEATURE_ON_DEVICES_MESSAGES.failure
              );
            }
            isClicked = true;
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, ENABLE_FEATURE_ON_DEVICES_MESSAGES.failure);
            isClicked = true;
          });
      }
    }
  };

  useEffect(() => {
    // logice to check if all devices are selected or not
    if (isCheck.length === 0) {
      setIsCheckAll(false);
      return;
    }
    if (isCheck.length !== list.length) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(true);
    }
  }, [isCheck, list, setIsCheckAll]);

  useEffect(() => {
    // to set error based on selection of devices
    isCheck.length > 0
      ? setError(null)
      : setError("Please select atleast one device");
  }, [isCheck]);

  useEffect(() => {
    // to format the selected list of devices
    const formattedList =
      userDetails.map((item) => {
        return {
          deviceId: item?.id,
          deviceName: item?.name || "N/A",
          expirationTime: null,
          qualcommDeviceId: item.sysDeviceId,
          tenantId: params?.customerId,
          status: item?.deviceStatus === "ACTIVE" ? "ACTIVE" : null,
        };
      }) || [];
    setList(formattedList);
  }, [userDetails]);

  console.log(list);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading={`Enable Feature - ${selectedFeature.featureName}`}
          onClose={onClose}
          onBatch={handleBatchEnable}
        />
        <AssociateFeatureToDevicesRowHeader
          bulkDateHandler={bulkDateHandler}
          isBatchingEnabled={isBatchingEnabled}
          handleSelectAll={handleSelectAll}
          isCheckAll={isCheckAll}
          batchDate={batchDate}
          handleBatchDate={handleBatchDate}
          error={error}
          maxExpiryDate={maxExpiryDate}
        />

        <div className={styles.formContainer}>
          {list.map((item, key) => (
            <AssociateFeatureToDevicesRow
              key={key}
              item={item}
              isBatchingEnabled={isBatchingEnabled}
              isCheck={isCheck}
              handleSelect={handleSelect}
              dateHandler={dateHandler}
              maxExpiryDate={maxExpiryDate}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <SubmitButton
            text={"Submit"}
            onClick={handleSubmit}
            type={"submit"}
          />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};
export default AssociateFeatureToDevices;
