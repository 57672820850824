import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

import ButtonsAndSearch from "./ButtonsAndSearch";
import backButtonIcon from "../../assets/images/common/back-button.svg";

import classes from "./DataTablePanelTest.module.css";

const DataTablePanelTest = (props) => {
  const {
    list,
    setFilteredList,
    filterKeys,
    backButton,
    disableSearch,
    buttons,
    setKeys,
  } = props;

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <IconButton onClick={handleBackButtonClick}>
        {backButton && <img src={backButtonIcon} alt="Previous page" />}
      </IconButton>
      <ButtonsAndSearch
        list={list}
        setFilteredList={setFilteredList}
        filterKeys={filterKeys}
        disableSearch={disableSearch}
        buttons={buttons}
        setKeys={setKeys}
      />
    </div>
  );
};

export default DataTablePanelTest;
