import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IconButton } from "@mui/material";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

import { CSS_ROUTES } from "../../constants/route-constants";
import DataTableHeaders from "../header/DataTableHeaders";
import SubscriptionPolicyInfoItem from "./SubscriptionPolicyInfoItem";
import { subscriptionPolicyHeaders } from "../helpers/header-helpers";
import EditSubscriptionPolicy from "./EditSubscriptionPolicy/EditSubscriptionPolicy";
import { getSubscriptionPolicyInfo } from "../../api/api-index";
import { DateFormatter, ExpiryDateFormatter } from "../helpers/utils";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import { ROLES } from "../../constants/role-constants";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import {
  columnMenuStyles,
  CustomDataGrid,
  panelStyles,
} from "../ui/CustomDataGrid";
import SubscriptionPolicyStatus from "./ActiveInactiveSubscriptionPolicy/SubscriptionPolicyStatus";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../hooks/useBreadcrumb";

import classes from "./SubscriptionPolicyInfo.module.css";

const SubscriptionPolicyInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [openEditSubscriptionPolicy, setOpenEditSubscriptionPolicy] =
    useState(false);
  const [subscriptionPolicyInfo, setSubscriptionPolicyInfo] = useState({});
  const [openInactive, setOpenInactive] = useState(false);
  const [breadCrumbArray] = useBreadcrumb("subscriptionInfo");
  const params = useParams();

  const handleOpenEditEditSubscriptionPolicy = () => {
    setOpenEditSubscriptionPolicy(true);
  };

  const closeEditSubscriptionPolicy = () => {
    setOpenEditSubscriptionPolicy(false);
  };

  const handleOpenInactivePopUp = () => {
    setOpenInactive(true);
  };

  const handleCloseInactivePopUp = () => {
    setOpenInactive(false);
  };

  const heading = subscriptionPolicyInfo?.policyName || "...";
  const headers = subscriptionPolicyHeaders({
    routes: {
      subscriptionPolicyInfo:
        CSS_ROUTES.subscriptionPolicy + "/" + params?.policyId,
    },
  });
  const columns = [
    {
      field: "chipset",
      headerName: "Chipset",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "maxAllowedDevice",
      headerName: "Max Devices",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "expirationTimeStamp",
      headerName: "Expiration Date",
      minWidth: 150,
      flex: 1,
      renderCell: (params) =>
        ExpiryDateFormatter(params.row.expirationTimeStamp),
    },
  ];
  useEffect(() => {
    getSubscriptionPolicyInfo(params.policyId)
      .then((response) => {
        if (response.status) {
          setSubscriptionPolicyInfo(response.data);
        } else {
          errorHandler(response?.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.log(error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [openEditSubscriptionPolicy, openInactive]);

  const editFormattedData = {
    id: subscriptionPolicyInfo?.subscriptionId,
    featureName: subscriptionPolicyInfo?.featureName,
  };
  const policyChipsetData = subscriptionPolicyInfo?.policyChipsetsList?.map(
    (item) => {
      return {
        id: uuidv4(),
        chipset: item.chipset,
        maxAllowedDevice: item.maxAllowedDevice || "-",
        expirationTimeStamp: item.expirationTimeStamp,
      };
    }
  );
  const buttons = hasPermittedRolesOrAttributes([ROLES.cssAdmin])
    ? [
        {
          key: "edit",
          clickHandler: handleOpenEditEditSubscriptionPolicy,
          disabled:
            subscriptionPolicyInfo?.featureStatus ||
            subscriptionPolicyInfo?.status === "INACTIVE"
              ? true
              : false,
          icon:
            subscriptionPolicyInfo?.featureStatus ||
            subscriptionPolicyInfo?.status === "INACTIVE" ? (
              <EditIcon />
            ) : (
              //  <img src={disableEditIcon} />
              <EditIcon />
              // <img src={editIcon} />
            ),
          onlyIcon: true,
          tooltipText: "Edit",
        },
        {
          key: "inactive",
          clickHandler: handleOpenInactivePopUp,
          disabled:
            subscriptionPolicyInfo?.status === "INACTIVE" ? true : false,
          icon:
            subscriptionPolicyInfo?.status === "INACTIVE" ? (
              <IconButton size="small">
                <ContentPasteOffIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            ) : (
              <IconButton size="small">
                <ContentPasteOffIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            ),
          onlyIcon: true,
          tooltipText: "Inactivate policy",
        },
      ]
    : null;

  const formattedInfo = {
    createdOn: subscriptionPolicyInfo?.createdOn,
    id: subscriptionPolicyInfo?.subscriptionId,
    extid: subscriptionPolicyInfo?.extPolicyId,
    featureTitle: subscriptionPolicyInfo?.featureTitle,
    featureName: subscriptionPolicyInfo?.featureName,
    customerName:
      subscriptionPolicyInfo?.customerName ||
      subscriptionPolicyInfo?.customerId,
    customerId: subscriptionPolicyInfo?.customerId,
    isvName: subscriptionPolicyInfo?.isvName,
    isvId: subscriptionPolicyInfo?.isvId,
    expirationDate: subscriptionPolicyInfo?.expirationTimeStamp,
    policyName: subscriptionPolicyInfo?.policyName,
    featureStatus: subscriptionPolicyInfo?.featureStatus,
    policyChipsetsList: subscriptionPolicyInfo?.policyChipsetsList,
    featureId: subscriptionPolicyInfo?.featureId,
    allowedRegion: subscriptionPolicyInfo?.allowedRegion,
    country: subscriptionPolicyInfo?.country,
  };

  return (
    <div className={classes.container}>
      {openEditSubscriptionPolicy && (
        <EditSubscriptionPolicy
          onClose={closeEditSubscriptionPolicy}
          data={editFormattedData}
        />
      )}
      <DataTablePanelTest
        backButton={true}
        disableSearch={true}
        buttons={buttons}
      />

      {openInactive && (
        <SubscriptionPolicyStatus
          onClose={handleCloseInactivePopUp}
          policyDetails={formattedInfo}
        />
      )}
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{heading}</p>
      </StyledBreadcrumbs>

      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        <div className={classes.info}>
          <div className={classes.items}>
            <div className={classes["info-items"]}>
              <div className={classes.policyHeader}>Policy Details</div>
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.policyName}
                header="Policy Name"
              />
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.extPolicyId}
                header="Policy Id"
              />
              <SubscriptionPolicyInfoItem
                text={DateFormatter(subscriptionPolicyInfo?.createdOn)}
                header="Created On"
              />
              <div className={classes.policyHeader}>Feature Details</div>
              <div className={classes.featureTitle}>
                <SubscriptionPolicyInfoItem
                  text={subscriptionPolicyInfo?.featureTitle}
                  header="Title"
                />
              </div>

              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.featureDesc}
                header="Description"
              />
              <div className={classes.policyHeader}>Policy Terms</div>
              <CustomDataGrid
                sx={{
                  height: "32vh",
                  "& .MuiDataGrid-row": {
                    cursor: "default",
                  },
                  "& .expired": {
                    background: "#EAEAEA",
                    color: "#C8BEBE",
                  },
                }}
                columns={columns}
                rows={policyChipsetData || []}
                disableColumnFilter
                disableColumnMenu
                hideFooter
                hideFooterSelectedRowCount
                scrollbarSize={17}
                getCellClassName={(params) => {
                  if (params.field === "id") {
                    return "id";
                  }
                  if (
                    moment
                      .utc(params.row.expirationTimeStamp)
                      .local()
                      .isBefore(new Date())
                  ) {
                    return "expired";
                  }
                  return "general";
                }}
                components={{
                  NoRowsOverlay: CustomNoRowMessage,
                }}
                componentsProps={{
                  panel: {
                    sx: panelStyles,
                  },
                  columnMenu: {
                    sx: columnMenuStyles,
                  },
                  noRowsOverlay: {
                    isLoading,
                    text: NO_ROW_MESSAGES.policyTerms,
                  },
                }}
              />
              <div className={classes.policyHeader}>ISV Details</div>
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.isvOrg}
                header="Organization"
              />
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.isvName}
                header="Tenant Name"
              />
              <div className={classes.policyHeader}>Customer Details</div>
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.custOrg}
                header="Organization"
              />
              <SubscriptionPolicyInfoItem
                text={subscriptionPolicyInfo?.customerName}
                header="Tenant Name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPolicyInfo;
