import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { MDDInfoHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { getAssociatedDevicesForMDD } from "../../api/api-index";
import { PAGE_SIZE } from "../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import { GET_MESSAGES } from "../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import errorHandler from "../helpers/error-helper";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import { ROLES } from "../../constants/role-constants";
import { columnsActions } from "../../store/slices/columns-slice";
import associateIcon from "../../assets/images/device/linkWhite.svg";
import { BUTTONS_TYPE } from "../../constants/panel-constants";
import UnlinkButton from "../ui/Buttons/UnlinkButton";
import DeleteDevices from "./deleteAssociatedDevices/DeleteDevices";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import { StyledLink } from "../ui/StyledLink";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";

import classes from "../ui/DataTable.module.css";

let selectedRow = null;
const AssociatedDevices = () => {
  const params = useParams();
  const [breadCrumbArray] = useBreadcrumb("mddInfo");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const initialState = useSelector(
    (state) => state.columnsInfo.associatedDevice
  );
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [devicesList, setdevicesList] = useState({
    count: 0,
    list: [],
    mddName: "",
  });

  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [deleteDevice, setDeleteDevice] = useState(false);

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleOpenDelete = (params) => {
    selectedRow = params.row;
    setDeleteDevice(true);
  };

  const handleCloseDelete = () => {
    selectedRow = null;
    setDeleteDevice(false);
  };

  const columns = [
    {
      field: "deviceId",
      headerName: "System Device ID",
      flex: 1,
      minWidth: 350,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Device Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "version",
      headerName: "MDD Installed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "pendingActions",
      headerName: "Pending Actions",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      hideable: false,
      renderCell: (param) => <div>{renderDelete(param)}</div>,
    },
  ];
  const renderDelete = (param) => {
    if (param.row.status === "delete") {
      return;
    } else {
      return (
        <UnlinkButton
          onClick={(event) => {
            event.stopPropagation();
            handleOpenDelete(param);
          }}
          size="small"
        />
      );
    }
  };
  const headers = MDDInfoHeaders({
    routes: {
      mdd:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        params?.mddId,
      associatedDevices:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        params?.mddId +
        "/associated-devices",
    },
  });

  const handleOpenAssociateFeatures = () => {
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mdd +
        "/" +
        params?.mddId +
        "/available-devices"
    );
  };

  const buttons = hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.deviceManager,
    ROLES.cssAdmin,
  ])
    ? [
        {
          key: "associate",
          text: "Associate Devices",
          clickHandler: handleOpenAssociateFeatures,
          disabled: false,
          icon: null,
          type: BUTTONS_TYPE.circle,
          icon: <img src={associateIcon} alt="link" />,
        },
      ]
    : null;

  function fetchMDDDevices() {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      mddId: params.mddId,
    };

    getAssociatedDevicesForMDD(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.mddDeviceMappingList.map(
            (item) => {
              return {
                id: item.deviceId,
                deviceId: item.deviceId,
                name: item.deviceName,
                version: item.currentVersion ? `v${item.currentVersion}` : "-",
                pendingActions:
                  item?.pendingActions && item?.pendingVersion
                    ? `${item?.pendingActions} - v${item?.pendingVersion}`
                    : "N/A",
                status: item?.pendingActions,
              };
            }
          );
          setdevicesList({
            count: response.data.count,
            list: formattedList,
            mddName: response.data.mddName,
          });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          //error handling function
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        // errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchMDDDevices();
  }, [pageNo]);

  const heading =
    devicesList != null && devicesList?.mddName
      ? `${selectedTenant?.tenantName} - Metrics Definition - ${devicesList?.mddName}`
      : `${selectedTenant?.tenantName} - Metrics Definition - N/A`;
  return (
    <Fragment>
      {deleteDevice && (
        <DeleteDevices
          onClose={handleCloseDelete}
          data={selectedRow}
          refreshData={fetchMDDDevices}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          disableSearch={true}
          backButton={true}
          list={devicesList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={["name", "deviceId"]}
          buttons={buttons}
        />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{devicesList?.mddName || "..."}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}

        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setAssociatedDevice(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              if (params.row.status === "Delete") {
                return "status";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  devicesList.list.length === filteredList.length
                    ? devicesList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.associatedDevices,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AssociatedDevices;
