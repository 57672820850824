import { useSelector } from "react-redux";

const RenderOnPermittedRoleAndAttr = (props) => {
  const { roles, attributes, children } = props;
  const userInfoForATenancy = useSelector(
    (state) => state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
  );
  const value = roles?.some((role) => userInfoForATenancy?.roles?.includes(role))
    ? children
    : attributes?.some((attrubute) => userInfoForATenancy?.attributes?.includes(attrubute))
    ? children
    : null;

  return value;
};

export default RenderOnPermittedRoleAndAttr;
