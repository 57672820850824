import * as yup from "yup";
const errorMessages = {
  name: {
    required: "Device Name is required",
  },
  allowedFeatures: {
    required: "Feature is required",
  },
  serialNo: {
    required: "Serial No. is required",
  },
};

export const schema = yup
  .object({
    name: yup.string(),
    serialNo: yup.string(),
    allowedFeatures: yup.array(),
  })
  .required();
