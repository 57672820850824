import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNotification, getUserInfoByUserName } from "../../api/api-index";
import KeycloakConfig from "../../config/keycloak-config";
import { ENTITY_TYPE } from "../../constants/keycloak-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../constants/route-constants";
import { userActions } from "../../store/slices/user-slice";
import UserTenantInfo from "./UserTenantInfo";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import SubmitButton from "../ui/Buttons/SubmitButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import zyter from "../../assets/images/menu/zyter.svg";

import classes from "./Landing.module.css";
import { fetchOrganizationInfo } from "../../store/actions/organizations-actions";
import { tenantsActions } from "../../store/slices/tenants-slice";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import { toast } from "react-toastify";

const Landing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );

  const handleTenantClick = (key) => {
    dispatch(userActions.setSelectedAppKey(key));
    if (userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.css) {
      navigate(CSS_ROUTES.organizations);
    } else if (userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.isv) {
      const data = {
        tenantId: key,
        tenantName: userInfo.userRoleInfoJson[key].tenantName,
        organizationId: userInfo.organizationId,
        organization: selectedOrganization?.name,
        tenantType: userInfo.userRoleInfoJson[key].appType,
      };
      dispatch(tenantsActions.setSelectedTenant(data));
      navigate(
        `${CSS_ROUTES.organizations}/${userInfo.organizationId}${PARTIAL_ROUTES.isvTenants}/${key}${PARTIAL_ROUTES.myFeatures}`
      );
    } else if (
      userInfo.userRoleInfoJson[key].appType === ENTITY_TYPE.customer
    ) {
      const data = {
        tenantId: key,
        tenantName: userInfo.userRoleInfoJson[key].tenantName,
        organizationId: userInfo.organizationId,
        organization: selectedOrganization?.name,
        tenantType: userInfo.userRoleInfoJson[key].appType,
        isQualcommManagingDevice:
          userInfo.userRoleInfoJson[key].isQualcommManagingDevice,
      };
      dispatch(tenantsActions.setSelectedTenant(data));
      // !userInfo.userRoleInfoJson[key].isQualcommManagingDevice
      //   ? navigate(
      //       `${CSS_ROUTES.organizations}/${userInfo.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.features}`
      //     )
      //   : navigate(
      //       `${CSS_ROUTES.organizations}/${userInfo.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.devices}`
      //     );

      navigate(
        `${CSS_ROUTES.organizations}/${userInfo.organizationId}${PARTIAL_ROUTES.customerTenants}/${key}${PARTIAL_ROUTES.devices}`
      );
    }
  };

  useEffect(() => {
    const options = {
      userName: KeycloakConfig.getUserId(),
    };
    getUserInfoByUserName(options)
      .then((response) => {
        if (response.status) {
          dispatch(userActions.setUserInfoState(response.data));

          const options = {
            organizationId: response?.data?.organizationId,
          };
          dispatch(fetchOrganizationInfo(options));
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);

  const userRolesInTenancies = [];

  for (const key in userInfo?.userRoleInfoJson) {
    if (Object.hasOwnProperty.call(userInfo?.userRoleInfoJson, key)) {
      const element = userInfo?.userRoleInfoJson[key];
      userRolesInTenancies.push(
        <UserTenantInfo
          key={key}
          info={element}
          onClick={() => handleTenantClick(key)}
        />
      );
    }
  }

  console.log("LANDING PAGE: ", userRolesInTenancies);
  useEffect(() => {
    if (userRolesInTenancies.length === 1) {
      handleTenantClick(userRolesInTenancies[0]?.key);
    }
  }, [userRolesInTenancies]);

  const handleRequest = () => {
    const options = {
      notificationName: "Add User To Tenancy",
      organizationId: selectedOrganization.organizationId,
      parameters: [userInfo.userName],
    };

    createNotification(options).then((response) => {
      if (response.status) toast.success("Request sent successfully");
    });
  };

  return (
    <div className={classes.container}>
      {userRolesInTenancies.length > 1 ? (
        <div className={classes.dcontainer}>
          {!selectedOrganization && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {selectedOrganization && (
            <Fragment>
              <div className={classes.heading}>Roles</div>
              <div className={classes.content}>{userRolesInTenancies}</div>
            </Fragment>
          )}
        </div>
      ) : userRolesInTenancies.length === 0 ? (
        selectedOrganization && (
          <div className={classes.info}>
            <div className={classes["info-header"]}>
              <div className={classes["info-header-icon"]}>
                <WarningRoundedIcon htmlColor="#fbc02d" sx={{ fontSize: 40 }} />
                <span>Information Messages</span>
              </div>
              <SubmitButton
                text="Request"
                onClick={(e) => {
                  handleRequest();
                }}
              />
            </div>
            <div className={classes.messages}>
              <p>You are not part of CSS or any tenancy.</p>
              <p>
                Please request your organization css/tenancy admin to add you in
                order for you to use the application.
              </p>
            </div>
          </div>
        )
      ) : null}
      <div className={classes.slant}>
        <div className={classes.logo}>
          <span>Powered by</span>
          <img src={zyter} alt="powered by zyter" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
