import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Autocomplete } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import BackdropOverlayModal from "../../../ui/BackdropOverlayModal";
import { schema } from "./EditFeatureOwnershipSchema";
import CommonHeader from "../../../ui/CommonHeader";
import errorHandler from "../../../helpers/error-helper";
import CustomTextField from "../../../ui/CustomTextField";
import SubmitButton from "../../../ui/Buttons/SubmitButton";
import CancelButton from "../../../ui/Buttons/CancelButton";
import { StyledPopper } from "../../../ui/StyledPopper";
import {
  getOrganizationsList,
  getTenantsList,
  updateFeatureTenantMapping,
} from "../../../../api/api-index";
import {
  EDIT_FEATURE_MAPPING_MESSAGES,
  GET_MESSAGES,
} from "../../../../constants/message-constants";

import classes from "../../../ui/Form/FormContainer.module.css";
import styles from "./EditFeatureOwnership.module.css";

let clicked = true;
const EditFeatureOwnership = (props) => {
  const {
    control,
    handleSubmit,
    resetField,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
  });
  const { onClose, userDetails, getUpdatedDataFn } = props;

  const watchOrgName = watch("organization");
  const watchTenantName = watch("tenantName");
  const [OrgOptions, setOrgOptions] = useState([]);
  const [TenantData, setTenantData] = useState([]);

  const submitHandler = (data) => {
    if (clicked) {
      clicked = false;
      const options = {
        organizationId: data.organization.id,
        tenantId: data.tenantName.id,
      };

      updateFeatureTenantMapping(userDetails.featureId, options)
        .then((response) => {
          if (response.status) {
            clicked = true;
            toast.success(EDIT_FEATURE_MAPPING_MESSAGES.success);
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(response.error, EDIT_FEATURE_MAPPING_MESSAGES.failure);
            clicked = true;
          }
        })
        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(response.error, EDIT_FEATURE_MAPPING_MESSAGES.failure);
          clicked = true;
        });
    }
  };
  useEffect(() => {
    const options = {
      pageNo: 0,
      pageSize: 1000,
    };

    getOrganizationsList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.organizations != null &&
          response.data.organizationCount != null
        ) {
          const newOrgValues = response.data.organizations.map((name) => {
            return { label: name.name, id: name.organizationId };
          });
          setOrgOptions(newOrgValues);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, []);
  useEffect(() => {
    if (watchOrgName !== undefined) {
      setValue("tenantName", null);
      const payload = {
        pageNo: 0,
        pageSize: 1000,
        organizationId: watchOrgName?.id,
        tenantType: "ISV",
      };
      getTenantsList(payload)
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.tenants != null &&
            response.data.count != null
          ) {
            const newTenantValues = response.data.tenants.map((item) => {
              return { label: item.tenantName, id: item.tenantId };
            });
            setTenantData(newTenantValues);
          } else {
            console.error(response.error);
            errorHandler(response.error, GET_MESSAGES.failure);
          }
        })

        .catch((error) => {
          console.error("ERROR: ", error);
          errorHandler(error, GET_MESSAGES.failure);
        });
    }
  }, [watchOrgName?.id]);
  const selectedOrganization = {
    label: userDetails?.organization,
    id: userDetails?.orgId,
  };
  const selectedTenant = {
    label: userDetails?.tenantName,
    id: userDetails?.tenantId,
  };
  const selectedFeature = {
    label: userDetails?.featureName,
    id: userDetails?.featureId,
  };
  useEffect(() => {
    if (
      watchOrgName?.id === selectedOrganization?.id &&
      watchTenantName?.id == undefined
    ) {
      setValue("tenantName", selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Edit Feature Ownership" onClose={onClose} />
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className={styles.section}>
            <div className={styles.row}>
              <Controller
                name="featureName"
                control={control}
                defaultValue={selectedFeature}
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    disablePortal
                    disabled
                    clearOnEscape
                    size="small"
                    id={name}
                    disableClearable
                    options={[]}
                    fullWidth
                    PopperComponent={StyledPopper}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    value={value || null}
                    renderInput={(params) => (
                      <CustomTextField
                        focused
                        // required
                        {...params}
                        label="Feature Name"
                        placeholder="Search feature name"
                      />
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.row}>
              <Controller
                name="organization"
                control={control}
                defaultValue={selectedOrganization}
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    clearOnEscape
                    size="small"
                    id={name}
                    options={OrgOptions}
                    fullWidth
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    isOptionEqualToValue={(option) =>
                      option.label === value.label
                    }
                    value={value || null}
                    PopperComponent={StyledPopper}
                    renderInput={(params) => (
                      <CustomTextField
                        focused
                        required
                        {...params}
                        label="Organization"
                        placeholder="Search organization name"
                      />
                    )}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.row}>
              <Controller
                name="tenantName"
                control={control}
                // defaultValue={selectedTenant}
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    disableClearable
                    disablePortal
                    clearOnEscape
                    size="small"
                    id={name}
                    options={TenantData}
                    isOptionEqualToValue={(option) =>
                      option.label === value.label
                    }
                    fullWidth
                    PopperComponent={StyledPopper}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    value={value || null}
                    renderInput={(params) => (
                      <CustomTextField
                        focused
                        required
                        {...params}
                        label="Tenant Name"
                        placeholder="Search tenant name"
                      />
                    )}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <CancelButton onClick={onClose} text={"Cancel"} />
            <SubmitButton text={"Submit"} type={"submit"} />
          </div>
        </form>
      </div>
    </BackdropOverlayModal>
  );
};

export default EditFeatureOwnership;
