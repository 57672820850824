import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { licenseInfoHeaders } from "../../helpers/header-helpers";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import DataTableHeaders from "../../header/DataTableHeaders";
import LicenseInfoItem from "./LicenseInfoItem";
import orgIcon from "../../../assets/images/features/org.svg";
import featureIcon from "../../../assets/images/features/default-feature-name.svg";
import tenantIcon from "../../../assets/images/features/tenant.svg";
import LicenseTable from "./LicenseTable";
import { getLicenseInfoList } from "../../../api/getLicenseInfoList";
import { GET_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../hooks/useBreadcrumb";

import classes from "./LicenseInfo.module.css";

const LicenseInfo = () => {
  const params = useParams();
  const [breadCrumbArray] = useBreadcrumb("tenantDeviceLicenseInfo");
  // const selectedFeatureMapping = useSelector(
  //   (state) => state.devicesLicensesInfo.selectedFeature
  // );

  const [licenseData, setLicenseData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const headers = licenseInfoHeaders({
    routes: {
      licenseInfo:
        CSS_ROUTES.tenantDeviceLicenses +
        "/" +
        params.featureName +
        "/" +
        params.tenantId,
    },
  });

  useEffect(() => {
    getLicenseInfoList(params.featureName, params.tenantId.toString())
      .then((response) => {
        if (response.status && response.data != null) {
          setLicenseData(response.data);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const heading = licenseData?.featureTitle;
  return (
    <div className={classes.container}>
      <DataTablePanelTest backButton={true} disableSearch={true} />
      <StyledBreadcrumbs>
        {breadCrumbArray.map((item) => (
          <StyledLink key={item.link} to={item.link}>
            {item.name}
          </StyledLink>
        ))}
        <p>{heading || "..."}</p>
      </StyledBreadcrumbs>
      <div className={classes.data}>
        <DataTableHeaders headers={headers} />
        <div className={classes.info}>
          <div className={classes.info_items}>
            <LicenseInfoItem
              text={licenseData?.organization}
              header="Organization"
              icon={orgIcon}
            />
            <LicenseInfoItem
              text={licenseData?.tenantName}
              header="Tenant Name"
              icon={tenantIcon}
            />
            <LicenseInfoItem
              text={licenseData?.featureTitle}
              header="Feature Title"
              // icon={featureIcon}
              icon={
                licenseData?.featureIcon != null
                  ? licenseData.featureIcon
                  : featureIcon
              }
              convertFromBase64={licenseData?.featureIcon != null ? true : null}
            />
            <div className={classes.feature_Table}>
              <LicenseTable
                featureData={licenseData?.featureDeviceDetailsList}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LicenseInfo;
