import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

const EditIconButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Edit",
    disabled = false,
  } = props;
  return !disabled ? (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label={tooltipText}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <EditIcon sx={{ color: disabled ? "grey" : color, width: 22 }} />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          disabled={disabled}
          aria-label={tooltipText}
          size={size != null ? size : "large"}
          onClick={onClick != null ? onClick : null}
        >
          <EditIcon sx={{ color: disabled ? "grey" : color, width: 22 }} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default EditIconButton;
