import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import BackdropOverlayModal from "../ui/BackdropOverlayModal";
import { CustomDataGrid } from "../ui/CustomDataGrid";
import CommonHeader from "../ui/CommonHeader";
import CancelButton from "../ui/Buttons/CancelButton";
import SubmitButton from "../ui/Buttons/SubmitButton";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import {
  getAllDevicesbyTenantId,
  editDeviceTenantId,
} from "../../api/api-index";
import CustomPagination from "../ui/CustomPagination";
import { PAGE_SIZE } from "../../constants/page-constants";
import { ONBOARD_DEVICE_MESSAGES } from "../../constants/message-constants";
import errorHandler from "../helpers/error-helper";

import classes from "./AddDevices.module.css";

const AddDevices = ({ onClose, tenantName, tenantId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deviceList, setDeviceList] = useState({ count: 0, list: [] });
  const [pageNo, setPageNo] = useState(0);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [allDeviceId, setAlldeviceId] = useState([]);
  let deviceId = [];
  const columns = [
    {
      field: "id",
      headerName: "System Device Id",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "chipsetName",
      headerName: "Chipset Name",
      flex: 1,
      minWidth: 150,
    },
  ];

  const fetchData = () => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      tenantId: tenantId,
    };
    getAllDevicesbyTenantId(options)
      .then((res) => {
        if (res?.status && res.data) {
          const formattedList = res.data.qualcommDeviceIdChipsetNameList?.map(
            (item) => {
              return {
                id: item.qualcommDeviceId,
                chipsetName: item.chipsetName,
              };
            }
          );
          setDeviceList({
            count: res.data.count,
            list: formattedList,
          });
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [pageNo]);

  const onCheckBoxSelect = (ids) => {
    let newSelectedDevices = [];
    ids?.forEach((id) => {
      const deviceObj = deviceList.list?.find((device) => device.id === id);
      if (deviceObj) {
        newSelectedDevices.push(deviceObj);
        deviceId.push(deviceObj.id);
        setAlldeviceId(deviceId);
      }
    });
    setSelectedDevices(newSelectedDevices);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };
  const submitHandler = () => {
    const options = {
      tenantId: tenantId,
      allDeviceId: allDeviceId,
    };
    editDeviceTenantId(options)
      .then((response) => {
        if (response.status) {
          toast.success(ONBOARD_DEVICE_MESSAGES.success);
          onClose();
        } else {
          errorHandler(response.error, ONBOARD_DEVICE_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error.data, ONBOARD_DEVICE_MESSAGES.failure);
      });
  };
  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader
          heading={`Add Device - ${tenantName}`}
          onClose={onClose}
        />
        <div>
          <CustomDataGrid
            sx={{
              height: "30rem",
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.6rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E6E6E7",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={deviceList.list || []}
            checkboxSelection={true}
            disableColumnFilter
            disableColumnMenu
            onSelectionModelChange={onCheckBoxSelect}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: deviceList.count || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.devices },
            }}
          />
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <SubmitButton
            // disabled={!selectedDevices?.length}
            text={"Submit"}
            onClick={submitHandler}
            type={"submit"}
          />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default AddDevices;
