import axios from "axios";
import { createNewUserApi } from "./apiEndpoints";

export const createNewUser = async ({
  firstName,
  lastName,
  emailId,
  phoneNo,
  password,
  organizationId,
}) => {
  let data = null;
  await axios
    .post(createNewUserApi, {
      firstName,
      lastName,
      emailId,
      phoneNo,
      password,
      organizationId,
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
