import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import KeycloakConfig from "../../config/keycloak-config";
import UserInfo from "./UserInfo";
import { OptionsButton } from "../ui/Buttons/OptionsButton";
import logoIcon from "../../assets/images/appbar/logo.svg";
import CleanBackdropOverlayModal from "../ui/CleanBackdropOverlayModal";
import { userActions } from "../../store/slices/user-slice";
import SettingsIcon from "../ui/Buttons/SettingsIcon";
import Tenants from "./Tenants";
import { COMMON_ROUTES } from "../../constants/route-constants";
import NotificationPopup from "./notificationPopup/NotificationPopup";
import CatalogButton from "../ui/Buttons/CatalogButton";

import classes from "./AppBar.module.css";
import { BASE_URL } from "../../api/apiEndpoints";

export const GREETING = ["Good Morning", "Good Afternoon", "Good Evening"];

const AppBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);
  const userRoles = useSelector(
    (state) => state.userInfo.userInfo?.userRoleInfoJson
  );
  const userName = useSelector((state) => state.userInfo.userInfo);

  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo?.selectedTenant
  );

  const handleLocal = () => {
    localStorage.setItem(
      "selectedTenant",
      JSON.stringify({ ...selectedTenant, appType: selectedAppType })
    );
  };

  const handleOptionsClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    dispatch({ type: "REDUX_STORE_RESET" });
    navigate("", { state: null });
    KeycloakConfig.doLogout();
  };

  const handleSelectTenant = () => {
    dispatch({ type: "REDUX_STORE_RESET" });
    navigate("/", { state: null });
    setOpen(!open);
  };

  const handleUserSettings = () => {
    navigate(COMMON_ROUTES.userSettings);
    setOpen(!open);
  };

  const handleLogoClick = () => {
    navigate("/");
    setOpen(false);
  };

  BASE_URL.baseUrl.includes("dev") &&
    console.log("KC: ", KeycloakConfig.printKC());
  const hour = new Date().getHours();
  let greeting = "";
  if (hour < 12) {
    greeting = GREETING[0];
  } else if (hour < 18) {
    greeting = GREETING[1];
  } else {
    greeting = GREETING[2];
  }

  const rolesCount = userRoles != null ? Object.keys(userRoles).length : 0;

  return (
    <div className={classes.container}>
      <div className={classes.logo} onClick={handleLogoClick}>
        <img src={logoIcon} alt="Logo" />
      </div>

      <div className={classes.rcontainer}>
        {selectedAppKey != null && <CatalogButton />}
        {selectedAppKey != null && <NotificationPopup />}
        {selectedAppKey != null && rolesCount > 1 && <Tenants />}
        <div className={classes.options}>
          <UserInfo userName={userName?.fullName} />
          <SettingsIcon onClick={handleOptionsClick} />
        </div>
      </div>
      {open && (
        <CleanBackdropOverlayModal onClick={handleOptionsClick}>
          <div className={classes.list}>
            <div className={classes.greetings}>
              <p>
                <b>{greeting}</b>, {userName?.firstName}
              </p>
              <div></div>
            </div>
            <OptionsButton
              variant="text"
              onClick={handleUserSettings}
              startIcon={<ManageAccountsIcon />}
            >
              Settings
            </OptionsButton>
            {rolesCount > 1 &&
              (selectedAppKey != null ||
                location.pathname === COMMON_ROUTES.userSettings) && (
                <OptionsButton
                  variant="text"
                  onClick={handleSelectTenant}
                  startIcon={<HomeWorkIcon />}
                >
                  Roles
                </OptionsButton>
              )}

            <OptionsButton
              variant="text"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
            >
              Logout
            </OptionsButton>
          </div>
        </CleanBackdropOverlayModal>
      )}
    </div>
  );
};

export default AppBar;
