import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Badge, IconButton } from "@mui/material";

const NotificationButton = (props) => {
  const { open, handleClick, UnHandledNotificationsCount } = props;
  return (
    <Avatar
      variant="rounded"
      onClick={handleClick}
      sx={
        !open
          ? {
              color: "#214572",
              backgroundColor: "rgba(62, 124, 168, 0.3)",
              ml: 2,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#2e5f9b",
                color: "#FFFFFF",
              },
            }
          : {
              color: "#FFFFFF",
              backgroundColor: "#2e5f9b",
              ml: 2,
              "&:hover": {
                color: "#FFFFFF",
                backgroundColor: "#2e5f9b",
              },
            }
      }
    >
      <Badge
        color={UnHandledNotificationsCount > 0 ? "error" : "default"}
        variant={"dot"}
      >
        {<NotificationsIcon />}
      </Badge>
    </Avatar>
  );
};

export default NotificationButton;
