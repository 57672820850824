import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import KeycloakConfig from "../../config/keycloak-config";
import logoIcon from "../../assets/images/appbar/logo.svg";
import qLogo from "../../assets/images/appbar/qcloud.svg";
import { CATALOG_MENU_OPTIONS } from "../../constants/catalog-constans";
import ListItem from "./ListItem";
import { COMMON_ROUTES } from "../../constants/route-constants";

import classes from "./CatalogAppBar.module.css";

export const GREETING = ["Good Morning", "Good Afternoon", "Good Evening"];

const CatalogAppBar = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:1000px)");
  const hour = new Date().getHours();
  let greeting = "";
  if (hour < 12) {
    greeting = GREETING[0];
  } else if (hour < 18) {
    greeting = GREETING[1];
  } else {
    greeting = GREETING[2];
  }
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img
          src={matches ? qLogo : logoIcon}
          alt="Logo"
          onClick={() => navigate(COMMON_ROUTES.catalog)}
        />
      </div>
      <div className={classes.c_list}>
        {CATALOG_MENU_OPTIONS.map((item) => (
          <NavLink
            key={item.name}
            to={item.route}
            className={({ isActive }) =>
              isActive ? classes.activeContainer : classes.m_container
            }
          >
            {({ isActive }) => <ListItem isActive={isActive} item={item} />}
          </NavLink>
        ))}
      </div>
      <div className={classes.rcontainer}>
        <div className={classes.greetings}>
          <p>
            <b>{greeting}</b>, {KeycloakConfig.getFirstName()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CatalogAppBar;
