export const NO_ROW_MESSAGES = {
  organizations: "No organizations found",
  subscriptionPolicy: "No subscription policy found",
  featureOwnership: "No feature ownership found",
  globalRepositories: "No global repositories found",
  localRepositories: "No local repositories found",
  deviceAndLicenses: "No device and Licenses found",
  users: "No users found",
  customerTenants: "No customer tenants found",
  isvTenants: "No ISV tenants found",
  features: "No features found",
  associatedUsers: "No associated users found",
  subscribers: " No subscribers found",
  containers: "No containers found",
  models: "No models found",
  template: "No template found",
  plugins: "No plugins found",
  versionHistory: "No version history found",
  devices: "No devices found",
  metrics: "No metrics found",
  associatedDevices: "No associated devices found",
  availableDevices: "No available devices found",
  archivedAlerts: "No archived alerts found",
  archivedEvents: "No archived events found",
  alerts: "No alerts found",
  events: "No events found",
  metricsDefinition: "No metrics found",
  associatedTags: "No associated tags found",
  devicesAndLicenses: "No devices and licenses found",
  policyTerms: "No policy terms found",
  tagData: "No tag found",
  preferredVersion: "No versions found",
  chipset: "No chipsets found",
  sdk: "No Versions found",
};
