import { Fragment } from "react";
import ReactDOM from "react-dom";

import classes from "./BackdropOverlayModal.module.css";

const Backdrop = () => {
  return <div className={classes.backdrop} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes["content-modal"]}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const BackdropOverlayModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
    </Fragment>
  );
};

export default BackdropOverlayModal;
