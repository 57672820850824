import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import FullScreenIcon from "../../ui/Buttons/FullScreenIcon";
import { ROLES } from "../../../constants/role-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import DataTableHeaders from "../../header/DataTableHeaders";
import { customerDevicesHeaders } from "../../helpers/header-helpers";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import LightTooltip from "../../ui/Tooltip/LightToolTip";
import CloudMetricPopup from "../../ui/cloudMetricPopup/CloudMetricPopup";
import CustomIframe from "../../ui/customIframe/CustomIframe";
import { DASHBOARD_NAME, IFRAME_BASE_URL } from "../../../api/iframeEndpoints";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledLink } from "../../ui/StyledLink";
import useTimeStatus from "../../../hooks/useTimeStatus";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";

import classes from "../../ui/DataTable.module.css";
import styles from "./MddDashboard.module.css";

let selectedItem = null;
const MddDashboard = () => {
  const [breadCrumbArray] = useBreadcrumb("devices");
  const params = useParams();
  const [dateRange, handleTime] = useTimeStatus();
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const [zoom, setzoom] = useState(false);
  const handleOpenDetails = (item) => {
    selectedItem = item;
    setzoom(true);
  };
  const handleCloseDetails = () => {
    selectedItem = null;
    setzoom(false);
  };

  const headers = customerDevicesHeaders({
    routes: {
      deviceList: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.devices
        : null,
      customerUsers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.associatedUsers
        : null,
      features: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features
        : null,
      mdd: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd
        : null,
      mddDashboard:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.mddDashboard,
    },
  });

  const cloudMetricPanelId = [
    { mainView: 6, detailView: 2 },
    { mainView: 7, detailView: 3 },
    { mainView: 8, detailView: 4 },
  ];
  return (
    <div className={classes.container}>
      {zoom && (
        <CloudMetricPopup
          selectedItem={selectedItem}
          heading="Metric Details"
          onClose={handleCloseDetails}
        />
      )}
      <DataTablePanelTest
        disableSearch={true}
        backButton={
          hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? true : false
        }
      />
      {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{selectedTenant?.tenantName}</p>
        </StyledBreadcrumbs>
      ) : (
        <p>{selectedTenant?.tenantName}</p>
      )}

      {/* <p>{selectedTenant?.tenantName}-Metrics Dashboard</p> */}
      <div className={classes.data}>
        <DataTableHeaders headers={headers} />

        <div className={styles.panel}>
          <h2 className={styles.sub_header}>
            Cloud Metrics
            <span className={styles.disclaimer}>
              * <i>All the data is from last 24 hrs</i>
            </span>
          </h2>
          <div className={styles.info}>
            {cloudMetricPanelId.map((item) => (
              <div key={item.mainView} className={styles.iframeGraph}>
                <div className={styles.header}>
                  <span></span>
                  <LightTooltip
                    title="Expand for more details"
                    placement="top"
                    arrow
                  >
                    <div>
                      <FullScreenIcon
                        onClick={() => handleOpenDetails(item.detailView)}
                      />
                    </div>
                  </LightTooltip>
                </div>
                <CustomIframe
                  src={`${IFRAME_BASE_URL.baseUrl}/dl6P7vF4k/${DASHBOARD_NAME.cloudMetricsMainView}?orgId=1&var-tenantId=${params.customerId}&theme=light&from=${dateRange.from}&to=${dateRange.to}&panelId=${item.mainView}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MddDashboard;
