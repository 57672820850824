import * as yup from "yup";
const errorMessages = {
  name: {
    required: "Feature Name is required",
  },
  title: {
    required: "Feature Title is required",
  },

  category: {
    required: "Feature Category is required",
  },
  pushToRepository: {
    required: "Command to push model/container/helm chart is required",
  },
  releaseName: {
    required: "Release name is required",
  },
  allowedChipsets: {
    required: "Atleast one chipset is required",
  },
  access: {
    required: "Feature access is required",
  },
  accessibleInRegions: {
    required: "Region is required",
  },
  noOfDevices: {
    typeError: "No. of devices value must be a number",
  },
};

export const schema = yup
  .object({
    name: yup.string().required(errorMessages.name.required),
    title: yup.string().required(errorMessages.title.required),
    featureDescription: yup.string(),

    category: yup.string().required(errorMessages.category.required),
    // pushToRepository: yup.string().required(errorMessages.pushToRepository.required),
    // releaseName: yup.string().required(errorMessages.releaseName.required),
    labels: yup.string(),
    allowedChipsets: yup
      .array()
      .required(errorMessages.allowedChipsets.required),
    access: yup.string().required(errorMessages.access.required),
    // accessibleInRegions: yup.string(),
    // noOfDevices: yup.string().typeError(errorMessages.noOfDevices.typeError),
  })
  .required();
