import axios from "axios";
import { regenerateSecretIdApi } from "./apiEndpoints";

export const regenerateSecretId = async (options) => {
  let data = null;
  await axios
    .post(regenerateSecretIdApi, null, {
      params: {
        realm: options.realm,
        clientUuid: options.client,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
