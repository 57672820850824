import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllSDKVersionList } from "../../../api/api-index";
import { GET_MESSAGES } from "../../../constants/message-constants";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import errorHandler from "../../helpers/error-helper";
import { ExpiryDateFormatter } from "../../helpers/utils";
import { CustomDataGrid } from "../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import classes from "./LinuxAndroidSDK.module.css";

function LinuxAndroidSDKList(props) {
  const {
    updateType,
    error,
    selectedVersion,
    tableHeader,
    changeStatus,
    tenantId,
    prevSelectedVersion,
  } = props;

  const [versionList, setVersionList] = useState({ count: 0, list: [] });
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 150,
      hideable: false,
      renderCell: (params) => {
        // console.log(selectedVersion, tableHeader);
        return (
          <>
            <Checkbox
              disabled={
                updateType === "AUTO" || updateType === "NA" ? true : false
              }
              checked={selectedVersion === params.row.id}
              size="small"
            />
            {params.row.version}
          </>
        );
      },
    },
    {
      field: "pushedOn",
      headerName: "Pushed On",
      flex: 1,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    const options = {
      tenantId: tenantId,
      repositoryType: tableHeader,
    };
    getAllSDKVersionList(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedList = response.data.sdkVersionList.map((item) => {
            return {
              id: item.sdkVersion,
              version: item.sdkVersion,
              pushedOn: ExpiryDateFormatter(item.createdOn),
              isSelectable:
                item.preferred === null || item.preferred === true
                  ? true
                  : false,
            };
          });

          setVersionList({
            count: response.data.count,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <h3>{tableHeader}</h3>
      <div className={classes.subContainer}>
        <p>Preference Type</p>
        <FormControl disabled={isLoading || versionList.list.length === 0}>
          <RadioGroup
            sx={{ mb: 1 }}
            row
            name="radio-buttons-group"
            value={updateType}
            onChange={(e) => {
              if (e.target.value === "AUTO") {
                changeStatus(
                  e.target.value,
                  tableHeader,
                  versionList.list[0].id
                );
              } else if (e.target.value === "MANUAL") {
                const tempPrevVersion =
                  prevSelectedVersion === "0.0.0"
                    ? versionList.list[0].id
                    : prevSelectedVersion;
                changeStatus(e.target.value, tableHeader, tempPrevVersion);
              } else {
                changeStatus(e.target.value, tableHeader, "0.0.0");
              }
            }}
          >
            <FormControlLabel
              sx={{ color: "#214572" }}
              disableTypography
              value="AUTO"
              control={<Radio size="small" />}
              label="Automatic Update"
            />

            <FormControlLabel
              sx={{ color: "#214572" }}
              disableTypography
              value="MANUAL"
              control={<Radio size="small" />}
              label="Manual Update"
            />
            <FormControlLabel
              sx={{ color: "#214572" }}
              disableTypography
              value="NA"
              control={<Radio size="small" />}
              label="N/A"
              disabled={prevSelectedVersion !== "0.0.0"}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <CustomDataGrid
          hideFooterPagination
          hideFooterSelectedRowCount
          hideFooter
          density="medium"
          sx={{
            height: "20rem",

            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E6E6E7",
            },
          }}
          selectionModel={selectedVersion}
          // getRowHeight={() => "auto"}
          columns={columns}
          rows={versionList?.list || []}
          checkboxSelection={false}
          disableColumnMenu
          disableColumnFilter
          scrollbarSize={17}
          onSelectionModelChange={(ids) => {
            if (ids.length > 0 && updateType === "MANUAL") {
              changeStatus(null, tableHeader, ids.toString());
            }
          }}
          getCellClassName={(params) => {
            if (params.field === "id") {
              return "id";
            }
            if (selectedVersion === params.id) {
              return "selectedVersion";
            }

            if (updateType === "AUTO" || updateType === "NA") {
              return "noCursorRequired";
            }

            if (params.row.isSelectable === false) {
              return "rowDisable";
            }

            return "general";
          }}
          componentsProps={{
            noRowsOverlay: {
              isLoading,
              text: NO_ROW_MESSAGES.sdk,
            },
          }}
          components={{
            NoRowsOverlay: CustomNoRowMessage,
          }}
        />
      </div>
    </div>
  );
}

export default LinuxAndroidSDKList;
