import { styled } from "@mui/material/styles";
import MUIChip from "@mui/material/Chip";

const CustomChip = styled(MUIChip)(() => ({
  backgroundColor: "#DFE7FF",
  borderColor: "#7AA1DF",
  color: "#033C83",
  fontFamily: "QualcommNext-Regular",
}));

const Chip = (props) => {
  const { text } = props;
  return <CustomChip label={text} variant="outlined" />;
};

export default Chip;
