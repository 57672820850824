import { toast } from "react-toastify";

import { deleteVersion } from "../../../api/api-index";
import { DELETE_VERSION_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./DeleteVersion.module.css";

const DeleteVersion = (props) => {
  const { onClose, versionDetails } = props;

  const onSubmit = () => {
    const payload = {
      qcommFeatureName: versionDetails?.featureName,
      featureVersion: versionDetails?.version,
    };

    deleteVersion(payload)
      .then((response) => {
        if (response.status) {
          toast.success(DELETE_VERSION_MESSAGES.success);
          onClose();
        } else {
          errorHandler(response.error, DELETE_VERSION_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error.response);
        errorHandler(error, DELETE_VERSION_MESSAGES.failure);
      });
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Version" onClose={onClose} />
        <div className={classes.text}>
          <p>
            Are you sure you want to delete <b>{versionDetails?.id}</b> version
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton text="Cancel" onClick={onClose} />
          <ConfirmDeleteButton text="Confirm" onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteVersion;
