import DeviceLicensesStat from "./license/DeviceLicensesStat";
import classes from "./Dashboard.module.css";
import HttpServerRequestsSeconds from "./HttpServerRequestsSeconds";
import MicorservicesCrashesStats from "./MicorservicesCrashesStats";
const Dashboard = () => {
  return (
    <div className={classes.container}>
      <DeviceLicensesStat />
      <HttpServerRequestsSeconds />
      <MicorservicesCrashesStats />
    </div>
  );
};

export default Dashboard;
