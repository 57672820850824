import { toast } from "react-toastify";

import { INACTIVE_POLICY_MESSAGES } from "../../../constants/message-constants";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";
import { editSubscriptionPolicy } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";

import classes from "./SubscriptionPolicyStatus.module.css";

let isClicked = true;
const SubscriptionPolicyStatus = (props) => {
  const { onClose, policyDetails, getUpdatedDataFn } = props;
  const policyFormRequest = (data) => {
    let request = null;
    if (data != null) {
      request = {
        extPolicyId: data.extid,
        policyName: data.policyName,
        status: "INACTIVE",
        customerId: data?.customerId,
        isvId: data?.isvId,
        featureName: data?.featureName,
        featureId: data?.featureId,
        allowedRegion: data?.allowedRegion,
        country: data?.country,
      };
    }

    return request;
  };

  const onSubmit = () => {
    if (isClicked) {
      isClicked = false;
      editSubscriptionPolicy(
        policyDetails?.id,
        policyFormRequest(policyDetails)
      )
        .then((response) => {
          if (response.status && response.data != null) {
            isClicked = true;
            toast.success(INACTIVE_POLICY_MESSAGES.success);
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          } else {
            errorHandler(response.error, INACTIVE_POLICY_MESSAGES.failure);
            isClicked = true;
          }
        })
        .catch((error) => {
          errorHandler(error, INACTIVE_POLICY_MESSAGES.failure);
          isClicked = true;
        });
    }
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Change Policy Status" onClose={onClose} />
        <div className={classes.warning}>
          <p>
            Are you sure you want to make
            <span
              className={classes.userNameStyles}
            >{` "${policyDetails?.policyName}" `}</span>
            policy inactive?
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <ConfirmDeleteButton onClick={onSubmit} text={"Confirm"} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default SubscriptionPolicyStatus;
