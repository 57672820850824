import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CustomButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#4D6994",
  "&:hover": {
    backgroundColor: "#1a4071",
  },
  textTransform: "var(--mui-button-text-transform)",
  borderRadius: "20px",
  fontFamily: "QualcommNext-Regular",
}));

const PanelButton = (props) => {
  const { onClick, text, disabled, simpleButton } = props;
  return (
    <CustomButton
      variant="contained"
      disableElevation
      disabled={disabled}
      onClick={onClick != null ? onClick : null}
      endIcon={simpleButton == null ? <AddCircleOutlineIcon /> : null}
    >
      {text}
    </CustomButton>
  );
};

export default PanelButton;
