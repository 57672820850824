import Keycloak from "keycloak-js";
import { KEYCLOAK_INIT } from "../constants/keycloak-constants";

const _kc = new Keycloak("/keycloak.json");
//  onLoad: "check-sso",
//     silentCheckSsoRedirectUri:
//       window.location.origin + "/silent-check-sso.html",
//     pkceMethod: "S256",
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init(KEYCLOAK_INIT)
    .then(() => {
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getOrganizationId = () => _kc.tokenParsed?.organizationId || null;

const getUsername = () => console.log(_kc);
_kc.tokenParsed?.name != null ? _kc.tokenParsed.name : null;

const getUserId = () =>
  _kc.idTokenParsed?.preferred_username != null
    ? _kc.idTokenParsed.preferred_username
    : null;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const userAbbreviation = () => {
  const { given_name, family_name } = _kc.tokenParsed;
  return given_name != null && family_name != null
    ? given_name[0] + family_name[0]
    : null;
};

const loadUserInfo = () => _kc.loadUserInfo();

const getUserInfo = () => _kc.userInfo;

const getRealm = () => _kc.realm;

const printKC = () => console.log(_kc);
const getFirstName = () => _kc.tokenParsed.given_name;
const KeycloakConfig = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  hasRole,
  getOrganizationId,
  getUsername,
  getUserId,
  userAbbreviation,
  loadUserInfo,
  getUserInfo,
  getRealm,
  printKC,
  getFirstName,
};

export default KeycloakConfig;
