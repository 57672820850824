import axios from "axios";
import { getAllSDKVersionListApi } from "../apiEndpoints";

export const getAllSDKVersionList = async (payload) => {
  let data = null;
  await axios
    .get(getAllSDKVersionListApi, {
      params: {
        ...payload,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
