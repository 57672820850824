import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomTextField from "../../ui/CustomTextField";

const CustomEndpointComponent = ({
  endpoint,
  handleRadioChange,
  handleUrlChange,
  disabled,
  categoryEnabled,
}) => {
  return (
    <>
      <FormControl>
        <RadioGroup
          row
          defaultValue={"CSS"}
          name="radio-buttons-group"
          value={endpoint.value}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            disabled={disabled || !categoryEnabled}
            sx={{ color: "#214572" }}
            disableTypography
            value="CSS"
            control={<Radio size="small" />}
            label="Report to CSS Cloud"
          />
          <FormControlLabel
            sx={{ color: "#214572" }}
            disabled={
              endpoint.isQualcommManagingDevice || disabled || !categoryEnabled
            }
            disableTypography
            value="CUSTOM"
            control={<Radio size="small" />}
            label="Report to Custom Endpoint"
          />
        </RadioGroup>
      </FormControl>

      <CustomTextField
        disabled={endpoint.value === "CSS" || disabled || !categoryEnabled}
        value={endpoint.url}
        sx={{ width: "20rem" }}
        required
        onChange={(e) => handleUrlChange(e)}
        size="small"
        placeholder="Endpoint"
        inputProps={{
          pattern: ".*[^ ].*",
          title: "Endpoint name cannot have only spaces",
          maxLength: 255,
        }}
      />
    </>
  );
};
export default CustomEndpointComponent;
