import classes from "./CatalogAppBar.module.css";

const ListItem = ({ isActive, item }) => {
  return (
    <div className={classes.listItem}>
      <div className="flex">{item.name}</div>
    </div>
  );
};
export default ListItem;
