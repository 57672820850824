import axios from "axios";
import { deleteArchiveNotificationsApi } from "./apiEndpoints";

export const deleteArchiveNotifications = async (payload) => {
  let data = null;
  console.log(payload);
  await axios
    .delete(deleteArchiveNotificationsApi, { data: { ...payload } })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
