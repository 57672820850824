import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpeedDialButton from "../../../ui/Buttons/SpeedDialButton";
import { Button, Chip, SpeedDial, SpeedDialAction } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import {
  columnMenuStyles,
  CustomVersionHistoryStatsDataGrid,
  panelStyles,
} from "../../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import CustomUpload from "../../uploadFile/CustomUpload";
import ConfirmDialogue from "../../confirmDialogue/ConfirmDialogue";
import { REPO_STATUS } from "../../../../constants/repo-constants";
import { hasPermittedRolesOrAttributes } from "../../../helpers/user-roles-and-attributes";
import { ROLES } from "../../../../constants/role-constants";

import classes from "../../../ui/DataTable.module.css";

const LatestStatusTable = ({
  list,
  showUpload,
  setShowUpload,
  showConfirm,
  setShowConfirm,
  setParamId,
  handleOpenUpload,
  handleOpenConfirm,
  handleCloseUpload,
  handleCloseConfirm,
  selectedVersion,
  updateListFn,
}) => {
  const [versionStats, setVersionStats] = useState({
    count: 0,
    list: [],
  });

  const selectedOrganizationType = useSelector((state) =>
    state.userInfo.userInfo?.orgType === "Internal" ? true : false
  );

  function statusChecker(status, versionInfo, isInternal = false, isDisabled) {
    switch (status) {
      case "Added to Local Repository":
        return (
          // <Chip sx={{ fontFamily: "QualcommNext-Regular" }} label="Verify" />
          null
        );
        break;
      case "Verified by Publisher":
        if (!hasPermittedRolesOrAttributes([ROLES.customerMonitor]))
          return (
            <SpeedDialButton
              isDisable={isDisabled}
              title="Approve by Validator"
              showAction={true}
              handler={() =>
                handleOpenUpload({
                  ...versionInfo,
                  nextStatus: REPO_STATUS.validated,
                })
              }
              denied={() =>
                handleOpenConfirm({
                  ...versionInfo,
                  nextStatus: REPO_STATUS.denied,
                })
              }
            />
          );
        break;
      case "Approved by Validator":
        if (!isInternal) {
          return (
            <SpeedDialButton
              isDisable={isDisabled}
              title="Add to Global Repository"
              showAction={true}
              handler={() =>
                handleOpenConfirm({
                  ...versionInfo,
                  nextStatus: REPO_STATUS.addToGlobal,
                })
              }
            />
          );
        }
        break;
      case "Added to Global Repository":
        break;
      default:
        return "";
        break;
    }
  }

  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const columns = [
    {
      field: "version",
      headerName: "Version",
      flex: 0.8,
      sortable: false,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      minWidth: 100,
    },
  ];
  columns.push({
    field: "Actions",
    headerName: "",
    flex: 1,
    sortable: false,
    minWidth: 300,
    renderCell: (params) => {
      const isvStatus =
        selectedVersion?.isvStatus === "MARKED_FOR_DELETE" ? true : false;
      return statusChecker(
        params.row.status,
        params.row,
        selectedOrganizationType,
        isvStatus
      );
    },
  });

  useEffect(() => {
    const formattedList = list?.map((item) => {
      return {
        id: uuidv4(),
        version: item.version,
        status: item.status,
        featureName: item?.qcommFeatureName,
        featureVersionId: item?.featureVersionId,
      };
    });
    setVersionStats({
      count: formattedList.length,
      list: formattedList,
    });
  }, [showUpload, showConfirm]);

  return (
    <>
      {showUpload && (
        <CustomUpload
          onClose={handleCloseUpload}
          versionDetails={{
            ...selectedVersion,
            oldStatus: "PUBLISHED",
          }}
          setParamId={setParamId}
          updateListFn={updateListFn}
        />
      )}
      {showConfirm && (
        <ConfirmDialogue
          onClose={handleCloseConfirm}
          versionDetails={selectedVersion}
          setParamId={setParamId}
          updateListFn={updateListFn}
        />
      )}

      <div className={classes.data}>
        <CustomVersionHistoryStatsDataGrid
          sx={{
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "0.7rem",
            },
          }}
          getRowHeight={() => "auto"}
          columns={columns}
          rows={versionStats.list || []}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
          scrollbarSize={17}
          hideFooter
          getCellClassName={(params) => {
            if (params.field === "id") {
              return "id";
            }

            return "general";
          }}
          components={{
            NoRowsOverlay: CustomNoRowMessage,
          }}
          componentsProps={{
            toolbar: {
              reference: setColumnButtonEl,
              children: null,
            },
            panel: {
              anchorEl: columnButtonEl,
              sx: panelStyles,
            },
            columnMenu: {
              sx: columnMenuStyles,
            },
          }}
        />
      </div>
    </>
  );
};

export default LatestStatusTable;
