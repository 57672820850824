import { getAttachmentLinkApi } from "./apiEndpoints";
import axios from "axios";
//name of the function and filename should be same
export const getAttachmentLink = async (payload) => {
  //api call for get request
  let data;
  await axios
    .get(
      `${getAttachmentLinkApi}`,

      {
        params: payload,
      }
    )
    .then((response) => {
      // console.log("response is", response.data);
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
