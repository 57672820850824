import { memo } from "react";
import { MenuItem } from "@mui/material";
import CustomTextField from "../../ui/CustomTextField";
import { TIME_CONSTANTS } from "../../../constants/time-constants";

const Component = ({ item, handleTime }) => {
  return (
    <>
      <CustomTextField
        disabled={!item.checked}
        sx={{ width: "8em" }}
        size="small"
        type="text"
        // label="Reporting rate"
        focused
        select
        value={item.reportingRate}
        onChange={(e) => handleTime(item.metricName, e.target.value)}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          },
        }}
      >
        {["5m", ...TIME_CONSTANTS["hrs"]].map((item, key) => (
          <MenuItem
            sx={{ fontFamily: "QualcommNext-Regular" }}
            key={key}
            value={item}
          >
            {item}
          </MenuItem>
        ))}
      </CustomTextField>
    </>
  );
};
export const TimeRow = memo(Component);
