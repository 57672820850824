import { Fragment, useEffect, useMemo, useState } from "react";
import { Badge, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import EditTenant from "../EditTenant/EditTenant";
import CreateNewTenant from "../CreateNewTenant/CreateNewTenant";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { organizationHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getTenantsList, searchTenantName } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { BUTTONS, BUTTONS_TYPE } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import { tenantsActions } from "../../../store/slices/tenants-slice";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import DeleteTenant from "../DeleteTenant/DeleteTenant";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import editIcon from "../../../assets/images/common/edit.svg";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../hooks/useBreadcrumb";

import classes from "../../ui/DataTable.module.css";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import CommonFilter from "../../ui/Form/commonFilter/CommonFilter";
import { columnsActions } from "../../../store/slices/columns-slice";
import useSearchKeys from "../../../hooks/useSearchKeys";

let selectedRow = null;

const ISVTenantsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [tenantsList, setTenantsList] = useState({ count: 0, list: [] });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditTenant, setOpenEditTenant] = useState(false);
  const [openDeleteTenant, setOpenDeleteTenant] = useState(false);
  const [openCreateNewTenant, setOpenCreateNewTenant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [breadCrumbArray] = useBreadcrumb("orgInfo");
  const [filterOptions, setFilterOptions] = useState({
    tenantName: "",
    pageNo: 0,
    isFilterApplied: false,
  });
  const initialState = useSelector((state) => state.columnsInfo.isvTenants);
  const [searchStatefn] = useSearchKeys(["name"], initialState);

  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );

  const handleRowClick = (row) => {
    const data =
      row?.row != null
        ? {
            tenantId: row.row.id,
            tenantName: row.row.name,
            organizationId: row.row.organizationId,
            organization: row.row.name,
            tenantType: row.row.tenantType,
          }
        : null;
    dispatch(tenantsActions.setSelectedTenant(data));
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        row.id +
        PARTIAL_ROUTES.myFeatures
    );
  };

  const handleEdit = (params) => {
    selectedRow =
      params?.row != null
        ? {
            tenantId: params.row.id,
            tenantName: params.row.name,
            organizationId: params.row.organizationId,
            organization: params.row.organization,
            tenantType: params.row.tenantType,
          }
        : null;
    setOpenEditTenant(true);
  };

  const handlePageChange = (_, newPage) => {
    setIsLoading(true);
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
  };

  const handleCreateNewTenant = () => {
    setOpenCreateNewTenant(true);
  };

  const handleOpenDeleteUser = (params) => {
    selectedRow = params.row;
    setOpenDeleteTenant(true);
  };

  const closeCreateNewTenant = () => {
    setOpenCreateNewTenant(false);
  };

  const closeEditTenant = () => {
    selectedRow = null;
    setOpenEditTenant(false);
  };

  const closeDeleteUser = () => {
    selectedRow = null;
    setOpenDeleteTenant(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 6,
      minWidth: 150,
      hideable: false,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 100,
      hideable: false,
      renderCell: (params) => (
        <div>
          <EditIconButton
            size="small"
            onClick={(event) => {
              event.ignore = true;
              handleEdit(params);
            }}
          />
          <DeleteIcon
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteUser(params);
            }}
          />
        </div>
      ),
    });

  const headers = organizationHeaders({
    routes: {
      organizationInfo: CSS_ROUTES.organizations + "/" + params.organizationId,
      users:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.users,
      isvTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants,
      customerTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants,
    },
  });

  function fetchTenantList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      organizationId: selectedOrganization?.organizationId || null,
      tenantType: TENANT_TYPE.isv,
      tenantName: filterOptions.tenantName
        ? filterOptions.tenantName.trim()
        : undefined,
    };

    searchTenantName(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.tenants != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.tenants.map((item) => {
            return {
              id: item.tenantId,
              name: item.tenantName,
              organization: item.organization,
              organizationId: item.organizationId,
              tenantName: item.tenantName,
              tenantType: item.tenantType,
            };
          });
          setTenantsList({ count: response.data.count, list: formattedList });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchTenantList();
  }, [filterOptions]);

  const tenantDetails =
    selectedOrganization != null
      ? {
          organizationId: selectedOrganization.organizationId,
          organization: selectedOrganization.name,
          tenantType: TENANT_TYPE.isv,
        }
      : null;

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && tenantsList.list.length === 1) {
      setFilterOptions({
        ...filterOptions,
        pageNo: filterOptions.pageNo - 1,
      });
    } else {
      fetchTenantList();
    }
  }

  const buttons = [
    {
      key: "createNewTenant",
      text: BUTTONS.createTenant,
      clickHandler: handleCreateNewTenant,
      disabled: false,
      icon: <AddCircleOutlineIcon />,
      type: BUTTONS_TYPE.circle,
    },
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filterableitems = [
    {
      name: "name",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenantName,
    },
  ];

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      tenantName: data.name,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 3
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              tenantName: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openEditTenant && (
        <EditTenant
          onClose={closeEditTenant}
          tenantDetails={selectedRow}
          getUpdatedDataFn={fetchTenantList}
        />
      )}
      {openCreateNewTenant && (
        <CreateNewTenant
          onClose={closeCreateNewTenant}
          tenantDetails={tenantDetails}
          getUpdatedDataFn={fetchTenantList}
        />
      )}
      {openDeleteTenant && (
        <DeleteTenant
          onClose={closeDeleteUser}
          userDetails={selectedRow}
          tenantType="ISV"
          getUpdatedDataFn={fetchUpdated}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p> {selectedOrganization?.name}</p>
        </StyledBreadcrumbs>

        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.5rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setisvTenantsColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : tenantsList.list}
            disableColumnFilter
            onRowClick={(params, event) => {
              if (!event.ignore) {
                handleRowClick(params);
              }
            }}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: tenantsList.count,
                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.isvTenants },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ISVTenantsList;
