import { Fragment, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { customerDevicesHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getUserListBySearch } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { BUTTONS } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import EditUser from "../EditUser/EditUser";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import RemoveUserFromTenancy from "../../tenants/RemoveUserFromTenancy/RemoveUserFromTenancy";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import { columnsActions } from "../../../store/slices/columns-slice";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";
import CommonFilter from "../../ui/Form/commonFilter/CommonFilter";
import { BUTTONS_TYPE } from "../../../constants/panel-constants";
import DataTablePanelTest from "../../panel/DataTablePanelTest";

import classes from "../../ui/DataTable.module.css";

let selectedRow = null;

const TenantCustomerUsersList = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("devices");
  const initialState = useSelector((state) => state.columnsInfo.customerUsers);
  const [searchStatefn] = useSearchKeys(
    ["name", "phoneNo", "emailId", "tenantName", "role"],
    initialState
  );
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );

  const [isLoading, setIsLoading] = useState(true);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditUserForm, setOpenEditUserForm] = useState(false);
  const [removeUserFromTenancy, setRemoveUserFromTenancy] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    userName: "",
    emailId: "",
    phoneNo: "",
    role: "",
    pageNo: 0,
    isFilterApplied: false,
  });

  const params = useParams();
  const navigate = useNavigate();

  // const handlePageChange = (_, newPage) => {
  //   setPageNo(newPage - 1);
  // };

  const handlePageChange = (_, newPage) => {
    setIsLoading(true);
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditUserForm(!openEditUserForm);
  };

  const openRemoveUserFromTenancy = (params) => {
    selectedRow = params.row;
    setRemoveUserFromTenancy(true);
  };

  const handleAddUsers = () => {
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.associatedUsers +
        PARTIAL_ROUTES.availableUsers
    );
  };

  const closeEditUserForm = () => {
    selectedRow = null;
    setOpenEditUserForm(false);
  };

  const closeRemoveUserFromTenancy = () => {
    selectedRow = null;
    setRemoveUserFromTenancy(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      flex: 1,
      minWidth: 150,
    },
  ];

  hasPermittedRolesOrAttributes([
    ROLES.isvAdmin,
    ROLES.customerAdmin,
    ROLES.cssAdmin,
  ]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 100,
      hideable: false,
      renderCell: (params) => (
        <div>
          <EditIconButton size="small" onClick={() => handleEdit(params)} />
          <DeleteIcon
            size="small"
            onClick={() => openRemoveUserFromTenancy(params)}
          />
        </div>
      ),
    });

  const headers = customerDevicesHeaders({
    routes: {
      deviceList: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.devices
        : null,
      customerUsers:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.associatedUsers,

      features: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features
        : null,
      mdd: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd
        : null,
      mddDashboard: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mddDashboard
        : null,
    },
  });

  function fetchFilteredCustomerUsersList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      tenantId: params.customerId,
      name: filterOptions.userName ? filterOptions.userName.trim() : undefined,
      emailId: filterOptions.emailId ? filterOptions.emailId.trim() : undefined,
      phone: filterOptions.phoneNo || undefined,
      roleName: filterOptions.role ? filterOptions.role.trim() : undefined,
    };

    getUserListBySearch(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.userDetails != null &&
          response.data.count != null
        ) {
          const formattedList =
            response.data.userDetails.map((item) => {
              let role = "N/A";
              if (
                selectedTenant.tenantId != null &&
                selectedTenant.tenantId !== ""
              ) {
                role =
                  item.userRoleInfoJson[selectedTenant.tenantId]?.roles.join(
                    ","
                  );
              }
              return {
                id: item.userId,
                userId: item.userId,
                name: `${item.firstName} ${item.lastName}`,
                firstName: item.firstName,
                lastName: item.lastName,
                emailId: item.emailId,
                phoneNo: item.phoneNo,
                tenantName: item.tenants?.toString(),
                keycloakId: item.keycloakUserId,
                role: role,
              };
            }) || [];
          setUsersList({ count: response.data.count, list: formattedList });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchFilteredCustomerUsersList();
  }, [filterOptions]);

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && usersList.list.length === 1) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFilteredCustomerUsersList();
    }
  }
  const addUserButton = hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.cssAdmin,
  ])
    ? {
        key: "addUsers",
        text: BUTTONS.addUsers,
        clickHandler: handleAddUsers,
        disabled: false,
        icon: <AddCircleOutlineIcon />,
        type: BUTTONS_TYPE.circle,
      }
    : null;
  const buttons = [
    addUserButton,
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filterableitems = [
    {
      name: "name",
      label: "Name",
      placeholder: "Enter name",
      defaultValue: filterOptions.userName,
    },
    {
      name: "emailId",
      label: "Email",
      placeholder: "Enter email Id",
      defaultValue: filterOptions.emailId,
    },
    {
      name: "phoneNo",
      label: "Phone",
      placeholder: "Enter phone number",
      defaultValue: filterOptions.phoneNo,
      type: "number",
    },
    {
      name: "role",
      label: "Role",
      placeholder: "Enter role",
      defaultValue: filterOptions.role,
    },
  ];

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      userName: data.name,
      emailId: data.emailId,
      phoneNo: data.phoneNo,
      role: data.role,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 5
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              userName: "",
              emailId: "",
              phoneNo: "",
              role: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      <div className={classes.container}>
        {openEditUserForm && (
          <EditUser
            onClose={closeEditUserForm}
            userDetails={selectedRow}
            getUpdatedDataFn={fetchFilteredCustomerUsersList}
          />
        )}
        {removeUserFromTenancy && (
          <RemoveUserFromTenancy
            onClose={closeRemoveUserFromTenancy}
            userDetails={selectedRow}
            getUpdatedDataFn={fetchUpdated}
          />
        )}

        <DataTablePanelTest
          buttons={buttons}
          disableSearch={true}
          backButton={hasPermittedRolesOrAttributes([ROLES.cssAdmin]) && true}
        />

        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}

        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setcustomerUsersColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : usersList.list}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: usersList.count,

                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.users },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TenantCustomerUsersList;
