export const IFRAME_BASE_URL = {
  /* Update base URL for specific deployments */
  baseUrl: "https://api-qsea-dev.zytersmartspaces.com/grafana/d-solo",
  testBaseUrl: "https://api-qsea-test.zytersmartspaces.com/grafana/d-solo",
  uatBaseUrl: "https://api-qsea-dev.zytersmartspaces.com/grafana/d-solo",
  /* Add other base urls for specific components */
};

export const DASHBOARD_NAME = {
  cssAdmin: "css-admin-metrics",
  cloudMetricsMainView: "cloud-metrics-customer-tenant-main-view",
  cloudMetricsHistoryView: "cloud-metrics-customer-tenant-history-view",
  deviceMetricsMainView: "device-health-metrics-main-view",
  deviceMetricsHistoryView: "device-health-metrics-history-view",
  deviceMetricsLastReportedView: "device-health-metrics-last-reported",
  qseaMetricsMainView: "qsea-platform-metrics-main-view",
  qseaMetricsHistoryView: "qsea-platform-metrics-history-view",
  qseaMetricsLastReportedView: "qsea-platform-metrics-last-reported",
};
