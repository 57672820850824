export const BASE_URL = {
  /* Update base URL for specific deployments */
  baseUrl: "https://api-qsea-dev.zytersmartspaces.com",
  DevBaseUrl: "https://api-qsea-dev.zytersmartspaces.com",
  testBaseUrl: "https://api-qsea-dev.zytersmartspaces.com",
  uatBaseUrl: "https://api-qsea-dev.zytersmartspaces.com",
  KeycloakServerUrl: "https://keycloak.zytersmartspaces.com",
  /* Add other base urls for specific components */
};

const SUB_URL = {
  organizations: "/organizations",
  users: "/users",
  tenant: "/tenant",
  tenants: "/tenants",
  features: "/features",
  devices: "/devices",
  subscriptionPolicy: "/subscriptions",
  bundelInfo: "/bundel-info",
  customer: "/customer",
  qcomm: "/qcomm",
  featureownership: "/feature-ownership",
  featureAndPolicy: "/searchByFeatureTitle",
  roles: "/roles",
  archive: "/archive",
  feature: "/feature",
  featureCatalog: "/feature-catalog",
  metrics: "/metrics",
  version: "/version",
  sdk: "/sdk",
};

// const APP = {
//   css: "/css",
//   isv: "/isv",
//   customer: "/customer",
//   keycloak: "/admin/realms",
// };

const APP = {
  css: "/user-management",
  isv: "/feature-management",
  customer: "/device-management",
  notification: "/notification-management",
  keycloak: "/admin/realms",
  license: "/license-management",
  metricsManagement: "/metrics-management",
};

const VERSION = {
  v1: "/v1",
};

export const associateRoleToUserApi =
  BASE_URL.KeycloakServerUrl + APP.keycloak + "/$/users/$/role-mappings/realm";
export const getAllRealmRolesApi =
  BASE_URL.KeycloakServerUrl + APP.keycloak + "/$/roles";

export const getOrganizationsListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + "/organizations";
export const createNewOrganizationApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.organizations;
export const editOrganizationApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.organizations;
export const deleteOrganizationApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.organizations;
export const uploadFileApi = //for upload icon and get icon same api is being used
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.organizations;
export const getAllUserListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + "/users";
export const getAllChipsetsApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + "/chipset";
export const getTenantsListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + "/tenants";
export const getTenantsByIdApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants + "/";
export const getTenantsByUserIdApi =
  BASE_URL.baseUrl +
  APP.css +
  VERSION.v1 +
  SUB_URL.tenants +
  SUB_URL.tenant +
  "/";
export const createNewTenantApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants;
export const editTenantApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants;
export const deleteTenantApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants;
export const searchTenantNameApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants + "/search";

export const editUserApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users;
export const createNewUserApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + "/users";
export const getUserInfoByUserNameApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users + "/";
export const deleteUserApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users;
export const availableUserListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users + "/available";
export const addUsersToTenantApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users + "/tenant";
export const removeUserFromTenancyApi =
  BASE_URL.baseUrl +
  APP.css +
  VERSION.v1 +
  SUB_URL.users +
  "/removeUsersFromTenant";

export const searchOrgNameApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.organizations + "/search";

export const regenerateSecretIdApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.tenants + "/secret";

export const getUsersRolesApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.roles;

export const getOrgIconApi =
  BASE_URL.baseUrl +
  APP.css +
  VERSION.v1 +
  SUB_URL.organizations +
  "/downloadFile";

export const searchUserListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.users + "/search";

//-------------------------------------------sdk-controller----------------------------------------------

export const getAllSDKVersionListApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.sdk;

export const createPreferredVersionForSDKApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.sdk + "/preferredVersion";

export const getPreferredVersionForSDKByCustomerIdApi =
  BASE_URL.baseUrl + APP.css + VERSION.v1 + SUB_URL.sdk + "/preferredVersion";

//------------------------------------license=management--------------------------------------------------
export const getSubscribersListByTenantApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/subscribers";

export const searchFeatureNameAndIdApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/featureDetailsAndSubscriptionIdList";
export const getMyModelListApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/subscriberInfoList";
export const getFeatureAndPolicyDetailsApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  SUB_URL.featureAndPolicy;

export const createSubscriptionPolicyApi =
  BASE_URL.baseUrl + APP.license + VERSION.v1 + SUB_URL.subscriptionPolicy;

export const deleteSubscriptionPolicyApi =
  BASE_URL.baseUrl + APP.license + VERSION.v1 + SUB_URL.subscriptionPolicy;
export const editSubscriptionPolicyApi =
  BASE_URL.baseUrl + APP.license + VERSION.v1 + SUB_URL.subscriptionPolicy;
export const getSubscriptionPolicyInfoApi =
  BASE_URL.baseUrl + APP.license + VERSION.v1 + SUB_URL.subscriptionPolicy;
export const getSubscriptionPolicyInfoByFeatureApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/list";
export const searchFeatureAndPolicyDetailsApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/featureAndPolicyDetails";
export const getSubscriptionPolicyListApi =
  BASE_URL.baseUrl +
  APP.license +
  VERSION.v1 +
  SUB_URL.subscriptionPolicy +
  "/search";

// -----------------------------------feature-management-----------------------------------------------------

export const getTenantFeaturesListApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + "/features";
export const getFeatureInfoApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features + "/";
export const createNewFeatureApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features;
export const editFeatureApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features + "/";
export const deleteFeatureApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features;
export const uploadFeatureIconApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features;
export const getFeatureNameApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features;
export const searchFeatureNameApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.features +
  "/searchByFeatureName";
export const searchFeatureTitleApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.features +
  "/searchByFeatureTitle";
export const getVersionHistoryListApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.feature + "/version";

export const getFeatureDetailsByTenantIdApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features;
export const deleteVersionApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.feature + "/version";
export const getAllRepoApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.feature +
  "/version/repositories";
export const getAllRepoHistoryApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.feature + "/version";
BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.features +
  "/searchByFeatureName";
export const getBundelInfoApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.features +
  SUB_URL.bundelInfo +
  "/";
export const getFeatureTenantMapApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.featureownership +
  "/search";

export const getTenantNotMappedApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.featureownership +
  "/tenantNotMapped";
export const getCustomerFeatureInfoApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.features + SUB_URL.customer;

export const getFeatureByTenantApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.featureownership;
export const updateFeatureTenantMappingApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.featureownership;

export const updateFeatureVersionApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.feature + "/version";

export const getAllCatalogApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.featureCatalog;
export const getCatalogInfoApi =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.featureCatalog;
export const configureFeatureCatalog =
  BASE_URL.baseUrl + APP.isv + VERSION.v1 + SUB_URL.featureCatalog;
export const getAttachmentLinkApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.feature +
  "/version" +
  "/downloadFile";

export const updatePreferredVersionApi =
  BASE_URL.baseUrl +
  APP.isv +
  VERSION.v1 +
  SUB_URL.feature +
  SUB_URL.version +
  "/preferredVersion";

// --------------------------------device-management-------------------------------------------
export const getAllDevicesApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + "/devices";
export const getAllDevicesListApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + "/devices/list";
export const editDeviceTenantApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + "/devices/tenant/";
export const editDeviceApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + "/devices";
export const getDeviceInfoApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + SUB_URL.devices;
export const enableFeatureOnDevicesApi =
  BASE_URL.baseUrl +
  APP.customer +
  VERSION.v1 +
  SUB_URL.devices +
  "/enable-feature";
export const getAvailableDevicesListApi =
  BASE_URL.baseUrl +
  APP.customer +
  VERSION.v1 +
  SUB_URL.devices +
  "/features/availableDevices";
export const getDeviceLicensesStatApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + SUB_URL.devices + "/search";
export const getDeviceDownloadedFeatureCountApi =
  BASE_URL.baseUrl + APP.customer + VERSION.v1 + SUB_URL.devices + "/features";
export const getLicenseInstallCount =
  BASE_URL.baseUrl +
  APP.customer +
  VERSION.v1 +
  SUB_URL.devices +
  "/licenseInstalledCount";
export const getCloudDeviceApi =
  BASE_URL.baseUrl +
  APP.customer +
  VERSION.v1 +
  SUB_URL.devices +
  "/cloud-devices";

//------------------------------notification-management------------------------------------------

export const getAllNotificationsApi =
  BASE_URL.baseUrl +
  APP.notification +
  VERSION.v1 +
  "/notifications/getNotifications";
export const editNotificationApi =
  BASE_URL.baseUrl + APP.notification + VERSION.v1 + "/notifications";
export const createNotificationApi =
  BASE_URL.baseUrl + APP.notification + VERSION.v1 + "/notifications";
export const deleteNotificationsApi =
  BASE_URL.baseUrl + APP.notification + VERSION.v1 + "/notifications";
export const archiveNotificationsApi =
  BASE_URL.baseUrl + APP.notification + VERSION.v1 + "/notifications/archive";
export const getAllNotificationNameApi =
  BASE_URL.baseUrl + APP.notification + VERSION.v1 + "/notificationInfo";

export const getAllArchiveNotificationsApi =
  BASE_URL.baseUrl +
  APP.notification +
  VERSION.v1 +
  SUB_URL.archive +
  "/notifications/getNotifications";
export const deleteArchiveNotificationsApi =
  BASE_URL.baseUrl +
  APP.notification +
  VERSION.v1 +
  SUB_URL.archive +
  "/notifications";
export const UnArchiveNotificationsApi =
  BASE_URL.baseUrl +
  APP.notification +
  VERSION.v1 +
  SUB_URL.archive +
  "/notifications/unArchive";
export const editArchiveNotificationsApi =
  BASE_URL.baseUrl +
  APP.notification +
  VERSION.v1 +
  SUB_URL.archive +
  "/notifications";

//----------------------------------------metrics-management---------------------------------------------------------------
export const getAllMddListApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  "/metricsDefinition/list";

export const getAMddInfoByMddIdApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/metricsDefinition";

export const getAllMetricsApi =
  BASE_URL.baseUrl + APP.metricsManagement + VERSION.v1 + SUB_URL.metrics;

export const createMetricsDefinitionApi = //using multiple places this api
  BASE_URL.baseUrl + APP.metricsManagement + VERSION.v1 + "/metricsDefinition";
export const editMetricsDefinitionApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/metricsDefinition";

export const getAvailableDevicesForMDDApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/mddDeviceMapping/availableDevices";

export const getAssociatedDevicesForMDDApi = //using this api for fetching mddDevices mapping,creating mapping, deleting and updating
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/mddDeviceMapping";

export const deleteAssociatedDeviceApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/mddDeviceMapping";
export const getMddVersionsApi =
  BASE_URL.baseUrl +
  APP.metricsManagement +
  VERSION.v1 +
  // SUB_URL.metrics +
  "/metricsDefinition/versions";
