import { useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { metricsActions } from "../../../store/slices/slices-index";

import styles from "./MetricPreferences.module.css";

const MetricPreferences = ({
  selectedMetrics,
  handleClose,
  type,
  deviceId,
}) => {
  const dispatch = useDispatch();
  const [metricsData, setMetricsData] = useState(selectedMetrics);
  const handleBulk = (value) => {
    setMetricsData({
      [deviceId]: metricsData[deviceId].map((item) => {
        return {
          ...item,
          selected: value,
        };
      }),
    });
  };

  const handleClick = (checked, value) => {
    setMetricsData({
      [deviceId]: metricsData[deviceId].map((item) => {
        if (item.metricId === value.metricId) {
          return {
            ...item,
            selected: checked,
          };
        } else {
          return item;
        }
      }),
    });
  };
  const handleSubmit = () => {
    if (
      metricsData[deviceId]?.filter((item) => item.selected === true).length > 0
    ) {
      if (type === "qseaPlatformMetrics") {
        dispatch(
          metricsActions.setQseaPlatformsstate({
            [deviceId]: metricsData[deviceId],
          })
        );
      } else {
        dispatch(
          metricsActions.setDeviceMetricstate({
            [deviceId]: metricsData[deviceId],
          })
        );
      }
      handleClose();
    } else {
      toast.error("Please select atleast one metric to display");
    }
  };

  return (
    <>
      <FormControlLabel
        disableTypography
        sx={{
          fontFamily: "QualcommNext-Medium",
          fontSize: "1rem",
          margin: "0.3rem 0",
          marginBottom: 0,
        }}
        control={
          <Checkbox
            size="small"
            onClick={(e) => handleBulk(e.target.checked)}
            checked={
              metricsData.hasOwnProperty(deviceId) &&
              metricsData[deviceId]?.filter((item) => item.selected != true)
                .length > 0
                ? false
                : true
            }
          />
        }
        label="Select All Metrics"
      />
      <hr />
      <div className={styles.metricContainer}>
        <div>
          {metricsData[deviceId]?.map((value) => {
            return (
              <div key={value.metricId}>
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox
                      size="small"
                      checked={value.selected}
                      onClick={(e) => handleClick(e.target.checked, value)}
                    />
                  }
                  label={value.displayName}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        size="small"
        fullWidth
        color="info"
        sx={{
          fontFamily: "QualcommNext-Regular",
          textTransform: "none",
          borderRadius: 0,
          backgroundColor: "#4770a4",
        }}
      >
        Apply
      </Button>
    </>
  );
};
export default MetricPreferences;
