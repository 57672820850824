import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

import CustomTextField from "../CustomTextField";
import CancelButton from "../Buttons/CancelButton";
import SubmitButton from "../Buttons/SubmitButton";
import UploadFileButton from "../Buttons/UploadFileButton";
import MenuItem from "@mui/material/MenuItem";
import { StyledFormControlForMultiselect } from "../StyledFormControlForMultiselect";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { StyledPopper } from "../StyledPopper";
import { debounce } from "../../helpers/utils";
import classes from "./BasicForm.module.css";

const BasicForm = (props) => {
  const { schema, onClose, onSubmit, openUploadFile, inputs } = props.details;

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
  });

  const registerHandler = (data) => {
    console.log("FORM: ", data);
    if (isDirty) {
      onSubmit(data);
    }
  };

  const handleDelete = (onChange, value, selected) => {
    const filteredData = selected.filter((v) => v !== value);
    onChange(filteredData);
  };

  const removeEmptySpaces = (stringVal) => {
    return /\s/g.test(stringVal);
  };

  const trimmedValue = (event) => {
    const isValid = removeEmptySpaces(event.target.value);
  };

  const handleInputs = () => {
    const rows = [];
    for (let i = 0; i < inputs.length; ) {
      let row = [];
      for (let j = 0; j < 2 && i < inputs.length; j++, i++) {
        const {
          apiCall,
          apiOptions,
          setInputChange,
          name,
          label,
          placeholder,
          defaultValue,
          type,
          disabled,
          select,
          multiselect,
          multiple,
          selectOptions = [],
          textarea,
          header,
          checkbox,
          radio,
          group,
          subscribed = [],
          maxLength = 255,
        } = inputs[i];
        const required = schema?.fields?.[name]?.exclusiveTests?.required;
        const options = selectOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            disabled={subscribed?.includes(option)}
            sx={{
              fontFamily: "QualcommNext-Regular",
            }}
          >
            {option}
          </MenuItem>
        ));
        if (header != null) {
          if (row.length === 0) {
            rows.push(
              <div key={"header#"} className={classes.row}>
                <p className={classes.header}>{header}</p>
              </div>
            );
          } else {
            rows.push(
              <div key={`${i}_${j}`} className={classes.row}>
                {row}
              </div>
            );
            rows.push(
              <div key={"header$"} className={classes.row}>
                <p className={classes.header}>{header}</p>
              </div>
            );
            row = [];
          }
          continue;
        }
        if (!!multiselect) {
          row.push(
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value } }) => (
                <StyledFormControlForMultiselect
                  required={!!required}
                  focused
                  fullWidth
                >
                  <InputLabel
                    id={`label-${name}`}
                    shrink={true}
                    sx={{
                      fontFamily: "QualcommNext-Regular",
                      fontSize: "1.12rem",
                    }}
                  >
                    {label}
                  </InputLabel>
                  <Select
                    sx={{
                      fontFamily: "QualcommNext-Regular",
                      fontSize: "1.12rem",
                    }}
                    onChange={onChange}
                    // onBlur={onBlur}
                    multiple
                    notched={true}
                    value={value || []}
                    label={label}
                    labelId={`label-${name}`}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <div key={value} className={classes.chip}>
                            {value}
                            {!subscribed?.includes(value) && (
                              <HighlightOffIcon
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  handleDelete(onChange, value, selected);
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </Box>
                    )}
                  >
                    {options}
                  </Select>
                </StyledFormControlForMultiselect>
              )}
            />
          );
        } else if (apiCall != null) {
          row.push(
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Autocomplete
                  id={name}
                  size="small"
                  // freeSolo
                  clearOnEscape
                  disablePortal
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option.subscriptionId === value.subscriptionId
                  }
                  options={apiOptions}
                  multiple={!!multiple}
                  // onBlur={onBlur}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onInputChange={debounce((event) => {
                    setInputChange(event.target.value);
                  })}
                  value={value || null}
                  PopperComponent={StyledPopper}
                  sx={{
                    ".MuiAutocomplete-tag": {
                      fontFamily: "QualcommNext-Regular",
                    },
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        required: !!required && value?.length === 0,
                      }}
                      focused
                      required={!!required}
                      label={label}
                      placeholder={placeholder}
                    />
                  )}
                />
              )}
            />
          );
        } else if (checkbox != null) {
          row.push(
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <FormControlLabel
                  sx={{
                    ".MuiCheckbox-root": {
                      padding: "0 9px",
                    },

                    ".MuiTypography-root": {
                      fontFamily: "QualcommNext-Regular",

                      color: "#6e6e6e",

                      fontSize: "1rem",
                    },
                  }}
                  control={
                    <Checkbox
                      onChange={field.onChange}
                      checked={field.value || false}
                      {...field}
                      disabled={!!disabled}
                    />
                  }
                  key={name}
                  label={label}
                  labelPlacement="end"
                  value={field.value || false}
                />
              )}
            />
          );
        } else if (radio != null) {
          row.push(
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <FormControl
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "2em",
                  }}
                >
                  <FormLabel
                    sx={{
                      fontFamily: "QualcommNext-Regular",
                      color: "#6e6e6e",
                    }}
                  >
                    {label}
                  </FormLabel>
                  <RadioGroup row {...field}>
                    {group.map((item) => (
                      <FormControlLabel
                        sx={{
                          ".MuiTypography-root": {
                            fontFamily: "QualcommNext-Regular",
                            color: "#6e6e6e",
                          },
                        }}
                        key={item.label}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          );
        } else {
          row.push(
            <Controller
              key={name}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <CustomTextField
                  autoComplete={type === "password" ? "new-password" : "off"}
                  onChange={onChange}
                  onKeyPress={
                    type === "number"
                      ? (evt) => {
                          if (["e", "E", "+", "-", "."].includes(evt.key)) {
                            evt.preventDefault();
                          }
                        }
                      : null
                  }
                  // onBlur={onBlur}
                  size="small"
                  focused
                  fullWidth
                  id={name}
                  label={label}
                  value={value || ""}
                  type={type}
                  InputProps={{
                    inputProps: {
                      maxLength: maxLength,
                    },
                  }}
                  multiline={!!textarea}
                  rows={textarea != null ? 3 : null}
                  select={!!select}
                  required={!!required}
                  disabled={!!disabled}
                  placeholder={placeholder}
                  error={!!errors?.[name]}
                  helperText={errors?.[name] && errors[name]?.message}
                >
                  {options}
                </CustomTextField>
              )}
            />
          );
        }

        if (checkbox != null) {
          if (row.length === 1) {
            rows.push(
              <div key="row0" className={classes.row}>
                {row[0]}
              </div>
            );
          } else if (row.length === 2) {
            rows.push(
              <div key="row0" className={classes.row}>
                {row[0]}
              </div>
            );
            rows.push(
              <div key="row1" className={classes.row}>
                {row[1]}
              </div>
            );
          }
          j++;
          row = [];
        }
        if (radio != null) {
          if (row.length === 1) {
            rows.push(
              <div key="radio0" className={classes.row}>
                {row[0]}
              </div>
            );
          } else if (row.length === 2) {
            rows.push(
              <div key="radio0" className={classes.row}>
                {row[0]}
              </div>
            );
            rows.push(
              <div key="radio1" className={classes.row}>
                {row[1]}
              </div>
            );
          }
          j++;
          row = [];
        }
        if (textarea != null || multiselect != null) {
          i++;
          break;
        }
      }
      row.length > 0 &&
        rows.push(
          <div key={i} className={classes.row}>
            {row}
          </div>
        );
    }

    return rows;
  };
  return (
    <form
      className={classes.form}
      // autoComplete="off"
      onSubmit={handleSubmit(registerHandler)}
    >
      {handleInputs()}
      {openUploadFile != null && (
        <div key="upload" className={classes.logo}>
          <UploadFileButton
            onClick={openUploadFile.open}
            text={openUploadFile.text}
          />
          <div></div>
        </div>
      )}
      <div key={"buttons"} className={classes.buttons}>
        <CancelButton onClick={onClose} text={"Cancel"} />
        <SubmitButton text={"Submit"} type={"submit"} disabled={!isDirty} />
      </div>
    </form>
  );
};

export default BasicForm;
