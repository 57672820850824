import { Button } from "@mui/material";

const UserSettingsHeaderTab = (props) => {
  const { id, text, onClick, active, img } = props;

  const properties = {
    borderBottom: "4px solid transparent",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    textTransform: "none",
    color: "#214572",
    padding: "0.4em 1em 0.4em 1em",
    fontFamily: "QualcommNext-Regular",
    fontSize: "clamp(1rem, 0.9088rem + 0.1351vw, 1.125rem)",
    ".MuiButton-startIcon": {
      pointerEvents: "none",
    },
  };

  if (active != null) {
    properties.borderBottom = "4px solid steelblue";
  }
  return (
    <Button id={id} sx={properties} onClick={onClick} startIcon={img}>
      {text}
    </Button>
  );
};

export default UserSettingsHeaderTab;
