import IconButton from "@mui/material/IconButton";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

const DeleteIcon = (props) => {
  const {
    onClick,
    size,
    color = "#FF6464",
    tooltipText = "Delete",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton
          aria-label="delete"
          size={size != null ? size : "large"}
          onClick={onClick != null ? onClick : null}
          disabled={disabled}
        >
          <MUIDeleteIcon sx={{ color: disabled ? "grey" : color, width: 22 }} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default DeleteIcon;
