import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",

  "&.MuiAccordion-root": {
    background: "#FDFDFD",
    border: "1px solid #999494",
    borderRadius: "12px",
    boxShadow: "none",
  },
  "&:before": {
    display: "none",
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  cursor: "text !important",
  position: "relative",
  paddingLeft: "5px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    cursor: "pointer",
    // position: "absolute",
    // top: -15,
    // right: 10,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "&.MuiAccordionSummary-root.Mui-expanded": {
    paddingLeft: "5px",
  },
  "&.MuiAccordionSummary-root.Mui-focusVisible": {
    backgroundColor: "#fff",
  },
}));
export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  // display: "flex",
  // flexWrap: "wrap",
  // width: "100%",
  // alignItems: "center",
  // justifyContent: "space-between",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))",
  gap: "1rem",
  marginLeft: "1rem",
}));
