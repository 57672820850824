import { Fragment, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import EditTenant from "../EditTenant/EditTenant";
import CreateNewTenant from "../CreateNewTenant/CreateNewTenant";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import { panelStyles, columnMenuStyles, CustomDataGrid } from "../../ui/CustomDataGrid";
import { tenantHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getTenantsByUserId } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { TENANTS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { ROLES } from "../../../constants/role-constants";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import { tenantsActions } from "../../../store/slices/tenants-slice";
import KeycloakConfig from "../../../config/keycloak-config";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import editIcon from "../../../assets/images/common/edit.svg";

import classes from "../../ui/DataTable.module.css";

let selectedRow = null;

const TenantCustomerList = () => {
  const [pageNo, setPageNo] = useState(0);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditTenant, setOpenEditTenant] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state.userInfo.userInfo?.userId);

  const handleRowClick = (params) => {
    const data =
      params?.row != null
        ? {
            tenantId: params.row.id,
            tenantName: params.row.name,
            organizationId: params.row.organizationId,
            organization: params.row.name,
            tenantType: params.row.tenantType,
          }
        : null;
    dispatch(tenantsActions.setSelectedTenant(data));
    // if (hasPermittedRolesOrAttributes([ROLES.customerAdmin])) {
    navigate(TENANTS_ROUTES.customerTenants + "/" + params.id + PARTIAL_ROUTES.devices);
    // }
  };

  const handleEdit = (params) => {
    selectedRow =
      params?.row != null
        ? {
            tenantId: params.row.id,
            tenantName: params.row.name,
            organizationId: params.row.organizationId,
            organization: params.row.name,
            tenantType: TENANT_TYPE.customer,
          }
        : null;
    setOpenEditTenant(true);
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const closeEditTenant = () => {
    selectedRow = null;
    setOpenEditTenant(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Tenant ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 6,
      minWidth: 150,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.customerAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 50,
      renderCell: (params) => (
        <div>
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleEdit(params);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </div>
      ),
    });

  const headers = tenantHeaders({
    routes: {
      // isvList: TENANTS_ROUTES.isvTenants,
      customersList: TENANTS_ROUTES.customerTenants,
    },
  });

  useEffect(() => {
    const options = {
      userId: userId,
      params: {
        pageNo: pageNo,
        pageSize: PAGE_SIZE,
        organizationId: KeycloakConfig.getOrganizationId(),
        tenantType: TENANT_TYPE.customer,
      },
    };

    getTenantsByUserId(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.tenants != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.tenants.map((item) => {
            return {
              id: item.tenantId,
              name: item.tenantName,
              organization: item.organization,
              organizationId: item.organizationId,
              tenantName: item.tenantName,
              tenantType: item.tenantType,
            };
          });
          setUsersList({ count: response.data.count, list: formattedList });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [pageNo, openEditTenant]);

  return (
    <Fragment>
      {openEditTenant && <EditTenant onClose={closeEditTenant} tenantDetails={selectedRow} />}
      <div className={classes.container}>
        <DataTablePanel
          list={usersList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={["id", "name"]}
        />
        <p>Tenants</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  usersList.list.length === filteredList.length
                    ? usersList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TenantCustomerList;
