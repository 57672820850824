import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import RightBottomChevronIcon from "../../../ui/Buttons/RightBottomChevronIcon";
import DataTablePanel from "../../../panel/DataTablePanel";
import DataTableHeaders from "../../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";

import { featureRepositoriesHeaders } from "../../../helpers/header-helpers";
import CustomPagination from "../../../ui/CustomPagination";
import CustomToolbar from "../../../ui/CustomToolbar";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import { getAllRepo } from "../../../../api/api-index";
import { PAGE_SIZE } from "../../../../constants/page-constants";
import { CSS_ROUTES } from "../../../../constants/route-constants";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import errorHandler from "../../../helpers/error-helper";
import DeleteIcon from "../../../ui/Buttons/DeleteIcon";
import LatestStatusTable from "./LatestStatusTable";
import DeleteFeature from "../../DeleteFeature/DeleteFeature";
import { REPO_STATUS, REPO_TYPE } from "../../../../constants/repo-constants";
import useSearchKeys from "../../../../hooks/useSearchKeys";
import { columnsActions } from "../../../../store/slices/columns-slice";
import { FeatureChip } from "../../../helpers/utils";
import { NO_ROW_MESSAGES } from "../../../../constants/no-row-message-constants";

import classes from "../../../ui/DataTable.module.css";
import vclasses from "./LocalRespositories.module.css";
import CommonFilter from "../../../ui/Form/commonFilter/CommonFilter";
import { BUTTONS, BUTTONS_TYPE } from "../../../../constants/panel-constants";
import { Badge } from "@mui/material";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";

let selectedRow = null;
let selectedVersion = null;
const LocalRepositories = () => {
  const dispatch = useDispatch();
  const initialState = useSelector((state) => state.columnsInfo.localRepo);
  const [searchStatefn] = useSearchKeys(
    ["organization", "tenant", "featureTitle"],
    initialState
  );
  const selectedOrganizationType = useSelector(
    (state) => state.userInfo.userInfo?.orgType
  );
  const selectedAppType = useSelector(
    (state) =>
      state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
        ?.appType
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    orgName: "",
    featureTitle: "",
    tenantName: "",
    pageNo: 0,
    isFilterApplied: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [paramId, setParamId] = useState(null);
  const [localFeatureVersionList, setLocalFeatureVersionList] = useState({
    count: 0,
    list: [],
  });
  const [openDeleteVersion, setOpenDeleteVersion] = useState(false);

  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleOpenUpload = (params) => {
    selectedVersion = params;
    setShowUpload(true);
  };
  const handleOpenConfirm = (params) => {
    selectedVersion = params;
    setShowConfirm(true);
  };

  const handleCloseUpload = () => {
    setShowUpload(false);
  };
  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const navigate = useNavigate();

  const handleRowClick = (params) => {
    /*TODO: change route */
    navigate(CSS_ROUTES.localRepositories + "/" + params.row.id);
  };

  const handlePageChange = (_, newPage) => {
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
    setIsLoading(true);
  };

  const handleOpenHistory = (row, list) => {
    if (paramId == null) {
      selectedVersion = row;
      setParamId({
        feature: row.id,
        list: list.map((i) => i),
      });
    } else {
      setParamId(null);
    }
  };
  const handleOpenDeleteVersion = (params) => {
    selectedRow = {
      featureName: params.row.qfeatureName,
      featureTitle: params.row.featureTitle,
      id: params.row.featureId,
    };

    setOpenDeleteVersion(true);
  };

  const closeDeleteVersion = () => {
    selectedRow = null;
    setOpenDeleteVersion(false);
  };

  const columns = [
    {
      field: "featureTitle",
      headerName: "Feature Title",
      flex: 1,
      minWidth: 250,
      hideable: false,
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "tenant",
      headerName: "Tenant Name",
      flex: 1,
      minWidth: 250,
    },
  ];
  columns.push({
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    minWidth: 100,
    hideable: false,
    renderCell: (params) => (
      <div style={{ display: "flex" }}>
        {selectedOrganizationType != "Internal" && params.row.isDeletable && (
          <DeleteIcon
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteVersion(params);
            }}
          />
        )}

        <RightBottomChevronIcon
          onClick={(event) => {
            event.stopPropagation();
            handleOpenHistory(params.row, params.row.latestStatus);
          }}
          active={!!paramId?.feature && paramId?.feature === params.row.id}
        />
        {!!paramId?.feature && paramId?.feature === params.row.id && (
          <div
            className={vclasses.vcontainer}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <LatestStatusTable
              list={paramId.list}
              showUpload={showUpload}
              setShowUpload={setShowUpload}
              showConfirm={showConfirm}
              setShowConfirm={setShowConfirm}
              setParamId={setParamId}
              handleOpenUpload={handleOpenUpload}
              handleOpenConfirm={handleOpenConfirm}
              handleCloseUpload={handleCloseUpload}
              handleCloseConfirm={handleCloseConfirm}
              selectedVersion={selectedVersion}
              updateListFn={fetchFiltereredLocalRepo}
            />
          </div>
        )}
        <div style={{ marginLeft: "1em" }}>
          {params.row.isvStatus && FeatureChip("Marked for delete by ISV")}
        </div>
      </div>
    ),
  });
  const headers = featureRepositoriesHeaders({
    routes: {
      localRepo: CSS_ROUTES.localRepositories,
      globalRepo: CSS_ROUTES.globalRepositories,
    },
  });

  function fetchFiltereredLocalRepo() {
    const options = {
      repositoryType: REPO_TYPE.local,
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      featureTitle: filterOptions.featureTitle
        ? filterOptions.featureTitle.trim()
        : undefined,
      organizationName: filterOptions.orgName
        ? filterOptions.orgName.trim()
        : undefined,
      tenantName: filterOptions.tenantName
        ? filterOptions.tenantName.trim()
        : undefined,
    };

    getAllRepo(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.featureRepositoryList != null &&
          response.data.totalCount != null
        ) {
          const formattedList =
            response.data.featureRepositoryList.map((item) => {
              return {
                id: item?.qcommFeatureName,
                organization: item?.organizationName,
                tenant: item?.tenantName,
                qfeatureName: item?.qcommFeatureName,
                featureTitle: item?.featureTitle,
                featureId: item?.featureId,
                isDeletable: item?.isDeletable,
                latestStatus: item?.featureRepositoryDetailsList,
                isvStatus: item?.isvStatus,
              };
            }) || [];
          setLocalFeatureVersionList({
            count: response.data.totalCount,
            list: formattedList,
          });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  const filterableitems = [
    {
      name: "featureTitle",
      label: "Feature Title",
      placeholder: "Enter feature title",
      defaultValue: filterOptions.featureTitle,
    },
    {
      name: "organization",
      label: "Organization Name",
      placeholder: "Enter organization name",
      defaultValue: filterOptions.orgName,
    },
    {
      name: "tenant",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenantName,
    },
  ];

  useEffect(() => {
    fetchFiltereredLocalRepo();
  }, [filterOptions]);

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every((x) => x === null || x === "");
    setFilterOptions({
      orgName: data.organization,
      featureTitle: data.featureTitle,
      tenantName: data.tenant,
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  function fetchUpdated() {
    if (
      filterOptions.pageNo > 0 &&
      localFeatureVersionList?.list?.length === 1
    ) {
      setFilterOptions({ ...filterOptions, pageNo: filterOptions.pageNo - 1 });
    } else {
      fetchFiltereredLocalRepo();
    }
  }

  const buttons = [
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 3
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);
  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              orgName: "",
              featureTitle: "",
              tenantName: "",
              pageNo: 0,
              isFilterApplied: false,
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openDeleteVersion && (
        <DeleteFeature
          onClose={closeDeleteVersion}
          userDetails={selectedRow}
          repositoryType={REPO_TYPE.local}
          selectedAppType={selectedAppType}
          getUpdatedDataFn={fetchUpdated}
        />
      )}

      <div className={classes.container}>
        <DataTablePanelTest
          backButton={false}
          disableSearch={true}
          buttons={buttons}
        />
        <p>Feature Repositories</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.7rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setlocalRepoColumnsState(newModel))
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : localFeatureVersionList.list}
            disableColumnFilter
            onRowClick={(params) => handleRowClick(params)}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }

              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: localFeatureVersionList.count,
                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.localRepositories,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LocalRepositories;
