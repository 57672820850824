import NotificationContainer from "./NotificationContainer";
import NotificationContent from "./NotificationContent";
import NotificationHeader from "./NotificationHeader";

import classes from "./Notification.module.css";

const Notification = ({
  handleClose,
  value,
  setValue,
  notifcations,
  setForceUpdate,
}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <NotificationHeader value={value} handleChange={handleChange} />
      <NotificationContent value={value} index={0}>
        {notifcations.map((item) => (
          <NotificationContainer
            key={item.notificationId}
            item={item}
            setForceUpdate={setForceUpdate}
            handleClose={handleClose}
          />
        ))}
        {notifcations.length === 0 && (
          <div className={classes.noData}>No alerts found!</div>
        )}
      </NotificationContent>
      <NotificationContent value={value} index={1}>
        {notifcations.map((item) => (
          <NotificationContainer
            key={item.notificationId}
            item={item}
            setForceUpdate={setForceUpdate}
          />
        ))}
        {notifcations.length === 0 && (
          <div className={classes.noData}>No events found!</div>
        )}
      </NotificationContent>
    </div>
  );
};
export default Notification;
