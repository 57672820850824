import classes from "./About.module.css";

const About = (props) => {
  const { text, header } = props;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.header}>{header}</div>
        <div className={classes.text}>{text || "N/A"}</div>
      </div>
    </div>
  );
};

export default About;
