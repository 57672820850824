import * as yup from "yup";
const errorMessages = {
  featureName: {
    required: "Feature name is required",
  },

  policyChipsets: {
    required: "Chipset number  is required",
  },

  policyMaxDevices: {
    positive: "Number must be greater than 0",
  },

  policyExpirationDate: {
    required: "Expiration date  is required",
  },

  customerOrganizationName: {
    required: "Name is required",
  },

  customerTenantName: {
    required: "Name is required",
  },
  policyName: {
    required: "Policy name is required",
  },
  contractId: {
    required: "Policy id is required",
  },
};

export const schema = yup
  .object({
    policyName: yup
      .string()
      .required(errorMessages.policyName.required)
      .matches(/^(?!\s*$).+/g, errorMessages.policyName.required),
    contractId: yup.string(),
    featureName: yup.object().required(errorMessages.featureName.required),

    customerOrganizationName: yup
      .object()
      .required(errorMessages.customerOrganizationName.required),

    customerTenantName: yup
      .string()
      .required(errorMessages.customerTenantName.required),
  })
  .required();
