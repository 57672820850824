import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-input, & .MuiInput-underline": {
    fontFamily: "QualcommNext-Regular",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontSize: "clamp(0.8125rem, 0.7213rem + 0.1351vw, 0.9375rem)",
    fontFamily: "QualcommNext-Regular",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #c0c0c0",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #F12121",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: "1.12rem",
  },
  "& .MuiInputLabel-root": {
    color: "#6e6e6e",
    fontFamily: "QualcommNext-Regular",
    fontSize: "1.12rem",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#6e6e6e",
    fontFamily: "QualcommNext-Regular",
    fontSize: "1.12rem",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "1.12rem",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#F12121",
  },
  "& .MuiFormLabel-asterisk": {
    color: "#F12121",
  },
}));

export default CustomTextField;
