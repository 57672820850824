import { useEffect, Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";

import classes from "./SDKPreference.module.css";
import { getPreferredVersionForSDKById } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import EditSDKPreference from "./EditSDKPreference";
import EditIconButton from "../../ui/Buttons/EditIconButton";

let selectedRow = null;
const SDKPreference = ({ selectedAppType }) => {
  const [loading, setloading] = useState(true);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [openEditTenant, setOpenEditTenant] = useState(false);
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);
  const [details, setDetails] = useState([]);

  const handleEdit = () => {
    selectedRow = {
      id: selectedAppKey,
    };

    setOpenEditTenant(true);
  };

  const closeEditTenant = () => {
    selectedRow = null;
    setOpenEditTenant(false);
  };
  useEffect(() => {
    getSDKVersion();
  }, []);

  function getSDKVersion() {
    const payload = {
      customerId: selectedAppKey,
      repositoryType: null,
    };
    getPreferredVersionForSDKById(payload)
      .then((response) => {
        if (response.status && response.data != null) {
          setDetails(response.data);
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
        setloading(false);
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }

  return loading ? (
    <div className={classes.loader}>
      <LinearProgress />
    </div>
  ) : (
    <Fragment>
      {openEditTenant ? (
        <div className={classes.editContainer}>
          <EditSDKPreference
            onClose={closeEditTenant}
            details={selectedRow}
            getUpdatedDataFn={getSDKVersion}
          />
        </div>
      ) : (
        <div className={classes.bgContainer}>
          <div className={classes.tenantHeaders}>
            QSEA-SDK Management
            <EditIconButton onClick={handleEdit} size="small" />
          </div>

          <table className={classes.settingsTable}>
            {details.map((value) => {
              return (
                <div>
                  <p>{value.repositoryType}</p>
                  <tr>
                    <th>
                      Preference Type :
                      {value.preferenceType === "NA"
                        ? "N/A"
                        : value.preferenceType}
                    </th>
                    {/* <td>- {value.preferenceType || "-"}</td> */}
                  </tr>
                  <tr>
                    <th>
                      Preferred Version :
                      {value.preferredVersion === "0.0.0"
                        ? "N/A"
                        : value.preferredVersion}
                    </th>
                    {/* <td>- {value.preferredVersion || "-"}</td> */}
                  </tr>
                </div>
              );
            })}
          </table>
        </div>
      )}
    </Fragment>
  );
};
export default SDKPreference;
