import {
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import PanelButtonTest from "../ui/Buttons/PanelButtonTest";

import classes from "./ButtonsAndSearch.module.css";

let text = null;
const ButtonsAndSearch = (props) => {
  const {
    list,
    setFilteredList,
    filterKeys,
    disableSearch,
    buttons,
    onlyIcon = false,
    disabled,
    setKeys,
  } = props;
  const handleSearch = (event) => {
    text = event;
    const searchTerm = event;
    if (searchTerm.length > 0) {
      const filteredList = [];
      const lowerCaseSearchTerm = searchTerm?.toLowerCase();
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < filterKeys.length; j++) {
          const key = filterKeys[j];
          if (
            list[i][key]
              ?.toString()
              ?.toLowerCase()
              .includes(lowerCaseSearchTerm)
          ) {
            filteredList.push(list[i]);
            break;
          }
        }
      }
      setFilteredList(filteredList);
    } else {
      setFilteredList(list);
    }
    setKeys != null && setKeys(searchTerm);
  };
  useEffect(() => {
    if (text) {
      handleSearch(text);
    }
  }, [list]);
  useEffect(() => {
    return () => {
      text = null;
    };
  }, []);

  return (
    <div className={classes.container}>
      {buttons?.map((button, key) => {
        if (button) {
          if (button?.onlyIcon) {
            return button.disabled ? (
              <IconButton key={key} disabled>
                <Avatar sx={{ bgcolor: "#E8E8EA" }}>{button.icon}</Avatar>
              </IconButton>
            ) : button.tooltipText ? (
              <Tooltip key={key} title={button.tooltipText || ""}>
                <IconButton onClick={button?.clickHandler}>
                  <Avatar sx={{ bgcolor: "rgb(77, 105, 148)" }}>
                    {button.icon}
                  </Avatar>
                </IconButton>
              </Tooltip>
            ) : (
              <Avatar
                key={key}
                onClick={button?.clickHandler}
                sx={{ bgcolor: "rgb(77, 105, 148)" }}
              >
                {button.icon}
              </Avatar>
            );
          } else {
            return (
              <PanelButtonTest
                key={button?.key}
                text={button?.text}
                onClick={button?.clickHandler}
                disabled={button?.disabled}
                icon={button?.icon}
                type={button?.type}
                onlyIcon={onlyIcon}
              />
            );
          }
        } else {
          return;
        }
      })}
      {(disableSearch == null || !disableSearch) && (
        <TextField
          label="Search"
          type="search"
          size="small"
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

export default ButtonsAndSearch;
