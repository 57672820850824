import CatalogCollection from "../components/catalog/CatalogCollection";

import CatalogContainer from "../components/catalog/CatalogContainer";

import CatalogModel from "../components/catalog/CatalogModel";

const CatalogPage = ({ type }) => {
  return type === "MODEL" ? (
    <CatalogModel />
  ) : type === "CONTAINER" ? (
    <CatalogContainer />
  ) : (
    <CatalogCollection />
  );
};

export default CatalogPage;
