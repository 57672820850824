import IconButton from "@mui/material/IconButton";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Tooltip } from "@mui/material";

const ChangePasswordIcon = (props) => {
  const { onClick, size, tooltipText = "Change Password" } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label="change-password"
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <LockResetIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ChangePasswordIcon;
