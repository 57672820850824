import { Fragment, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import EditUser from "../../user/EditUser/EditUser";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { isvTenantsHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getAllUserList } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { BUTTONS } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import RemoveUserFromTenancy from "../RemoveUserFromTenancy/RemoveUserFromTenancy";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import editIcon from "../../../assets/images/common/edit.svg";
import EditIconButton from "../../ui/Buttons/EditIconButton";

import classes from "../../ui/DataTable.module.css";

let selectedRow = null;

const ISVTenantUsersList = () => {
  const [pageNo, setPageNo] = useState(0);
  const [usersList, setUsersList] = useState({ count: 0, list: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [removeUserFromTenancy, setRemoveUserFromTenancy] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleEdit = (params) => {
    selectedRow = params.row;
    setOpenEditUser(true);
  };

  const openRemoveUserFromTenancy = (params) => {
    selectedRow = params.row;
    setRemoveUserFromTenancy(true);
  };

  const closeEditUser = () => {
    selectedRow = null;
    setOpenEditUser(false);
  };

  const closeRemoveUserFromTenancy = () => {
    selectedRow = null;
    setRemoveUserFromTenancy(false);
  };

  const handleOpenPanel = () => {
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.availableUsers
    );
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "User ID",
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.cssAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <EditIconButton size="small" onClick={() => handleEdit(params)} />
          <DeleteIcon
            size="small"
            onClick={() => openRemoveUserFromTenancy(params)}
          />
        </div>
      ),
    });

  const headers = isvTenantsHeaders({
    routes: {
      isvUsers:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants +
        "/" +
        params.tenantId +
        PARTIAL_ROUTES.users,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      organizationId: params.organizationId,
      tenantId: params.tenantId,
    };

    getAllUserList(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.userDetails != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.userDetails.map((item) => {
            let role = "N/A";
            if (
              selectedTenant.tenantId != null &&
              selectedTenant.tenantId !== ""
            ) {
              role =
                item.userRoleInfoJson[selectedTenant.tenantId]?.roles.join(",");
            }
            return {
              id: item.userId,
              userId: item.userId,
              name: item.fullName,
              firstName: item.firstName,
              lastName: item.lastName,
              emailId: item.emailId,
              phoneNo: item.phoneNo,
              tenantName: item.tenants?.toString(),
              keycloakId: item.keycloakUserId,
              role: role,
              appType: item.userRoleInfoJson[selectedTenant.tenantId].appType,
            };
          });
          setUsersList({ count: response.data.count, list: formattedList });
          setFilteredList(formattedList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [pageNo, openEditUser, removeUserFromTenancy]);

  return (
    <Fragment>
      {openEditUser && (
        <EditUser onClose={closeEditUser} userDetails={selectedRow} />
      )}
      {removeUserFromTenancy && (
        <RemoveUserFromTenancy
          onClose={closeRemoveUserFromTenancy}
          userDetails={selectedRow}
        />
      )}
      <div className={classes.container}>
        <DataTablePanel
          name={BUTTONS.addUsers}
          list={usersList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={[
            "id",
            "name",
            "phoneNo",
            "emailId",
            "tenantName",
            "role",
          ]}
          backButton={true}
          clickHandler={handleOpenPanel}
        />
        <p>{selectedTenant?.tenantName}</p>
        <div className={classes.data}>
          {/* <DataTableHeaders headers={headers} /> */}
          <CustomDataGrid
            columns={columns}
            rows={filteredList || []}
            disableColumnFilter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  usersList.list.length === filteredList.length
                    ? usersList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ISVTenantUsersList;
