import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Tooltip } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const RemoveIconButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Remove",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label={tooltipText}
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <IndeterminateCheckBoxIcon
          sx={{ color: disabled ? "grey" : color, width: 22 }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default RemoveIconButton;
