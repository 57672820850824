import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import { CircularProgress } from "@mui/material";

import { organizationHeaders } from "../helpers/header-helpers";
import EditOrganization from "./EditOrganization/EditOrganization";
import DataTableHeaders from "../header/DataTableHeaders";
import OrganizationInfoItem from "./OrganizationInfoItem";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../constants/route-constants";
import addressIcon from "../../assets/images/organizations/address.svg";
import countryIcon from "../../assets/images/organizations/country.svg";
import emailIcon from "../../assets/images/organizations/email.svg";
import phoneIcon from "../../assets/images/organizations/phone.svg";
import internalOrg from "../../assets/images/organizations/internal-org.svg";
import zipcodeIcon from "../../assets/images/organizations/zipcode.svg";
import websiteIcon from "../../assets/images/organizations/website.svg";
import { fetchOrganizationInfo } from "../../store/actions/organizations-actions";
import noPreview from "../../assets/images/common/no-preview.svg";
import DataTablePanelTest from "../panel/DataTablePanelTest";
import DeleteIcon from "../ui/Buttons/DeleteIcon";
import EditUploadPhoto from "./EditOrganization/EditUploadPhoto";
import DeleteOrganization from "./DeleteOrganization/DeleteOrganization";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../hooks/useBreadcrumb";

import classes from "../ui/DataTable.module.css";
import infoClasses from "./OrganizationInfo.module.css";
import { getOrgIconData } from "../../api/api-index";
import errorHandler from "../helpers/error-helper";
import { dataURLtoFile } from "../helpers/utils";

let imgIcon = null;
const OrganizationInfo = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("orgInfo");
  const [loading, setloading] = useState(true);
  const params = useParams();
  const [openEditOrganizationForm, setOpenEditOrganizationForm] =
    useState(false);

  const [deleteOrg, setDeleteOrg] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(false);

  const [orgIcon, setOrgIcon] = useState(null);

  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );

  const headers = organizationHeaders({
    routes: {
      organizationInfo: CSS_ROUTES.organizations + "/" + params.organizationId,
      users:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.users,
      isvTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.isvTenants,
      customerTenants:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants,
    },
  });

  const closeEditOrganizationForm = () => {
    setOpenEditOrganizationForm(false);
  };

  const handleOpenPanel = () => {
    setOpenEditOrganizationForm(true);
  };

  const closeDeleteOrg = () => {
    setDeleteOrg(false);
  };
  const closeUploadLogo = () => {
    setUploadLogo(false);
  };

  const openDeleteOrg = () => {
    setDeleteOrg(true);
  };
  const openUploadLogo = () => {
    setUploadLogo(true);
  };

  const formattedData =
    selectedOrganization != null
      ? {
          id: selectedOrganization.organizationId,
          name: selectedOrganization.name,
          phone: selectedOrganization.phoneNumber,
          email: selectedOrganization.email,
          zipcode: selectedOrganization.zipcode,
          organizationIcon: null,
          website: selectedOrganization.website,
          address: selectedOrganization.address,
          country: selectedOrganization.country,
          orgType: selectedOrganization.orgType,
        }
      : null;

  useEffect(() => {
    const options = {
      organizationId: params.organizationId,
    };
    dispatch(fetchOrganizationInfo(options)).then((res) => {
      setloading(false);
    });
  }, [openEditOrganizationForm, deleteOrg, uploadLogo]);

  useEffect(() => {
    if (
      selectedOrganization.organizationIcon != null &&
      selectedOrganization.organizationId === params.organizationId
    ) {
      fetchOrgIcon(selectedOrganization.organizationIcon);
    } else {
      setOrgIcon(null);
    }
  }, [selectedOrganization.organizationIcon]);

  function fetchOrgIcon(path) {
    const options = {
      iconPath: path,
    };
    getOrgIconData(options)
      .then((response) => {
        if (response.status && response.data != null) {
          if (response.data) {
            let oldFile = response.data;

            setOrgIcon(oldFile);
          }
        } else {
          console.log(response.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const buttons = [
    {
      key: "edit",
      clickHandler: handleOpenPanel,
      disabled: false,
      icon: <EditIcon />,
      onlyIcon: true,
      tooltipText: "Edit",
    },
    {
      key: "logo",
      clickHandler: openUploadLogo,
      disabled: false,
      icon: <CameraOutlinedIcon />,
      onlyIcon: true,
      tooltipText: "Change logo",
    },
    {
      key: "delete",
      clickHandler: openDeleteOrg,
      disabled: false,
      icon: <DeleteIcon color="white" size="small" />,
      onlyIcon: true,
      // tooltipText: "Delete",
    },
  ];

  return loading ? (
    <div className={infoClasses.loader}>
      <CircularProgress />
    </div>
  ) : (
    <Fragment>
      {openEditOrganizationForm && (
        <EditOrganization
          onClose={closeEditOrganizationForm}
          data={formattedData}
        />
      )}
      {uploadLogo && (
        <EditUploadPhoto
          icon={selectedOrganization.organizationIcon}
          organizationId={formattedData.id}
          onClose={closeUploadLogo}
        />
      )}

      {deleteOrg && (
        <DeleteOrganization
          onClose={closeDeleteOrg}
          onDeleteClose={closeDeleteOrg}
          organizationId={formattedData.id}
          organizationName={formattedData.name}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p> {selectedOrganization?.name}</p>
        </StyledBreadcrumbs>

        <div className={classes.data}>
          <DataTableHeaders headers={headers} />
          {/* <div className={infoClasses.dummy}></div> */}
          <div className={infoClasses.content}>
            <div className={infoClasses.info}>
              {selectedOrganization && (
                <div className={infoClasses.items}>
                  {/* {selectedOrganization.orgType != null && (
                    <AlertBanner
                      severity="info"
                      text="This organization is managed internally"
                    />
                  )} */}
                  <div className={infoClasses.logo}>
                    <img
                      src={
                        orgIcon != null
                          ? "data:image/*;base64," + orgIcon
                          : noPreview
                      }
                      alt="logo"
                    />
                  </div>
                  <div className={infoClasses["info-items"]}>
                    <OrganizationInfoItem
                      text={selectedOrganization.phoneNumber}
                      header="Phone Number"
                      icon={phoneIcon}
                    />
                    <OrganizationInfoItem
                      text={selectedOrganization.address}
                      header="Address"
                      icon={addressIcon}
                    />
                    {/* <OrganizationInfoItem
                      text={selectedOrganization.region}
                      header="Region"
                      icon={regionIcon}
                    /> */}
                    <OrganizationInfoItem
                      text={selectedOrganization.country}
                      header="Country"
                      icon={countryIcon}
                    />
                    <OrganizationInfoItem
                      text={selectedOrganization.zipcode}
                      header="Zipcode"
                      icon={zipcodeIcon}
                    />
                    <OrganizationInfoItem
                      text={selectedOrganization.email}
                      header="Email"
                      icon={emailIcon}
                    />
                    <OrganizationInfoItem
                      text={selectedOrganization.website}
                      header="Website"
                      icon={websiteIcon}
                    />
                    {selectedOrganization.orgType != null && (
                      <OrganizationInfoItem
                        text={selectedOrganization.orgType}
                        header="Organization Type"
                        icon={internalOrg}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrganizationInfo;
