import { Fragment, useEffect } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

import CustomTextField from "../ui/CustomTextField";
import RenderOnPermittedRoleAndAttr from "../permission/RenderOnPermittedRoleAndAttr";
import { ROLES } from "../../constants/role-constants";
import PanelButton from "../ui/Buttons/PanelButton";
import editOrganizationIcon from "../../assets/images/organizations/edit-organizations.svg";

import classes from "./CreateEditSearch.module.css";

let text = null;
const CreateEditSearch = (props) => {
  const {
    name,
    list,
    setFilteredList,
    filterKeys,
    selectedUsers,
    simpleButton,
    editButton,
    clickHandler,
    policyTermsClickHandler,
    addUsersClickHandler,
    disableSearch,
  } = props;
  const handleSearch = (event = "") => {
    text = event;
    const searchTerm = event;
    if (searchTerm.length > 0) {
      const filteredList = [];
      const lowerCaseSearchTerm = searchTerm?.toLowerCase();
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < filterKeys.length; j++) {
          const key = filterKeys[j];
          if (
            list[i][key]
              ?.toString()
              ?.toLowerCase()
              .includes(lowerCaseSearchTerm)
          ) {
            filteredList.push(list[i]);
            break;
          }
        }
      }
      setFilteredList(filteredList);
    } else {
      setFilteredList(list);
    }
  };

  useEffect(() => {
    if (text) {
      handleSearch(text);
    }
  }, [list]);

  useEffect(() => {
    return () => {
      text = null;
    };
  }, []);

  return (
    <div className={classes.container}>
      <Fragment>
        <RenderOnPermittedRoleAndAttr
          roles={[ROLES.cssAdmin, ROLES.isvAdmin, ROLES.customerAdmin]}
        >
          {selectedUsers != null && (
            <PanelButton
              disabled={selectedUsers.length === 0 ? true : false}
              text="Add Selected Users"
              onClick={addUsersClickHandler}
            />
          )}
          {name != null && (
            <PanelButton
              text={name}
              onClick={
                policyTermsClickHandler != null
                  ? policyTermsClickHandler
                  : clickHandler
              }
              simpleButton={simpleButton}
            />
          )}
        </RenderOnPermittedRoleAndAttr>
        {disableSearch == null && (
          <CustomTextField
            label="Search"
            type="search"
            size="small"
            onChange={(event) => handleSearch(event.target.value)}
            // variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        {editButton != null && (
          <RenderOnPermittedRoleAndAttr
            roles={[ROLES.cssAdmin, ROLES.isvAdmin, ROLES.customerAdmin]}
          >
            <IconButton onClick={clickHandler}>
              <img src={editOrganizationIcon} alt="Edit" />
            </IconButton>
          </RenderOnPermittedRoleAndAttr>
        )}
      </Fragment>
    </div>
  );
};

export default CreateEditSearch;
