import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import DataTableHeaders from "../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import { customerDevicesHeaders } from "../helpers/header-helpers";
import CustomPagination from "../ui/CustomPagination";
import CustomToolbar from "../ui/CustomToolbar";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { PAGE_SIZE } from "../../constants/page-constants";
import { PARTIAL_ROUTES, CSS_ROUTES } from "../../constants/route-constants";
import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";
import { getCloudDevice } from "../../api/api-index";
import { columnsActions } from "../../store/slices/columns-slice";
import { customerDeviceActions } from "../../store/slices/slices-index";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { StyledLink } from "../ui/StyledLink";
import { StyledBreadcrumbs } from "../ui/StyledBreadcrumbs";
import DataTablePanelTest from "../panel/DataTablePanelTest";

import classes from "../ui/DataTable.module.css";

const CustomerManagedDeviceList = () => {
  const dispatch = useDispatch();
  const [breadCrumbArray] = useBreadcrumb("devices");

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);

  const [deviceList, setDeviceList] = useState({
    count: 0,
    list: [],
  });

  const selectedTenant = useSelector(
    (state) => state.tenantsInfo.selectedTenant
  );

  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const handleRowClick = (row) => {
    const data =
      row?.row != null
        ? {
            id: row.row.id,
            qualcommDeviceId: row.row.qualcommDeviceId,
            customerManaged: true,
          }
        : null;
    dispatch(customerDeviceActions.setSelectedDevice(data));
    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices +
        "/" +
        row?.row?.customerDeviceId +
        "/health-metrics"
    );
  };

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const qDeviceId = hasPermittedRolesOrAttributes([ROLES.cssAdmin])
    ? [
        {
          field: "qualcommDeviceId",
          headerName: "System Device Id",
          minWidth: 450,
          flex: 1,
          hideable: false,
        },
      ]
    : [];
  const columns = [
    ...qDeviceId,
    {
      field: "customerDeviceId",
      headerName: "Customer Device Id",
      minWidth: 150,
      flex: 1,
      hideable: false,
    },
  ];

  const headers = customerDevicesHeaders({
    routes: {
      deviceList:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.devices,
      customerUsers: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.associatedUsers
        : null,
      features: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.features
        : null,
      mdd: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mdd
        : null,
      mddDashboard: hasPermittedRolesOrAttributes([ROLES.cssAdmin])
        ? CSS_ROUTES.organizations +
          "/" +
          params.organizationId +
          PARTIAL_ROUTES.customerTenants +
          "/" +
          params.customerId +
          PARTIAL_ROUTES.mddDashboard
        : null,
    },
  });

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      tenantId: params.customerId,
    };

    getCloudDevice(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedList =
            response.data?.customerCloudDevicesList.map((item) => {
              return {
                id: uuidv4(),
                customerDeviceId: item?.customerDeviceId || "N/A",
                qualcommDeviceId: item?.qualcommDeviceId || "N/A",
              };
            }) || [];
          setDeviceList({
            count: response.data.count,
            list: formattedList,
          });
          setFilteredList(formattedList);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo, selectedTenant]);

  return (
    <Fragment>
      <div className={classes.container}>
        <DataTablePanelTest
          list={deviceList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={["qualcommDeviceId", "customerDeviceId"]}
          backButton={hasPermittedRolesOrAttributes([ROLES.cssAdmin]) && true}
        />
        {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? (
          <StyledBreadcrumbs>
            {breadCrumbArray.map((item) => (
              <StyledLink key={item.link} to={item.link}>
                {item.name}
              </StyledLink>
            ))}
            <p>{selectedTenant?.tenantName}</p>
          </StyledBreadcrumbs>
        ) : (
          <p>{selectedTenant?.tenantName}</p>
        )}
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            onRowClick={(params, event) => {
              if (!event.ignore) {
                handleRowClick(params);
              }
            }}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  deviceList.list.length === filteredList.length
                    ? deviceList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.devices },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default CustomerManagedDeviceList;
