import dashboardIcon from "../assets/images/menu/dashboard.svg";
import organizationsIcon from "../assets/images/menu/organizations.svg";
import cssAdminsIcon from "../assets/images/menu/css-admins.svg";
import adminActionsIcon from "../assets/images/menu/admin-actions.svg";
import isvTenantActionsIcon from "../assets/images/menu/isv-tenant-actions.svg";
import customerTenantActionsIcon from "../assets/images/menu/customer-tenant-actions.svg";
import permissionsIcon from "../assets/images/menu/permissions.svg";
import subscriptionPolicyIcon from "../assets/images/menu/subscription-policy.svg";
import usersIcon from "../assets/images/menu/users.svg";
import featuresIcon from "../assets/images/menu/features.svg";
import devicesIcon from "../assets/images/menu/devices.svg";
import subscriberIcon from "../assets/images/menu/subscriber.svg";
import mddIcon from "../assets/images/menu/mdd.svg";

import dashboardWhiteIcon from "../assets/images/menu/dashboard-white.svg";
import featureOwnerShipIcon from "../assets/images/menu/featureOwnerShip.svg";
import featureOwnerShipWhiteIcon from "../assets/images/menu/featureOwnerShipWhite.svg";
import organizationsWhiteIcon from "../assets/images/menu/organizations-white.svg";
import cssAdminsWhiteIcon from "../assets/images/menu/css-admins-white.svg";
import adminActionsWhiteIcon from "../assets/images/menu/admin-actions-white.svg";
import isvTenantActionsWhiteIcon from "../assets/images/menu/isv-tenant-actions-white.svg";
import customerTenantActionsWhiteIcon from "../assets/images/menu/customer-tenant-actions-white.svg";
import permissionsWhiteIcon from "../assets/images/menu/permissions-white.svg";
import subscriptionPolicyWhiteIcon from "../assets/images/menu/subscription-policy-white.svg";
import usersWhiteIcon from "../assets/images/menu/users-white.svg";
import featuresWhiteIcon from "../assets/images/menu/features-white.svg";
import devicesWhiteIcon from "../assets/images/menu/devices-white.svg";
import subscriberWhiteIcon from "../assets/images/menu/subscriber-white.svg";
import mddWhiteIcon from "../assets/images/menu/mdd-white.svg";
import metricsDefIcon from "../assets/images/menu/metrics_def.svg";
import metricsDefWhiteIcon from "../assets/images/menu/metrics_def-white.svg";
import metricsDashboardIcon from "../assets/images/menu/metrics_dashboard.svg";
import metricsDashboardWhiteIcon from "../assets/images/menu/metrics_dashboard-white.svg";
import { RiGitRepositoryCommitsLine } from "react-icons/ri";

import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import { CSS_ROUTES, TENANTS_ROUTES } from "./route-constants";

const MENU_NAMES = {
  dashboard: "Dashboard",
  organizations: "Organizations",
  cssAdmins: "CSS Admins",
  adminActions: "Admin Actions",
  isvTenantActions: "ISV Tenant Actions",
  customerTenantActions: "Customer Tenant Actions",
  permissions: "Permissions",
  subscriptionPolicy: "Subscription Policy",
  tenants: "Tenants",
  isvTenants: "Tenants",
  customerTenants: "Tenants",
  repositories: "Feature Repositories",
  featureOwnerShip: "Feature Ownership",
  devices: "Devices",
  customerUsers: "Users",
  customerFeatures: "Features",
  isvFeatures: "Features",
  isvUsers: "Users",
  subscribers: "Subscribers",
  mdd: "Metrics",
  sdk: "QSEA - SDK Repositories",
};
const SUB_MENU_NAMES = {
  mddList: "Metrics Definition",
  mddDashboard: "Metrics Dashboard",
};

const MENU_OPTIONS = {
  dashboard: {
    name: MENU_NAMES.dashboard,
    route: CSS_ROUTES.dashboard,
    img: dashboardIcon,
    imgWhite: dashboardWhiteIcon,
  },
  organizations: {
    name: MENU_NAMES.organizations,
    route: CSS_ROUTES.organizations,
    img: organizationsIcon,
    imgWhite: organizationsWhiteIcon,
  },

  cssAdmins: {
    name: MENU_NAMES.cssAdmins,
    route: CSS_ROUTES.cssAdmins,
    img: cssAdminsIcon,
    imgWhite: cssAdminsWhiteIcon,
  },
  adminActions: {
    name: MENU_NAMES.adminActions,
    route: CSS_ROUTES.adminActions,
    img: adminActionsIcon,
    imgWhite: adminActionsWhiteIcon,
  },
  isvTenantActions: {
    name: MENU_NAMES.isvTenantActions,
    route: CSS_ROUTES.isvTenantActions,
    img: isvTenantActionsIcon,
    imgWhite: isvTenantActionsWhiteIcon,
  },
  customerTenantActions: {
    name: MENU_NAMES.customerTenantActions,
    route: CSS_ROUTES.customerTenantActions,
    img: customerTenantActionsIcon,
    imgWhite: customerTenantActionsWhiteIcon,
  },
  permissions: {
    name: MENU_NAMES.permissions,
    route: CSS_ROUTES.permissions,
    img: permissionsIcon,
    imgWhite: permissionsWhiteIcon,
  },
  subscriptionPolicy: {
    name: MENU_NAMES.subscriptionPolicy,
    route: CSS_ROUTES.subscriptionPolicy,
    img: subscriptionPolicyIcon,
    imgWhite: subscriptionPolicyWhiteIcon,
  },
  isvTenants: {
    name: MENU_NAMES.isvTenants,
    route: TENANTS_ROUTES.isvTenants,
    img: isvTenantActionsIcon,
    imgWhite: isvTenantActionsWhiteIcon,
  },
  customerTenants: {
    name: MENU_NAMES.customerTenants,
    route: TENANTS_ROUTES.customerTenants,
    img: isvTenantActionsIcon,
    imgWhite: isvTenantActionsWhiteIcon,
  },
  repositories: {
    name: MENU_NAMES.repositories,
    route: CSS_ROUTES.repositories,
    img: <RiGitRepositoryCommitsLine size="1.6em" color="#5677A0" />,
    imgWhite: <RiGitRepositoryCommitsLine size="1.6em" color="#FFFFFF" />,
    ricon: true,
  },
  featureOwnerShip: {
    name: MENU_NAMES.featureOwnerShip,
    route: CSS_ROUTES.featureOwnerShip,
    img: featureOwnerShipIcon,
    imgWhite: featureOwnerShipWhiteIcon,
  },
  devices: {
    name: MENU_NAMES.devices,
    img: devicesIcon,
    imgWhite: devicesWhiteIcon,
  },
  customerUsers: {
    name: MENU_NAMES.customerUsers,
    img: usersIcon,
    imgWhite: usersWhiteIcon,
  },
  customerFeatures: {
    name: MENU_NAMES.customerFeatures,
    img: featuresIcon,
    imgWhite: featuresWhiteIcon,
  },
  isvFeatures: {
    name: MENU_NAMES.isvFeatures,
    img: featuresIcon,
    imgWhite: featuresWhiteIcon,
  },
  isvUsers: {
    name: MENU_NAMES.isvUsers,
    img: usersIcon,
    imgWhite: usersWhiteIcon,
  },
  subscribers: {
    name: MENU_NAMES.subscribers,
    img: subscriberIcon,
    imgWhite: subscriberWhiteIcon,
  },
  mdd: {
    name: MENU_NAMES.mdd,
    img: mddIcon,
    imgWhite: mddWhiteIcon,
    route: TENANTS_ROUTES.customerMdd,
  },
  sdk: {
    name: MENU_NAMES.sdk,
    img: <AppSettingsAltIcon size="1.6em" color="#5677A0" />,
    imgWhite: <AppSettingsAltIcon size="1.6em" color="#FFFFFF" />,
    route: CSS_ROUTES.sdk,
    ricon: true,
  },
};
const SUB_MENU_OPTIONS = {
  mddDifinitionList: {
    name: SUB_MENU_NAMES.mddList,
    img: metricsDefIcon,
    imgWhite: metricsDefWhiteIcon,
  },
  mddDashboard: {
    name: SUB_MENU_NAMES.mddDashboard,
    img: metricsDashboardIcon,
    imgWhite: metricsDashboardWhiteIcon,
  },
};
export { MENU_NAMES, MENU_OPTIONS, SUB_MENU_OPTIONS };
