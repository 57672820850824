import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import MUIDeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import { GridCellParams } from "@mui/x-data-grid";

import editIcon from "../../../assets/images/common/edit.svg";
import closeIcon from "../../../assets/images/common/close-icon.svg";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import { ExpiryDateFormatter, FeatureChip, output } from "../../helpers/utils";
import CustomTextField from "../../ui/CustomTextField";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import { STATUS } from "../../../constants/status-constants";

export default function BasicEditingGrid({
  featureList,
  setFeatureList,
  setstate,
}) {
  const rows = [];
  const [rowId, setRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState({
    id: null,
    expirationTime: null,
  });

  const handleSave = (maxDate) => {
    const newAction =
      maxDate.row.status === STATUS.INSTALL_PENDING ||
      maxDate.row.status === null ||
      maxDate.row.status === STATUS.INSTALL_FAILURE
        ? STATUS.INSTALL_PENDING
        : STATUS.MARKED_FOR_UPDATE;
    if (
      selectedRow.expirationTime != null &&
      selectedRow.expirationTime.isValid()
    ) {
      if (
        moment(selectedRow.expirationTime).isAfter(
          moment(maxDate?.row?.policyExpirationTime)
        )
      ) {
        setRowId(null);
      } else if (
        moment(selectedRow.expirationTime).isBefore(moment(new Date()), "d")
      ) {
        setRowId(null);
      } else {
        // let itemIndex = featureList.findIndex((element) => {
        //   console.log(selectedRow.id, element.qualcommFeatureId);
        //   element.qualcommFeatureId === selectedRow.id;
        // });
        // console.log(itemIndex);
        featureList[selectedRow.id] = {
          ...featureList[selectedRow.id],
          expirationTime: selectedRow.expirationTime,
          tempExpTime: selectedRow.expirationTime,
          currentAction: newAction,
        };
        setstate(false);
        setRowId(null);
      }
    } else {
      setRowId(null);
    }

    setSelectedRow({
      id: null,
      expirationTime: null,
    });
  };

  featureList.length > 0 &&
    featureList.forEach((element, key) => {
      rows.push({
        ...element,
        id: key,
        expirationTime: element?.expirationTime,
        preferredVersion: element?.preferredVersion || "-",
        type: element?.type ? "new" : "old",
        status: element?.status,
      });
    });

  const disableEditAndDelete = [
    STATUS.INSTALL_PENDING,
    STATUS.MARKED_FOR_UPDATE,
    STATUS.MARKED_FOR_DISABLE,
  ];
  //disabling edit and delete action for these status

  const columns = [
    {
      field: "featureTitle",
      headerName: "Features",
      minWidth: 250,
      editable: false,
      flex: 1,
    },
    {
      minWidth: 100,
      field: "preferredVersion",
      headerName: "Preferred Version",
      editable: false,
      flex: 1,
      // renderCell: (params) =>(
      //   params.row?.preferredVersion != null
      //     ? params.row?.preferredVersion
      //     : "-")
    },
    {
      minWidth: 150,
      field: "expirationTime",
      headerName: "Expiration Date",
      type: "dateTime",
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return params?.row?.id === rowId ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              id={params.row.id}
              value={
                selectedRow.expirationTime == null
                  ? params.row.expirationTime
                  : selectedRow.expirationTime
              }
              onChange={(event) => {
                setSelectedRow({
                  id: params.row.id,
                  expirationTime: event,
                });
              }}
              disablePast
              maxDate={moment(params?.row?.policyExpirationTime)}
              inputFormat="DD-MM-YYYY"
              renderInput={(paramss) => {
                return (
                  <CustomTextField
                    size="small"
                    focused
                    fullWidth
                    {...paramss}
                    error={paramss.error}
                  />
                );
              }}
            />
          </LocalizationProvider>
        ) : (
          ExpiryDateFormatter(params.row.expirationTime)
        );
      },
    },
    {
      minWidth: 200,
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return params.row.status != "-" ? output[params.row.status] : "-";
      },
    },
  ];

  columns.push({
    field: "Actions",
    headerName: "Actions",
    minWidth: 100,
    flex: 0.5,
    sortable: false,
    renderCell: (params) => {
      const setOpenEdit = () => {
        setRowId(params?.row?.id);
      };
      const setCloseEdit = () => {
        setRowId(null);
      };

      const deleteFeatureFromTable = () => {
        let itemIndex = featureList.map((element) => {
          if (element.qualcommFeatureId === params.row.qualcommFeatureId) {
            return {
              ...element,
              currentAction: STATUS.MARKED_FOR_DISABLE,
              tempExpTime: new Date(),
            };
          } else {
            return element;
          }
        });

        setTimeout(() => {
          setFeatureList(itemIndex);
          setstate(false);
        });
      };

      const deleteNewAddedFeature = () => {
        let itemIndex = featureList.filter((element) => {
          if (element.qualcommFeatureId !== params.row.qualcommFeatureId) {
            return element;
          }
        });

        setTimeout(() => {
          setFeatureList(itemIndex);
        });
      };

      // if (params.row.status !== "MARKED_FOR_DISABLE") {
      return params?.row?.id !== rowId ? (
        <div>
          <EditIconButton
            size="small"
            onClick={setOpenEdit}
            disabled={
              disableEditAndDelete.includes(params.row.status) ||
              params?.row?.currentAction === STATUS.MARKED_FOR_DISABLE
            }
          />
          <DeleteIcon
            tooltipText="Disable Feature"
            disabled={
              disableEditAndDelete.includes(params.row.status) ||
              params?.row?.currentAction === STATUS.MARKED_FOR_DISABLE
            }
            onClick={() => {
              params.row.type === "old"
                ? deleteFeatureFromTable()
                : deleteNewAddedFeature();
            }}
            size={"small"}
          />
          {/* <Tooltip title="Disable Feature" placement="top">
            <IconButton
              size="small"
              onClick={() => {
                params.row.type === "old"
                  ? deleteFeatureFromTable()
                  : deleteNewAddedFeature();
              }}
            >
              <MUIDeleteIcon sx={{ color: "#FF6464" }} />
            </IconButton>
          </Tooltip> */}
        </div>
      ) : (
        <div>
          <IconButton size="small" onClick={() => handleSave(params)}>
            <DoneIcon />
          </IconButton>
          <IconButton size="small" onClick={setCloseEdit}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
      );
      // }
    },
  });

  return (
    <div style={{ height: 350, width: "100%" }}>
      <DataGrid
        sx={{
          fontFamily: "QualcommNext-Regular",
          "& .MuiDataGrid-cell": {
            // border: "none",

            paddingLeft: "1em",

            "&:focus-within": {
              outline: "none",
            },
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "0.6rem",
          },
          "& .device_marked_disable": {
            background: "#EAEAEA",
            color: "#C8BEBE",
          },
        }}
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        experimentalFeatures={{ newEditingApi: false }}
        hideFooter
        getCellClassName={(params) => {
          if (
            params.row.status === STATUS.MARKED_FOR_DISABLE ||
            params?.row?.currentAction === STATUS.MARKED_FOR_DISABLE
          ) {
            return "device_marked_disable";
          }
        }}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          NoRowsOverlay: CustomNoRowMessage,
        }}
        componentsProps={{
          noRowsOverlay: { text: NO_ROW_MESSAGES.features },
        }}
      />
    </div>
  );
}
