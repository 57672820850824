import { Button } from "@mui/material";

const FeatureButton = ({ children, onClick, label, disabled = false }) => {
  return (
    <Button
      disabled={disabled}
      size="small"
      variant="contained"
      aria-label={label}
      disableElevation
      onClick={onClick != null ? onClick : null}
      sx={{
        textTransform: "Capitalize",
        fontFamily: "QualcommNext-Regular",
        borderColor: "#214572",
        color: "#fff",
        backgroundColor: "#4D6994",
        "&:hover": {
          backgroundColor: "#1a4071",
        },
      }}
    >
      {children}
    </Button>
  );
};
export default FeatureButton;
