import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdOutlineUploadFile } from "react-icons/md";

import CancelButton from "../../ui/Buttons/CancelButton";
import CloseIcon from "../../ui/Buttons/CloseIcon";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import { ADD_LOGO_MESSAGES } from "../../../constants/message-constants";
import { uploadFeatureIcon } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import { dataURLtoFile } from "../../helpers/utils";

import classes from "./UploadFeatureIcon.module.css";

const baseStyle = {
  padding: "2.5em",
  borderRadius: "8px",
  border: "2px dashed #58A7FF",
  backgroundColor: "#F8FAFF",
  color: "#BCCEFA",
  outline: "none",
  transition: "border .24s ease-in-out",
  minHeight: "20vh",
  width: "45vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3em",
};

const editBaseStyle = {
  ...baseStyle,
  width: "100%",
};

const activeStyle = {
  border: "2px dashed #2196f3",
};

const acceptStyle = {
  border: "2px dashed #00e676",
  backgroundColor: "#00e67633",
};

const rejectStyle = {
  border: "2px dashed #ff1744",
  backgroundColor: "#ff174433",
};
const mdStyles = {
  ...baseStyle,
  flexDirection: "column",
  width: "100%",
};
let clicked = true;
const UploadFeatureIcon = (props) => {
  const matches = useMediaQuery("(max-width:1000px)");
  const {
    featureId,
    onClose,
    type = "New",
    icon = [],
    isFeatureDownloadable,
  } = props;
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState({
    status: false,
    error: "",
  });
  const onDropAccepted = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setRejected({ status: false, error: "" });
  }, []);
  const onDropRejected = (file) => {
    const errStatus =
      file[0]?.errors[0]?.code === "too-many-files"
        ? "Please select only one file"
        : file[0]?.errors[0]?.code === "file-too-large"
        ? "File size exceeds 1 MB"
        : "File type must be jpeg/png format";
    setRejected({ status: true, error: errStatus || "" });
    setFiles([]);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noKeyboard: true,
    maxSize: 1000000,
    maxFiles: 1,
    accept: ".jpeg, .png, .jpg",
  });

  const style = useMemo(
    () => ({
      ...(matches ? mdStyles : type === "Edit" ? editBaseStyle : baseStyle),
      ...(rejected.status ? rejectStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, rejected.status, matches, type]
  );

  const handleDeleteFile = (name) => {
    setFiles(files.filter((i) => i.name !== name));
  };

  const handleUploadFile = () => {
    if (clicked) {
      clicked = false;
      if (files.length > 0 && files[0]?.name) {
        uploadFeatureIcon({
          featureIcon: files[0],
          featureId: featureId,
        })
          .then((response) => {
            if (response.status) {
              toast.success(ADD_LOGO_MESSAGES.success);
              onClose(isFeatureDownloadable);
            } else {
              errorHandler(response.error, ADD_LOGO_MESSAGES.failure);
            }
            clicked = true;
          })
          .catch((error) => {
            clicked = true;
            console.error("ERROR: ", error);
            errorHandler(response.error, ADD_LOGO_MESSAGES.failure);
          });
      } else {
        setRejected({ status: true, error: "Please add file" });
        clicked = true;
      }
    }
  };
  const thumbs = files.map((file, i) => (
    <div className={classes.files} key={i}>
      <div className={classes.img_container}>
        <CloseIcon
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteFile(file?.name);
          }}
        />
      </div>
      <div className={classes.uploadedImg}>
        <img
          src={file.preview ? file.preview : "data:image/*;base64," + file}
          alt={file?.name || "Logo"}
        />
      </div>
      {/* {file?.name && <p>{`Name: ${file.name}`}</p>} */}
      <div className={classes.file_details}>
        <p>
          {file.preview
            ? `Size: ${(file?.size / 1024).toFixed(1)} KB`
            : `Size ${(atob(file).length / 1024).toFixed(1)} KB`}
        </p>
        {/* {file.type && (
          <p>
            Type:{" "}
            <span>{file.type?.slice(file?.type.indexOf("/") + 1) || "-"}</span>
          </p>
        )} */}
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (icon.length > 0) {
      let oldFile = [dataURLtoFile("data:text/plain;base64," + icon, "TEST")];
      setFiles(
        oldFile.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }
  }, []);

  return (
    <div
      className={type === "Edit" ? classes.editContainer : classes.container}
    >
      {type != "Edit" && <p className={classes.input_label}>Upload Logo</p>}
      <div>
        <section {...getRootProps({ style })}>
          <div className={classes.upload_container}>
            <input {...getInputProps()} />
            <MdOutlineUploadFile
              className={classes.uploadIcon}
              color="#58A7FF"
              size={30}
            />
            <p className={classes.upload}>
              Click or drag image file to this area to upload
              <em>
                (one file with jpeg/png format of max.size 1 MB is allowed)
              </em>
            </p>
          </div>
          {thumbs}
        </section>
        <p className={classes.error}>{rejected.error}</p>
      </div>
      <div className={classes.buttons}>
        <CancelButton
          onClick={() => onClose(isFeatureDownloadable)}
          text={
            type === "Edit" || !isFeatureDownloadable
              ? "Cancel"
              : "Skip and continue"
          }
        />
        <SubmitButton onClick={handleUploadFile} text={"Submit"} />
      </div>
    </div>
  );
};

export default UploadFeatureIcon;
