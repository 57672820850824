import axios from "axios";
import { getAssociatedDevicesForMDDApi } from "./apiEndpoints";

export const getMetricsBasedonDevice = async (options) => {
  let data = null;

  await axios
    .get(`${getAssociatedDevicesForMDDApi}/${options.deviceId}`)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
