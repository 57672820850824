import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DataTableHeaders from "../../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import { availableDevicesHeaders } from "../../../helpers/header-helpers";
import CustomPagination from "../../../ui/CustomPagination";
import CustomToolbar from "../../../ui/CustomToolbar";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import { getAvailableDevicesListByFeature } from "../../../../api/api-index";
import { PAGE_SIZE } from "../../../../constants/page-constants";
import {
  CSS_ROUTES,
  PARTIAL_ROUTES,
  TENANTS_ROUTES,
} from "../../../../constants/route-constants";
import { GET_MESSAGES } from "../../../../constants/message-constants";
import { hasPermittedRolesOrAttributes } from "../../../helpers/user-roles-and-attributes";
import errorHandler from "../../../helpers/error-helper";
import DataTablePanelTest from "../../../panel/DataTablePanelTest";
import AssociateFeatureToDevices from "./AssociateFeatureToDevices";
import { ROLES } from "../../../../constants/role-constants";
import useSearchKeys from "../../../../hooks/useSearchKeys";
import { columnsActions } from "../../../../store/slices/columns-slice";
import { NO_ROW_MESSAGES } from "../../../../constants/no-row-message-constants";

import classes from "../../../ui/DataTable.module.css";
import { universalChipFormatter } from "../../../helpers/utils";

const AvailableDevices = () => {
  const dispatch = useDispatch();
  const initialState = useSelector(
    (state) => state.columnsInfo.subscriptionPolicy
  );
  const [searchStatefn] = useSearchKeys(
    [
      "name",
      "sysDeviceId",
      "deviceTags",
      "chipsetName",
      "featureEnabled",
      "deviceStatus",
    ],
    initialState
  );

  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [devicesList, setdevicesList] = useState({ count: 0, list: [] });
  const [selectedDevices, setSelectedDevices] = useState({ ids: [], rows: [] });
  const [filteredList, setFilteredList] = useState([]);
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEnableFeature, setOpenEnableFeature] = useState(false);
  const [forceUpdate, setForceUpdate] = useState({});

  const params = useParams();

  const selectedFeature = useSelector(
    (state) => state.featuresInfo?.selectedFeature
  );

  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const handleEnableFeature = () => {
    setOpenEnableFeature(true);
  };

  const closeEnableFeature = () => {
    setOpenEnableFeature(false);
  };

  const columns = [
    {
      field: "sysDeviceId",
      headerName: "System Device ID",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "name",
      headerName: "Device Name",
      flex: 1,
      minWidth: 150,
      hideable: false,
    },
    {
      field: "deviceTags",
      headerName: "Tags",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <div>{params?.row?.deviceTags?.toString().trimEllip(20)}</div>;
      },
    },
    {
      field: "chipsetName",
      headerName: "Chipset Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "featureEnabled",
      headerName: "Features Enabled",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>{params?.row?.featureEnabled?.toString().trimEllip(50)}</div>
        );
      },
    },
    {
      field: "deviceStatus",
      headerName: "Status",
      minWidth: 150,
      // flex: 1,
      renderCell: (params) => {
        if (params?.row?.deviceStatus === "ACTIVE")
          return (
            <>{universalChipFormatter({ label: "Active", color: "success" })}</>
          );
        else {
          return "-";
        }
      },
    },
  ];

  const headers = availableDevicesHeaders({
    routes: {
      devicesList:
        CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        params.customerId +
        PARTIAL_ROUTES.features +
        "/" +
        params.featureId +
        "/available-devices",
    },
  });
  const buttons = hasPermittedRolesOrAttributes([
    ROLES.customerAdmin,
    ROLES.deviceManager,
    ROLES.cssAdmin,
  ])
    ? [
        {
          key: "selectDevices",
          text: "Associate Selected Devices",
          clickHandler: handleEnableFeature,
          disabled: selectedDevices.ids.length === 0 ? true : false,
          icon: null,
        },
      ]
    : null;

  useEffect(() => {
    const options = {
      pageNo: pageNo,
      pageSize: PAGE_SIZE,
      featureId: selectedFeature.id,
      tenantId: params.customerId,
      qualcommFeatureId: selectedFeature.qualcommFeatureId,
      qualcommFeatureName: selectedFeature.qualcommFeatureName,
      chipsetNameList: [selectedFeature.chipsetName],
    };

    getAvailableDevicesListByFeature(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.deviceDetailsList.map((item) => {
            return {
              id: item.deviceId,
              name: item.deviceName,
              sysDeviceId: item.qualcommDeviceId,
              deviceTags:
                item.deviceTags.length > 0
                  ? item.deviceTags.map(
                      (value) => `${value.tag}:${value.tagValue}`
                    )
                  : "-",
              chipsetName: item?.chipsetName || "-",
              featureEnabled: item?.featuresEnabled || "-",
              expirationTime: item?.expirationTime,
              deviceStatus: item?.status ? "ACTIVE" : "-",
            };
          });
          setdevicesList({ count: response.data.count, list: formattedList });
          setFilteredList(formattedList);
        } else {
          console.log(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
          //error handling function
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, [pageNo, forceUpdate]);

  return (
    <Fragment>
      {openEnableFeature && (
        <AssociateFeatureToDevices
          onClose={closeEnableFeature}
          userDetails={selectedDevices.rows}
          selectedFeature={selectedFeature}
          setForceUpdate={setForceUpdate}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          list={devicesList.list || []}
          setFilteredList={setFilteredList}
          filterKeys={searchStatefn}
          buttons={buttons}
        />
        <p>Associate Devices - {selectedFeature?.featureName}</p>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(
                columnsActions.setsubscriptionPolicyColumnsState(newModel)
              )
            }
            getRowHeight={() => "auto"}
            columns={columns}
            rows={filteredList || []}
            checkboxSelection={true}
            disableColumnFilter
            scrollbarSize={17}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = filteredList.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedDevices({ ids: ids, rows: selectedRows });
            }}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total:
                  devicesList.list.length === filteredList.length
                    ? devicesList.count
                    : filteredList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.availableDevices,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AvailableDevices;
