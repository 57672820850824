import * as yup from "yup";
const errorMessages = {
  name: {
    required: "Feature Name is required",
    matches: "No spaces and alphanumeric is allowed",
  },
  title: {
    required: "Feature Title is required",
  },
  releaseName: {
    required: "Release name is required",
  },
  allowedChipsets: {
    required: "Atleast one chipset is required",
  },
};

export const schema = yup
  .object({
    name: yup.string(),
    // .matches(
    //   /^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$/g,
    //   errorMessages.name.matches
    // )
    // .required(errorMessages.name.required)
    title: yup.string().required(errorMessages.title.required),
    featureDescription: yup.string(),
    category: yup.string(),
    // releaseName: yup.string().required(errorMessages.releaseName.required),
    labels: yup.string(),
    allowedChipsets: yup
      .array()
      .required(errorMessages.allowedChipsets.required),
  })
  .required();
