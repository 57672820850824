import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const ColorButton = styled(Button)(() => ({
  color: "#FFFFFF",
  backgroundColor: "#4D6994",
  border: "1px solid #4D6994",
  fontFamily: "QualcommNext-Regular",
  "&:hover": {
    backgroundColor: "#1a4071",
  },
  "&.Mui-disabled": {
    // color: "#fff",
    backgroundColor: "#d3d3d3",

    border: "1px solid #d3d3d3",
  },
  paddingLeft: "1.8rem",
  paddingRight: "1.8rem",
}));

const SubmitButton = (props) => {
  const { onClick, text, type, startIcon, endIcon, disabled } = props;
  return (
    <ColorButton
      disabled={disabled}
      onClick={onClick != null ? onClick : null}
      type={type != null ? type : null}
      startIcon={startIcon != null ? startIcon : null}
      endIcon={endIcon != null ? endIcon : null}
    >
      {text}
    </ColorButton>
  );
};

export default SubmitButton;
