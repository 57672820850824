import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import PreferredVersion from "../preferredVersion/PreferredVersion";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { featureHeaders } from "../../helpers/header-helpers";
import DataTableHeaders from "../../header/DataTableHeaders";
import CustomerFeaturesInfoItem from "./CustomerFeaturesInfoItem";
import About from "./About";
import featureNameIcon from "../../../assets/images/features/default-feature-name.svg";
import categoryIcon from "../../../assets/images/features/category.svg";
import orgIcon from "../../../assets/images/features/org.svg";
import version from "../../../assets/images/features/preferred-version.svg";
import { ROLES } from "../../../constants/role-constants";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { BUTTONS } from "../../../constants/panel-constants";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import { getCustomerFeatureInfo } from "../../../api/getCustomerFeatureInfo";
import errorHandler from "../../helpers/error-helper";
import { GET_MESSAGES } from "../../../constants/message-constants";
import secured from "../../../assets/images/features/secured-status.svg";
import AlertBanner from "../../ui/Alert/AlertBanner";
import { StyledLink } from "../../ui/StyledLink";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";

import classes from "./CustomerFeaturesInfo.module.css";

const CustomerFeaturesInfo = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [breadCrumbArray] = useBreadcrumb("customerFeatureInfo");
  const [featureInfo, setFeatureInfo] = useState({});
  const [isInactive, setIsInactive] = useState(true);
  const [isPreferredVersionOpen, setIsPreferredVersionOpen] = useState(false);
  const [chipset, setChipset] = useState({ active: [], inActive: [] });
  const tenantInfo = useSelector((state) => state.tenantsInfo.selectedTenant);
  const tenantName = tenantInfo?.tenantName;
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const orgType = useSelector(
    (state) => state.organizationsInfo.selectedOrganization.orgType
  );

  const tenantType = location.pathname.includes(PARTIAL_ROUTES.isvTenants)
    ? PARTIAL_ROUTES.isvTenants
    : location.pathname.includes(PARTIAL_ROUTES.customerTenants)
    ? CSS_ROUTES.organizations +
      "/" +
      params.organizationId +
      PARTIAL_ROUTES.customerTenants
    : "";

  const isvCustomerTenantId = params.tenantId || params.customerId;

  const featureType = location.pathname.includes(PARTIAL_ROUTES.features)
    ? PARTIAL_ROUTES.features
    : location.pathname.includes(PARTIAL_ROUTES.myFeatures)
    ? PARTIAL_ROUTES.myFeatures
    : "";

  const headers = featureHeaders({
    routes: {
      featureInfo:
        tenantType +
        "/" +
        isvCustomerTenantId +
        featureType +
        "/" +
        params.featureId,
    },
  });

  function fetchFeatureInfo() {
    const payload = {
      featureId: params.featureId,
      tenantId: isvCustomerTenantId,
    };
    getCustomerFeatureInfo(payload)
      .then((response) => {
        if (response.status && response.data != null) {
          const activeChipset = [];
          const inActiveChipset = [];

          setFeatureInfo(response.data);

          if (response.data.featurePolicyTerm != null) {
            response.data.featurePolicyTerm.forEach((element) => {
              if (element.status == null || element.status === "ACTIVE") {
                setIsInactive(false);
                element.policyChipsetsList.forEach((active) => {
                  activeChipset.push(active.chipset);
                });
              }
            });

            response.data.featurePolicyTerm.forEach((element) => {
              if (element.status !== null || element.status !== "ACTIVE") {
                element.policyChipsetsList.forEach((inactive) => {
                  !activeChipset.includes(inactive.chipset) &&
                    !inActiveChipset.includes(inactive.chipset) &&
                    inActiveChipset.push(inactive.chipset);
                });
              }
            });

            setChipset({ active: activeChipset, inActive: inActiveChipset });
          }
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }

  useEffect(() => {
    // const options = {
    //   featureId: params.featureId,
    // };
    // dispatch(fetchFeatureInfo(options));
    fetchFeatureInfo();
  }, []);

  const heading =
    featureInfo?.featureTitle != null
      ? `${tenantName} - ${featureInfo.featureTitle}`
      : `${tenantName} - N/A`;

  const viewButton = isQualcommManagingDevice
    ? {
        key: "view-details",
        text: BUTTONS.viewDevices,
        clickHandler: () => {
          navigate(
            tenantType +
              "/" +
              isvCustomerTenantId +
              featureType +
              "/" +
              params.featureId +
              "/" +
              featureInfo?.featureName
          );
        },
        disabled: featureInfo?.featureName != null ? false : true,
        icon: null,
      }
    : null;
  const preferedVersionButton =
    hasPermittedRolesOrAttributes([ROLES.customerAdmin, ROLES.cssAdmin]) &&
    featureInfo?.isFeatureDownloadable
      ? {
          key: "prefered-version",
          text: "Set Preferred Version",
          clickHandler: () => {
            setIsPreferredVersionOpen(true);
          },
          disabled:
            (orgType === "Internal" && featureInfo?.featureName != null) ||
            (featureInfo?.featureName != null && !isInactive)
              ? false
              : true,
          icon: null,
        }
      : null;

  const buttons = [preferedVersionButton, viewButton];
  return (
    <Fragment>
      {isPreferredVersionOpen && (
        <PreferredVersion
          onClose={() => setIsPreferredVersionOpen(false)}
          featureName={featureInfo?.featureName}
          featureTitle={featureInfo?.featureTitle}
          preferenceType={featureInfo?.preferenceType}
          currentVersion={featureInfo?.preferredVersion}
          info={isvCustomerTenantId}
          updateFunction={fetchFeatureInfo}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        {/* {hasPermittedRolesOrAttributes([ROLES.cssAdmin]) ? ( */}
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p>{featureInfo.featureTitle || "..."}</p>
        </StyledBreadcrumbs>
        {/* ) : (
          <p>{heading}</p>
        )} */}

        <div className={classes.data}>
          <DataTableHeaders headers={headers} />
          <div className={classes.info}>
            <div className={classes.items}>
              {featureInfo?.featurePolicyTerm && isInactive && (
                <AlertBanner text="This feature has an inactive policy" />
              )}

              <div className={classes["info-items"]}>
                <CustomerFeaturesInfoItem
                  text={
                    featureInfo?.featureTitle != null
                      ? featureInfo.featureTitle
                      : "N/A"
                  }
                  header="Feature Title"
                  icon={
                    featureInfo?.featureIcon != null
                      ? featureInfo.featureIcon
                      : featureNameIcon
                  }
                  convertFromBase64={
                    featureInfo?.featureIcon != null ? true : false
                  }
                />
                <CustomerFeaturesInfoItem
                  text={
                    featureInfo?.featureCategory != null
                      ? featureInfo?.featureCategory
                      : "N/A"
                  }
                  header="Category"
                  icon={categoryIcon}
                />
                <CustomerFeaturesInfoItem
                  text={
                    featureInfo?.offeredBy != null
                      ? featureInfo.offeredBy
                      : "N/A"
                  }
                  header="Organization"
                  icon={orgIcon}
                />
                <CustomerFeaturesInfoItem
                  text={featureInfo?.securedFeature ? "Yes" : "No"}
                  header="Secured"
                  icon={secured}
                />
                <CustomerFeaturesInfoItem
                  text={featureInfo?.preferredVersion}
                  header="Preferred Version"
                  icon={version}
                />
                <CustomerFeaturesInfoItem
                  text={featureInfo?.isFeatureDownloadable ? "Yes" : "No"}
                  header="Downloadable Feature"
                  muiicon={
                    featureInfo?.isFeatureDownloadable ? (
                      <FileDownloadIcon htmlColor="#649EE6" />
                    ) : (
                      <FileDownloadOffIcon htmlColor="#649EE6" />
                    )
                  }
                />
                <CustomerFeaturesInfoItem
                  chipsets={chipset}
                  header="Allowed Chipsets"
                  muiicon={<DeveloperBoardIcon htmlColor="#649EE6" />}
                />

                <About
                  text={
                    featureInfo?.featureDesc != null
                      ? featureInfo.featureDesc
                      : "N/A"
                  }
                  header="About"
                />
              </div>
            </div>
          </div>
          <div className={classes.licence}>*Licence Terms & Conditions</div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerFeaturesInfo;
