import { Fragment, useEffect, useMemo, useState } from "react";
import { Badge, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterListSharpIcon from "@mui/icons-material/FilterListSharp";

import EditTenant from "../EditTenant/EditTenant";
import CreateNewTenant from "../CreateNewTenant/CreateNewTenant";
import DataTablePanel from "../../panel/DataTablePanel";
import DataTableHeaders from "../../header/DataTableHeaders";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import { organizationHeaders } from "../../helpers/header-helpers";
import CustomPagination from "../../ui/CustomPagination";
import CustomToolbar from "../../ui/CustomToolbar";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { getTenantsList, searchTenantName } from "../../../api/api-index";
import { PAGE_SIZE } from "../../../constants/page-constants";
import { CSS_ROUTES, PARTIAL_ROUTES } from "../../../constants/route-constants";
import { BUTTONS, BUTTONS_TYPE } from "../../../constants/panel-constants";
import { ROLES } from "../../../constants/role-constants";
import { TENANT_TYPE } from "../../../constants/tenant-type-constants";
import { tenantsActions } from "../../../store/slices/tenants-slice";
import DeleteIcon from "../../ui/Buttons/DeleteIcon";
import DeleteTenant from "../DeleteTenant/DeleteTenant";
import { hasPermittedRolesOrAttributes } from "../../helpers/user-roles-and-attributes";
import { GET_MESSAGES } from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import ManageTenantIcon from "../../ui/Buttons/ManageTenantIcon";
import ClientIDAndSecret from "../CreateNewTenant/ClientIDAndSecret";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import useSearchKeys from "../../../hooks/useSearchKeys";
import { columnsActions } from "../../../store/slices/slices-index";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";
import SDKPreference from "../sdk/SDKPreference";
import SDKButton from "../../ui/Buttons/SDKButton";
import { StyledLink } from "../../ui/StyledLink";
import { StyledBreadcrumbs } from "../../ui/StyledBreadcrumbs";
import useBreadcrumb from "../../../hooks/useBreadcrumb";

import classes from "../../ui/DataTable.module.css";
import EditSDKPreference from "../sdk/EditSDKPreference";
import DataTablePanelTest from "../../panel/DataTablePanelTest";
import CommonFilter from "../../ui/Form/commonFilter/CommonFilter";

let selectedRow = null;

const CustomerTenantsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const initialState = useSelector(
    (state) => state.columnsInfo.customerTenants
  );
  const [searchStatefn] = useSearchKeys(
    ["name", "managedDevices"],
    initialState
  );
  const [isLoading, setIsLoading] = useState(true);
  const [tenantsList, setTenantsList] = useState({ count: 0, list: [] });
  const [columnButtonEl, setColumnButtonEl] = useState(null);
  const [openEditTenant, setOpenEditTenant] = useState(false);
  const [openDeleteTenant, setOpenDeleteTenant] = useState(false);
  const [openCreateNewTenant, setOpenCreateNewTenant] = useState(false);
  const [openManagedDevices, setOpenManagedDevices] = useState(false);
  const [openSDK, setOpenSDK] = useState(false);
  const [breadCrumbArray] = useBreadcrumb("orgInfo");
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    tenantName: "",
    isQualcomManaged: "Both",
    pageNo: 0,
    isFilterApplied: false,
  });

  const selectedOrganization = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  // const isQualcommManagingDevice = useSelector(
  //   (state) =>
  //     state.userInfo.userInfo?.userRoleInfoJson?.[state.userInfo.selectedAppKey]
  //       ?.isQualcommManagingDevice
  // );
  const handleRowClick = (row) => {
    const data = {
      tenantId: row?.row?.id,
      tenantName: row?.row?.name,
      organizationId: row?.row?.organizationId,
      organization: row?.row?.organization,
      tenantType: row?.row?.tenantType,
      isQualcommManagingDevice: row.row.managedDevices == "No" ? false : true,
    };
    dispatch(tenantsActions.setSelectedTenant(data));
    // data.isQualcommManagingDevice
    //   ? navigate(
    //       CSS_ROUTES.organizations +
    //         "/" +
    //         params.organizationId +
    //         PARTIAL_ROUTES.customerTenants +
    //         "/" +
    //         row.id +
    //         PARTIAL_ROUTES.devices
    //     )
    //   : navigate(
    //       CSS_ROUTES.organizations +
    //         "/" +
    //         params.organizationId +
    //         PARTIAL_ROUTES.customerTenants +
    //         "/" +
    //         row.id +
    //         PARTIAL_ROUTES.features
    //     );

    navigate(
      CSS_ROUTES.organizations +
        "/" +
        params.organizationId +
        PARTIAL_ROUTES.customerTenants +
        "/" +
        row.id +
        PARTIAL_ROUTES.devices
    );
  };

  const handleEdit = (params) => {
    selectedRow = {
      tenantId: params?.row?.id,
      tenantName: params?.row?.name,
      organizationId: selectedOrganization.organizationId,
      organization: selectedOrganization.name,
      tenantType: TENANT_TYPE.customer,
    };
    if (params?.row?.managedDevices === "Yes") {
      selectedRow.isQualcommManagingDevice = true;
    }
    setOpenEditTenant(true);
  };

  const handlePageChange = (_, newPage) => {
    setIsLoading(true);
    setFilterOptions({ ...filterOptions, pageNo: newPage - 1 });
  };

  const handleCreateNewTenant = () => {
    setOpenCreateNewTenant(true);
  };

  const handleManagedDevices = (params) => {
    selectedRow = {
      clientId: params.row?.clientIdSecret?.clientId,
      secret: params.row?.clientIdSecret?.clientSecret,
      client: params.row?.clientIdSecret?.client,
    };
    setOpenManagedDevices(true);
  };

  const closeCreateNewTenant = () => {
    setOpenCreateNewTenant(false);
  };

  const handleOpenDeleteUser = (params) => {
    selectedRow = params.row;
    setOpenDeleteTenant(true);
  };
  const closeEditTenant = () => {
    selectedRow = null;
    setOpenEditTenant(false);
  };
  const closeDeleteUser = () => {
    selectedRow = null;
    setOpenDeleteTenant(false);
  };

  const closeOpenManagedDeives = () => {
    setOpenManagedDevices(false);
  };

  const handleOpenSDK = (params) => {
    selectedRow = params.row;
    setOpenSDK(true);
  };

  const closeOpenSDK = (params) => {
    setOpenSDK(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 3,
      minWidth: 300,
      hideable: false,
    },
    {
      field: "managedDevices",
      headerName: "Devices Managed by Qualcomm",
      flex: 3,
      minWidth: 100,
    },
  ];

  hasPermittedRolesOrAttributes([ROLES.cssAdmin, ROLES.isvAdmin]) &&
    columns.push({
      field: "Actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      minWidth: 200,
      hideable: false,
      renderCell: (params) => (
        <div>
          {params.row?.managedDevices === "No" ? (
            <ManageTenantIcon
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleManagedDevices(params);
              }}
            />
          ) : (
            <ManageTenantIcon disabled={true} size="small" />
          )}
          <EditIconButton
            size="small"
            onClick={(event) => {
              event.ignore = true;
              handleEdit(params);
            }}
          />
          <SDKButton
            tooltipText="QSEA-SDK Management"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenSDK(params);
            }}
            size="small"
          />
          <DeleteIcon
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteUser(params);
            }}
          />
        </div>
      ),
    });

  const headers = hasPermittedRolesOrAttributes([
    ROLES.cssAdmin,
    ROLES.cssMonitor,
  ])
    ? organizationHeaders({
        routes: {
          organizationInfo:
            CSS_ROUTES.organizations + "/" + params.organizationId,
          users:
            CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.users,
          isvTenants:
            CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.isvTenants,
          customerTenants:
            CSS_ROUTES.organizations +
            "/" +
            params.organizationId +
            PARTIAL_ROUTES.customerTenants,
        },
      })
    : null;
  function fetchTenantList() {
    const options = {
      pageNo: filterOptions.pageNo,
      pageSize: PAGE_SIZE,
      organizationId: selectedOrganization?.organizationId || null,
      tenantType: TENANT_TYPE.customer,
      tenantName: filterOptions.tenantName
        ? filterOptions.tenantName.trim()
        : undefined,
      isQualcommManagingDevice:
        filterOptions.isQualcomManaged === "Yes"
          ? true
          : filterOptions.isQualcomManaged === "No"
          ? false
          : undefined,
    };

    searchTenantName(options)
      .then((response) => {
        if (
          response.status &&
          response.data != null &&
          response.data.tenants != null &&
          response.data.count != null
        ) {
          const formattedList = response.data.tenants.map((item) => {
            return {
              id: item.tenantId,
              name: item.tenantName,
              organization: item.organization,
              organizationId: item.organizationId,
              tenantName: item.tenantName,
              tenantType: item.tenantType,
              managedDevices:
                item?.isQualcommManagingDevice === true ? "Yes" : "No",
              clientIdSecret: !item?.isQualcommManagingDevice
                ? item?.clientIdSecret
                : null,
            };
          });
          setTenantsList({ count: response.data.count, list: formattedList });
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchTenantList();
  }, [filterOptions]);

  const tenantDetails =
    selectedOrganization != null
      ? {
          organizationId: selectedOrganization.organizationId,
          organization: selectedOrganization.name,
          tenantType: TENANT_TYPE.customer,
        }
      : null;

  function fetchUpdated() {
    if (filterOptions.pageNo > 0 && tenantsList.list.length === 1) {
      setFilterOptions({
        ...filterOptions,
        pageNo: filterOptions.pageNo - 1,
      });
    } else {
      fetchTenantList();
    }
  }

  const buttons = [
    {
      key: "createNewTenant",
      text: BUTTONS.createTenant,
      clickHandler: handleCreateNewTenant,
      disabled: false,
      icon: <AddCircleOutlineIcon />,
      type: BUTTONS_TYPE.circle,
    },
    {
      key: "filter",
      text: filterOptions.isFilterApplied ? (
        <Badge color="error" variant="dot">
          <FilterListSharpIcon />
        </Badge>
      ) : (
        <FilterListSharpIcon />
      ),
      clickHandler: () => setOpenFilter(true),
      disabled: false,
      icon: null,
      type: BUTTONS_TYPE.square,
    },
  ];

  const filterableitems = [
    {
      name: "name",
      label: "Tenant Name",
      placeholder: "Enter tenant name",
      defaultValue: filterOptions.tenantName,
    },
    {
      name: "managedDevices",
      label: "Qualcomm Managed",
      placeholder: "Qualcomm Managed",
      defaultValue: filterOptions.isQualcomManaged,
      select: true,
      options: ["Both", "Yes", "No"],
    },
  ];

  const submitHandler = (data) => {
    const isEmpty = Object.values(data).every(
      (x) => x === null || x === "" || x === "Both"
    );
    setFilterOptions({
      tenantName: data.name,
      isQualcomManaged: data.managedDevices || "Both",
      pageNo: 0,
      isFilterApplied: !isEmpty,
    });
    setOpenFilter(false);
    setIsLoading(true);
  };

  const filteredItems = useMemo(() => {
    return searchStatefn.length != 3
      ? [...filterableitems.filter((i) => searchStatefn.includes(i.name))]
      : [...filterableitems];
  }, [searchStatefn, filterOptions]);

  return (
    <Fragment>
      {openFilter && (
        <CommonFilter
          items={filteredItems}
          onClose={() => setOpenFilter(false)}
          onSubmit={submitHandler}
          onClear={() => {
            setFilterOptions({
              tenantName: "",
              pageNo: 0,
              isFilterApplied: false,
              isQualcomManaged: "Both",
            });
            setOpenFilter(false);
            setIsLoading(true);
          }}
        />
      )}
      {openSDK && (
        <BackdropOverlayModal>
          <div className={classes.popupContainer}>
            <EditSDKPreference onClose={closeOpenSDK} details={selectedRow} />
          </div>
        </BackdropOverlayModal>
      )}
      {openManagedDevices && (
        <BackdropOverlayModal>
          <div className={classes["client-container"]}>
            <ClientIDAndSecret
              onClose={closeOpenManagedDeives}
              details={selectedRow}
            />
          </div>
        </BackdropOverlayModal>
      )}
      {openEditTenant && (
        <EditTenant
          getUpdatedDataFn={fetchTenantList}
          onClose={closeEditTenant}
          tenantDetails={selectedRow}
        />
      )}
      {openCreateNewTenant && (
        <CreateNewTenant
          getUpdatedDataFn={fetchTenantList}
          onClose={closeCreateNewTenant}
          tenantDetails={tenantDetails}
        />
      )}
      {openDeleteTenant && (
        <DeleteTenant
          getUpdatedDataFn={fetchUpdated}
          onClose={closeDeleteUser}
          userDetails={selectedRow}
        />
      )}
      <div className={classes.container}>
        <DataTablePanelTest
          backButton={true}
          disableSearch={true}
          buttons={buttons}
        />
        <StyledBreadcrumbs>
          {breadCrumbArray.map((item) => (
            <StyledLink key={item.link} to={item.link}>
              {item.name}
            </StyledLink>
          ))}
          <p> {selectedOrganization?.name}</p>
        </StyledBreadcrumbs>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.5rem",
                wordBreak: "break-word",
              },
            }}
            getRowHeight={() => "auto"}
            columns={columns}
            rows={isLoading ? [] : tenantsList.list}
            disableColumnFilter
            onRowClick={(params, event) => {
              if (!event.ignore) {
                handleRowClick(params);
              }
            }}
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            columnVisibilityModel={initialState}
            onColumnVisibilityModelChange={(newModel) =>
              dispatch(columnsActions.setcustomerTenantsColumnsState(newModel))
            }
            components={{
              Pagination: CustomPagination,
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: tenantsList.count,
                handlePageChange: handlePageChange,
                page: filterOptions.pageNo,
              },
              toolbar: {
                reference: setColumnButtonEl,
                children: <DataTableHeaders headers={headers} />,
              },
              panel: {
                anchorEl: columnButtonEl,
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },
              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.customerTenants,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerTenantsList;
