export const LOGIN_REQUIRED = "login-required";
export const CHECK_SSO = "check-sso";
export const KEYCLOAK_INIT = {
  onLoad: LOGIN_REQUIRED,
};

export const ENTITY_TYPE = {
  css: "CSS",
  isv: "ISV",
  customer: "CUSTOMER",
};
