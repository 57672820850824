import { useNavigate } from "react-router-dom";

import HeaderInfo from "./HeaderInfo";

import classes from "./DataTableHeaders.module.css";
import MenuButton from "../ui/Buttons/MenuButton";

const DataTableHeaders = (props) => {
  const { headers } = props;
  const navigate = useNavigate();
  const handleHeaderClick = (route) => {
    navigate(route);
  };
  return (
    <div className={classes.container}>
      <div className={classes.menu}>
        <MenuButton />
      </div>
      {headers.map((headerInfo) => {
        if (headerInfo.route != null)
          return (
            <HeaderInfo
              key={headerInfo.name}
              headerInfo={headerInfo}
              handleHeaderClick={handleHeaderClick}
            />
          );
      })}
    </div>
  );
};

export default DataTableHeaders;
