import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import LockResetIcon from "@mui/icons-material/LockReset";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";

import UserSettingsHeaderTab from "./UserSettingsHeaderTab";

import classes from "./UserSettingsHeaders.module.css";

const UserSettingsHeaders = (props) => {
  const { active, handleHeader, selectedAppType } = props;
  return (
    <div className={classes.container}>
      <UserSettingsHeaderTab
        id="profile"
        text="Profile"
        onClick={handleHeader}
        active={active === "profile" ? active : null}
        img={<AccountCircleSharpIcon />}
      />
      <UserSettingsHeaderTab
        id="change-password"
        text="Change Password"
        onClick={handleHeader}
        active={active === "change-password" ? active : null}
        img={<LockResetIcon />}
      />
      {(selectedAppType === "CUSTOMER" || selectedAppType === "ISV") && (
        <UserSettingsHeaderTab
          id="tenantSettings"
          text="Tenant Settings"
          onClick={handleHeader}
          active={active === "tenantSettings" ? active : null}
          img={<EngineeringIcon />}
        />
      )}
      {selectedAppType === "CUSTOMER" && (
        <UserSettingsHeaderTab
          id="sdkSettings"
          text="QSEA-SDK Management"
          onClick={handleHeader}
          active={active === "sdkSettings" ? active : null}
          img={<AppSettingsAltIcon />}
        />
      )}
    </div>
  );
};

export default UserSettingsHeaders;
