import { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, SpeedDial, SpeedDialAction, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";

import { REPO_STATUS } from "../../../../constants/repo-constants";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../../ui/CustomDataGrid";
import CustomNoRowMessage from "../../../ui/CustomNoRowMessage";
import {
  downloadFile,
  ExpiryDateFormatter,
  getDocumentLink,
  fetchFileNameFromAddr,
} from "../../../helpers/utils";
import useRepoStatus from "../../../../hooks/useRepoStatus";
import { getAttachmentLink } from "../../../../api/api-index";
import { FILE_DOWNLOAD_MESSAGES } from "../../../../constants/message-constants";

const StatusTable = ({
  list,
  handleClick,
  handleGlobal,
  version,
  currentISVStatus,
}) => {
  const featureVersionId = list[0].featureVersionId;

  const isDisabled = currentISVStatus === "MARKED_FOR_DELETE" ? true : false;

  const formattedList = list.map((item) => {
    return {
      id: item.status,
      status: item.status,
      remarks: item.remark,
      handledBy: item.createdBy,
      attachments: item.attachment,
      // attachmentName: item.attachmentName,
      date: ExpiryDateFormatter(item.createdOn),
      qcommFeatureName: item?.qcommFeatureName,
      featureVersionId: item?.featureVersionId,
      // attachmentPath: item?.attachmentName || "",
    };
  });

  let [StatusHistory, repoStatusGenerator] = useRepoStatus(formattedList);
  useEffect(() => {
    repoStatusGenerator();
  }, [list]);

  function handleDownloadFile(path, fileName) {
    const payload = {
      attachment: path,
    };
    getAttachmentLink(payload).then((res) => {
      if (res.status != false) {
        downloadFile(res, fileName);
        toast.success(FILE_DOWNLOAD_MESSAGES.success);
      } else {
        toast.error(FILE_DOWNLOAD_MESSAGES.failure);
      }
    });
  }

  const columns = [
    {
      field: "status",
      headerName: "Status",
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.enabled ? (
              <SpeedDial
                sx={{
                  ".MuiFab-root": {
                    boxShadow: "none",
                    textTransform: "Capitalize",
                    fontFamily: "QualcommNext-Regular",
                    fontSize: "0.8rem",
                  },
                  ".MuiSpeedDial-actions": {
                    paddingLeft: "35px",
                  },
                  ".MuiFab-extended": {
                    cursor: "text",
                  },
                }}
                open={true}
                ariaLabel="SpeedDial basic example"
                icon={
                  params.row.status === "Added to Global Repository"
                    ? "Add to Global Repository"
                    : params.row.status === "Approved by Validator"
                    ? "Approve by Validator"
                    : params.row.status
                }
                direction="right"
                FabProps={{
                  disabled: isDisabled,
                  variant: "extended",
                  size: "small",
                  color: "primary",
                  disableRipple: true,
                }}
              >
                {params.row.status !== "Added to Global Repository" ? (
                  <SpeedDialAction
                    icon={
                      <CheckCircleIcon
                        color={isDisabled ? "grey" : "primary"}
                      />
                    }
                    tooltipTitle="Approve"
                    disabled={isDisabled}
                    onClick={() =>
                      handleClick(params, featureVersionId, version)
                    }
                  />
                ) : (
                  <SpeedDialAction
                    icon={
                      <CheckCircleIcon
                        color={isDisabled ? "grey" : "primary"}
                      />
                    }
                    tooltipTitle="Approve"
                    disabled={isDisabled}
                    onClick={() =>
                      handleGlobal(params, featureVersionId, version, "global")
                    }
                  />
                )}
                {params.row.status !== "Added to Global Repository" && (
                  <SpeedDialAction
                    icon={<CancelIcon />}
                    disabled={isDisabled}
                    tooltipTitle="Deny"
                    onClick={() =>
                      handleGlobal(params, featureVersionId, version, "denied")
                    }
                  />
                )}
              </SpeedDial>
            ) : (
              <span>{params.row.status}</span>
            )}
          </>
        );
      },
    },

    {
      field: "handledBy",
      headerName: "Handled By",
      minWidth: 100,
      flex: 0.8,
      sortable: false,
    },

    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      flex: 0.4,
      sortable: false,
    },

    {
      field: "remarks",
      headerName: "Remarks",
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.value ? <div>{params.value}</div> : "";
      },
    },

    {
      field: "attachments",
      headerName: "Attachments",
      minWidth: 120,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return params.row.attachments != null ? (
          <Tooltip title={fetchFileNameFromAddr(params.row.attachments)}>
            <Button
              id="=btn-dw"
              sx={{
                textTransform: "Capitalize",
                fontFamily: "QualcommNext-Regular",
              }}
              size="small"
              endIcon={<DownloadIcon />}
              variant="outlined"
              onClick={() =>
                handleDownloadFile(
                  params.row.attachments,
                  fetchFileNameFromAddr(params.row.attachments)
                )
              }
              // href={getDocumentLink(params.row.attachments)}
              // download={params.row.attachmentName + ".csv"}
              aria-label="download"
            >
              {fetchFileNameFromAddr(params.row.attachments).trimEllip(10)}
            </Button>
          </Tooltip>
        ) : (
          "-"
        );
      },
    },
  ];

  function formatter() {
    const formattedStatusList = StatusHistory.find(
      (status) => status.status === "Denied by Validator"
    );

    if (formattedStatusList?.id) {
      StatusHistory = StatusHistory.filter(
        (item) =>
          item.status !== "Added to Global Repository" &&
          item.featureVersionId === formattedStatusList.featureVersionId
      );
    }
  }

  formatter();

  return (
    <CustomDataGrid
      sx={{
        height: "35vh",
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "0.8rem",
          wordBreak: "break-word",
          cursor: "default",
        },
      }}
      getRowHeight={() => "auto"}
      columns={columns}
      rows={StatusHistory}
      disableColumnFilter
      disableColumnMenu
      hideFooterSelectedRowCount
      hideFooter
      scrollbarSize={17}
      getCellClassName={(params) => {
        if (params.field === "id") {
          return "id";
        }
        if (
          (params.row.handledBy == null && params.row.enabled == null) ||
          params.row.enabled == false
        ) {
          return "status";
        }
        if (currentISVStatus == "MARKED_FOR_DELETE") {
          return "ISVStatus";
        }
        return "general";
      }}
      components={{
        NoRowsOverlay: CustomNoRowMessage,
      }}
      componentsProps={{
        panel: {
          sx: panelStyles,
        },
        columnMenu: {
          sx: columnMenuStyles,
        },
      }}
    />
  );
};

export default StatusTable;
