import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(() => ({
  color: "#4d6994",
  borderColor: "#4d6994",
  "&:hover": {
    borderColor: "#1a4071",
  },
}));

const UploadFileButton = (props) => {
  const { onClick, text } = props;
  return (
    <ColorButton
      variant="outlined"
      fullWidth
      disableElevation
      onClick={onClick != null ? onClick : null}
    >
      {text}
    </ColorButton>
  );
};

export default UploadFileButton;
