import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { DELETE_SUBSCRIPTION_POLICY_MESSAGES } from "../../../constants/message-constants";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";
import { deleteSubscriptionPolicy } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";

import classes from "./DeleteSubscriptionPolicy.module.css";

const DeleteSubscriptionPolicy = (props) => {
  const navigate = useNavigate();
  const { onClose, policyId, getUpdatedDataFn } = props;

  const onSubmit = () => {
    deleteSubscriptionPolicy(policyId)
      .then((response) => {
        if (response.status) {
          toast.success(DELETE_SUBSCRIPTION_POLICY_MESSAGES.success);
          getUpdatedDataFn && getUpdatedDataFn();
        } else {
          errorHandler(
            response.error,
            DELETE_SUBSCRIPTION_POLICY_MESSAGES.failure
          );

          console.log(response.error);
        }
        onClose();
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, DELETE_SUBSCRIPTION_POLICY_MESSAGES.failure);
        onClose();
      });
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Delete Subscription Policy" onClose={onClose} />
        <div className={classes.warning}>
          <p>
            Are you sure you want to delete
            <span className={classes.userNameStyles}>{` "${policyId}" `}</span>?
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <ConfirmDeleteButton text={"Confirm"} onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default DeleteSubscriptionPolicy;
