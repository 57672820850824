import { styled } from "@mui/material/styles";
import MUIChip from "@mui/material/Chip";

const CustomChip = styled(MUIChip)(() => ({
  backgroundColor: "#e7e1e1",
  borderColor: "grey",
  color: "black",
  fontFamily: "QualcommNext-Regular",
}));

const InactiveChip = (props) => {
  const { text } = props;
  return <CustomChip label={text} variant="outlined" />;
};

export default InactiveChip;
