import { CircularProgress } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";

const CustomNoRowMessage = ({ text, isLoading = false }) => {
  return (
    <GridOverlay>
      {isLoading ? <CircularProgress /> : <div>{text || "No data found"}</div>}
    </GridOverlay>
  );
};

export default CustomNoRowMessage;
