import axios from "axios";
import { configureFeatureCatalog } from "./apiEndpoints";

export const configureCatalog = async (payload) => {
  let data = null;
  const url = `${configureFeatureCatalog}/${payload.featureName}`;

  const headers = new Headers();
  headers.append("Content-Type", "multipart/form-data");

  const formdata = new FormData();
  payload.filesArray.forEach((element, key) => {
    formdata.append(`image${key + 1}`, element);
  });
  formdata.append(`titleTextData`, JSON.stringify(payload.listArr));
  const requestOptions = {
    headers: headers,
    body: formdata,
    redirect: "follow",
  };
  await axios
    .post(
      url,
      formdata,

      requestOptions
    )
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
