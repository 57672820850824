export const CSS_ROUTES = {
  dashboard: "/dashboard",
  httpServerRequestsSecondsCount:
    "/dashboard/http_server_requests_seconds_count",
  microservicesCrashCount: "/dashboard/microservices_Crash_Count",
  tenantDeviceLicenses: "/dashboard/tenant-device-licenses",
  tenantDeviceLicensesById:
    "/dashboard/tenant-device-licenses/:featureName/:tenantId",
  organizations: "/organizations",
  organizationInfo: "/organizations/:organizationId",
  organizationUsers: "/organizations/:organizationId/users",
  isvTenants: "/organizations/:organizationId/isv-tenants",
  isvTenantUsers: "/organizations/:organizationId/isv-tenants/:tenantId/users",
  isvTenantAvailableUsers:
    "/organizations/:organizationId/isv-tenants/:tenantId/available-users",
  customerTenants: "/organizations/:organizationId/customer-tenants",
  customerTenantUsers:
    "/organizations/:organizationId/customer-tenants/:customerId/users",
  customerTenantAvailableUsers:
    "/organizations/:organizationId/customer-tenants/:customerId/available-users",
  cssAdmins: "/css-admins",
  adminActions: "/admin-actions",
  isvTenantActions: "/isv-tenant-actions",
  customerTenantActions: "/customer-tenant-actions",
  permissions: "/permissions",
  subscriptionPolicy: "/subscription-policy",
  subscriptionPolicyInfo: "/subscription-policy/:policyId",
  featureOwnerShip: "/feature-ownership",
  repositories: "/repositories",
  localRepositories: "/repositories/local",
  localRepositoryInfo: "/repositories/local/:repoId",
  globalRepositories: "/repositories/global",
  globalRepositoryInfo: "/repositories/global/:repoId",
  associatedDevices: "/mdd/associated-devices",
  availableDevices: "/mdd/available-devices",
  sdk: "/sdk",
  androidSDk: "/sdk/androidSDK",
  linuxSDk: "/sdk/linuxSDK",
};

export const TENANTS_ROUTES = {
  isvTenants: "/organizations/:organizationId/isv-tenants",
  isvTenantFeatures:
    "/organizations/:organizationId/isv-tenants/:tenantId/my-features",
  isvTenantUsers:
    "/organizations/:organizationId/isv-tenants/:tenantId/associated-users",
  isvTenantsAddAvailableUsers:
    "/organizations/:organizationId/isv-tenants/:tenantId/associated-users/available-users",
  isvTenantFeaturesInfo:
    "/organizations/:organizationId/isv-tenants/:tenantId/my-features/:featureId",
  isvTenantFeatureSubscribers:
    "/organizations/:organizationId/isv-tenants/:tenantId/my-features/:featureId/subscribers",
  isvTenantFeaturesVersionHistory:
    "/organizations/:organizationId/isv-tenants/:tenantId/my-features/:featureId/version-history",
  isvSubscribers:
    "/organizations/:organizationId/isv-tenants/:tenantId/subscribers",
  isvSubscribersInfo:
    "/organizations/:organizationId/isv-tenants/:tenantId/subscribers/:customerId",
  isvContents:
    "/organizations/:organizationId/isv-tenants/:tenantId/my-features/:featureId/contents",
  customerTenants: "/organizations/:organizationId/customer-tenants",
  customerTenantDevices:
    "/organizations/:organizationId/customer-tenants/:customerId/devices",
  customerTenantUsers:
    "/organizations/:organizationId/customer-tenants/:customerId/associated-users",
  customerTenantAddAvailableUsers:
    "/organizations/:organizationId/customer-tenants/:customerId/associated-users/available-users",
  customerTenantDeviceInfo:
    "/organizations/:organizationId/customer-tenants/:customerId/devices/:deviceId",
  customerTenantDeviceHealthMetrics:
    "/organizations/:organizationId/customer-tenants/:customerId/devices/:deviceId/health-metrics",
  customerTenantDeviceQSEAMetrics:
    "/organizations/:organizationId/customer-tenants/:customerId/devices/:deviceId/qsea-metrics",
  customerTenantModels:
    "/organizations/:organizationId/customer-tenants/:customerId/my-models",
  customerTenantModelInfo:
    "/organizations/:organizationId/customer-tenants/:customerId/my-models/:featureId",
  customerTenantContainers:
    "/organizations/:organizationId/customer-tenants/:customerId/my-containers",
  customerTenantContainerInfo:
    "/organizations/:organizationId/customer-tenants/:customerId/my-containers/:featureId",
  customerTenantFeatures:
    "/organizations/:organizationId/customer-tenants/:customerId/features",
  customerTenantFeatureInfo:
    "/organizations/:organizationId/customer-tenants/:customerId/features/:featureId",
  customerTenantModelDevices:
    "/organizations/:organizationId/customer-tenants/:customerId/my-models/:featureId/:featureName",
  customerTenantContainerDevices:
    "/organizations/:organizationId/customer-tenants/:customerId/my-containers/:featureId/:featureName",
  customerTenantFeatureDevices:
    "/organizations/:organizationId/customer-tenants/:customerId/features/:featureId/:featureName",
  customerTenantFeatureAvailableDevices:
    "/organizations/:organizationId/customer-tenants/:customerId/features/:featureId/available-devices",
  customerMdd:
    "/organizations/:organizationId/customer-tenants/:customerId/mdd",
  customerMddDashboard:
    "/organizations/:organizationId/customer-tenants/:customerId/mdd-dashboard",
  customerMddInfo:
    "/organizations/:organizationId/customer-tenants/:customerId/mdd/:mddId",
  availableDevicesForMdd:
    "/organizations/:organizationId/customer-tenants/:customerId/mdd/:mddId/available-devices",
  associatedDevicesForMdd:
    "/organizations/:organizationId/customer-tenants/:customerId/mdd/:mddId/associated-devices",
};

export const PARTIAL_ROUTES = {
  users: "/users",
  associatedUsers: "/associated-users",
  isvTenants: "/isv-tenants",
  customerTenants: "/customer-tenants",
  availableUsers: "/available-users",
  myFeatures: "/my-features",
  devices: "/devices",
  myModels: "/my-models",
  myContainers: "/my-containers",
  features: "/features",
  versionHistory: "/version-history",
  subscribers: "/subscribers",
  alerts: "/alerts",
  events: "/events",
  contents: "/contents",
  mdd: "/mdd",
  mddDashboard: "/mdd-dashboard",
};

export const COMMON_ROUTES = {
  userSettings: "/user-settings",
  notificationsAlerts: "/notifications/alerts",
  notificationsEvents: "/notifications/events",
  archiveNotificationsAlerts: "/archive/notifications/alerts",
  archiveNotificationsEvents: "/archive/notifications/events",
  catalog: "/catalog",
  catalogContainers: "/catalog-containers",
  catalogModels: "/catalog-models",
  catalogCollections: "/catalog-collections",
  CatalogInfo: "/catalog/:featureName",
  CatalogModelInfo: "/catalog-models/:featureName",
  CatalogContainerInfo: "/catalog-Containers/:featureName",
  CatalogCollectionInfo: "/catalog-Collections/:featureName",
};
