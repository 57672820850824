import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tooltip } from "@mui/material";

const HandledButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Handled",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <CheckCircleIcon sx={{ color: disabled ? "grey" : color }} />
      </IconButton>
    </Tooltip>
  );
};

export default HandledButton;
