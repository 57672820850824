import axios from "axios";
import { getFeatureInfoApi } from "./apiEndpoints";

export const getFeatureInfo = async (options) => {
  let data = null;
  await axios
    .get(getFeatureInfoApi + options?.featureId, {
      params: {
        pageNo: options.pageNo,
        pageSize: options.pageSize,
      },
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error);
      data = error.response;
      data.status = false;
    });
  return data;
};
