import Chip from "../../ui/Chip/Chip"

import classes from "./TenantFeaturesInfoItem.module.css";

const TenantFeaturesInfoItem = (props) => {
  const { text, chipsets, header, icon, muiicon, convertFromBase64 } = props;
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {chipsets == null ? (
          <div className={classes.text}>{text || "N/A"}</div>
        ) : (
          <div className={classes.chipsets}>
            {chipsets?.map((chip) => (
              <Chip key={chip} text={chip} />
            ))}
          </div>
        )}
        <div className={classes.header}>{header}</div>
      </div>
      <div className={classes.img}>
        {icon != null && !convertFromBase64 && (
          <img src={icon} alt={text} className={classes.icon} />
        )}
        {icon != null && convertFromBase64 && (
          <img src={"data:image/*;base64," + icon} alt={text} className={classes.icon} />
        )}
        {muiicon != null && muiicon}
      </div>
    </div>
  );
};

export default TenantFeaturesInfoItem;
