import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Autocomplete, MenuItem } from "@mui/material";
import moment from "moment";
import { debounce } from "../../helpers/utils";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import { StyledPopper } from "../../ui/StyledPopper";
import { getFeatureAndPolicyDetails } from "../../../api/api-index";
import CustomTextField from "../../ui/CustomTextField";
import { STATUS } from "../../../constants/status-constants";

import styles from "./AssociateFeature.module.css";

function AddFeatureExpiration({
  tenantId,
  setFeatureList,
  featureList,
  deviceInfo,
  featureArray,
  setstate,
}) {
  const [featuredetails, setFeaturedetails] = useState({
    featueLabel: null,
    featureId: "",
    featureExpiration: null,
    policyExpirationTime: "",
    preferredVersion: "",
    isFeatureDownloadable: false,
  });
  const [searchFeatureText, setsearchFeatureText] = useState(null);
  const [featureOptions, setFeatureOptions] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const submitHandler = () => {
    if (
      featuredetails.featureId != "" &&
      featuredetails.featureExpiration != null &&
      featuredetails.featureExpiration.isValid()
    ) {
      if (
        moment(featuredetails.featureExpiration).isAfter(
          moment(featuredetails?.policyExpirationTime)
        )
      ) {
        return;
      } else if (
        moment(featuredetails.featureExpiration).isBefore(
          moment(new Date()),
          "d"
        )
      ) {
        return;
      } else {
        setFeatureList([
          {
            ...featuredetails.featureId,
            currentAction: STATUS.INSTALL_PENDING,
            status: "-",
            tempExpTime: moment(featuredetails.featureExpiration)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD"),
            expirationTime: moment(featuredetails.featureExpiration)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD"),
            policyExpirationTime: featuredetails?.policyExpirationTime,
            type: "new",
          },
          ...featureList,
        ]);
        setFeaturedetails({
          featureId: "",
          featureExpiration: null,
          featueLabel: null,
          preferredVersion: "",
          isFeatureDownloadable: false,
        });
        setFeatureOptions([]);
        setIsEmpty(false);
        setstate(false);
      }
    } else {
      setIsEmpty(true);
    }
  };
  useEffect(() => {
    if (
      searchFeatureText !== null &&
      searchFeatureText !== "" &&
      searchFeatureText !== 0
    ) {
      getFeatureAndPolicyDetails({
        featureTitle: searchFeatureText,
        customerId: tenantId,
        chipsetName: deviceInfo.chipsetName,
      })
        .then((response) => {
          if (
            response.status &&
            response.data != null &&
            response.data.length > 0
          ) {
            const formattedFeatureTitle = response.data
              .filter((title) => {
                if (
                  !featureArray.includes(title.qualcommFeatureId)
                  // title.chipset.includes(deviceInfo?.chipsetName)
                )
                  return title;
              })
              .map((title) => {
                return {
                  label: title.featureTitle,
                  id: {
                    qualcommFeatureName: title.qualcommFeatureName,
                    qualcommFeatureId: title.qualcommFeatureId,
                    subscriptionId: title.subscriptionId,
                    featureTitle: title.featureTitle,
                    expirationTime:
                      title?.policyChipsetsList?.length > 0
                        ? title?.policyChipsetsList?.[0]?.expirationTimeStamp
                        : null,
                    featureName: title.featureName,
                    featureId: title.featureId,
                    preferredVersion: title.preferredVersion,
                    isFeatureDownloadable: title?.isFeatureDownloadable,
                  },
                };
              });

            setFeatureOptions(formattedFeatureTitle);
          } else {
            setFeatureOptions([]);
          }
        })
        .catch((error) => {
          console.error(error.response);
        });
    } else {
      setFeatureOptions([]);
    }
  }, [searchFeatureText]);

  return (
    <div className={styles.row}>
      <div className={styles.field_div}>
        <Autocomplete
          disablePortal
          clearOnEscape
          size="small"
          disableClearable
          options={featureOptions}
          fullWidth
          isOptionEqualToValue={(option) =>
            option.label === featuredetails?.featueLabel
          }
          onChange={(event, newValue) => {
            setFeaturedetails({
              ...featuredetails,
              featureId: newValue.id,
              featueLabel: newValue.label,
              policyExpirationTime: newValue.id.expirationTime,
              preferredVersion: newValue?.id?.preferredVersion,
              isFeatureDownloadable: newValue?.id?.isFeatureDownloadable,
            });
          }}
          onInputChange={debounce((event, newValue) => {
            setsearchFeatureText(newValue);
          })}
          value={featuredetails.featueLabel}
          PopperComponent={StyledPopper}
          renderInput={(params) => (
            <CustomTextField
              focused
              {...params}
              label="Enable Feature"
              placeholder="Search Feature"
              error={isEmpty && !featuredetails.featueLabel ? true : false}
            />
          )}
        />
        <div className={styles.prefered_version}>
          {featuredetails?.isFeatureDownloadable &&
            featuredetails.preferredVersion == null &&
            "No preferred version is selected for this feature"}
        </div>
      </div>
      <div className={styles.field_div}>
        <div className={styles.field_div_1}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Feature Expiration Date"
              value={featuredetails.featureExpiration || null}
              onChange={(newValue) => {
                setFeaturedetails({
                  ...featuredetails,
                  featureExpiration: newValue,
                });
              }}
              disablePast
              maxDate={moment(
                featureOptions.filter(
                  (item) => item.label === searchFeatureText
                )[0]?.id?.expirationTime || null
              )}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => {
                return (
                  <CustomTextField
                    size="small"
                    focused
                    fullWidth
                    {...params}
                    error={
                      (params.error ||
                        (isEmpty && !featuredetails.featureExpiration)) &&
                      true
                    }
                  />
                );
              }}
            />
          </LocalizationProvider>
          {/* <CustomTextField
            size="small"
            focused
            fullWidth
            label="Feature Expiration Date"
            InputProps={{
              inputProps: {
                max: moment(
                  featureOptions[0]?.id?.expirationTime || null
                ).format("YYYY-MM-DD"),
                min: moment(new Date()).format("YYYY-MM-DD"),
              },
            }}
            onChange={(event, newValue) => {
              event.preventDefault();
              setFeaturedetails({
                ...featuredetails,
                featureExpiration: event?.target?.value,
              });
            }}
            value={featuredetails.featureExpiration || ""}
            type="date"
            error={isEmpty && !featuredetails.featureExpiration ? true : false}
          /> */}

          <SubmitButton
            disabled={
              featuredetails?.isFeatureDownloadable &&
              featuredetails.preferredVersion == null
            }
            text={"Add"}
            type={"button"}
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default AddFeatureExpiration;
