import { styled } from "@mui/material/styles";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";

const MUIGridToolbarContainer = styled(GridToolbarContainer)(() => ({
  justifyContent: "space-between",
  gap: "1em",
  "& .MuiButton-root": {
    fontFamily: "QualcommNext-Regular",
    fontWeight: "bold",
    color: "#214572",
  },
  paddingLeft: "0",
}));

const CustomToolbar = (props) => {
  const { reference, icons, children } = props;
  return (
    <MUIGridToolbarContainer>
      {children}
      {reference && <GridToolbarColumnsButton ref={reference} />}
      {icons && <div>{icons}</div>}
    </MUIGridToolbarContainer>
  );
};

export default CustomToolbar;
