import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { removeUserFromTenancy } from "../../../api/api-index";
import { REMOVE_USER_FROM_TENANCY_MESSAGES } from "../../../constants/message-constants";
import { OPERATION_CONSTANTS } from "../../../constants/operation-constants";
import { userActions } from "../../../store/slices/user-slice";
import errorHandler from "../../helpers/error-helper";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CancelButton from "../../ui/Buttons/CancelButton";
import ConfirmDeleteButton from "../../ui/Buttons/ConfirmDeleteButton";
import CommonHeader from "../../ui/CommonHeader";

import classes from "./RemoveUserFromTenancy.module.css";

const RemoveUserFromTenancy = (props) => {
  const { onClose, userDetails, getUpdatedDataFn } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const selectedTenantType = useSelector(
    (state) => state.tenantsInfo?.selectedTenant
  );
  const selectedUserId = useSelector(
    (state) => state.userInfo.userInfo?.userId
  );

  const onSubmit = () => {
    const options = {
      organizationId: selectedTenantType?.organizationId,
      tenantId: params?.tenantId || params?.customerId,
      userId: userDetails?.userId,
      tenantType: selectedTenantType?.tenantType,
    };

    const operationType = {
      opType: OPERATION_CONSTANTS.REMOVE,
    };
    removeUserFromTenancy(options, operationType)
      .then((response) => {
        if (response.status) {
          toast.success(REMOVE_USER_FROM_TENANCY_MESSAGES.success);
          if (userDetails?.userId === selectedUserId) {
            dispatch(userActions.resetUserInfo());
            navigate("/");
          } else {
            onClose();
            getUpdatedDataFn && getUpdatedDataFn();
          }
        } else {
          errorHandler(
            response.error,
            REMOVE_USER_FROM_TENANCY_MESSAGES.failure
          );
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        toast.error(REMOVE_USER_FROM_TENANCY_MESSAGES.failure);
        errorHandler(error, REMOVE_USER_FROM_TENANCY_MESSAGES.failure);
      });
  };

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading="Remove User from Tenancy" onClose={onClose} />
        <div className={classes.warning}>
          <p>
            Are you sure you want to remove{" "}
            <b>{userDetails?.firstName || ""} </b>
            <b>{userDetails?.lastName || ""} </b>
            user from tenancy ?
          </p>
          <p>This action is irreversible !</p>
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <ConfirmDeleteButton text={"Confirm"} onClick={onSubmit} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default RemoveUserFromTenancy;
