import { createSlice } from "@reduxjs/toolkit";

const initialSubscribersState = {
  selectedSubscriber: null,
};

const subscribersSlice = createSlice({
  name: "subscribers",
  initialState: initialSubscribersState,
  reducers: {
    setSelectedSubscriber(state, action) {
      state.selectedSubscriber = action.payload;
    },
  },
});

const subscriberActions = subscribersSlice.actions;

export { subscribersSlice, initialSubscribersState, subscriberActions };
