import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { LinearProgress, MenuItem } from "@mui/material";

import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import CancelButton from "../../ui/Buttons/CancelButton";
import CustomTextField from "../../ui/CustomTextField";
import { CustomDeviceAccordion } from "./CustomDeviceAccordion";
import { CustomQseaAccordion } from "./CustomQseaAccordion";
import { createMetricsDefinition, getAllMetrics } from "../../../api/api-index";
import errorHandler from "../../helpers/error-helper";
import {
  CREATE_MDD_MESSAGES,
  GET_MESSAGES,
} from "../../../constants/message-constants";
import { METRIC_CATEGORY } from "../../../constants/metrics-constants";
import { RETENTION_PERIOD } from "../../../constants/time-constants";

import classes from "./CreateMDD.module.css";

const CreateMDD = ({ fetchAllMddList, onClose }) => {
  let clicked = true;
  const isQualcommManagingDevice = useSelector(
    (state) => state.tenantsInfo.selectedTenant.isQualcommManagingDevice
  );
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [retentionPeriod, setRetentionPeriod] = useState("90d");
  const [deviceMetrics, setdeviceMetrics] = useState([]);
  const [qseaMetrics, setQseaMetrics] = useState([]);

  const [deviceEndpoint, setDeviceEndpoint] = useState({
    value: "CSS",
    url: "",
    isQualcommManagingDevice,
  });
  const [qseaEndpoint, setQseaEndpoint] = useState({
    value: "CSS",
    url: "",
    isQualcommManagingDevice,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const deviceCheckedMetrics = deviceMetrics.filter((item) => item.checked);
    const qseaCheckedMetrics = qseaMetrics.filter((item) => item.checked);

    const metricArray = [];
    deviceCheckedMetrics.length > 0 &&
      metricArray.push({
        metricCategory: METRIC_CATEGORY.deviceMetrics,
        endpointType: deviceEndpoint.value,
        endpoint: deviceEndpoint.url || null,
        mddMetricsCategory: deviceCheckedMetrics.map((item) => {
          return {
            metricName: item.metricName,
            reportingRate: item.reportingRate,
            metricType: item.metricType,
            metricId: item.metricId,
          };
        }),
      });
    qseaCheckedMetrics.length > 0 &&
      metricArray.push({
        metricCategory: METRIC_CATEGORY.qseaMetrics,
        endpointType: qseaEndpoint.value,
        endpoint: qseaEndpoint.url || null,
        mddMetricsCategory: qseaCheckedMetrics.map((item) => {
          return {
            metricName: item.metricName,
            reportingRate: item.reportingRate,
            metricType: item.metricType,
            metricId: item.metricId,
          };
        }),
      });
    const requestData = {
      tenantId: params.customerId,
      mddName: name,
      retentionPeriod: retentionPeriod,
      metricsCategory: metricArray,
    };
    if (clicked) {
      if (deviceCheckedMetrics.length > 0 || qseaCheckedMetrics.length > 0) {
        clicked = false;
        createMetricsDefinition(requestData)
          .then((response) => {
            if (response.status) {
              toast.success(CREATE_MDD_MESSAGES.success);
              fetchAllMddList();
              onClose();
            } else {
              console.error(response.error);
              errorHandler(response.error, CREATE_MDD_MESSAGES.failure);
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            errorHandler(error, CREATE_MDD_MESSAGES.failure);
          })
          .finally(() => {
            clicked = true;
          });
      } else {
        toast.error("Please select atleast one metric");
      }
    }
  };

  useEffect(() => {
    getAllMetrics({ source: "Device" })
      .then((response) => {
        if (response.status && response.data != null) {
          const deviceMetricsList = response.data.Device_Health_Metrics.map(
            (item) => {
              return {
                metricName: item.metricName,
                displayName: item.displayName,
                reportingRate: item.reportingRate,
                checked: false,
                metricCategory: METRIC_CATEGORY.deviceMetrics,
                metricType: item.metricType,
                metricId: item.metricId,
              };
            }
          );
          const qseaMetricsList = response.data.QSEA_Platform_Metrics.map(
            (item) => {
              return {
                metricName: item.metricName,
                displayName: item.displayName,
                reportingRate: item.reportingRate,
                checked: false,
                metricCategory: METRIC_CATEGORY.qseaMetrics,
                metricType: item.metricType,
                metricId: item.metricId,
              };
            }
          );
          setdeviceMetrics(deviceMetricsList);
          setQseaMetrics(qseaMetricsList);
        } else {
          console.error(response.error);
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading={"Create MDD"} onClose={onClose} />

        {!isLoading ? (
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <div className={`${classes.row} ${classes.firstR}`}>
              <CustomTextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                focused
                fullWidth
                type="text"
                label="MDD Name"
                required
                placeholder="Enter MDD Name"
                inputProps={{
                  pattern: ".*[^ ].*",
                  title: "MDD name cannot have only spaces",
                  maxLength: 255,
                }}
              />
              <CustomTextField
                required
                sx={{ flexBasis: { md: "50%", lg: "25%" } }}
                size="small"
                fullWidth
                label="Retention Period"
                focused
                select
                value={retentionPeriod}
                onChange={(e) => setRetentionPeriod(e.target.value)}
              >
                {RETENTION_PERIOD.map((item, key) => (
                  <MenuItem
                    sx={{ fontFamily: "QualcommNext-Regular" }}
                    key={key}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            </div>
            <div className={classes.row}>
              <CustomDeviceAccordion
                deviceEndpoint={deviceEndpoint}
                setDeviceEndpoint={setDeviceEndpoint}
                deviceMetrics={deviceMetrics}
                setdeviceMetrics={setdeviceMetrics}
              />
            </div>
            <div className={classes.row}>
              <CustomQseaAccordion
                qseaEndpoint={qseaEndpoint}
                setQseaEndpoint={setQseaEndpoint}
                qseaMetrics={qseaMetrics}
                setQseaMetrics={setQseaMetrics}
              />
            </div>
            <div className={classes.buttons}>
              <CancelButton onClick={onClose} text={"Cancel"} />
              <SubmitButton text={"Submit"} type={"submit"} />
            </div>
          </form>
        ) : (
          <LinearProgress />
        )}
      </div>
    </BackdropOverlayModal>
  );
};
export default CreateMDD;
