import IconButton from "@mui/material/IconButton";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Tooltip } from "@mui/material";

const ArchiveButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Archive",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <ArchiveIcon sx={{ color: disabled ? "grey" : color }} />
      </IconButton>
    </Tooltip>
  );
};

export default ArchiveButton;
