import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { BiUnlink } from "react-icons/bi";

const UnlinkButton = (props) => {
  const {
    onClick,
    size,
    color = "rgb(33, 69, 114)",
    tooltipText = "Remove Association",
    disabled = false,
  } = props;
  return (
    <Tooltip title={tooltipText}>
      <IconButton
        aria-label={tooltipText}
        disabled={disabled}
        size={size != null ? size : "large"}
        onClick={onClick != null ? onClick : null}
      >
        <BiUnlink size={22} color="#FF6464" />
      </IconButton>
    </Tooltip>
  );
};

export default UnlinkButton;
