import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Chip } from "@mui/material";
import { MdOutlineUploadFile } from "react-icons/md";

import CancelButton from "../../ui/Buttons/CancelButton";
import CloseIcon from "../../ui/Buttons/CloseIcon";
import SubmitButton from "../../ui/Buttons/SubmitButton";
import {
  ADD_LOGO_MESSAGES,
  ISV_APPROVED_VERSION,
  ISV_VERIFY_VERSION,
} from "../../../constants/message-constants";
import errorHandler from "../../helpers/error-helper";
import CustomTextField from "../../ui/CustomTextField";
import BackdropOverlayModal from "../../ui/BackdropOverlayModal";
import CommonHeader from "../../ui/CommonHeader";
import KeycloakConfig from "../../../config/keycloak-config";
import { updateFeatureVersion } from "../../../api/api-index";

import classes from "./CustomUpload.module.css";

const baseStyle = {
  padding: "0.5em",
  borderRadius: "8px",
  border: "1px dashed #908F8F",
  backgroundColor: "#F8FAFF",
  color: "#BCCEFA",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "12vh",
  width: "45vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3em",
};

const errorBaseStyle = {
  ...baseStyle,
  border: "1px dashed red",
  backgroundColor: "#ff174433",
};

const activeStyle = {
  border: "2px dashed #2196f3",
};

const acceptStyle = {
  border: "2px dashed #00e676",
  backgroundColor: "#00e67633",
};

const rejectStyle = {
  border: "2px dashed #ff1744",
  backgroundColor: "#ff174433",
};
const mdStyles = {
  ...baseStyle,
  flexDirection: "column",
  gap: 0,
};
const CustomUpload = (props) => {
  const matches = useMediaQuery("(max-width:1000px)");
  let clicked = true;
  const {
    organizationId,
    onClose,
    versionDetails,
    setParamId,
    heading = "Approve",
    updateListFn,
  } = props;

  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState({
    status: false,
    error: "",
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const [comment, setComment] = useState("");

  const onDropAccepted = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setRejected({ status: false, error: "" });
    setIsEmpty(false);
  }, []);
  const onDropRejected = (file) => {
    const errStatus =
      file[0]?.errors[0]?.code === "too-many-files"
        ? "Please select only one file"
        : file[0]?.errors[0]?.code === "file-too-large"
        ? "File size exceeds 1 MB"
        : "Only one file with pdf/excel/csv format is allowed";
    setRejected({ status: true, error: errStatus || "" });
    setFiles([]);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 1000000,
    accept:
      "text/csv, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const style = useMemo(
    () => ({
      ...(matches ? mdStyles : baseStyle),
      ...(rejected.status ? rejectStyle : {}),
      ...(isEmpty ? errorBaseStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      isEmpty,
      rejected.status,
      matches,
    ]
  );

  const handleRemoveFile = () => {
    setFiles([]);
  };

  const requestFormatter = (data, commentByUser) => {
    let request = null;
    if (data) {
      request = {
        featureName: data.featureName,
        featureVersionId: data.featureVersionId,
        featureVersionStatus: versionDetails?.nextStatus,
        comment: commentByUser,
        createdBy: KeycloakConfig.getUserId(),
        oldFeatureVersionStatus: data.oldStatus,
      };
    }

    return request;
  };

  const handleUploadFile = () => {
    if (clicked) {
      clicked = false;
      if (files.length > 0 && files[0]?.name) {
        setIsEmpty(false);
        updateFeatureVersion(
          requestFormatter(versionDetails, comment),
          files[0]
        )
          .then((response) => {
            if (response.status) {
              if (heading === "Approve") {
                toast.success(ISV_APPROVED_VERSION.success);
              } else {
                toast.success(ISV_VERIFY_VERSION.success);
              }
              onClose();
              setParamId && setParamId(null);
            } else {
              console.error(response.error);
              if (heading === "Approve") {
                errorHandler(response.error, ISV_APPROVED_VERSION.failure);
              } else {
                errorHandler(response.error, ISV_VERIFY_VERSION.failure);
              }
              clicked = true;
            }
          })
          .catch((error) => {
            console.error("ERROR: ", error);
            if (heading === "Approve") {
              errorHandler(error, ISV_APPROVED_VERSION.failure);
            } else {
              errorHandler(error, ISV_VERIFY_VERSION.failure);
            }
            clicked = true;
          })
          .finally(() => {
            updateListFn && updateListFn();
          });
      } else {
        setRejected({ status: true, error: "Please add file" });
        clicked = true;
        // setIsEmpty(true);
      }
    }
  };

  const thumbs = files.map((file, i) => (
    <div className={classes.files} key={i}>
      {file?.name && (
        <Chip
          sx={{ fontFamily: "QualcommNext-Regular" }}
          label={file?.name.trimEllip(15)}
          //   onClick={handleDeleteFile}
          onDelete={handleRemoveFile}
        />
      )}
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const header = `${heading} ${versionDetails?.version}`;

  return (
    <BackdropOverlayModal>
      <div className={classes.container}>
        <CommonHeader heading={header} onClose={onClose} />
        <div>
          <section {...getRootProps({ style })}>
            <div className={classes.upload_container}>
              <input {...getInputProps()} />
              <MdOutlineUploadFile
                className={classes.uploadIcon}
                color="#58A7FF"
                size={30}
              />
              <p className={classes.upload}>
                Click or drag file to this area to upload
                <em>
                  (Only one file with pdf/excel/csv format of max. size 1 MB is
                  allowed)
                </em>
              </p>
            </div>
            {thumbs}
          </section>
          <p className={classes.error}>{rejected.error}</p>
        </div>
        <div>
          <CustomTextField
            multiline
            size="small"
            focused
            fullWidth
            id="key"
            maxRows={4}
            InputProps={{
              inputProps: {
                maxLength: 255,
              },
            }}
            label="Comment"
            placeholder="Enter comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            type="text"
          />
        </div>
        <div className={classes.buttons}>
          <CancelButton onClick={onClose} text={"Cancel"} />
          <SubmitButton onClick={handleUploadFile} text={heading} />
        </div>
      </div>
    </BackdropOverlayModal>
  );
};

export default CustomUpload;
