import { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";


import classes from "./CleanBackdropOverlayModal.module.css";

const Backdrop = (props) => {
  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.key === "Escape") {
        props.onClick();
      }
    };
    document.addEventListener("keydown", closeOnEsc);
    return () => {
      document.removeEventListener("keydown", closeOnEsc);
    };
  }, [props]);

  return <div className={classes.backdrop} onClick={props.onClick} />;
  
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes["content-modal"]}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const CleanBackdropOverlayModal = (props) => {
  const { onClick } = props;
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClick={onClick} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default CleanBackdropOverlayModal;
