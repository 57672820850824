import axios from "axios";
import { editSubscriptionPolicyApi } from "./apiEndpoints";

export const editSubscriptionPolicy = async (policyId, payload) => {
  let data = null;

  await axios
    .patch(`${editSubscriptionPolicyApi}`, {
      ...payload,
      subscriptionId: policyId,
    })
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
