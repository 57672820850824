import { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";
import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../../ui/CustomDataGrid";
import CustomPagination from "../../ui/CustomPagination";
import CustomNoRowMessage from "../../ui/CustomNoRowMessage";
import { ExpiryDateFormatter } from "../../helpers/utils";
import classes from "../../ui/DataTable.module.css";
import styles from "./MDDFeatureEnabledList.module.css";
import { NO_ROW_MESSAGES } from "../../../constants/no-row-message-constants";

const MDDfeatureEnabledList = ({
  data,
  isLoading,
  tableTitle,
  versionTitle,
  shouldHideTitle,
}) => {
  const categoryColumns = [
    {
      field: "displayName",
      headerName: "Metrics",
      flex: 2,
      sortable: false,
    },
    {
      field: "reportingRate",
      headerName: "Reporting Rate",
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={shouldHideTitle ? styles.hiddenHeader : styles.header}>
          {tableTitle}
        </span>
        <span className={styles.header}>{versionTitle}</span>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "0.8rem",
                wordBreak: "break-word",
              },
            }}
            columns={categoryColumns}
            // getRowHeight={() => "auto"}
            rows={shouldHideTitle && isLoading ? [] : data}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              panel: {
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: {
                isLoading,
                text: NO_ROW_MESSAGES.metricsDefinition,
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MDDfeatureEnabledList;
