export const FEATURE_REPO_TYPES = {
  model: "MODELS",
  container: "CONTAINERS",
  collections: "COLLECTIONS",
};
export const REPO_TYPE = {
  local: "Local",
  global: "Global",
};
export const REPO_STATUS = {
  published: "PUBLISHED",
  validated: "VALIDATED",
  addToGlobal: "ADDED_TO_GLOBAL_REGISTRY",
  denied: "DENIED",
};
