import { Suspense, lazy } from "react";
import { LinearProgress } from "@mui/material";
// import OrganizationsList from "../components/organizations/OrganizationsList";
const OrganizationsList = lazy(() => import("../components/organizations/OrganizationsList"));

const OrganizationPage = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <OrganizationsList />
    </Suspense>
  );
};

export default OrganizationPage;
