import axios from "axios";
import { updateFeatureVersionApi } from "./apiEndpoints";

//  featureVersionId: options.featureVersionId,
//     featureVersionStatus: options.featureVersionStatus,
//     createdBy: options.createdBy,
//     comment: options.comment,
//     oldFeatureVersionStatus: options.oldFeatureVersionStatus,

export const updateFeatureVersion = async (options, attachment = null) => {
  let data = null;
  const headers = new Headers();
  headers.append("Content-Type", "multipart/form-data");
  const formdata = new FormData();
  formdata.append("featureVersionAttachment", attachment);

  const requestOptions = {
    headers: headers,
    body: formdata,
    redirect: "follow",
  };
  const params = new URLSearchParams({
    featureVersionId: options.featureVersionId,
    featureVersionStatus: options.featureVersionStatus,
    createdBy: options.createdBy,
    comment: options.comment,
    oldFeatureVersionStatus: options.oldFeatureVersionStatus,
  });
  // params.append("comment", "[]");

  await axios
    .patch(
      `${updateFeatureVersionApi}/${options.featureName}`,

      formdata,

      { params: params },

      requestOptions
    )

    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.error(error.response);
      data = error.response.data;
      data.status = false;
    });
  return data;
};
