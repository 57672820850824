import axios from "axios";
import KeycloakConfig from "./keycloak-config";
import { AXIOS_GLOBAL_HEADERS } from "../constants/axios-constants";

const config = () => {
  axios.defaults.baseURL = AXIOS_GLOBAL_HEADERS.baseURL;
  axios.defaults.headers.post[AXIOS_GLOBAL_HEADERS.contentTypeKey] =
    AXIOS_GLOBAL_HEADERS.contentTypeValue;
  axios.interceptors.request.use((config) => {
    if (KeycloakConfig.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${KeycloakConfig.getToken()}`;
        return Promise.resolve(config);
      };
      return KeycloakConfig.updateToken(cb);
    }
  });
};

const AxiosConfig = {
  config,
};

export default AxiosConfig;
