import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";

import { getUserInfoByUserName } from "../../../api/api-index";
import KeycloakConfig from "../../../config/keycloak-config";
import { GET_MESSAGES } from "../../../constants/message-constants";
import { fetchOrganizationInfo } from "../../../store/actions/organizations-actions";
import { userActions } from "../../../store/slices/user-slice";
import errorHandler from "../../helpers/error-helper";
import PersonalInfoItem from "./PersonalInfoItem";
import EditUser from "../EditUser/EditUser";
import EditIconButton from "../../ui/Buttons/EditIconButton";
import { getInitials } from "../../helpers/utils";

import classes from "./PersonalInfo.module.css";

const PersonalInfo = () => {
  const [openEditUser, setOpenEditUser] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const organizationInfo = useSelector(
    (state) => state.organizationsInfo.selectedOrganization
  );
  const abbrev = userInfo
    ? getInitials(userInfo.fullName)
    : KeycloakConfig.userAbbreviation();
  const handleOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const closeOpenEditUser = () => {
    setOpenEditUser(false);
  };
  useEffect(() => {
    const options = {
      userName: KeycloakConfig.getUserId(),
    };
    getUserInfoByUserName(options)
      .then((response) => {
        if (response.status) {
          dispatch(userActions.setUserInfoState(response.data));

          const options = {
            organizationId: response?.data?.organizationId,
          };
          dispatch(fetchOrganizationInfo(options));
        } else {
          errorHandler(response.error, GET_MESSAGES.failure);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [openEditUser]);

  const userDetails =
    openEditUser && userInfo != null
      ? {
          userId: userInfo?.userId,
          firstName: userInfo?.firstName,
          lastName: userInfo?.lastName,
          emailId: userInfo?.emailId,
          phoneNo: userInfo?.phoneNo,
          keycloakId: userInfo?.keycloakUserId,
          noBackdrop: true,
        }
      : null;
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  return openEditUser ? (
    <div className={classes.container}>
      <EditUser onClose={closeOpenEditUser} userDetails={userDetails} />
    </div>
  ) : (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          <span>Personal Details</span>
          <EditIconButton onClick={handleOpenEditUser} size="small" />
        </div>
        <div className={classes.content}>
          <div className={classes.details}>
            <PersonalInfoItem field="First Name" value={userInfo?.firstName} />
            <PersonalInfoItem field="Last Name" value={userInfo?.lastName} />
            <PersonalInfoItem field="Email" value={userInfo?.emailId} />
            <PersonalInfoItem field="Phone Number" value={userInfo?.phoneNo} />
            <PersonalInfoItem
              field="Organization"
              value={organizationInfo?.name}
            />
          </div>
          <div className={classes.avatar}>
            <Avatar
              alt={abbrev}
              src="/static/images/avatar/1.jpg"
              sx={{
                width: 70,
                height: 70,
                fontFamily: "QualcommNext-Regular",
                bgcolor: stringToColor(abbrev),
              }}
            >
              {abbrev}
            </Avatar>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PersonalInfo;
