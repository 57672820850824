import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PAGE_SIZE } from "../../constants/page-constants";

import classes from "./CustomPagination.module.css";

const MUICustomPagination = styled(Pagination)(() => ({
  ".MuiPagination-ul": {
    background: "#ffffff",
    border: "0.3px solid #f0f0f0",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "0.5em 0",
  },
  ".MuiPaginationItem-root": {
    color: "#626262",
    fontSize: "clamp(14px, 100%, 100%)",
    textAlign: "center",
    fontFamily: "QualcommNext-Regular",
    fontWeight: "600",
  },
  ".MuiPagination-ul li button svg": {
    background: "#f0f0f0",
    borderRadius: "4px",
  },
  ".MuiPaginationItem-textPrimary.Mui-selected,.MuiPaginationItem-textPrimary.Mui-selected:hover":
    {
      color: "#fff",
      backgroundColor: "#4D6994",
    },
  ".MuiPaginationItem-sizeSmall .MuiPaginationItem-icon": {
    fontsSize: "2.286em",
  },
  ".MuiPaginationItem-sizeSmall": {
    margin: "0 5px",
  },
}));

const CustomPagination = (props) => {
  const { total, page, handlePageChange, dynamicName = "Records" } = props;
  return (
    <div className={classes.tab}>
      Total {dynamicName} - {total}
      <MUICustomPagination
        color="primary"
        shape="rounded"
        count={Math.ceil(total / PAGE_SIZE)}
        page={page + 1}
        onChange={handlePageChange}
        // sx={paginationStyles}
      />
    </div>
  );
};

export default CustomPagination;
