import { useEffect, Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, LinearProgress } from "@mui/material";

import ClientIDAndSecret from "./CreateNewTenant/ClientIDAndSecret";
import EditTenant from "./EditTenant/EditTenant";
import { getTenantsById } from "../../api/api-index";
import errorHandler from "../helpers/error-helper";
import { GET_MESSAGES } from "../../constants/message-constants";
import { ROLES } from "../../constants/role-constants";
import { hasPermittedRolesOrAttributes } from "../helpers/user-roles-and-attributes";

import classes from "../user/UserSettings/UserSettings.module.css";
import EditIconButton from "../ui/Buttons/EditIconButton";

let selectedRow = null;
const TenantDetails = ({ selectedAppType }) => {
  const [loading, setloading] = useState(true);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [openEditTenant, setOpenEditTenant] = useState(false);
  const selectedAppKey = useSelector((state) => state.userInfo.selectedAppKey);

  const handleEdit = () => {
    selectedRow = {
      tenantId: tenantDetails.tenantId,
      tenantName: tenantDetails.tenantName,
      organizationId: tenantDetails.organizationId,
      organization: tenantDetails.organization,
      tenantType: selectedAppType,
      isQualcommManagingDevice: tenantDetails.isQualcommManagingDevice,
      noBackdrop: true,
    };

    setOpenEditTenant(true);
  };

  const closeEditTenant = () => {
    selectedRow = null;
    setOpenEditTenant(false);
  };
  useEffect(() => {
    const options = {
      tenantId: selectedAppKey,
    };
    getTenantsById(options)
      .then((response) => {
        if (response.status && response.data != null) {
          const formattedData = {
            ...response.data,
            clientIdSecret: response.data.clientIdSecret && {
              clientId: response.data.clientIdSecret.clientId,
              client: response.data.clientIdSecret.client,
              secret: response.data.clientIdSecret.clientSecret,
            },
          };
          setTenantDetails(formattedData);
          setloading(false);
        }
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        errorHandler(error, GET_MESSAGES.failure);
      });
  }, [openEditTenant]);

  return loading ? (
    <div className={classes.loader}>
      <LinearProgress />
    </div>
  ) : (
    <Fragment>
      {openEditTenant ? (
        <div className={classes.editContainer}>
          <EditTenant onClose={closeEditTenant} tenantDetails={selectedRow} />
        </div>
      ) : (
        <div className={classes.bgContainer}>
          <div className={classes.tenantHeaders}>
            Tenant Details
            {hasPermittedRolesOrAttributes([
              ROLES.customerAdmin,
              ROLES.isvAdmin,
            ]) && <EditIconButton onClick={handleEdit} size="small" />}
          </div>

          <table className={classes.settingsTable}>
            <tr>
              <th>Tenant Type :</th>
              <td>{selectedAppType}</td>
            </tr>
            <tr>
              <th>Tenant Name :</th>
              <td>{tenantDetails?.tenantName}</td>
            </tr>
            {selectedAppType === "CUSTOMER" && (
              <tr>
                <th>Devices Managed by Qualcomm :</th>
                <td>
                  {tenantDetails?.isQualcommManagingDevice ? "Yes" : "No"}
                </td>
              </tr>
            )}
          </table>
        </div>
      )}

      {selectedAppType === "CUSTOMER" && tenantDetails?.clientIdSecret && (
        <>
          <div className={classes.bgContainer}>
            <ClientIDAndSecret
              heading={false}
              details={tenantDetails?.clientIdSecret}
            />
          </div>
        </>
      )}
    </Fragment>
  );
};
export default TenantDetails;
