import * as yup from "yup";
const errorMessages = {
  firstName: {
    required: "First name is required",
    matches: "Only alphabets are allowed.",
  },
  lastName: {
    required: "Last name is required",
    matches: "Only alphabets are allowed.",
  },
  emailId: {
    required: "Email is required",
    matches: "Enter valid email",
  },
  phoneNo: {
    required: "Phone number is required",
    min: "Phone number must be atleast 10 digits",
    matches: "Phone number format is incorrect",
  },
};

export const schema = yup
  .object({
    firstName: yup
      .string()
      .required(errorMessages.firstName.required)
      .matches(/^[a-zA-Z][ a-zA-Z]+$/g, errorMessages.firstName.matches),
    lastName: yup
      .string()
      .required(errorMessages.lastName.required)
      .matches(/^[a-zA-Z]*[ a-zA-Z]+$/g, errorMessages.lastName.matches),
    emailId: yup
      .string()
      .required(errorMessages.emailId.required)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorMessages.emailId.matches
      ),
    phoneNo: yup
      .string()
      .matches(/^[+]*[0-9]+$/g, errorMessages.phoneNo.matches)
      .test("len", "Must be in range of 10 to 13 digits", (val) => {
        return val.match(/[^+]/g).length <= 13 &&
          val.match(/[^+]/g).length >= 10
          ? true
          : false;
      })
      .required(errorMessages.phoneNo.required)
      .typeError(errorMessages.phoneNo.required),
  })
  .required();
