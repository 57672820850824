import { useLocation } from "react-router-dom";
import AppBar from "../components/appbar/AppBar";
import CatalogAppBar from "../components/catalogAppBar/CatalogAppBar";
import MenuBar from "../components/menu/MenuBar";
import CatalogMenuBar from "../components/catalogMenu/CatalogMenuBar";
import KeycloakConfig from "../config/keycloak-config";
import { COMMON_ROUTES } from "../constants/route-constants";
import classes from "./BaseLayout.module.css";
const BaseLayout = (props) => {
  // const [AppBar, MenuBar, ComponentPage] = props.children;
  const location = useLocation();
  const backgroundImg =
    location.pathname === COMMON_ROUTES.userSettings ||
    location.pathname === "/";
  return (
    KeycloakConfig.isLoggedIn() && (
      <div className={classes.container}>
        <div className={classes.appbar}>
          {location.pathname.includes("/catalog") ? (
            <CatalogAppBar />
          ) : (
            <AppBar />
          )}
        </div>
        {location.pathname.includes("/catalog") ? (
          <div className={classes.content}>{props.children}</div>
        ) : (
          <div className={classes.content}>
            <div>
              <MenuBar />
            </div>
            {backgroundImg ? (
              <div className={classes.background}>{props.children}</div>
            ) : (
              <div className={classes.page}>{props.children}</div>
            )}
          </div>
        )}
      </div>
    )
  );
};
export default BaseLayout;
