import * as yup from "yup";

const errorMessages = {
  password: {
    required: "New password is required",
    matches:
      "Must contain at least 8 characters including a minimum of 1 special character, 1 number, and mix of uppercase and lowercase letters",
  },
  confirmPassword: {
    required: "Confirm new password is required",
    matches: "Passwords must match",
  },
};
export const schema = yup
  .object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        errorMessages.password.matches
      )
      .required(errorMessages.password.required),
    confirmPassword: yup
      .string()
      .required(errorMessages.confirmPassword.required)
      .oneOf([yup.ref("password")], errorMessages.confirmPassword.matches),
  })
  .required();
