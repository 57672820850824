import { getCatalogInfoApi } from "./apiEndpoints";
import axios from "axios";
//name of the function and filename should be same
export const getCatalogInfo = async (featureName) => {
  //api call for get request
  let data;
  await axios
    .get(`${getCatalogInfoApi}/${featureName}`)
    .then((response) => {
      data = response.data;
    })
    .catch((error) => {
      console.log(error.response);
      data = error.response;
      data.status = false;
    });
  return data;
};
