import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const CloseIcon = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      aria-label="cancel"
      size="large"
      onClick={onClick != null ? onClick : null}
    >
      <ClearIcon />
    </IconButton>
  );
};

export default CloseIcon;
