import { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";

import {
  panelStyles,
  columnMenuStyles,
  CustomDataGrid,
} from "../ui/CustomDataGrid";
import CustomPagination from "../ui/CustomPagination";
import CustomNoRowMessage from "../ui/CustomNoRowMessage";
import { ExpiryDateFormatter, output } from "../helpers/utils";

import classes from "../ui/DataTable.module.css";
import styles from "./DeviceFeatureEnabledList.module.css";
import { NO_ROW_MESSAGES } from "../../constants/no-row-message-constants";

const DeviceFeatureEnabledList = ({ featureData, isLoading }) => {
  const featureDataList = featureData.map((item) => ({
    ...item,
    id: uuidv4(),
    featureTitle: item?.featureTitle,
    expirationDate: ExpiryDateFormatter(item?.expirationTime) || "-",
    licenseInstallation: item?.firstDownloadTime,
    status: item.status,
  }));

  const [pageNo, setPageNo] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPageNo(newPage - 1);
  };

  const columns = [
    {
      field: "featureTitle",
      headerName: "Feature Title",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "licenseInstallation",
      headerName: "License Installation",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => output[params.row.status] || "-",
    },
  ];

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={styles.header}>Features Enabled And Installed</span>
        <div className={classes.data}>
          <CustomDataGrid
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "default",
              },
            }}
            columns={columns}
            rows={featureDataList || []}
            disableColumnFilter
            disableColumnMenu
            hideFooterSelectedRowCount
            scrollbarSize={17}
            getCellClassName={(params) => {
              if (params.field === "id") {
                return "id";
              }
              return "general";
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowMessage,
            }}
            componentsProps={{
              pagination: {
                total: featureDataList.length || 0,
                handlePageChange: handlePageChange,
                page: pageNo,
              },

              panel: {
                sx: panelStyles,
              },
              columnMenu: {
                sx: columnMenuStyles,
              },

              noRowsOverlay: { isLoading, text: NO_ROW_MESSAGES.features },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DeviceFeatureEnabledList;
